import React, { createContext, useContext, useEffect, useState } from "react";
import { useGetContributor, useGetTags } from "src/queries/PeopleSection/api";
import SafeContext from "./SafeContext";

export const MassPayoutContext = createContext({});

const MassPayoutContextProvider = ({ children }) => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [selectedTags, setSelectedTags] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [tagInfocus, setTagInFocus] = useState();
    const [peopleInTable, setPeopleInTable] = useState([]);
    const [peopleByTag, setPeopleByTag] = useState({});
    const [selectedPeopleByTag, setSelectedPeopleByTag] = useState({});
    const [payoutQueueByTag, setPayoutQueueByTag] = useState({});
    const [isReviewPayout, setIsReviewPayout] = useState(false);
    const { currentSafeAddress }: any = useContext(SafeContext);
    const [selectedData, setSelectedData] = useState<any>({
        token: {},
    });
    const [fiatConversion, setFiatConversion] = useState(0);
    const [fiatValue, setFiatValue] = useState(0);
    const [isEligibleToTx, setIsEligibleToTx] = useState(true);
    const contributorsQuery = useGetContributor(
        currentSafeAddress,
        searchString,
        selectedTags,
        100000,
    );

    const [payable, setPayable] = useState(0);
    // const tagsQuery = useGetTags(currentSafeAddress, "");

    // On selecting tags, put the first one in focus
    useEffect(() => {
        if (selectedTags.length > 0) {
            setTagInFocus(selectedTags[0]);
        }
    }, [selectedTags]);

    useEffect(() => {
        contributorsQuery.refetch();
    }, [searchString, selectedTags]);

    useEffect(() => {
        if (contributorsQuery.isSuccess) {
            let contributors = contributorsQuery.data;
            let contributorObj = {};
            contributors?.contributors.forEach(cont => {
                let { tags } = cont;
                tags.forEach(({ tagName }) => {
                    if (contributorObj[tagName]) {
                        contributorObj[tagName].push(cont);
                    } else {
                        contributorObj[tagName] = [cont];
                    }
                });
            });
            setPeopleByTag(contributorObj);
        }
    }, [contributorsQuery.data]);

    // when focus shifts to a tag, show relevant people in table
    useEffect(() => {
        if (tagInfocus) {
            setPeopleInTable(peopleByTag[tagInfocus] || []);
        } else {
            setPeopleInTable([]);
        }
    }, [tagInfocus, peopleByTag]);
    const resetPayout = () => {
        setTagInFocus(null);
        setPeopleInTable([]);
        setPeopleByTag({});
        setSelectedPeopleByTag({});
        setPayoutQueueByTag({});
        setIsReviewPayout(false);
        setSelectedTags([]);
        setIsEligibleToTx(true);
    };
    return (
        <MassPayoutContext.Provider
            value={{
                isCollapsed,
                setIsCollapsed,
                selectedTags,
                setSelectedTags,
                searchString,
                setSearchString,
                tagInfocus,
                setTagInFocus,
                selectedPeopleByTag,
                setSelectedPeopleByTag,
                peopleInTable,
                setPeopleInTable,
                peopleByTag,
                payoutQueueByTag,
                setPayoutQueueByTag,
                isReviewPayout,
                setIsReviewPayout,
                selectedData,
                setSelectedData,
                fiatConversion,
                setFiatConversion,
                fiatValue,
                setFiatValue,
                resetPayout,
                isEligibleToTx,
                setIsEligibleToTx,
                rawData: contributorsQuery,
                payable,
                setPayable,
            }}
        >
            {children}
        </MassPayoutContext.Provider>
    );
};

export default MassPayoutContextProvider;
