import React, { useEffect, useContext } from "react";
import { SelectedPeopleBlockStyled, ContentContainer } from "./styles/SelectedPeopleBlock";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import { Typography, Box } from "@mui/material";
import TagCollapse from "./SelectedPeople/TagCollapse";
import { LoadingButton } from "@mui/lab";
import TransferContext from "src/contexts/TransferContext";
const SelectedPeopleBlock = () => {
    const { payoutQueueByTag, setPayoutQueueByTag, setTagInFocus }: any =
        useContext(MassPayoutContext);
    const { setMassPayoutStep }: any = useContext(TransferContext);

    const deletePeople = (address, tag) => {
        let newRows = payoutQueueByTag[tag].filter(item => item.address !== address);
        if (newRows.length > 0) {
            setPayoutQueueByTag({
                ...payoutQueueByTag,
                [tag]: newRows,
            });
        } else {
            let newQueue = { ...payoutQueueByTag };
            delete newQueue[tag];
            setPayoutQueueByTag(newQueue);
        }
    };

    return (
        <SelectedPeopleBlockStyled>
            {Object.keys(payoutQueueByTag).length === 0 ? (
                <Box
                    height="100%"
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    mb={"50px"}
                >
                    <Typography color="#D6D5DF" fontSize="20px" fontWeight={600}>
                        Queue is Empty
                    </Typography>
                </Box>
            ) : (
                <>
                    <Box p="16px">
                        <Typography fontSize="14px" color="title.main" fontWeight={600}>
                            Queue List ({Object.keys(payoutQueueByTag).length})
                        </Typography>
                    </Box>
                    <ContentContainer>
                        {Object.keys(payoutQueueByTag).map(item => (
                            <TagCollapse
                                tag={item}
                                key={item}
                                people={payoutQueueByTag[item]}
                                deletePeople={deletePeople}
                            />
                        ))}
                    </ContentContainer>
                </>
            )}
            {Object.keys(payoutQueueByTag).length > 0 && (
                <Box p="20px">
                    <LoadingButton
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            setMassPayoutStep(2);
                        }}
                        style={{ fontWeight: 600 }}
                    >
                        Set Payout
                    </LoadingButton>
                </Box>
            )}
        </SelectedPeopleBlockStyled>
    );
};

export default SelectedPeopleBlock;
