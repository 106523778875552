import { request, gql } from "graphql-request";
import {
    ADD_CONTRIBUTOR,
    ADD_TAG_TO_CONTRIBUTOR,
    ADD_TAG_TO_SAFE,
    BULK_REMOVE_CONTRIBUTOR,
    EDIT_CONTRIBUTOR,
    GET_CONTRIBUTOR,
    GET_TAGS,
    REMOVE_CONTRIBUTOR,
    REMOVE_TAG_TO_CONTRIBUTOR,
    BULK_ADD_CONTRIBUTOR_WITH_TAGS,
} from "../constants";
import { useMutation, useQuery } from "react-query";
import { graphQLClient } from "../graphqlClient";
import { queryClient } from "pages/_app";

const baseURI = `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`;

export function useGetContributor(
    safeAddress,
    searchString,
    selectedTags,
    limit = 8,
    offset = 0,
    options = {},
) {
    return useQuery(
        [GET_CONTRIBUTOR, safeAddress, searchString, selectedTags, limit, offset],
        async () => {
            if (safeAddress) {
                const { searchContributors } = await graphQLClient(baseURI, safeAddress).request(
                    gql`
                        query SearchContributors(
                            $safeAddress: String
                            $searchString: String
                            $selectedTags: [String]
                            $limit: Int
                            $offset: Int
                        ) {
                            searchContributors(
                                safeAddress: $safeAddress
                                searchString: $searchString
                                selectedTags: $selectedTags
                                limit: $limit
                                offset: $offset
                            ) {
                                count
                                total
                                contributors {
                                    address
                                    name
                                    nickName
                                    ens
                                    tags {
                                        tagName
                                        id
                                    }
                                }
                            }
                        }
                    `,
                    { safeAddress, searchString, selectedTags, limit, offset },
                );
                return searchContributors;
            } else {
                return;
            }
        },
        options,
    );
}

export function useGetTags(safeAddress, searchString) {
    return useQuery([GET_TAGS, safeAddress, searchString], async () => {
        const { searchTags } = await graphQLClient(baseURI, safeAddress).request(
            gql`
                query SearchTags(
                    # $safeAddress: String!
                    $searchString: String!
                ) {
                    searchTags(
                        # safeAddress: $safeAddress
                        searchString: $searchString
                    ) {
                        tagName
                        id
                    }
                }
            `,
            { safeAddress, searchString },
        );
        return searchTags;
    });
}

export function useAddContributor() {
    const mutation = useMutation(
        async ({ safeAddress, address, nickName, ens }: any) => {
            // console.log(nickName, "nickName");
            const { addWalletAsContributor } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation AddContributor(
                        # $safeAddress: String!
                        $address: String!
                        $nickName: String!
                        $ens: String
                    ) {
                        addWalletAsContributor(
                            # safeAddress: $safeAddress
                            address: $address
                            nickName: $nickName # ens: $ens
                            ens: $ens
                        ) {
                            safeAddress
                            nickName
                        }
                    }
                `,
                { safeAddress, address, nickName, ens },
            );
            return addWalletAsContributor;
        },
        {
            mutationKey: ADD_CONTRIBUTOR,
        },
    );
    return mutation;
}

export function useEditContributor() {
    const mutation = useMutation(
        async ({ safeAddress, address, nickName }: any) => {
            const { editContributor } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation EditContributor(
                        # $safeAddress: String!
                        $address: String!
                        $nickName: String
                    ) {
                        editContributor(
                            # safeAddress: $safeAddress
                            address: $address
                            nickName: $nickName
                        ) {
                            safeAddress
                        }
                    }
                `,
                { safeAddress, address, nickName },
            );
            return editContributor;
        },
        {
            mutationKey: EDIT_CONTRIBUTOR,
        },
    );
    return mutation;
}

export function useRemoveContributor() {
    const mutation = useMutation(
        async ({ safeAddress, address, nickName }: any) => {
            const { removeWalletAsContributor } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation RemoveWalletAsContributor(
                        # $safeAddress: String!
                        $address: String!
                    ) {
                        removeWalletAsContributor(
                            # safeAddress: $safeAddress
                            address: $address
                        ) {
                            safeAddress
                        }
                    }
                `,
                { safeAddress, address },
            );
            return removeWalletAsContributor;
        },
        {
            mutationKey: REMOVE_CONTRIBUTOR,
        },
    );
    return mutation;
}
export function useAddTagToContributor() {
    const mutation = useMutation(
        async ({ safeAddress, address, tagName }: any) => {
            const { addTagToContributor } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation AddTagToContributor(
                        # $safeAddress: String!
                        $address: String!
                        $tagName: String!
                    ) {
                        addTagToContributor(
                            # safeAddress: $safeAddress
                            address: $address
                            tagName: $tagName
                        ) {
                            safeAddress
                        }
                    }
                `,
                { safeAddress, address, tagName },
            );
            return addTagToContributor;
        },
        {
            mutationKey: ADD_TAG_TO_CONTRIBUTOR,
        },
    );
    return mutation;
}

export function useRemoveTagToContributor() {
    const mutation = useMutation(
        async ({ safeAddress, address, tagName }: any) => {
            const { addTagToContributor } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation RemoveTagFromContributor(
                        # $safeAddress: String!
                        $address: String!
                        $tagName: String!
                    ) {
                        removeTagFromContributor(
                            # safeAddress: $safeAddress
                            address: $address
                            tagName: $tagName
                        ) {
                            safeAddress
                        }
                    }
                `,
                { safeAddress, address, tagName },
            );
            return addTagToContributor;
        },
        {
            mutationKey: REMOVE_TAG_TO_CONTRIBUTOR,
        },
    );
    return mutation;
}

export function useAddTag() {
    const mutation = useMutation(
        async ({ safeAddress, tagName }: any) => {
            const { addTagToContributor } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation AddTag($tagParams: TagParams) {
                        addTag(tagParams: $tagParams) {
                            tagName
                        }
                    }
                `,
                {
                    tagParams: {
                        tagName,
                    },
                },
            );
            return addTagToContributor;
        },
        {
            mutationKey: ADD_TAG_TO_SAFE,
        },
    );
    return mutation;
}

export function usebulkAddContributorWithTags() {
    return useMutation(
        async ({ bulkAddParams, safeAddress }: any) => {
            const { bulkAddContributor } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation BulkAddContributor($bulkAddParams: [ContributorsWithTags]) {
                        bulkAddContributor(bulkAddParams: $bulkAddParams) {
                            status
                        }
                    }
                `,
                {
                    bulkAddParams,
                },
            );
            return bulkAddContributor;
        },
        {
            mutationKey: BULK_ADD_CONTRIBUTOR_WITH_TAGS,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CONTRIBUTOR);
                queryClient.invalidateQueries(GET_TAGS);
            },
        },
    );
}

export function useBulkRemoveContributors() {
    const mutation = useMutation(
        async ({ safeAddress, addresses }: any) => {
            const { status } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation BulkRemoveContributors($addresses: [String]) {
                        bulkRemoveContributors(wallets: $addresses) {
                            status
                        }
                    }
                `,
                {
                    addresses,
                },
            );
            return status;
        },
        {
            mutationKey: BULK_REMOVE_CONTRIBUTOR,
        },
    );
    return mutation;
}
