import { Button, Checkbox, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
    CSVTableBody,
    CSVTableHeader,
    ErrorContainer,
    WrapperCSVTable,
    Row,
    CSVTableAction,
} from "./styles";

import NormalRow from "./NormalRow";
import ErrorRow from "./ErrorRow";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { formatAmount } from "src/helpers/utils/web3Utils";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

export default function PayoutTable({
    fileName,
    data,
    setActiveTab,
    tokensInSafe,
    safeTokenBalancesByTokenName,
    setProcessedData,
    csvData,
    setCsvData,
    setBalances,
    tags,
}) {
    const [action, setAction] = useState("");
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [open, setOpen] = useState(false);

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        const dataToRemove = [...csvData];
        selectedRows
            .sort((a, b) => b - a)
            .map(index => {
                dataToRemove.splice(index, 1);
            });
        setCsvData(dataToRemove);
        setOpen(false);
        setSelectedRows([]);
    };

    const handleRemoveRow = (item, i) => {
        const newData = csvData.filter((_, index) => index !== i);

        setCsvData(newData);

        setSelectedRows([]);
    };

    const handleSelectPeople = (e, data, index) => {
        let rows = [...selectedRows];

        if (e.target.checked) {
            rows.push(index);
        } else if (!e.target.checked) {
            rows = rows.filter(item => item !== index);
        }

        setSelectedRows(rows);
    };

    useEffect(() => {
        if (selectedRows.length) {
            setAction("delete");
        } else {
            setAction("");
        }
    }, [selectedRows]);

    return (
        <WrapperCSVTable>
            <CSVTableHeader>
                {action === "error" ? (
                    <Typography></Typography>
                ) : (
                    <Typography fontSize={16} fontWeight={600} color={"#232427"}>
                        {fileName}
                    </Typography>
                )}
                {action === "error" ? <ErrorContainer></ErrorContainer> : null}
            </CSVTableHeader>
            <CSVTableBody>
                <Row>
                    <Box>
                        <Checkbox
                            onChange={e => {
                                if (e.target.checked) {
                                    setSelectedRows(
                                        Array.from({ length: csvData?.length }, (v, i) => i),
                                    );
                                } else {
                                    setSelectedRows([]);
                                }
                            }}
                            checked={
                                csvData?.length > 0 && selectedRows?.length === csvData?.length
                            }
                            indeterminate={
                                csvData?.length > 0 &&
                                selectedRows?.length > 0 &&
                                selectedRows?.length < csvData?.length
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            disabled={!(csvData?.length > 0)}
                            sx={{
                                color: "#C9CCD3",
                                "&.Mui-checked": {
                                    color: "#2962EF",
                                },
                                "& .MuiSvgIcon-root": { fontSize: 25 },
                            }}
                        ></Checkbox>
                    </Box>
                    {action === "delete" ? (
                        <>
                            <Box>
                                <Typography color="#232427" fontWeight={600}>
                                    {selectedRows.length === 1
                                        ? `1 person`
                                        : `${selectedRows.length} people`}{" "}
                                    selected
                                </Typography>
                            </Box>
                            <Box></Box>
                            <Box></Box>
                            <Box style={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button
                                    startIcon={<DeleteOutlineOutlinedIcon />}
                                    onClick={() => setOpen(true)}
                                    variant="outlined"
                                    color="error"
                                >
                                    Delete
                                </Button>
                            </Box>
                            <Box></Box>
                        </>
                    ) : (
                        <>
                            <Box className="table-header">NAME</Box>
                            <Box className="table-header">TOKEN AMOUNT</Box>
                            <Box className="table-header">CURRENT PRICE</Box>
                            <Box className="table-header">TOTAL IN USD</Box>
                            <Box className="table-header">TAGS</Box>
                        </>
                    )}
                </Row>
                <Box className="scrollable-view">
                    {csvData?.map((item, index) =>
                        item.isErrorAtRow ? (
                            <ErrorRow
                                handleSelectPeople={handleSelectPeople}
                                handleClick={handleClick}
                                selectedRows={selectedRows}
                                item={item}
                                tokensInSafe={tokensInSafe}
                                safeTokenBalancesByTokenName={safeTokenBalancesByTokenName}
                                index={index}
                                data={csvData}
                                setCsvData={setCsvData}
                                setBalances={setBalances}
                                tags={tags}
                                handleRemoveRow={handleRemoveRow}
                            ></ErrorRow>
                        ) : (
                            <NormalRow
                                handleSelectPeople={handleSelectPeople}
                                handleClick={handleClick}
                                selectedRows={selectedRows}
                                item={item}
                                index={index}
                            ></NormalRow>
                        ),
                    )}
                    <Row
                        sx={{
                            border: "none !important",
                        }}
                    >
                        <Box></Box>
                        <Box></Box>
                        <Box></Box>
                        <Box></Box>
                        <Box>
                            <Typography
                                marginLeft={"8px"}
                                color={"#232427"}
                                fontWeight={700}
                                fontSize={16}
                                marginTop={"15px"}
                            >
                                {isNaN(
                                    csvData?.reduce((acc, curr) => (acc += curr.fiatValue ?? 0), 0),
                                )
                                    ? "--"
                                    : ` $${formatAmount(
                                          csvData?.reduce(
                                              (acc, curr) => (acc += curr.fiatValue ?? 0),
                                              0,
                                          ),
                                          2,
                                          2,
                                      )}`}
                            </Typography>
                        </Box>
                        <Box></Box>
                        <Box></Box>
                    </Row>
                </Box>
            </CSVTableBody>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    zIndex: 10000,
                }}
                fullWidth
            >
                <DialogTitle
                    style={{ color: "#25274F", fontSize: 18, fontWeight: 600 }}
                    id="alert-dialog-title"
                >
                    {"Delete Confirmation"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        style={{ color: "#636B81", fontSize: 16, fontWeight: 400 }}
                        id="alert-dialog-description"
                    >
                        Are you sure you want to delete {selectedRows.length} row
                        {selectedRows.length > 1 ? "s" : ""}?
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    style={{
                        background: "#F5F8FE",
                        padding: 10,
                    }}
                >
                    <Button
                        variant="contained"
                        style={{
                            color: "#636B81",
                            background: "white",
                            border: "1px solid #DFE1E5",
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button variant="contained" color="primary" onClick={handleDelete} autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </WrapperCSVTable>
    );
}
