import styled from "@emotion/styled";
import { List } from "@mui/material";

export const DaoDetails: any = styled.div`
    display: flex;
    gap: 10px;
    letter-spacing: 0px;
    text-align: left;
    margin-bottom: 17px;

    font-style: normal;
    .flex-column {
        display: flex;
        flex-direction: column;
        width: 70%;
    }
    .flex-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 2px;
    }
    .dao-name {
        font-size: 14px;

        font-weight: 500;
        line-height: 18px;

        color: #25274f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .address {
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
        line-height: 16px;
        color: ${({ theme }: any) => theme.palette.title.light};
    }

    .edit-icon {
        margin-right: 3px;
        margin-bottom: 3px;
        height: 14px;
        width: 14px;
        color: ${({ theme }: any) => theme.palette.title.light};
        vertical-align: middle;
        cursor: pointer;
    }
    .drop-icon {
        height: fit-content;

        svg {
            cursor: pointer;
            vertical-align: middle;
        }
    }

    .balance {
        display: flex;
        justify-content: space-between;
    }
`;

export const DaoDashboardProfileBox: any = styled.div`
    margin-left: 14px;
    padding-bottom: 12px;
    margin-right: 14px;
    padding-top: 12px;
    margin-bottom: 40px;
    border-bottom: 1px #eff0f2 solid;
`;

export const NavLinkList = styled(List)`
    flex: 100%;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    align-content: flex-start;
    justify-content: flex-start;
    align-items: stretch;
    padding-top: 0;
`;

export const StyledNestedList = styled.div`
    padding: 0px;
    display: flex;
    justify-content: flex-start;
    padding-top: 8px;
    padding-right: 16px;
    padding-bottom: 8px;
    padding-left: 16px;
    cursor: pointer;
    .title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0px;
    }
    > div:nth-of-type(1) {
        margin-right: 10px;
        align-self: center;
    }
    > div:nth-of-type(3) {
        margin-left: auto;
        margin-top: -4px;
    }
    > div:nth-of-type(4) {
        margin-left: auto;
    }
`;
