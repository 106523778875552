import { init } from "@web3-onboard/react";
import injectedModule from "@web3-onboard/injected-wallets";
import trezorModule from "@web3-onboard/trezor";
import walletConnectModule, { WalletConnectOptions } from "@web3-onboard/walletconnect";
import { supportedChains } from "./utils/supportedNetworks";
import coinbaseWallet from "@web3-onboard/coinbase";

// const dappId = process.env.NEXT_PUBLIC_BLOCKNATIVE_API_KEY;

// export function initOnboard(subscriptions) {
//     return Onboard({
//         dappId,
//         hideBranding: false,
//         networkId,
//         // apiUrl,
//         // darkMode: true,
//         subscriptions,
//         walletSelect: {
//             wallets: [
//                 { walletName: "walletLink", rpcUrl: rpcUrl, appName: "Parcel", preferred: true },
//                 { walletName: "metamask" },
//                 {
//                     walletName: "trezor",
//                     appUrl: "https://reactdemo.blocknative.com",
//                     email: "aaron@blocknative.com",
//                     rpcUrl,
//                 },
//                 {
//                     walletName: "walletConnect",
//                     // infuraKey: process.env.NEXT_PUBLIC_INFURA_TOKEN,
//                     display: {
//                         desktop: true,
//                     },
//                     // as stated in the documentation, `infuraKey` is not mandatory if rpc is provided
//                     networkId,
//                     // bridge: 'https://safe-walletconnect.gnosis.io/',
//                     rpc: RPC_URLS,
//                 },
//                 {
//                     walletName: "ledger",
//                     rpcUrl,
//                 },
//                 { walletName: "coinbase" },
//                 {
//                     walletName: "fortmatic",
//                     apiKey: process.env.NEXT_PUBLIC_FORTMATIC_API_KEY || "pk_test_886ADCAB855632AA",
//                 },
//             ],
//         },
//         walletCheck: [
//             { checkName: "derivationPath" },
//             { checkName: "connect" },
//             { checkName: "accounts" },
//             { checkName: "network" },
//             { checkName: "balance", minimumBalance: "0" },
//         ],
//     });
// }

const apiKey = process.env.NEXT_PUBLIC_DAPP_ID;

const wcV2InitOptions: WalletConnectOptions = {
    version: 2,
    /**
     * Project ID associated with [WalletConnect account](https://cloud.walletconnect.com)
     */
    projectId: process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID,
    /**
     * Chains required to be supported by all wallets connecting to your DApp
     */
    // requiredChains: [1, 56],
};

const injected = injectedModule();

const walletConnect = walletConnectModule(wcV2InitOptions);

// const fortmatic = fortmaticModule({
//     apiKey: "pk_test_886ADCAB855632AA",
// });

const trezorOptions = {
    email: "test@test.com",
    appUrl: "https://www.blocknative.com",
};

const trezor = trezorModule(trezorOptions);

const coinbase = coinbaseWallet({
    darkMode: false,
});

export const initWeb3Onboard = init({
    wallets: [injected, trezor, walletConnect, coinbase],
    chains: [
        ...supportedChains.map(({ id, token, label, rpcUrl }) => {
            return {
                id,
                token,
                label,
                rpcUrl,
            };
        }),
    ],
    theme: {
        "--w3o-font-family": "'Metropolis' ,sans-serif",
    },

    apiKey: apiKey,
    appMetadata: {
        name: "Parcel",
        icon: "https://app.parcel.money/svg/parcel_logo.svg", // svg string icon
        logo: "https://app.parcel.money/svg/parcel_latest_logo.svg", // svg string logo
        description: "Parcel Payment OS for Contributor Economy",
        agreement: {
            version: "1.0",
            privacyUrl: "https://parcel-site-2-0-git-dev-parcelmoney.vercel.app/privacy.html",
            termsUrl: "https://parcel-site-2-0-git-dev-parcelmoney.vercel.app/terms.html",
        },
        explore: "https://parcel.money",
    },
    accountCenter: {
        desktop: {
            position: "topRight",
            enabled: false,
            minimal: false,
        },
        mobile: {
            position: "topRight",
            enabled: false,
            minimal: false,
        },
    },
});
