import { useMutation, useQuery } from "react-query";
import { GET_SAFE_DETAILS, SET_NEW_SAFE } from "../constants";
import { gql } from "graphql-request";
import fetcher from "../fetch";
import { graphQLClient } from "src/queries/graphqlClient";
const baseURI = `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`;
import { ethGasStationEndpoint, polygonGasStationEndpoint } from "src/constants/endpoints";
import {
    gnosisURL,
    GET_GNOSIS_SAFE_DETAILS,
    GET_TOKEN,
    GET_GNOSIS_SAFES,
    GET_PARCEL_SAFES,
    AUTH_TOKEN_CHECK,
} from "../constants";
import { networkId } from "src/helpers/utils/networks";
export function useCreateSafe() {
    return useMutation(
        async ({ safe }: any) => {
            const { AddSafe } = await graphQLClient(baseURI).request(
                gql`
                    mutation AddSafe($safe: SafeParams) {
                        addSafe(safe: $safe) {
                            safeAddress
                            networkId
                            isImported
                            owners {
                                address
                                name
                            }
                        }
                    }
                `,
                {
                    safe,
                },
            );
            return AddSafe;
        },
        {
            mutationKey: SET_NEW_SAFE,
        },
    );
}

export function useGetGnosisSafeDetails(walletAddress, options) {
    return useQuery(
        [GET_GNOSIS_SAFE_DETAILS, walletAddress],
        async () => await fetcher(`${gnosisURL}/safes/${walletAddress}`, {}),
        options,
    );
}

export function useGetGnosisSafes(walletAddress, options) {
    return useQuery(
        [GET_GNOSIS_SAFES, walletAddress],
        async () => await fetcher(`${gnosisURL}/owners/${walletAddress}/safes`, {}),
        options,
    );
}

export function useGetToken(
    signedMessage,
    walletAddress,
    networkId,
    isEnabled = true,
    isArgent,
    isGnosisWallet,
    message,
) {
    return useQuery(
        GET_TOKEN,
        async () =>
            await fetcher(`${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/login`, {
                method: "POST",
                body: JSON.stringify({
                    signedMessage,
                    walletAddress,
                    networkId,
                    isArgent,
                    isGnosisWallet,
                    message,
                }),
                headers: {
                    "content-type": "application/json",
                },
                credentials: "include",
                mode: "cors",
            }),
        {
            enabled: !!signedMessage && isEnabled,
        },
    );
}

export function useGetParcelSafes(address, options) {
    return useQuery(
        [GET_PARCEL_SAFES, address],
        async () => {
            const { me } = await graphQLClient(baseURI).request(
                gql`
                    query Owners {
                        me {
                            ownedSafes {
                                safeAddress
                                safeName
                                nickName
                                owners {
                                    address
                                    name
                                }
                                isImported
                            }
                            name
                            address
                            contributedSafes {
                                safeAddress
                                nickName
                                isImported
                                safeAccess {
                                    hasSafeAccess
                                    isTransactionCreationAllowed
                                }
                            }
                        }
                    }
                `,
            );
            return me;
        },
        options,
    );
}

export function useGetSafeDetails(safeAddress, options = {}) {
    return useQuery(
        GET_SAFE_DETAILS,
        async () => {
            if (safeAddress) {
                const { safe } = await graphQLClient(baseURI).request(
                    gql`
                        query Safe($safeAddress: String) {
                            safe(safeAddress: $safeAddress) {
                                threshold
                                networkId
                                isImported
                                owners {
                                    address
                                    name
                                    ens
                                    nickName
                                }
                            }
                        }
                    `,
                    { safeAddress },
                );
                return safe;
            } else {
                return;
            }
        },
        options,
    );
}

export function useCheckAuth(options) {
    return useQuery(
        AUTH_TOKEN_CHECK,
        async () => {
            const wallet = await graphQLClient(baseURI).rawRequest(
                gql`
                    query AuthCheck {
                        me {
                            ownedSafes {
                                safeAddress
                                safeName
                                nickName
                                owners {
                                    address
                                    name
                                }
                                isImported
                            }
                            name
                            address
                            contributedSafes {
                                safeAddress
                                nickName
                                isImported
                                safeName
                                safeAccess {
                                    hasSafeAccess
                                    isTransactionCreationAllowed
                                }
                            }
                        }
                    }
                `,
            );
            return wallet;

            // return new Promise((resolve, reject) => {
            //     const wallet = graphQLClient(baseURI).request(
            //         gql`
            //             query AuthCheck {
            //                 me {
            //                     ownedSafes {
            //                         safeAddress
            //                         safeName
            //                         owners {
            //                             address
            //                             name
            //                         }
            //                         isImported
            //                     }
            //                     name
            //                     address
            //                     contributedSafes {
            //                         safeAddress
            //                         nickName
            //                         isImported
            //                     }
            //                 }
            //             }
            //         `,
            //     );
            //     if (wallet) {
            //         resolve(wallet);
            //     } else {
            //         reject();
            //     }
            // })
            //     .then(wallet => wallet)
            //     .catch(err => console.log(err));
        },
        options,
    );
}

export function useDisconnect(options) {
    return useQuery(
        GET_TOKEN,
        async () =>
            await fetcher(`${process.env.NEXT_PUBLIC_BACKEND_URL}/auth/disconnect`, {
                method: "GET",
                headers: {
                    "content-type": "application/json",
                },
                credentials: "include",
                mode: "cors",
            }),
        options,
    );
}

export function useGetAverageGasPrice() {
    const options = {
        method: "GET",
        avoidAuthHeaders: true,
    };

    if (networkId === 137) {
        return useQuery(
            GET_GNOSIS_SAFE_DETAILS,
            async () => await fetcher(polygonGasStationEndpoint, options),
            { refetchOnWindowFocus: false },
        );
    }
    return useQuery(
        GET_GNOSIS_SAFE_DETAILS,
        async () => await fetcher(ethGasStationEndpoint, options),
        { refetchOnWindowFocus: false },
    );
}
