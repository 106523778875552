import * as React from "react";
import { Typography } from "@mui/material";
import { Button } from "@mui/material";
import NetworkError from "assets/network_error.svg";
import Image from "next/image";

import { StyledDialog } from "./styles";

const NetworkChangeModal = ({ showModal, handleNetworkSwtich, buttonText }) => {
    return (
        <StyledDialog onClose={() => {}} aria-labelledby="customized-dialog-title" open={showModal}>
            <div
                style={{
                    padding: "30px 60px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <div style={{ marginBottom: 30 }}>
                    <Image src={NetworkError} />
                </div>

                <Typography color={"#232427"} fontSize={18} fontWeight={800}>
                    Your wallet is on a different network!
                </Typography>

                <Typography color={"#5E5C6C"} fontSize={16} fontWeight={400}>
                    Switch network to continue using the app
                </Typography>
                <div style={{ marginTop: 25 }}>
                    <Button variant="contained" onClick={handleNetworkSwtich}>
                        {buttonText}
                    </Button>
                </div>
            </div>
        </StyledDialog>
    );
};

export default NetworkChangeModal;
