import React, { useContext, useState } from "react";
import { AddressText, NameAddressWrapper, NameText, ProfileWrapper, StampHolder } from "./style";
import CopyOnClick from "../common/Copy/CopyOnClick";
import { useGetContributor } from "src/queries/PeopleSection/api";
import SafeContext from "src/contexts/SafeContext";
import Skeleton from "@mui/material/Skeleton";

export default function NameAddress({ name, address, ens, style = {}, hideName = false }) {
    const [currentContributor, setCurrentContributor]: any = useState({});

    const minifyAddress = (address, middleChars = 4, endChars = 4) => {
        if (!address) return "";

        return `${address.substring(0, middleChars + 2)}...${address.substring(
            address.length - endChars,
        )}`;
    };

    const { currentSafeAddress }: any = useContext(SafeContext);
    const contributor = useGetContributor(currentSafeAddress, "", [], 1000000, 0, {
        enabled: !(name && name !== ""),
    });

    React.useEffect(() => {
        if (contributor.status == "success" && !(name && name !== "")) {
            const { contributors } = contributor.data || [];
            if (contributors) {
                const currentContributor = contributors?.find(_contributor => {
                    return _contributor?.address == address;
                });

                if (currentContributor) {
                    setCurrentContributor(currentContributor);
                }
            }
        }
    }, [contributor.status, name]);

    return (
        <ProfileWrapper style={{ ...style }}>
            <StampHolder>
                <img className="identicon" src={`https://cdn.stamp.fyi/avatar/${address}?s=37`} />
            </StampHolder>

            <NameAddressWrapper>
                {hideName ? (
                    <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                ) : (
                    <NameText>
                        {name && name !== "" ? (
                            name
                        ) : (
                            <>
                                {currentContributor.nickName
                                    ? currentContributor.nickName > 20
                                        ? `${currentContributor.nickName.substring(0, 10)}...`
                                        : currentContributor.nickName
                                    : "Unnamed"}
                            </>
                        )}
                    </NameText>
                )}

                <CopyOnClick value={ens ? ens : address} text="Click to Copy Contributor Address">
                    <AddressText>{ens ? ens : minifyAddress(address)}</AddressText>
                </CopyOnClick>
            </NameAddressWrapper>
        </ProfileWrapper>
    );
}
