import { createContext, useContext, useEffect, useState } from "react";
import {
    NATIVE_ICON_URL,
    nativeTokenName,
    nativeTokenSymbol,
    gnosisURL,
    ZERO_ADDRESS,
    FETCH_TOKEN_CONVERSION_ENDPOINT,
} from "src/queries/constants";
import {
    useGetChildSafesMetaData,
    useGetCurrentSafeDetails,
    useGetTokensAndPriceBySafe,
} from "src/queries/Safe/api";
import { useGetGnosisSafeDetails } from "src/queries/onboard/api";
import {
    useGetExecutedTransactionFromGnosis,
    useGetPendingTransactionFromGnosis,
} from "src/queries/Transaction/api";
import TransactionContext from "./TransactionContext";
import { getAmountFromWei } from "src/helpers/tx-helpers";
import { useActiveBaseContext, useContract } from "src/hooks";
import { TokenConversionFromGnosis } from "src/queries/ContributorRequest/api";
import Safe from "@safe-global/protocol-kit";
import fetcher from "src/queries/fetch";
import { queryClient } from "pages/_app";
import { isArray } from "lodash";
import { useIsSafeWhitelistedOnPayroll } from "src/queries/parcelPayroll";
import { getProxyFactoryContractAddress } from "src/constants/payroll";
import { networkId } from "src/helpers/utils/networks";
import PayrollProxyFactoryABI from "src/constants/abis/PayrollProxyFactory.json";
import { getTokenContractAddress } from "mapping/tokenAddress";
import Decimal from "decimal.js-light";
import { weiToString } from "src/helpers/bignumberUtils";

const SafeContext = createContext({});

export const SafeContextProvider = ({ children }) => {
    const [currentSafeAddress, setCurrentSafeAddress] = useState();

    const [tokensInSafe, setTokensInSafe] = useState({
        [nativeTokenSymbol]: {
            name: nativeTokenName,
            symbol: nativeTokenSymbol,
            logoUri: NATIVE_ICON_URL,
            decimals: 18,
            tokenAddress: nativeTokenSymbol,
            fiatConversion: 1,
        },
    });

    const [tokensInSafeForSettings, setTokensInSafeForSettings] = useState({
        [nativeTokenSymbol]: {
            name: nativeTokenName,
            symbol: nativeTokenSymbol,
            logoUri: NATIVE_ICON_URL,
            decimals: 18,
            tokenAddress: nativeTokenSymbol,
            fiatConversion: 1,
        },
    });

    const [safeTokenBalancesByTokenName, setSafeTokenBalancesByTokenName] = useState({});
    const [safeTokenBalances, setSafeTokenBalances] = useState({});
    const [safeBalanceInUSD, setSafeBalanceInUSD] = useState({ [nativeTokenSymbol]: 0 }); //added default value to avoid error in reduce function
    const [safeStaticDetails, setSafeStaticDetails] = useState<any>({
        owners: [],
        safeName: "",
        isMetaTxEnabled: false,
        whitelistedTokens: [],
        categories: [],
        safeAddress: "",
        threshold: 0,
        networkId: 1,
    });
    const [rawSafeTokenData, setRawSafeTokenData] = useState([]);
    const transactionContext = useContext(TransactionContext);
    const [safeTokenBalancesInDecimal, setSafeTokenBalancesInDecimal] = useState({});
    const [isExceed, setIsExceed] = useState(false);
    const [safeSdk, setSafeSdk] = useState<Safe>(null);
    const {
        ethersAdapterOwner,
        account,
        contributedSafes,
        ownedSafes,
        chainId,
        connector,
        library,
    } = useActiveBaseContext().web3;
    // const { safeSdk: gnosisSdk } = useSafeSdk(ethersAdapterOwner, currentSafeAddress);
    const [nonce, setNonce] = useState(0);
    const [approveEstimate, setApproveEstimation] = useState(null);
    const [isDelegateAccess, setIsDelegateAccess] = useState(false);
    const [isDelegateCanCreateTx, setIsDelegateCanCreateTx] = useState(false);
    const [lastConfirmedTransactionNonce, setLastConfirmedTransactionNonce] = useState(null);
    const [safeDetailsFromGnosis, setSafeDetailsFromGnosis] = useState({});
    const { data: gnosisSafeDetailsApi } = useGetGnosisSafeDetails(currentSafeAddress, {
        enabled: Boolean(currentSafeAddress),
    });
    const [listOfGnosisSafeOwner, setListOfGnosisSafeOwner] = useState([]);
    const [childSafes, setChildSafes] = useState([]);
    const [childSafesMeta, setChildSafesMeta] = useState([]);
    const [safePendingTransaction, setSafePendingTransaction] = useState([]);
    const [safeExecutedTransaction, setSafeExecutedTransaction] = useState<any>([]);
    const [childPendingTxnCount, setChildPendingTxnCount] = useState([]);
    const [safeSDKInitializer, setInitializer] = useState(null);
    const [payrollStatus, setPayrollStatus] = useState({
        whitelisted: false,
        onboarded: false,
        activated: false,
        demoCompleted: false,
        payrollsMigrated: false,
        payrollIdsMigrated: [],
        enforceMigration: false,
        allowV2Usage: false,
    });

    const [payrollContractAddress, setPayrollContractAddress] = useState(null);

    const payrollContract = useContract(
        getProxyFactoryContractAddress(networkId),
        PayrollProxyFactoryABI,
        false,
        library,
        account,
    );

    useEffect(() => {
        const getPayrollContractAddress = async () => {
            if (payrollContract && currentSafeAddress) {
                const PCAddress = await payrollContract.parcelAddress(currentSafeAddress);
                setPayrollContractAddress(PCAddress);
            }
        };
        getPayrollContractAddress();
    }, [payrollContract, currentSafeAddress]);

    useEffect(() => {
        let gnosisOwner = [];

        const filterListOfOwners = async owners => {
            for await (const owner of owners) {
                try {
                    const isOwner = await fetcher(`${gnosisURL}/safes/${owner?.address}`, {});
                    if (isOwner?.address) {
                        gnosisOwner.push(owner?.address);
                    }
                } catch (err) {}
            }
            setListOfGnosisSafeOwner(gnosisOwner);
        };
        if (safeStaticDetails?.owners?.length > 0) {
            filterListOfOwners(safeStaticDetails?.owners);
        }
    }, [safeStaticDetails]);

    useEffect(() => {
        const getChildSafes = async address => {
            try {
                const childSafes = await fetcher(`${gnosisURL}/owners/${address}/safes`, {});
                setChildSafes(childSafes?.safes || []);
            } catch {
                setChildSafes([]);
            }
        };

        if (currentSafeAddress) {
            getChildSafes(currentSafeAddress);
        }
        return () => {
            setChildSafes([]);
            setChildSafesMeta([]);
        };
    }, [currentSafeAddress]);

    useEffect(() => {
        const getPendingChildTxns = async safes => {
            try {
                const data = await Promise.all(
                    safes.map(async safeAddress => {
                        const res = await fetch(
                            `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?executed=false&trusted=true`,
                        );
                        const formattedRes = (await res.json())?.results;
                        const txns: any = await transactionContext.setUniqueNonceMap(
                            formattedRes,
                            safeAddress,
                            true,
                        );
                        return { safeAddress, pendingCount: txns.length };
                    }),
                );
                setChildPendingTxnCount(data);
            } catch {
                setChildPendingTxnCount([]);
            }
        };

        if (childSafes.length) {
            getPendingChildTxns(childSafes);
        }
    }, [childSafes]);

    const [countPendingActionForMe, setCountPendingActionForMe] = useState(0);
    const [countPendingTransactions, setCountPendingTransactions] = useState(0);
    const [fromDaoStatsCard, setFromDaoStatsCard] = useState(false);

    // Prefetching Transaction List on Safe Loaded
    const pendingTransaction = useGetPendingTransactionFromGnosis(
        currentSafeAddress,
        {
            refetchOnWindowFocus: true,
            enabled: Boolean(currentSafeAddress),
            onSuccess: async data => {
                const txns: any = await transactionContext.setUniqueNonceMap(
                    data,
                    currentSafeAddress,
                    true,
                );
                transactionContext.pendingTxn = txns;
                setSafePendingTransaction(txns);
                setCountPendingTransactions(txns?.length || 0);
                const pendingActionForMe = txns?.filter(
                    ({ approvedConfirmation, rejectedConfirmation }) =>
                        !(approvedConfirmation?.findIndex(({ owner }) => owner === account) > -1) &&
                        !(rejectedConfirmation?.findIndex(({ owner }) => owner === account) > -1),
                )?.length;
                setCountPendingActionForMe(pendingActionForMe);
            },
        },
        !safeStaticDetails?.enableSpam,
    );

    const executedTransaction = useGetExecutedTransactionFromGnosis(currentSafeAddress, {
        refetchOnWindowFocus: true,
        enabled: Boolean(currentSafeAddress),
        onSuccess: async data => {
            const txns = await transactionContext.setUniqueNonceMap(
                data,
                currentSafeAddress,
                false,
            );
            transactionContext.executedTxn = txns;
            setSafeExecutedTransaction(txns);
        },
    });

    const payrollAppStatus = useIsSafeWhitelistedOnPayroll(currentSafeAddress, {
        enabled: Boolean(currentSafeAddress),
        refetchOnWindowFocus: true,
        onSuccess: data => {
            setPayrollStatus({
                whitelisted: data?.success,
                onboarded: data?.onboarded,
                activated: data?.activated,
                ...data?.safe,
            });
        },
    });

    useEffect(() => {
        setInitializer(
            setInterval(() => {
                if (!safeSdk) {
                    if (ethersAdapterOwner !== null && currentSafeAddress) {
                        console.log("reinitialising sdk");
                        createSafeSDK(ethersAdapterOwner, currentSafeAddress);
                    }
                } else {
                    clearInterval(safeSDKInitializer);
                }
            }, 5000),
        );
    }, []);

    useEffect(() => {
        if (!currentSafeAddress && account) {
            let lastLoggedInSafeAddress: any = localStorage.getItem(
                "parcelv2_lastLoggedInSafeAddress",
            );
            if (lastLoggedInSafeAddress) {
                setCurrentSafeAddress(lastLoggedInSafeAddress);
            }
        }
    }, [account]);

    useEffect(() => {
        if (gnosisSafeDetailsApi) {
            setSafeDetailsFromGnosis(gnosisSafeDetailsApi);
        }
    }, [gnosisSafeDetailsApi]);

    const createSafeSDK = async (ethAdapter, safeAddress) => {
        if (ethAdapter !== null && safeAddress) {
            const safeSdk: Safe = await Safe.create({
                ethAdapter: ethAdapter,
                safeAddress,
            });
            setSafeSdk(safeSdk);
            setSafeNonce();
        }
    };

    useEffect(() => {
        if (!safeSdk) {
            if (ethersAdapterOwner !== null && currentSafeAddress) {
                console.log("reinitialise sdk");
                createSafeSDK(ethersAdapterOwner, currentSafeAddress);
            }
        } else {
            safeSdk.getAddress().then(address => {
                if (address !== currentSafeAddress) {
                    console.log("reinitialise sdk for new safe");
                    createSafeSDK(ethersAdapterOwner, currentSafeAddress);
                }
            });
        }
    }, [currentSafeAddress, ethersAdapterOwner, safeSdk]);

    const setSafeNonce = async () => {
        if (safeSdk) {
            const nonce = await safeSdk.getNonce();
            setNonce(nonce);
        }
    };

    const prefetchTransaction = async () => {
        pendingTransaction.refetch();
        executedTransaction.refetch();
    };

    const awaitRefetchTransaction = async () => {
        queryClient.invalidateQueries("get-pending-transaction-from-gnosis");
        queryClient.invalidateQueries("get-executed-transaction-from-gnosis");
        await pendingTransaction.refetch();
        await executedTransaction.refetch();
    };

    useEffect(() => {
        if (currentSafeAddress) {
            if (
                !ownedSafes.find(_s => _s.safeAddress == currentSafeAddress) &&
                contributedSafes.find(_s => _s.safeAddress == currentSafeAddress) &&
                contributedSafes.find(_s => _s.safeAddress == currentSafeAddress)?.safeAccess
                    ?.hasSafeAccess
            ) {
                setIsDelegateAccess(true);
            }
            localStorage.setItem("parcelv2_lastLoggedInSafeAddress", currentSafeAddress);
            prefetchTransaction();
        }
    }, [currentSafeAddress]);

    useEffect(() => {
        setLastConfirmedTransactionNonce(null);
    }, [currentSafeAddress, chainId, account, connector]);
    const {
        data: safeDetailsData,
        isLoading: isSafeDetailsLoading,
        isSuccess: isSafeDetailsSuccess,
        isError: safeDetailsError,
    }: any = useGetCurrentSafeDetails(currentSafeAddress);

    useEffect(() => {
        if (safeDetailsError) {
            return;
        }
        if (safeDetailsData) setSafeStaticDetails(safeDetailsData);
    }, [safeDetailsData]);

    const childMeta: any = useGetChildSafesMetaData(childSafes || []);

    useEffect(() => {
        if (childMeta.isError) {
            return;
        }
        if (childMeta.data) {
            setChildSafesMeta(childMeta?.data?.nestedSafes || []);
        }
    }, [childMeta]);

    const { data, isSuccess, isError, isLoading } = useGetTokensAndPriceBySafe(currentSafeAddress, {
        enabled: Boolean(currentSafeAddress),
        cacheTime: 60000,
        retry: false,
        refetchOnMount: false,
        retryOnMount: false,
    });
    const spamTokens = safeStaticDetails?.spamTokens || [];

    const getFinalTokens = (data, st = []) => {
        const isSpamToken = tokenAddress => st.includes(tokenAddress);
        if (!isArray(data)) return [];
        return data.filter(t => {
            if (t.token) {
                return !isSpamToken(t.tokenAddress);
            }
            return !isSpamToken(nativeTokenSymbol);
        });
    };
    useEffect(() => {
        if (isError) {
            return;
        }

        async function setupToken() {
            if (data && isSuccess && !isError && !isLoading && !isSafeDetailsLoading) {
                const newDataFiltered = getFinalTokens(data, spamTokens);

                let newData = await Promise.all(
                    newDataFiltered?.map(async token => {
                        try {
                            const tokenAddressContract = token?.token
                                ? getTokenContractAddress(token?.tokenAddress)
                                : ZERO_ADDRESS;
                            const decimals = token?.token ? token?.token?.decimals : 18;
                            let fiatConversion = "0";
                            let fiatBalance = "0";

                            const res = await fetch(FETCH_TOKEN_CONVERSION_ENDPOINT, {
                                method: "POST",
                                body: JSON.stringify({
                                    tokenAddress: tokenAddressContract,
                                    networkId: networkId,
                                }),
                                headers: {
                                    "content-type": "application/json",
                                },
                            });
                            const resData = await res.json();
                            fiatConversion = resData?.value;

                            const tokenAmount = token?.balance;

                            const tokenAmountInDecimals = weiToString(tokenAmount || 0, decimals);
                            fiatBalance = new Decimal(tokenAmountInDecimals)
                                ?.mul(fiatConversion)
                                ?.toString();

                            return {
                                ...token,
                                fiatConversion,
                                fiatBalance,
                            };
                        } catch (err) {
                            return {
                                ...token,
                            };
                        }
                    }),
                );

                newData && newData.length > 0 && setRawSafeTokenData(newData);

                if (Array.isArray(newData)) {
                    resolvingTokensInSafe(newData);
                    resolvingTokensInSafeForSettings(data);
                }

                Array.isArray(newData) &&
                    setSafeTokenBalances(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] =
                                item.balance;
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeTokenBalancesByTokenName(
                        newData?.reduce((obj, item) => {
                            obj[item.token ? item.token?.symbol : nativeTokenSymbol] = {
                                balance: Number(item.balance),
                                decimal: item.token ? item.token?.decimals : 18,
                                logoURI: item.token ? item.token?.logoUri : NATIVE_ICON_URL,
                                fiatConversion: item.fiatConversion,
                                fiatBalance: item.fiatBalance,
                                address: item.tokenAddress,
                            };
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeTokenBalancesInDecimal(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] =
                                getAmountFromWei(
                                    item.balance,
                                    item.tokenAddress ? item.token.decimals : 18,
                                );
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeBalanceInUSD(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] = Number(
                                item.fiatBalance,
                            );
                            return obj;
                        }, {}),
                    );
            }
        }

        setupToken();
    }, [data, isSuccess, isError, isLoading, currentSafeAddress, isSafeDetailsLoading]);

    const resolvingTokensInSafe = async data => {
        let tokenData = await Promise.all(
            data.map(async token => {
                if (token.token) {
                    return {
                        ...token.token,
                        tokenAddress: token.tokenAddress,
                        fiatConversion: Number(token.fiatConversion),
                    };
                } else {
                    return {
                        name: nativeTokenName,
                        symbol: nativeTokenSymbol,
                        logoUri: NATIVE_ICON_URL,
                        decimals: 18,
                        tokenAddress: nativeTokenSymbol,
                        fiatConversion: Number(token.fiatConversion),
                    };
                }
            }),
        );
        let result = tokenData.reduce((agg, tokenItem) => {
            agg[tokenItem.tokenAddress] = tokenItem;
            return agg;
        }, {});
        setTokensInSafe(result);
    };

    const resolvingTokensInSafeForSettings = async data => {
        if (!isArray(data)) {
            setTokensInSafeForSettings({
                [nativeTokenSymbol]: {
                    name: nativeTokenName,
                    symbol: nativeTokenSymbol,
                    logoUri: NATIVE_ICON_URL,
                    decimals: 18,
                    tokenAddress: nativeTokenSymbol,
                    fiatConversion: 1,
                },
            });
            return;
        }

        let tokenData = await Promise.all(
            data.map(async token => {
                if (token.token) {
                    return {
                        ...token.token,
                        tokenAddress: token.tokenAddress,
                        fiatConversion: Number(token.fiatConversion),
                    };
                } else {
                    return {
                        name: nativeTokenName,
                        symbol: nativeTokenSymbol,
                        logoUri: NATIVE_ICON_URL,
                        decimals: 18,
                        tokenAddress: nativeTokenSymbol,
                        fiatConversion: Number(token.fiatConversion),
                    };
                }
            }),
        );
        let result = tokenData.reduce((agg, tokenItem) => {
            agg[tokenItem.tokenAddress] = tokenItem;
            return agg;
        }, {});
        setTokensInSafeForSettings(result);
    };

    const getSafeBalance = () => {
        return Object.values(safeBalanceInUSD).reduce(
            (sum: number, balance: number) => sum + balance,
            0,
        );
    };

    return (
        <SafeContext.Provider
            value={{
                currentSafeAddress,
                setCurrentSafeAddress,
                tokensInSafe,
                setTokensInSafe,
                safeTokenBalances,
                setSafeTokenBalances,
                safeBalanceInUSD,
                setSafeBalanceInUSD,
                rawSafeTokenData,
                setRawSafeTokenData,
                getSafeBalance,
                safeStaticDetails,
                safeTokenBalancesInDecimal,
                safeTokenBalancesByTokenName,
                isExceed,
                setIsExceed,
                safeSdk,
                nonce,
                setSafeNonce,
                approveEstimate,
                setApproveEstimation,
                isDelegateAccess,
                setIsDelegateAccess,
                isDelegateCanCreateTx,
                setIsDelegateCanCreateTx,
                lastConfirmedTransactionNonce,
                setLastConfirmedTransactionNonce,
                safeDetailsFromGnosis,
                listOfGnosisSafeOwner,
                childSafes,
                childSafesMeta,
                countPendingActionForMe,
                countPendingTransactions,
                fromDaoStatsCard,
                setFromDaoStatsCard,
                prefetchTransaction,
                awaitRefetchTransaction,
                safePendingTransaction,
                safeExecutedTransaction,
                childPendingTxnCount,
                isSafeDetailsLoading,
                isSafeDetailsSuccess,
                tokensInSafeForSettings,
                payrollStatus,
                payrollContractAddress,
            }}
        >
            {children}
        </SafeContext.Provider>
    );
};

export default SafeContext;
