import React, { useContext, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { StyledTableCell } from "./styles";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import Rows from "./Rows";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Box, Button, Typography, IconButton } from "@mui/material";
import DeleteModal from "../DeleteModal";
import SetTokenModal from "../../SetTokenModal";
import TransferContext from "src/contexts/TransferContext";
import SortIcon from "assets/sort.svg";
import Image from "next/image";

interface Column {
    id: "name" | "token" | "settings";
    label?: string;
    minWidth?: number;
    align?: "right" | "left";
    format?: (value: number) => string;
}

const columns: readonly Column[] = [
    { id: "name", label: "NAME", minWidth: 170, align: "left" },
    { id: "token", label: "TOKEN AMOUNT", minWidth: 100, align: "left" },
    { id: "settings", label: "", minWidth: 100, align: "left" },
];

function PeopleTokenTable({ tagInfocus, isVisible, setDisableReview }) {
    const {
        payoutQueueByTag,
        setPayoutQueueByTag,
        setTagInFocus,
        setSelectedTags,
        selectedTags,
    }: any = useContext(MassPayoutContext);

    const { setMassPayoutStep, massPayoutStep }: any = useContext(TransferContext);
    const [selectedRows, setSelectedRows] = useState([]);
    const [finalSelected, setFinalSelected] = useState([]);
    const [open, setOpen] = useState(false);
    const [maxValueErr, setMaxValueErr] = useState(false);
    const [tokenOpen, setTokenOpen] = useState(false);
    const [nameColSortOrder, setNameColSortOrder] = useState("");

    const handleSelectAll = () => {
        if (selectedRows.length === payoutQueueByTag[tagInfocus]?.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(payoutQueueByTag[tagInfocus]);
        }
    };

    useEffect(() => {
        if (payoutQueueByTag[tagInfocus]) {
            let updatedSelectedRows = payoutQueueByTag[tagInfocus].filter(people =>
                selectedRows.some(item => item.address === people.address),
            );
            setFinalSelected(updatedSelectedRows);
        }
    }, [payoutQueueByTag[tagInfocus]]);

    const handleBulkDelete = () => {
        let updatedQueue = { ...payoutQueueByTag };
        const selectedRowsAddress = selectedRows.map(item => item.address);
        const removedQueue = updatedQueue[tagInfocus].filter(
            item => !selectedRowsAddress.includes(item.address),
        );
        if (removedQueue.length === 0) {
            let updated = { ...payoutQueueByTag };
            delete updated[tagInfocus];
            setPayoutQueueByTag(updated);
            if (Object.keys(updated).length) {
                let nextActive = Object.keys(updated)[0];
                setTagInFocus(nextActive);
            }
        } else {
            setPayoutQueueByTag({ ...payoutQueueByTag, [tagInfocus]: removedQueue });
        }
        setOpen(false);
        setSelectedRows([]);
    };
    useEffect(() => {
        if (selectedTags.length === 0) {
            setMassPayoutStep(1);
        }
        if (massPayoutStep !== 2 && !payoutQueueByTag[tagInfocus]) {
            let nextActive = Object.keys(payoutQueueByTag)[0];
            setTagInFocus(nextActive);
        }
        if (Object.keys(payoutQueueByTag).length === 0) {
            setMassPayoutStep(1);
        }
    }, [selectedTags, payoutQueueByTag]);

    const sortTableByName = () => {
        if (payoutQueueByTag[tagInfocus]) {
            const newRows = [...payoutQueueByTag[tagInfocus]];
            if (nameColSortOrder === "asc") {
                newRows.sort((r1, r2) => r2.nickName.localeCompare(r1.nickName));
                setNameColSortOrder("des");
            } else {
                newRows.sort((r1, r2) => r1.nickName.localeCompare(r2.nickName));
                setNameColSortOrder("asc");
            }
            const newObj = {
                ...payoutQueueByTag,
                [tagInfocus]: newRows,
            };
            setPayoutQueueByTag(newObj);
        }
    };

    return (
        <Paper
            sx={{
                width: "100%",
                overflow: "hidden",
                border: "1px solid #EAEAEA",
                borderRadius: "4px",
                display: isVisible ? "block" : "none",
            }}
            elevation={0}
            key={tagInfocus}
        >
            <TableContainer
                sx={{
                    maxHeight: 582,
                    px: "20px",
                }}
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell padding="checkbox">
                                <Checkbox
                                    sx={{ color: "#CAC9D6" }}
                                    indeterminate={
                                        selectedRows.length > 0 &&
                                        selectedRows.length < payoutQueueByTag[tagInfocus]?.length
                                    }
                                    checked={
                                        payoutQueueByTag[tagInfocus]?.length > 0 &&
                                        selectedRows.length === payoutQueueByTag[tagInfocus]?.length
                                    }
                                    inputProps={{
                                        "aria-label": "select all desserts",
                                    }}
                                    onClick={handleSelectAll}
                                />
                            </StyledTableCell>
                            {selectedRows.length > 0 ? (
                                <>
                                    <StyledTableCell key={1} align="left">
                                        <Typography color="title.main" fontWeight={500}>
                                            {selectedRows.length}{" "}
                                            {selectedRows.length == 1 ? "person" : "people"}{" "}
                                            selected
                                        </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell key={2} align="left"></StyledTableCell>
                                    <StyledTableCell key={3} align="right">
                                        <Box display="flex" justifyContent="flex-end">
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                // sx={{ mr: "20px" }}
                                                startIcon={<DeleteOutlineOutlinedIcon />}
                                                onClick={() => setOpen(true)}
                                            >
                                                Remove
                                            </Button>
                                            {/* <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => setTokenOpen(true)}
                                            >
                                                Set Token
                                            </Button> */}
                                        </Box>
                                    </StyledTableCell>
                                </>
                            ) : (
                                columns.map(column => (
                                    <StyledTableCell
                                        key={column.id}
                                        align={column.align}
                                        sx={{
                                            minWidth: column.minWidth,
                                        }}
                                    >
                                        {column.label}
                                        {column.id === "name" && (
                                            <IconButton size="small" onClick={sortTableByName}>
                                                <Image src={SortIcon} />
                                            </IconButton>
                                        )}
                                    </StyledTableCell>
                                ))
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {payoutQueueByTag[tagInfocus]?.map((row, index) => {
                            return (
                                <Rows
                                    data={row}
                                    key={index}
                                    index={index}
                                    setSelectedRows={setSelectedRows}
                                    selectedRows={selectedRows}
                                    setDisableReview={setDisableReview}
                                />
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <DeleteModal
                open={open}
                setOpen={setOpen}
                count={selectedRows.length}
                tag={tagInfocus}
                handleBulkDelete={handleBulkDelete}
            />
            <SetTokenModal
                open={tokenOpen}
                setOpen={setTokenOpen}
                count={selectedRows.length}
                tag={tagInfocus}
                maxValueErr={maxValueErr}
                setMaxValueErr={setMaxValueErr}
            />
        </Paper>
    );
}

export default PeopleTokenTable;
