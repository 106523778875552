import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const Banner = styled.div`
    padding: 18px 24px 24px;
    gap: 16px;
    width: 100%;
    margin-bottom: 16px;
    background: #25274f;
    box-shadow: 2px 20px 48px rgba(37, 39, 79, 0.12);
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;
    position: relative;
`;

export const BannerTitle: any = styled(Typography)`
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #ffffff;
    strong {
        font-weight: 700;
    }
`;

export const ButtonHolder = styled.div`
    display: flex;
    flex-direction: row;
    gap: 8px;
`;

export const Iconholder = styled.div`
    position: absolute;
    right: 24px;
    top: 0;
    height: 100%;
    img {
        height: 100%;
    }
`;

export const CloseWrapper = styled.div`
    position: absolute;
    right: 24px;
    top: 24px;
    cursor: pointer;
    color: #fff;
`;
