import * as React from "react";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { NavLink } from "src/components/Buttons";
import { StyledNestedList } from "./styles";
import NavBarIcons from "src/components/CustomIcons/NavBarIcons";
import { useRouter } from "next/router";
import { ListItemText, Typography } from "@mui/material";
import { NumberCircle } from "src/components/Buttons/styles";

export default function NestedList({
    open,
    setOpen,
    title,
    iconName,
    subMenus,
    pendingNumbers = 0,
}) {
    const router = useRouter();
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <div style={{ marginBottom: "15px", background: open && "#F5F8FE" }}>
            <StyledNestedList onClick={handleClick}>
                <div>
                    <NavBarIcons name={iconName} color={undefined} size={undefined} />
                </div>
                <div>
                    <Typography className="title">{title}</Typography>
                </div>
                <div>
                    {pendingNumbers > 0 && (
                        <ListItemText style={{ marginLeft: "auto" }}>
                            <NumberCircle
                                badgeContent={pendingNumbers}
                                color="warning"
                            ></NumberCircle>
                        </ListItemText>
                    )}
                </div>
                <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
            </StyledNestedList>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {subMenus?.length > 0 &&
                    subMenus.map(({ title, href, isactive, pendingTxnCount }, index) => (
                        <div key={title}>
                            <NavLink
                                title={title}
                                href={href}
                                isactive={isactive}
                                mb={index !== subMenus.length - 1}
                                pendingNumbers={pendingTxnCount}
                            />
                        </div>
                    ))}
            </Collapse>
        </div>
    );
}
