import React from "react";
import { PeopleSearchStyled } from "./styles/PeopleSearchBlock";
import SearchBar from "./SearchBar";
import TagFilter from "./TagFilter";

const PeopleSearchBlock = () => {
    return (
        <PeopleSearchStyled
            style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
            }}
        >
            <TagFilter />
            <SearchBar style={{}} />
        </PeopleSearchStyled>
    );
};

export default PeopleSearchBlock;
