import {
    CGNetwork,
    CGSimpleTokenPriceResponse,
    CoinGeckoCoinData,
    FiatCurrency,
    FiatPrices,
} from "./types/DynamicFiatFeeds";
import { supportedNetwork } from "./types/Networks";

export const getCoinData = async (coinId: string): Promise<CoinGeckoCoinData> => {
    let coinDataParams = new URLSearchParams();

    coinDataParams.append("localization", "en");
    coinDataParams.append("tickers", "false");
    coinDataParams.append("market_data", "true");
    coinDataParams.append("community_data", "false");
    coinDataParams.append("developer_data", "false");
    // if (process.env.COINGECKO_API_KEY && process.env.COINGECKO_API_KEY !== "") {
    //     coinDataParams.append("x_cg_demo_api_key", process.env.COINGECKO_API_KEY);
    // }

    return fetch(
        `${process.env.COINGECKO_ROOT_URL}/coins/${coinId}?${
            process.env.COINGECKO_QUERY_PARAM || ""
        }&${coinDataParams.toString()}`,
    ).then(res => res.json());
};

export const getCGTokenPrices = async (
    tokenAddresses: string[],
    networkId: supportedNetwork,
    fiatCurrencies: FiatCurrency[],
): Promise<CGSimpleTokenPriceResponse> => {
    let coinDataParams = new URLSearchParams();

    coinDataParams.append("contract_addresses", tokenAddresses.join(","));
    coinDataParams.append("vs_currencies", fiatCurrencies.join(","));
    coinDataParams.append("include_market_cap", "false");
    coinDataParams.append("include_24hr_vol", "false");
    coinDataParams.append("include_24hr_change", "false");
    coinDataParams.append("include_last_updated_at", "false");
    coinDataParams.append("precision", "6");
    // if (process.env.COINGECKO_API_KEY && process.env.COINGECKO_API_KEY !== "") {
    //     coinDataParams.append("x_cg_demo_api_key", process.env.COINGECKO_API_KEY);
    // }

    return fetch(
        `${process.env.COINGECKO_ROOT_URL}/simple/token_price/${networkId_cgMappings[networkId]}?${
            process.env.COINGECKO_QUERY_PARAM || ""
        }&${coinDataParams.toString()}`,
    ).then(res => res.json());
};

export const supportedFiatCurrencies: string[] = ["usd", "eur", "aud", "inr"];

// Coingecko network id mappings
export const cg_networkIdMappings: {
    [key: string]: { networkId: supportedNetwork; nativeTokenCoinId: string };
} = {
    ethereum: { networkId: 1, nativeTokenCoinId: "ethereum" },
    "binance-smart-chain": { networkId: 56, nativeTokenCoinId: "binancecoin" },
    "arbitrum-one": { networkId: 42161, nativeTokenCoinId: "arbitrum" },
    "polygon-pos": { networkId: 137, nativeTokenCoinId: "matic-network" },
    "optimistic-ethereum": { networkId: 10, nativeTokenCoinId: "ethereum" },
    goerli: { networkId: 5, nativeTokenCoinId: "ethereum" },
};

// Network ID to coingecko network id mappings
export const networkId_cgMappings: Record<supportedNetwork, CGNetwork> = {
    1: "ethereum",
    56: "binance-smart-chain",
    42161: "arbitrum-one",
    137: "polygon-pos",
    10: "optimistic-ethereum",
    5: "ethereum",
};

export const getNativeTokenPrice = async (
    networkId: supportedNetwork,
    fiatCurrency: FiatCurrency,
): Promise<string> => {
    let data = await getCoinData(
        cg_networkIdMappings[networkId_cgMappings[networkId]].nativeTokenCoinId,
    );

    if (!data?.market_data?.current_price) {
        throw new Error("No current price found for token");
    }

    if (!data.market_data.current_price[fiatCurrency]) {
        throw new Error(`No current price found in ${fiatCurrency} for token`);
    }

    return data.market_data.current_price[fiatCurrency].toString();
};

export const getNativeTokenPrices = async (
    networkId: supportedNetwork,
    fiatCurrencies: FiatCurrency[],
): Promise<FiatPrices> => {
    let data = await getCoinData(
        cg_networkIdMappings[networkId_cgMappings[networkId]].nativeTokenCoinId,
    );

    if (!data?.market_data?.current_price) {
        throw new Error("No current price found for token");
    }

    let fiatPrices = Object.keys(data.market_data.current_price).filter(
        fiatId => fiatCurrencies.indexOf(fiatId as FiatCurrency) !== -1,
    );

    if (!fiatPrices.length) {
        throw new Error(`No current price found in ${fiatCurrencies.join(",")} for token`);
    }

    return fiatPrices.reduce((agg, fiatCurrency) => {
        agg[fiatCurrency] = (
            data.market_data.current_price[fiatCurrency as FiatCurrency] || 0
        ).toString();
        return agg;
    }, {});
};
