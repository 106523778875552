import styled from "@emotion/styled";
import {
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import { Chip } from "@mui/material";

export const TagSelectorBoxWrapper = styled(Box)`
    flex: 100%;
    margin-top: 20px;
    padding: 20px;
    border: 1px solid ${({ theme }: any) => theme.table.borderColor};
    border-radius: 7px;
    overflow: auto;
    @media only screen and (min-width: 768px) {
        margin-right: 15px;
        flex: 50%;
        max-width: 50%;
    }
    .MuiTableCell-root {
        background-color: #fff;
    }

    .heading {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
        color: #25274f;
    }

    .subheading {
        margin-top: 5px;
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        color: #636b81;
    }
`;

export const TagsBox = styled.div`
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    border-radius: 4px;
    max-height: 80%;
    min-height: 80%;
    overflow: scroll;
    padding: 15px 30px 15px 15px;
    display: flex;
    flex-direction: column;
`;

export const SelctionBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
`;

export const SelectedBadge = styled(Chip)`
    padding: 9px;
    background: #ffffff;
    border: 1px solid ${({ theme }: any) => theme.palette.primary.main};
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    color: ${({ theme }: any) => theme.palette.primary.main};
    font-weight: 500;

    &:hover {
        background: #ffffff;
    }
    .MuiSvgIcon-root {
        color: ${({ theme }: any) => theme.palette.primary.main};
    }
`;

export const Badge = styled(Chip)`
    padding: 9px;
    background: #ffffff;
    border: 1px solid ${({ theme }: any) => theme.palette.border_color.main};
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
`;

export const AddToQueueButton = styled(Button)`
    width: 100%;
`;
