import { utils, Contract } from "ethers";
import * as Sentry from "@sentry/nextjs";
import Decimal from "decimal.js-light";
import { getNextPendingNonce } from "./tx-helpers";
import SafeApiKit from "@safe-global/api-kit";
import Safe from "@safe-global/protocol-kit";
import { MetaTransactionData } from "@safe-global/safe-core-sdk-types";
import { generatePreValidatedSignature } from "@safe-global/protocol-kit/dist/src/utils";
import addresses from "src/constants/addresses";
import { standardizeTransaction, getHexDataLength, joinHexData, getAmountInWei } from "./tx-helper";
import { createMetaTx } from "src/queries/Transaction/api";
import { nativeTokenSymbol } from "src/queries/constants";

const { ZERO_ADDRESS } = addresses;

const gasPrice = 0; // If 0, then no refund to relayer
const gasToken = ZERO_ADDRESS;
const baseGasEstimate = 0;
const executor = ZERO_ADDRESS;

const getERC20Contract = (contractAddress: string, customToken: Contract): Contract => {
    if (contractAddress && customToken) {
        return customToken.attach(contractAddress);
    }
    return customToken;
};

//masspayout by tag
export default async function createMassPayout(
    safeService: SafeApiKit,
    safeSdk: Safe,
    transactions: any[],
    safeAddress: string,
    customToken,
    multiSend,
    library,
    account,
    enqueueSnackbar,
    setIsTxStarted,
    closeModal,
    isMetaTxEnabled = false,
    isMetaTxLimitAllowed = false,
    setOpenModal,
    setTxStatus,
    isDelegateAccess = false,
    isDelegateCanCreateTx = false,
    isWalletEOA = false,
    gnosisSafeVersion = undefined,
    networkId = 1,
    isHardwareWallet = false,
    getGasEstimateTransactionExecution = undefined,
    ethAdapter,
    isGasLowEnough = true, // setting this to true by default to avoid breaking the existing functionality
) {
    setIsTxStarted(true);
    setOpenModal(true);
    try {
        const { saveToStorage } = await import("src/helpers/utils/storage/index");
        const { formatUnits, parseEther } = utils;
        const setTransactions: MetaTransactionData[] = [];
        await Promise.all(
            Object.values(transactions)
                .flat()
                .map(async (people: { selectedTokens: any; address: any }) => {
                    if (people.selectedTokens) {
                        await Promise.all(
                            people.selectedTokens.map(async token => {
                                if (token.token.tokenAddress === nativeTokenSymbol) {
                                    setTransactions.push({
                                        to: people.address,
                                        value: formatUnits(
                                            parseEther(`${new Decimal(token.amount).toFixed(18)}`),
                                            "wei",
                                        ),
                                        data: "0x",
                                        operation: 0,
                                    });
                                } else {
                                    const erc20 = getERC20Contract(
                                        token.token.tokenAddress,
                                        customToken,
                                    );
                                    if (!erc20) {
                                        throw new Error("ERC20 token undefined");
                                    }
                                    const tokenDecimals = await erc20.decimals();
                                    setTransactions.push({
                                        to: token.token.tokenAddress,
                                        value: "0",
                                        operation: 0,
                                        data: erc20.interface.encodeFunctionData("transfer", [
                                            people.address,
                                            getAmountInWei(
                                                `${new Decimal(token.amount).toFixed(
                                                    tokenDecimals,
                                                )}`,
                                                tokenDecimals,
                                            ),
                                        ]),
                                    });
                                }
                            }),
                        );
                    }
                }),
        );

        // Use Custom calculated Nonce
        const nonce = await getNextPendingNonce(safeAddress, safeSdk);

        const options = {
            // safeTxGas, // Optional
            // baseGas, // Optional
            // gasPrice, // Optional
            // gasToken, // Optional
            // refundReceiver, // Optional
            nonce,
            // gasLimit, // Optional
        };

        // DO NOT REMOVE THIS LOG. REMOVING THIS LOG RESULTS IN FAILED TRANSACTION CREATION
        console.log({ safeSdk });
        // DO NOT REMOVE THIS LOG. REMOVING THIS LOG RESULTS IN FAILED TRANSACTION CREATION

        const threshold = await safeSdk.getThreshold();
        const safeTransaction = await safeSdk.createTransaction({
            safeTransactionData: setTransactions,
            options,
        });
        const txHash = await safeSdk.getTransactionHash(safeTransaction);

        if (isDelegateAccess && isDelegateCanCreateTx) {
            try {
                // Gnosis only allows delegates to sign the hash
                const signature = await safeSdk.signTransactionHash(txHash);
                await safeService.proposeTransaction({
                    safeAddress,
                    safeTransactionData: safeTransaction.data,
                    safeTxHash: txHash,
                    senderAddress: account,
                    senderSignature: signature.data,
                    origin: "Parcel V2",
                });

                setIsTxStarted(false);
                return {
                    status: true,
                    data: {
                        nonce,
                        txHash,
                        status: "Pending",
                    },
                };
            } catch (e) {
                setIsTxStarted(false);
                if (
                    e &&
                    (e === 4001 ||
                        e.message === "wallet_rejected_transaction" ||
                        e.code === "ACTION_REJECTED")
                ) {
                    return {
                        status: false,
                        data: {
                            message: `Transaction Declined`,
                        },
                    };
                } else if (e && e.message.includes("is not an owner or delegate")) {
                    console.error(e);
                    Sentry.captureException(e);
                    return {
                        status: false,
                        data: {
                            message: `You do not have permissions to take this action. Please contact your safe owner.`,
                        },
                    };
                } else {
                    console.error(e);
                    Sentry.captureException(e);
                    return {
                        status: false,
                        data: {
                            message: `Creation Failed, Please Try Again.`,
                        },
                    };
                }
            }
        }

        // console.log(threshold, "from threshold");
        if (threshold && threshold == 1) {
            // const ethLibAdapter = new EthersAdapter({
            //     ethers,
            //     signer: library.getSigner(account),
            // });

            // const dataHash = encodeMultiSendCallData(setTransactions, ethLibAdapter, multiSend);
            // console.log(multiSend, dataHash, "from contract check");
            // const { safeTxGas, baseGas } = await getGasEstimate(
            //     safeAddress,
            //     {
            //         safe: safeAddress,
            //         to: safeTransaction.data.to,
            //         value: safeTransaction.data.value,
            //         data: safeTransaction.data.data,
            //         operation: safeTransaction.data.operation,
            //         gasToken: safeTransaction.data.gasToken,
            //     },
            //     0,
            // );

            await library.getFeeData();
            const preValidateSignature = generatePreValidatedSignature(account);
            const gasEstimationResult = await getGasEstimateTransactionExecution(
                safeTransaction?.data,
                preValidateSignature?.data,
            );
            const gasLimit = gasEstimationResult + 21000;
            console.log(gasLimit, "from gasLimit contract");
            // enqueueSnackbar(`Transfer started`, {
            //     variant: "success",
            // });
            setTxStatus({
                status: null,
                data: {
                    message: `Please Check Your Wallet To Proceed`,
                },
            });
            if (isMetaTxEnabled && isMetaTxLimitAllowed && isGasLowEnough) {
                await safeSdk.signTransaction(safeTransaction);
                // await txApprove.transactionResponse?.wait();
                const approvedSign = safeTransaction.signatures.get(account.toLowerCase()).data;

                const {
                    data: { to, data, value, operation, safeTxGas: safeTxGasSdk },
                } = safeTransaction;

                const txHash = await createMetaTx({
                    from: safeAddress,
                    to: safeAddress,
                    params: [
                        to,
                        value,
                        data,
                        operation,
                        safeTxGasSdk,
                        baseGasEstimate,
                        gasPrice,
                        gasToken,
                        executor,
                        approvedSign,
                    ],
                    gasLimit,
                });
                setTxStatus({
                    status: null,
                    data: {
                        message: `Creating Transaction`,
                        hash: txHash,
                    },
                });
                await library.waitForTransaction(txHash);
                setTxStatus({
                    status: true,
                    data: {
                        hash: txHash,
                        txHash,
                        nonce,
                        status: "Success",
                        message: "Transaction Created",
                    },
                });
                saveToStorage("OPEN_TX", "1");
                saveToStorage("LAST_NOUNCE", nonce);
                saveToStorage("LAST_TX_HASH", txHash);
                setIsTxStarted(false);

                return {
                    status: true,
                    data: {
                        txHash,
                        nonce,
                        status: "Success",
                    },
                };
            } else {
                const executed = await safeSdk.executeTransaction(safeTransaction, {
                    gasLimit: gasLimit,
                });
                setTxStatus({
                    status: null,
                    data: {
                        message: `Creating Transaction`,
                        hash: txHash,
                    },
                });
                await executed.transactionResponse?.wait();
                // console.log(executed, txHash, "sucess");
                // enqueueSnackbar(`Transfer Succeed`, {
                //     variant: "success",
                // });
                setTxStatus({
                    status: true,
                    data: {
                        hash: executed.hash,
                        txHash,
                        nonce,
                        status: "Success",
                        message: "Transaction Created",
                    },
                });
                saveToStorage("OPEN_TX", "1");
                saveToStorage("LAST_NOUNCE", nonce);
                saveToStorage("LAST_TX_HASH", executed.hash);
                setIsTxStarted(false);

                return {
                    status: true,
                    data: {
                        txHash,
                        nonce,
                        status: "Success",
                    },
                };
            }

            // const isTransactionExecutable = (safeThreshold: number, transaction: SafeMultisigTransactionResponse) => {
            //     return transaction.confirmations.length >= safeThreshold
            //   }
            // console.log(safeTransaction, txHash, executed);
        } else {
            if (threshold && threshold > 1) {
                // setIsTxStarted(false);
                setTxStatus({
                    status: null,
                    data: {
                        message: `Please Check Your Wallet To Proceed`,
                    },
                });

                if (isWalletEOA) {
                    let txResponse = await safeService.proposeTransaction({
                        safeAddress,
                        safeTransactionData: safeTransaction.data,
                        safeTxHash: txHash,
                        senderAddress: account,
                        senderSignature: null,
                        origin: "Parcel V2",
                    });
                } else {
                    const signature = await safeSdk.signTransaction(safeTransaction);
                    let txResponse = await safeService.proposeTransaction({
                        safeAddress,
                        safeTransactionData: safeTransaction.data,
                        safeTxHash: txHash,
                        senderAddress: account,
                        senderSignature: signature.signatures.get(account.toLowerCase()).data,
                    });
                }
                // const txResponse = await safeSdk.approveTransactionHash(txHash);
                // await txResponse.transactionResponse?.wait();

                // const txApprove = await safeSdk.approveTransactionHash(txHash);
                // await txApprove.transactionResponse?.wait();
                // console.log(safeAddress, safeTransaction, txHash, account);

                //  console.log("Multi sig created", txResponse);

                saveToStorage("LAST_NOUNCE", nonce);
                saveToStorage("LAST_TX_HASH", txHash);
                saveToStorage("OPEN_TX", "1");
                setIsTxStarted(false);

                // return {
                //     status: true,
                //     data: {
                //         txHash,
                //         nonce,
                //         status: "Pending",
                //     },
                // };

                if (isWalletEOA) {
                    return {
                        status: true,
                        data: {
                            hash: txHash,
                            txHash,
                            nonce,
                            status: "Pending",
                            message:
                                "Transaction Created successfully. Since you are signed as a Nested Multisig, additional Approval is required in the next step.",
                        },
                    };
                } else {
                    return {
                        status: true,
                        data: {
                            hash: txHash,
                            txHash,
                            nonce,
                            status: "Pending",
                            message: "Transaction Created",
                        },
                    };
                }
            } else {
                setTxStatus({
                    status: false,
                    data: {
                        message: `Creation Failed, Please Try Again.`,
                    },
                });
                throw new Error("Error in threshold");
            }

            // enqueueSnackbar("Not supporting ", { variant: "error" });
        }

        // const safeTxHash = await safeSdk.getTransactionHash(safeTransaction);
        // const tx = await safeService.getTransaction(safeTxHash);

        // console.log(tx, safeTxHash);
    } catch (error) {
        setIsTxStarted(false);
        console.log(error);
        //enqueueSnackbar("Transfer Failed", { variant: "error" });
        if (error && (error.code === 4001 || error.code === "ACTION_REJECTED")) {
            setTxStatus({
                status: false,
                data: {
                    message: `Transaction Declined`,
                },
            });
        } else {
            setTxStatus({
                status: false,
                data: {
                    message: `Creation Failed, Please Try Again.`,
                },
            });
        }
        return {
            status: false,
        };
    }
}

const encodeMultiSendCallData = (transactions, ethLibAdapter, multiSend) => {
    const standardizedTxs = transactions.map(standardizeTransaction);
    console.log(standardizedTxs, "from stdtx");
    return multiSend.interface.encodeFunctionData("multiSend", [
        joinHexData(
            standardizedTxs.map(tx =>
                ethLibAdapter.abiEncodePacked(
                    { type: "uint8", value: tx.operation },
                    { type: "address", value: tx.to },
                    { type: "uint256", value: tx.value },
                    {
                        type: "uint256",
                        value: getHexDataLength(tx.data),
                    },
                    { type: "bytes", value: tx.data },
                ),
            ),
        ),
    ]);
};

export const getPayoutQueueForMassPayout = transactions => {
    let payoutQueue = [];
    Object.values(transactions)
        .flat()
        .forEach((item: any) => {
            if (item?.selectedTokens?.length > 0) {
                item?.selectedTokens?.forEach(_ => {
                    payoutQueue.push({
                        payoutCategory: "",
                        payoutComment: "",
                        selectedToken: _?.token?.tokenAddress,
                        tag: item?.tags,
                        tokenDecimal: _?.token?.decimals,
                        tokenSymbol: _?.token.symbol,
                        tokenValue: _?.amount,
                        tokenValueInUSD: _?.fiatValue,
                        walletAddress: item?.address,
                        walletENS: false,
                        walletNickName: item?.nickName,
                    });
                });
            }
        });
    return payoutQueue;
};
