import styled from "@emotion/styled";
import TableCell from "@mui/material/TableCell";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";

export const StyledTableCell = styled(TableCell)`
    background-color: #fff;
    border-color: #eaeaea;
    color: #8b91a1;
    font-size: 13px;
`;

export const StyledAutoCompleteWrapper = styled(Box)`
    .MuiAutocomplete-paper {
        margin-top: 10px !important;
        .MuiAutocomplete-option {
            padding: 14px;
        }
    }
`;
export const StyledAutoComplete = styled(Autocomplete)`
    .MuiFormControl-root {
        .MuiOutlinedInput-input {
            height: 18px;
            font-size: 14px;
            width: 30%;
            font-weight: 500;
            padding: 2px;
        }
    }
`;

export const StyledInputField = styled(TextField)`
    .MuiOutlinedInput-input {
        height: 18px;
        font-weight: 500;
        font-size: 14px;
        padding: 11px 10px;
    }
`;
