import styled from "@emotion/styled";
import theme from "styles/theme";
import { Button } from "@mui/material";

export const DropZoneWrapper: any = styled.div`
    margin-top: 27px;
    padding: 42px 0px;
    background-color: ${(props: any) => (props.hasError ? "#FFF8F8" : "#F5F8FE")};
    width: 100%;
    border-radius: 8px;
    border: 1px dashed ${(props: any) => (props.hasError ? "red" : theme.palette.primary.main)};

    z-index: ${(props: any) => (props.dropactive ? 2100 : 1)};

    #loader circle {
        stroke-width: 1 !important;
    }
`;

export const DragContainer: any = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const ImageWrapper: any = styled.div`
    padding: 23px 29px;
    padding-bottom: 21px;

    background-color: ${(props: any) => (props.hasError ? "#FBDEDC" : "#e1eafd")};
    border-radius: 50%;
`;

export const DropFileText: any = styled.div`
    padding-top: 22px;

    font-size: 16px;

    font-weight: 400;
    line-height: 16px;
    color: #5e5c6c;
    .browse-file {
        color: ${theme.palette.primary.main};
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const StyledButton: any = styled(Button)`
    background-color: white;
    margin-top: 16px;

    span {
        color: #2962ef;
        font-weight: 600;
    }
`;

export const Overlays: any = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(35, 36, 39, 0.3);
    z-index: -100;
    cursor: pointer;
`;

export const ButtonWrapper: any = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;
