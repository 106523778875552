import { useState, useEffect } from "react";
import { formatEther } from "@ethersproject/units";
import { useActiveBaseContext } from "src/hooks";
import * as d3 from "d3";

// import { networkNames } from 'constants/networks';

export const ChainId = () => {
    const { chainId } = useActiveBaseContext().web3;
    return Number.isInteger(chainId) ? chainId : "";
};

// export const BlockNumber = () => {
//   const { chainId, library } = useActiveBaseContext().web3;
//   const [blockNumber, setBlockNumber] = useState();
//   useEffect(() => {
//     if (!!library) {
//       let stale = false;

//       library
//         .getBlockNumber()
//         .then(blockNumber => {
//           if (!stale) {
//             setBlockNumber(blockNumber);
//           }
//         })
//         .catch(() => {
//           if (!stale) {
//             setBlockNumber(null);
//           }
//         });

//       const updateBlockNumber = blockNumber => {
//         setBlockNumber(blockNumber);
//       };
//       library.on('block', updateBlockNumber);

//       return () => {
//         stale = true;
//         library.removeListener('block', updateBlockNumber);
//         setBlockNumber(undefined);
//       };
//     }
//   }, [library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

//   return (
//     <>
//       {Number.isInteger(blockNumber)
//         ? blockNumber.toLocaleString()
//         : blockNumber === null
//         ? 'Error'
//         : !!library
//         ? '...'
//         : ''}
//     </>
//   );
// };

// export const Account = (isFormat = true) => {
//   const { account } = useActiveWeb3React();

//   const accountNo =
//     account === undefined ? null : account === null ? null : account;

//   if (accountNo && isFormat) {
//     return `${accountNo.substring(0, 9)}...${accountNo.substring(
//       accountNo.length - 4
//     )}`;
//   }

//   return accountNo;
// };

// export const Balance = () => {
//   const { account, library, chainId } = useActiveWeb3React();

//   const [balance, setBalance] = useState();
//   useEffect(() => {
//     if (!!account && !!library) {
//       let stale = false;

//       library
//         .getBalance(account)
//         .then(balance => {
//           if (!stale) {
//             setBalance(balance);
//           }
//         })
//         .catch(() => {
//           if (!stale) {
//             setBalance(null);
//           }
//         });

//       return () => {
//         stale = true;
//         setBalance(undefined);
//       };
//     }
//   }, [account, library, chainId]); // ensures refresh if referential identity of library doesn't change across chainIds

//   return !!balance ? parseFloat(formatEther(balance)).toPrecision(4) : null;
// };

// const etherscanPrefixByChainId = {
//   1: "",
//   3: `${networkNames.ROPSTEN.toLowerCase()}.`,
//   4: `${networkNames.RINKEBY.toLowerCase()}.`,
//   42: `${networkNames.KOVAN.toLowerCase()}.`,
// };
// export const getEtherscanLink = ({ chainId, type = "tx", hash, address }) => {
//   if (chainId === 137) {
//     if (type === "tx") {
//       return `https://polygonscan.com/tx/${hash}`;
//     } else if (type === "address") {
//       return `https://polygonscan.com/address/${address}`;
//     }
//     return `https://polygonscan.com/`;
//   }

//   if (type === "tx") {
//     return `https://${etherscanPrefixByChainId[chainId]}etherscan.io/${type}/${hash}`;
//   } else if (type === "address") {
//     return `https://${etherscanPrefixByChainId[chainId]}etherscan.io/${type}/${address}`;
//   }
//   return `https://${etherscanPrefixByChainId[chainId]}etherscan.io/`;
// };

// export const TransactionUrl = ({ hash, children, ...rest }) => {
//   const { chainId } = useActiveWeb3React();

//   return (
//     <a
//       href={getEtherscanLink({ chainId, hash })}
//       rel="noopener noreferrer"
//       target="_blank"
//       {...rest}
//     >
//       {children || `View Transaction`}
//     </a>
//   );
// };
export const minifySafeName = name => {
    if (!name) return "";

    return name.length > 14 ? `${name.substring(0, 20)}...` : name;
};

export const minifyName = (name, maxChar = 14) => {
    if (!name) return "";

    return name.length > maxChar ? `${name.substring(0, maxChar)}...` : name;
};

export const minifyAddress = (address, middleChars = 6, endChars = 4) => {
    if (!address) return "";
    if (address.length < 20) return address;
    if (address.substr(-4) == ".eth") return address;
    return `${address.substring(0, middleChars + 2)}...${address.substring(
        address.length - endChars,
    )}`;
};

export const replaceAddresswithMinification = (string, middleChars = 7, endChars = 4) => {
    if (!string) return "";

    return string.replaceAll(/0x[a-zA-Z0-9]{40}/g, match => {
        return minifyAddress(match, middleChars, endChars);
    });
};

// export const getClickable = (
//   string,
//   chainId,
//   type = 'tx',
//   minifyString = true
// ) => {
//   if (!string) return '';

//   return (
//     <a
//       href={getEtherscanLink({
//         chainId,
//         type,
//         address: type === 'tx' ? '' : string,
//         hash: type === 'tx' ? string : '',
//       })}
//       rel="noopener noreferrer"
//       target="_blank"
//       className="clickable-links"
//     >
//       {minifyString ? minifyAddress(string) : string}
//     </a>
//   );
// };

export const formatAmount = (string, minDecimal = 0, maxDecimal = 2) => {
    let truncatedString = truncate(string, maxDecimal);
    // Fifteen Zeroes for Quadrillion
    return Math.abs(Number(string)) >= 1.0e15
        ? truncate(Number(string) / 1.0e15, maxDecimal) + "Q"
        : // Twelve Zeroes for Trillions
        Math.abs(Number(string)) >= 1.0e12
        ? truncate(Number(string) / 1.0e12, maxDecimal) + "T"
        : // Nine Zeroes for Billions
        Math.abs(Number(string)) >= 1.0e9
        ? truncate(Number(string) / 1.0e9, maxDecimal) + "B"
        : // Six Zeroes for Millions
        Math.abs(Number(string)) >= 1.0e6
        ? truncate(Number(string) / 1.0e6, maxDecimal) + "M"
        : Number(truncatedString).toLocaleString(undefined, {
              minimumFractionDigits: minDecimal,
              maximumFractionDigits: maxDecimal,
          });
};

export const formatAmountWithoutTruncate = (string, minDecimal = 0, maxDecimal = 2) => {
    return Number(string).toLocaleString(undefined, {
        minimumFractionDigits: minDecimal,
        maximumFractionDigits: maxDecimal,
    });
};

export const formatAmountWithSymbol = (labelValue, minDecimal = 0, maxDecimal = 2) => {
    // console.log("sss", Math.abs(Number(labelValue)) >= 1.0e12)
    if (Math.abs(Number(labelValue)) >= 1.0e12) {
        const value = formatAmount(Math.abs(Number(labelValue)) / 1.0e12, minDecimal, maxDecimal);
        // console.log(value);
        if (value.length > 6) return value.substring(0, 6) + "...";
        return value + "T";
    }
    if (Math.abs(Number(labelValue)) >= 1.0e9) {
        const value = formatAmount(Math.abs(Number(labelValue)) / 1.0e9, minDecimal, maxDecimal);
        return value + "B";
    }
    if (Math.abs(Number(labelValue)) >= 1.0e6) {
        const value = formatAmount(Math.abs(Number(labelValue)) / 1.0e6, minDecimal, maxDecimal);
        return value + "M";
    }

    if (Math.abs(Number(labelValue)) >= 1.0e3) {
        const value = formatAmount(Math.abs(Number(labelValue)) / 1.0e3, minDecimal, maxDecimal);
        return value + "K";
    }
    return formatAmount(Math.abs(Number(labelValue)), minDecimal, maxDecimal);
};

export const truncate = (number, index = 2) => {
    // cutting the number

    if (Number(number) != Math.floor(number)) {
        if (number?.toString())
            return +number.toString().slice(0, number.toString().indexOf(".") + (index + 1));
    } else {
        return number;
    }
};

export function isValidURL(string) {
    let res = string.match(
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
    return res !== null;
}

const colors = d3.scaleOrdinal(d3.schemeCategory10);

export const getColorByString = s => colors(s);
