import { graphQLClient } from "../graphqlClient";
import { baseURI } from "../constants";
import { useQuery, useMutation } from "react-query";
import { gql } from "graphql-request";
import { queryClient } from "pages/_app";

export function useGetIntegrationsList() {
    return useQuery(
        "get-all-integration",
        async () => {
            const { GetIntegrations } = await graphQLClient(baseURI).request(
                gql`
                    query GetIntegrations {
                        GetIntegrations {
                            integrationId
                            paymentType
                            appToken
                            integrationName
                            logoUri
                            description
                            slug
                        }
                    }
                `,
            );
            return GetIntegrations;
        },
        {
            refetchOnWindowFocus: false,
        },
    );
}

export function useGetProposalsList(safeAddress, { status, integrationId }) {
    return useQuery("get-proposals", async () => {
        const { ProposedTransactionBySafe } = await graphQLClient(baseURI, safeAddress).request(
            gql`
                query Query($status: [String], $integrationId: String) {
                    ProposedTransactionBySafe(status: $status, integrationId: $integrationId) {
                        proposalId
                        safeAddress
                        proposalName
                        paymentType
                        paymentCategory
                        transactionId
                        description
                        status
                        nonce
                        disbursement {
                            fiatValue
                            fixedUsd
                            tokenAddress
                            category
                            tag_name
                            tokenValue
                            recipentAddress
                            referenceLink
                            comment
                        }
                    }
                }
            `,
            { status, integrationId },
        );
        return ProposedTransactionBySafe;
    });
}

export function useUpdateProposal() {
    return useMutation(
        async ({ safeAddress, proposalIds, newStatus, transactionId }: any) => {
            const { updateProposalStatus } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation(
                        $newStatus: String
                        $transactionId: String
                        $proposalIds: [String]
                    ) {
                        updateProposalStatus(
                            newStatus: $newStatus
                            transactionId: $transactionId
                            proposalIds: $proposalIds
                        ) {
                            proposalId
                            paymentType
                            paymentCategory
                            proposalName
                            safeAddress
                            transactionId
                            status
                        }
                    }
                `,
                {
                    proposalIds,
                    newStatus,
                    transactionId,
                },
            );

            return updateProposalStatus;
        },
        {
            mutationKey: "update_external_transactions",
            onSuccess: () => {
                // console.log("successfully Updated");
                queryClient.invalidateQueries("get-proposals");
            },
        },
    );
}

export function useUpdateProposalStatusByTxId() {
    return useMutation(
        async ({ safeAddress, transactionId, newStatus }: any) => {
            const { updateProposalStatusByTxId } = await graphQLClient(
                baseURI,
                safeAddress,
            ).request(
                gql`
                    mutation UpdateProposalStatusByTxId(
                        $transactionId: String
                        $newStatus: String
                    ) {
                        updateProposalStatusByTxId(
                            transactionId: $transactionId
                            newStatus: $newStatus
                        ) {
                            proposalId
                            status
                        }
                    }
                `,
                {
                    transactionId,
                    newStatus,
                },
            );

            return updateProposalStatusByTxId;
        },
        {
            mutationKey: "update_external_transactions_by_txId",
            onSuccess: () => {
                // console.log("successfully Updated");
                queryClient.invalidateQueries("get-proposals");
            },
        },
    );
}

// export function useUpdate() {
//     return useMutation(
//         async ({ safeAddress, requestIds, newStatus }: any) => {
//             const { updateRequestStatus } = await graphQLClient(baseURI, safeAddress).request(
//                 gql`
//                     mutation BulkUpdateRequestStatus($requestIds: [String], $newStatus: String) {
//                         bulkUpdateRequestStatus(requestIds: $requestIds, newStatus: $newStatus) {
//                             requestId
//                         }
//                     }
//                 `,
//                 {
//                     requestIds,
//                     newStatus: newStatus.toLowerCase(),
//                 },
//             );

//             return updateRequestStatus;
//         },
//         {
//             mutationKey: BULK_UPDATE_REQUEST_STATUS_BY_IDS,
//             onSuccess: () => {
//                 console.log("successfully Updated");
//                 queryClient.invalidateQueries(GET_REQUESTS_BY_SAFE);
//                 queryClient.invalidateQueries(GET_REQUESTS_BY_CONTRIBUTOR);
//                 queryClient.invalidateQueries(GET_REQUEST_DETAIL_BY_REQUESTID);
//             },
//         },
//     );
// }
