import { request, gql } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "pages/_app";
import { graphQLClient } from "../graphqlClient";
import {
    CREATE_WORKFLOW,
    baseURI,
    UPDATE_PAYROLL_IMPLEMENTATION,
    GET_PAYROLL_BY_SAFE,
    UPDATE_PAYROLL_WORKFLOW,
    DELETE_PAYROLL_BY_ID,
    UPDATE_PAYROLL_WORKFLOW_STATUS,
} from "src/queries/constants";

export function useCreateWorkflow() {
    return useMutation(
        async ({
            name,
            status,
            payouts,
            scheduleType,
            scheduleValue,
            safeAddress,
            startDate,
        }: any) => {
            const { addPayroll } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation AddPayroll($payrollParams: PayrollParams) {
                        addPayroll(payrollParams: $payrollParams) {
                            payrollId
                        }
                    }
                `,
                {
                    payrollParams: {
                        name,
                        status,
                        txnTags: null,
                        scheduleType,
                        scheduleValue,
                        payouts,
                        startDate,
                    },
                },
            );

            return addPayroll;
        },
        {
            mutationKey: CREATE_WORKFLOW,
        },
    );
}

export function useUpdatePayrollImplementation() {
    return useMutation(
        async ({ payrollId, transactionId, safeAddress }: any) => {
            const { updatePayrollImplementation } = await graphQLClient(
                baseURI,
                safeAddress,
            ).request(
                gql`
                    mutation UpdatePayrollImplementation(
                        $payrollId: String
                        $transactionId: String
                    ) {
                        updatePayrollImplementation(
                            payrollId: $payrollId
                            transactionId: $transactionId
                        ) {
                            payrollId
                            name
                            status
                            implementations
                            lastTransaction
                            scheduleType
                        }
                    }
                `,
                {
                    payrollId,
                    transactionId,
                },
            );

            return updatePayrollImplementation;
        },
        {
            mutationKey: UPDATE_PAYROLL_IMPLEMENTATION,
        },
    );
}

export function updatePayrollWorkflow() {
    return useMutation(
        async ({
            payrollId,
            name,
            status,
            payouts,
            scheduleType,
            scheduleValue,
            safeAddress,
            startDate,
        }: any) => {
            const { updatePayroll } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation UpdatePayroll($payrollId: String, $payrollParams: PayrollParams) {
                        updatePayroll(payrollId: $payrollId, payrollParams: $payrollParams) {
                            payrollId
                        }
                    }
                `,
                {
                    payrollId,
                    payrollParams: {
                        name,
                        status,
                        txnTags: null,
                        scheduleType,
                        scheduleValue,
                        payouts,
                        startDate,
                    },
                },
            );

            return updatePayroll;
        },
        {
            mutationKey: UPDATE_PAYROLL_WORKFLOW,
        },
    );
}

export function deletePayrollByIdWorkflow() {
    const mutation = useMutation(
        async ({ payrollId, safeAddress }: any) => {
            // console.log(payrollId, safeAddress, "payrollId, safeAddress");

            const { deletePayrollById } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation DeletePayrollById($payrollId: String) {
                        deletePayrollById(payrollId: $payrollId)
                    }
                `,
                {
                    payrollId,
                },
            );
            return deletePayrollById;
        },
        {
            mutationKey: DELETE_PAYROLL_BY_ID,
            onSuccess: data => {
                queryClient.invalidateQueries(GET_PAYROLL_BY_SAFE);
            },
        },
    );
    return mutation;
}

export function updatePayrollWorkflowStatus() {
    return useMutation(
        async ({ payrollId, newStatus, safeAddress }: any) => {
            const { updatePayroll } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation UpdatePayrollStatus($payrollId: String, $newStatus: String) {
                        updatePayrollStatus(payrollId: $payrollId, newStatus: $newStatus) {
                            payrollId
                        }
                    }
                `,
                {
                    payrollId,
                    newStatus,
                },
            );

            return updatePayroll;
        },
        {
            mutationKey: UPDATE_PAYROLL_WORKFLOW_STATUS,
        },
    );
}
