import { Search } from "@mui/icons-material";
import { InputAdornment, InputBase } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { KeyboardShortcut, SearchWorkflowStyled } from "./styles/SearchWorkflow";
import SafeContext from "src/contexts/SafeContext";

const SearchContributors = ({ setWalletAddress, setWalletNickName, searchTerm, setSearchTerm }) => {
    const [searchInFocus, setSearchInFocus] = useState(false);
    const [showResults, toggleResults] = useState(false);
    const searchBox = useRef(null);
    // const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const { currentSafeAddress }: any = useContext(SafeContext);

    useEffect(() => {
        const handleCommandF = (e: any) => {
            if (e.key === "f" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                searchBox?.current?.childNodes[1]?.focus();
            }
            if (e.key === "Escape") {
                searchBox?.current?.childNodes[1]?.blur();
            }
        };

        document.addEventListener("keydown", handleCommandF);

        return () => {
            document.removeEventListener("keydown", handleCommandF);
        };
    }, []);

    useEffect(() => {
        if (searchTerm.length >= 2) {
            toggleResults(true);
        } else {
            toggleResults(false);
        }
    }, [searchTerm]);

    const selectResult = contributor => {
        toggleResults(false);
        setWalletAddress(contributor.address);
        setWalletNickName(contributor.nickName);
        setSearchTerm("");
    };

    return (
        <SearchWorkflowStyled>
            <InputBase
                ref={searchBox}
                className="input-text"
                placeholder="Search by Flow Name"
                inputProps={{ "aria-label": "Search by Workflow name" }}
                startAdornment={
                    <InputAdornment position="start">
                        <Search />
                    </InputAdornment>
                }
                endAdornment={
                    <KeyboardShortcut>{searchInFocus ? "ESC" : "Cmd + F"}</KeyboardShortcut>
                }
                onFocus={e => setSearchInFocus(true)}
                onBlur={e => setSearchInFocus(false)}
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                style={{ background: "#EFF0F2" }}
                sx={{
                    span: {
                        overflow: "hidden",
                        "white-space": "nowrap",
                        background: "none",
                    },
                }}
            />
        </SearchWorkflowStyled>
    );
};

export default SearchContributors;
