import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import InputComponent from "src/components/InputComponent";
import SearchFilterTags from "src/components/PeopleSection/SearchFilter";
import { LoadingButton } from "@mui/lab";
import Dialog from "@mui/material/Dialog";
import { useSnackbar } from "notistack";
import { utils } from "ethers";
import { useQueryClient } from "react-query";
import {
    useAddContributor,
    useAddTagToContributor,
    useGetContributor,
} from "src/queries/PeopleSection/api";
import { formatAddress } from "src/helpers/utils/common";
import { GET_CONTRIBUTOR, GET_TAGS } from "src/queries/constants";

const AddPeopleModal = ({
    openAddPeopleModal,
    setOpenAddPeopleModal,
    walletAddress,
    currentSafeAddress,
}) => {
    const { mutateAsync: addSafeContributor } = useAddContributor();
    const { data: contributors } = useGetContributor(currentSafeAddress, "", [], 1000000);
    const [loadingSaveContributor, setLoadingSaveContributor] = useState(false);
    const addTagToContributor = useAddTagToContributor();
    const [personName, setPersonName] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();

    const handleSaveContributor = async () => {
        setLoadingSaveContributor(true);
        if (!personName) {
            enqueueSnackbar("Person Name can't be empty!", { variant: "error" });
            setLoadingSaveContributor(false);
            return;
        }
        if (
            contributors?.contributors?.find(
                ({ nickName }) => nickName.toLowerCase() === personName.toLowerCase(),
            )
        ) {
            enqueueSnackbar(personName + " is taken. Please try different name.", {
                variant: "error",
            });
            setLoadingSaveContributor(false);
            return;
        }
        try {
            const resolvedName = await formatAddress(walletAddress);
            if (resolvedName.isAddress) {
                await addSafeContributor({
                    safeAddress: currentSafeAddress,
                    address: utils.getAddress(walletAddress),
                    nickName: personName,
                });
            } else if (resolvedName.isENS) {
                await addSafeContributor({
                    safeAddress: currentSafeAddress,
                    address: utils.getAddress(resolvedName.fullAddress),
                    nickName: personName,
                    ens: resolvedName.ens,
                });
            } else {
                enqueueSnackbar("Invalid ENS/Address", { variant: "error" });
            }
            if (resolvedName.isAddress || resolvedName.isENS) {
                for (let tag of selectedTags) {
                    await addTagToContributor.mutateAsync({
                        safeAddress: currentSafeAddress,
                        address: resolvedName.fullAddress,
                        tagName: tag,
                    });
                }
                queryClient.invalidateQueries(GET_CONTRIBUTOR);
                queryClient.invalidateQueries(GET_TAGS);
                enqueueSnackbar("Record added Successfully!", {
                    variant: "success",
                });
            }
        } catch (error) {
            enqueueSnackbar("Unable to add record.", {
                variant: "error",
            });
        } finally {
            setLoadingSaveContributor(false);
            setOpenAddPeopleModal(false);
            setPersonName("");
            setSelectedTags([]);
        }
    };

    const handleSelectedTags = (tags, index) => {
        setSelectedTags(tags);
    };

    return (
        <Dialog open={openAddPeopleModal} fullWidth onClose={() => {}}>
            <Box
                display={"flex"}
                padding={"20px"}
                gap={"20px"}
                flexDirection={"column"}
                minWidth={"520px"}
            >
                <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                    <Typography
                        fontWeight={"500"}
                        fontSize={"18px"}
                        lineHeight={"18px"}
                        color={"#25274F"}
                    >
                        Add To Contacts
                    </Typography>
                    <ClearIcon
                        sx={{ color: "#636B81", cursor: "pointer" }}
                        onClick={() => {
                            setOpenAddPeopleModal(false);
                            setPersonName("");
                            setSelectedTags([]);
                        }}
                    />
                </Box>
                <Box
                    display={"flex"}
                    flexDirection={"column"}
                    border={"1px solid #DFE1E5"}
                    borderRadius={"4px"}
                    gap={"4px"}
                    padding={"12px"}
                >
                    <Typography
                        fontWeight={"600"}
                        fontSize={"12px"}
                        lineHeight={"12px"}
                        color={"#25274F"}
                    >
                        Wallet Address
                    </Typography>
                    <Typography
                        fontWeight={"500"}
                        fontSize={"12px"}
                        lineHeight={"16px"}
                        color={"#8B91A1"}
                    >
                        {walletAddress}
                    </Typography>
                </Box>
                <InputComponent
                    sx={{ background: "#F5F6F7" }}
                    wrapperStyle={{ paddingTop: "0px" }}
                    type="text"
                    value={personName}
                    onChange={e => setPersonName(e.target.value)}
                    label={"Person Name"}
                    placeholder="Name"
                    inputStyles={{
                        alignItems: "center",
                        background: "#F5F6F7",
                        color: "#636B81",
                        border: "none",
                        lineHeight: 1.5,
                    }}
                />
                <Box>
                    <Typography
                        fontWeight={"500"}
                        fontSize={"12px"}
                        lineHeight={"12px"}
                        color={"#636B81"}
                        marginBottom={"5px"}
                    >
                        Tag
                    </Typography>
                    <SearchFilterTags
                        isEdit={false}
                        value={selectedTags}
                        isCollasped={false}
                        setTagsToAddContributor={handleSelectedTags}
                        index={0}
                        fromTransferModule={true}
                    />
                </Box>
            </Box>
            <Box
                padding={"14px 20px"}
                sx={{ background: "#F5F8FE" }}
                display={"flex"}
                justifyContent={"flex-end"}
            >
                <LoadingButton
                    style={{ background: "#2962EF", color: "#ffffff", marginLeft: "auto" }}
                    onClick={handleSaveContributor}
                    loading={loadingSaveContributor}
                >
                    Save
                </LoadingButton>
            </Box>
        </Dialog>
    );
};

export default AddPeopleModal;
