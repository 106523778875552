import React, { Fragment, useState } from "react";
import Drawer from "@mui/material/Drawer";
import {
    WhatsNewHeader,
    WhatsNewHeaderTitle,
    WhatsNewBody,
    Card,
    CardDate,
    CardTitle,
    CardBodyText,
    CardBodyTextContent,
    CardBodyLink,
    CardBodyLinkContent,
    CardSubHeading,
    SeeMore,
} from "./styles";
import ParcelLogo from "assets/parcelPWithoutBg.svg";
import BlueArrow from "assets/blueArrow.svg";
import CloseIcon from "@mui/icons-material/Close";

const NewRelease = ({ drawerState, setDrawerState, setReleaseBannerSeenStorage = args => {} }) => {
    const newReleases = [
        {
            title: `Parcel v2.3.5 out now!`,
            date: "Monday, September 12, 2022",
            subheading: `Announcing Shareable Transaction Pages 🔥`,
            showMore: false,
            content: [
                {
                    text: [
                        {
                            li: `Share transaction pages with other signers to quickly collect approvals`,
                            hide: "false",
                        },
                        {
                            li: `Share public transaction details with your community, no logins required`,
                            hide: "false",
                        },
                        {
                            li: `Open any transaction in the app and Click on "Share Transaction" button in the top right to copy the link`,
                            hide: "false",
                        },
                    ],
                    link: "false",
                    hide: "false",
                },
            ],
        },
        {
            title: `Parcel x Tenderly`,
            date: "Monday, August 22, 2022",
            subheading: `Simulate Transactions using Tenderly on Parcel 🔥`,
            showMore: false,
            content: [
                {
                    text: [
                        {
                            li: `Tenderly is a tool for developers to review, monitor and simulate the smart contracts they've written.`,
                            hide: "false",
                        },
                        {
                            li: `With the integration of Tenderly into Parcel, users now have the ability to simulate every single transaction that they plan to create from Parcel and get an overview of the transaction flow.`,
                            hide: "false",
                        },
                        {
                            li: `Having done a simulation, you can confidently sign transactions with full awareness of how it will look like on-chain.
                            `,
                            hide: "false",
                        },
                    ],
                    link: "false",
                    hide: "false",
                },
            ],
        },
        {
            title: `Parcel v2.3.0 is Live now!`,
            date: "Saturday, July 30, 2022",
            subheading: "Introducing Squads and Budgeting in Private Beta 💥",
            showMore: false,
            content: [
                {
                    text: [
                        {
                            li: `Add and Track multisigs from all the squads across your organisation.`,
                            hide: "false",
                        },
                        {
                            li: `Receive Fixed or Milestone based Budget requests from your squads and process them.`,
                            hide: "false",
                        },
                        {
                            li: `Currently Available only in Private beta. DM us on discord to gain access.`,
                            hide: "false",
                        },
                    ],
                    link: "false",
                    hide: "false",
                },
            ],
        },
        {
            title: `Introducing Nested Multisigs!`,
            date: "Saturday, July 16, 2022",
            subheading:
                "Nested safes are normal Gnosis safes but with a tiny upgrade: A safe address can also be a signer! 💯",
            showMore: false,
            content: [
                {
                    text: [
                        {
                            li: `Now you can Toggle between nested safes within our transactions tab.`,
                            hide: "false",
                        },
                        {
                            li: `View a segregated context rich tx history of each nested safe.`,
                            hide: "false",
                        },
                        {
                            li: `Perform signer duties on multiple subgroups with smooth transaction workflows.`,
                            hide: "false",
                        },
                    ],
                    link: "false",
                    hide: "false",
                },
                {
                    text: "Read more about Nested Multisigs",
                    link: "true",
                    hide: "false",
                    href: "https://blog.parcel.money/parcel-v2-2-4-launching-nested-safes/",
                },
            ],
        },
        {
            title: `Parcel X Coordinape`,
            date: "Saturday, July 2, 2022",
            subheading: "Coordinape Integration is now live on Parcel! 💫",
            showMore: false,
            content: [
                {
                    text: [
                        {
                            li: `Connect your Coordinape account and pay the participants of Completed Epochs.`,
                            hide: "false",
                        },
                        {
                            li: `Select budget in multiple tokens in token values or USD values and distribute based on Coordinape results.`,
                            hide: "false",
                        },
                        {
                            li: `Open Integrations -> Coordinape and try now!`,
                            hide: "false",
                        },
                    ],
                    link: "false",
                    hide: "false",
                },
            ],
        },
        {
            title: `Parcel v2.2.1 is live now!`,
            date: "Monday, June 20, 2022",
            subheading: "Introducing email notifications on Parcel! 🤟",
            showMore: false,
            content: [
                {
                    text: [
                        {
                            li: `Now enable email alerts for all transactions created on Parcel and speed up approvals with other signers.
                            `,
                            hide: "false",
                        },
                    ],
                    link: "false",
                    hide: "false",
                },
            ],
        },
    ];

    const [seeMoreState, setSeeMoreState] = useState({});

    const handleSeeMore = index => {
        const tempState = seeMoreState;
        tempState[index] = !seeMoreState[index];

        setSeeMoreState({ ...tempState });
    };

    const handleDrawerClose = () => {
        setReleaseBannerSeenStorage(true);
        setDrawerState(false);
        setSeeMoreState({});
    };

    return (
        <Drawer anchor="right" open={drawerState} onClose={() => handleDrawerClose()}>
            <WhatsNewHeader>
                <img src={ParcelLogo.src} />
                <WhatsNewHeaderTitle> What's new on Parcel </WhatsNewHeaderTitle>
                <CloseIcon onClick={handleDrawerClose} style={{ cursor: "pointer" }} />
            </WhatsNewHeader>
            <WhatsNewBody>
                {newReleases.map((item, index) => {
                    return (
                        <Card key={index}>
                            <CardDate>{item.date}</CardDate>
                            <CardTitle>{item.title}</CardTitle>
                            <CardSubHeading>{item.subheading}</CardSubHeading>

                            {item.content.map((textItem, t) => {
                                return (
                                    <Fragment key={t}>
                                        {textItem.link === "true" &&
                                            (seeMoreState[index] || textItem.hide === "false") && (
                                                <CardBodyLink>
                                                    <CardBodyLinkContent
                                                        href={textItem.href}
                                                        className="blue-text"
                                                        target="_blank"
                                                    >
                                                        {textItem.text}
                                                    </CardBodyLinkContent>
                                                    <img
                                                        src={BlueArrow.src}
                                                        className="blue-arrow"
                                                    ></img>
                                                </CardBodyLink>
                                            )}
                                        {textItem.link === "false" &&
                                            typeof textItem.text === "object" &&
                                            (seeMoreState[index] || textItem.hide === "false") && (
                                                <CardBodyText>
                                                    {textItem.text.map((text, index) => {
                                                        return (
                                                            <CardBodyTextContent key={index}>
                                                                {text.li}
                                                            </CardBodyTextContent>
                                                        );
                                                    })}
                                                </CardBodyText>
                                            )}
                                    </Fragment>
                                );
                            })}

                            {item.showMore && (
                                <SeeMore onClick={() => handleSeeMore(index)}>
                                    {seeMoreState[index] ? `Show Less -` : `Show More +`}
                                </SeeMore>
                            )}
                        </Card>
                    );
                })}
            </WhatsNewBody>
        </Drawer>
    );
};

export default NewRelease;
