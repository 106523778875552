import React, { useContext, useEffect, useState } from "react";
import { ModuleHeading } from "../styles/common";
import TransferContext from "src/contexts/TransferContext";
import { WorkflowTransferStyled } from "./styles/WorkflowTransfer";

import { Box, Button } from "@mui/material";
import {
    MassPayoutStepNameStyled,
    MassPayoutStyled,
    SafeBalance,
    SafeBalanceBlock,
    SlantDivider,
    TagAndBalanceBox,
    TitleHolder,
} from "./styles/MassPayout";
import SearchWorkflow from "./SearchWorkflow";
import Table from "./Table";
import ReviewScreen from "./ReviewScreen";
import SelectedTagsBlock from "./SelectedTagsBlock";
import { LoadingButton } from "@mui/lab";
import { CSVTableAction } from "../CSVPayout/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useGetParollBySafe } from "src/queries/Workflow/api";
import { useUpdatePayrollImplementation } from "src/queries/PayrollWorkflow/api";
import { GET_PAYROLL_BY_SAFE } from "src/queries/constants";
import SafeContext from "src/contexts/SafeContext";
import { CheckIfLimitsAreNotConsumed } from "src/queries/Safe/api";
import { useActiveBaseContext, useContract } from "src/hooks";
import { useSafeSdk } from "src/hooks/useSafeSdk";
import addresses from "src/constants/addresses";
import ERC20ABI from "src/constants/abis/ERC20.json";
const MULTISEND_ADDRESS = addresses.MULTISEND_ADDRESS;
import MultiSendABI from "src/constants/abis/MultiSend.json";
import { useSnackbar } from "notistack";
import createMassPayout from "src/helpers/createMassPayout";
import router from "next/router";
import { updateTransactionIdToBudget, useCreateTransaction } from "src/queries/Transaction/api";
import MetaTxModal from "src/components/MetaTxModal";
import { queryClient } from "pages/_app";
import { formatAmount } from "src/helpers/utils/web3Utils";
import { getPayoutGroupByTag } from "src/helpers/Payroll";
import { PayrollContext } from "src/contexts/PayrollContext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Image from "next/image";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import GnosisSafeABI from "src/constants/abis/GnosisSafe.json";
import { Typography } from "@mui/material";
import { etherscan } from "src/constants/endpoints";
import { sendEmail } from "src/queries/emailNotification";
import useEstimateGasContract from "src/hooks/useEstimateGasContract";
import ParcelPayrollBanner from "src/components/ParcelPayrollBanner";

const WorkflowTransfer = () => {
    const { getGasEstimateTransactionExecution } = useEstimateGasContract();
    const {
        ethersAdapterOwner,
        safeService,
        library,
        account,
        isWalletEOA,
        chainId,
        isHardwareWallet,
        isGasLowEnough,
    }: any = useActiveBaseContext().web3;
    const {
        setWalletAddress,
        setWalletNickName,
        description,
        setDescription,
        gasLimit,
        payrollPreviewStep,
        MASSPAYOUT_MAX_STEPS,
        isTxStarted,
        setPayrollPreviewStep,
        setIsTxStarted,
        closeModal,
        resetTransfer,
        transferType,
        isEligibleTx,
        setIsEligibleTx,
        setCategory,
        category,
    }: any = useContext(TransferContext);
    const {
        setPayoutQueueByTag,
        payoutQueueByTag,
        resetPayout,
        selectedTags,
        setSelectedTags,
        tagInfocus,
        setTagInFocus,
        selectedPayroll,
        setSelectedPayroll,
    }: any = useContext(PayrollContext);
    const {
        getSafeBalance,
        currentSafeAddress,
        safeStaticDetails: { isMetaTxEnabled = false, owners: _owners = [], safeName = "" } = {},
        tokensInSafe,
        isDelegateAccess,
        isDelegateCanCreateTx,
        safeDetailsFromGnosis,
        payrollStatus,
    }: any = useContext(SafeContext);
    const customToken = useContract(addresses.ZERO_ADDRESS, ERC20ABI, true, library, account);
    const multiSend = useContract(MULTISEND_ADDRESS, MultiSendABI, true, library, account);
    const proxyContract = useContract(currentSafeAddress, GnosisSafeABI, true, library, account);

    const [isMetaTxLimitAllowed, setIsMetaTxLimitAllowed] = useState(false);
    const { data, status, isLoading } = useGetParollBySafe(currentSafeAddress, ["active"]);
    const [payrollData, setPayrollData] = useState([]);
    const [txStatus, setTxStatus] = useState<any>({
        status: null,
        data: {
            message: "Gathering Information",
        },
    });
    const [openModal, setOpenModal] = useState(false);
    const [budget, setBudget] = useState(null);

    const { safeSdk } = useSafeSdk(ethersAdapterOwner, currentSafeAddress);

    const { enqueueSnackbar } = useSnackbar();

    const { mutateAsync } = useCreateTransaction();

    const [searchTerm, setSearchTerm] = useState("");
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);

    const { mutateAsync: updatePayroll } = useUpdatePayrollImplementation();
    const { mutateAsync: linkTransactionIdToBudget } = updateTransactionIdToBudget();

    useEffect(() => {
        CheckIfLimitsAreNotConsumed(currentSafeAddress)
            .then(data => setIsMetaTxLimitAllowed(data))
            .catch(err => setIsMetaTxLimitAllowed(false));
    }, [currentSafeAddress]);

    useEffect(() => {
        if (status === "success" && data?.length > 0) {
            setPayrollData(data);
        }
    }, [data, status]);

    //client side filtering
    useEffect(() => {
        if (searchTerm && !!data && data.length > 0) {
            setPayrollData(
                data?.filter(({ name }) => name.toLowerCase().includes(searchTerm.toLowerCase())),
            );
        } else {
            setPayrollData(data);
        }
    }, [searchTerm, data]);

    useEffect(() => {
        if (payrollPreviewStep == 2) {
            setIsEligibleTx(true);
            return;
        }
    }, [payrollPreviewStep]);

    const handleCloseConfirmationModal = () => {
        setOpenConfirmationModal(false);
    };

    const showReviewPage = (payrollId, wfName, payoutSelected) => {
        const payout: any = getPayoutGroupByTag(payoutSelected, tokensInSafe);
        setSelectedPayroll({
            payrollId,
            name: wfName,
        });
        setPayoutQueueByTag(payout);
        setTagInFocus(Object.keys(payout)[0]);
        setSelectedTags([...Object.keys(payout)]);
        setDescription("");
        setPayrollPreviewStep(payrollPreviewStep + 1);
    };

    const handleCreateTxWithMetaTransaction = async () => {
        if (isMetaTxEnabled && isMetaTxLimitAllowed) {
            try {
                setIsTxStarted(true);
                const threshold = await safeSdk.getThreshold();
                if (threshold && threshold == 1) return setOpenConfirmationModal(true);
                handleCreateTx();
            } catch (err) {
                handleCreateTx();
            } finally {
                setIsTxStarted(false);
            }
        } else {
            handleCreateTx();
        }
    };

    const handleCreateTx = async () => {
        let txstatus = await createMassPayout(
            safeService,
            safeSdk,
            payoutQueueByTag,
            currentSafeAddress,
            customToken,
            multiSend,
            library,
            account,
            enqueueSnackbar,
            setIsTxStarted,
            closeModal,
            isMetaTxEnabled,
            isMetaTxLimitAllowed,
            setOpenModal,
            setTxStatus,
            isDelegateAccess,
            isDelegateCanCreateTx,
            isWalletEOA,
            safeDetailsFromGnosis?.version,
            chainId,
            isHardwareWallet,
            getGasEstimateTransactionExecution,
            ethersAdapterOwner,
            isGasLowEnough,
        );
        setTxStatus(txstatus);
        if (txstatus.status) {
            let disbursement = [];

            for (let tag in payoutQueueByTag) {
                payoutQueueByTag[tag].map(
                    (people: { selectedTokens: any; address: any; tags: any }) => {
                        if (people.selectedTokens) {
                            people.selectedTokens.map(token => {
                                disbursement.push({
                                    address: people.address,
                                    amount: parseFloat(token.amount),
                                    token: token.token.symbol,
                                    fiatValue: token.token.fiatConversion * token.amount,
                                    fiatCurrency: "USD",
                                    tokenValue: parseFloat(token.amount),
                                    tagName: tag,
                                    disbursementType: category,
                                });
                            });
                        }
                    },
                );
            }
            const { addTransaction } = await mutateAsync({
                txParams: {
                    transaction: {
                        description: description.trim(),
                        paymentType: transferType,
                        nonce: txstatus.data.nonce,
                        status: txstatus.data.status,
                        transactionHash: txstatus.data.txHash,
                        transactionFees: 0,
                        TransactionDisbursementDetails: disbursement,
                    },
                },
                currentSafeAddress,
            });

            // link transaction to budget
            // budget is null by default or 0 if selected "do not link with any budget"
            if (budget) {
                await linkTransactionIdToBudget({
                    safeAddress: currentSafeAddress,
                    transactionId: addTransaction.transactionId,
                    budgetId: budget,
                });
            }

            let updatedBy = _owners.find(owner => owner.address == account);
            if (txstatus?.data?.status === "Pending") {
                const filteredOtherOwners = _owners
                    .filter(owner => owner.address != account)
                    .map(({ ownerEmail, nickName }) => {
                        return {
                            emailAddress: ownerEmail,
                            ownerName: nickName ? nickName : "Safe Owner",
                        };
                    });
                const payeesLength = Object.values(payoutQueueByTag).reduce(
                    (prev, item: any) => (prev += item?.length),
                    0,
                );
                sendEmail(
                    payeesLength,
                    safeName,
                    filteredOtherOwners,
                    description.trim(),
                    updatedBy?.nickName,
                    addTransaction.transactionId,
                );
            }

            await updatePayroll({
                payrollId: selectedPayroll?.payrollId,
                transactionId: addTransaction.transactionId,
                safeAddress: currentSafeAddress,
            });

            queryClient.invalidateQueries(GET_PAYROLL_BY_SAFE);
        } else {
            enqueueSnackbar("Error", { variant: "error" });
        }
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setTxStatus({
            status: null,
            data: {
                message: "Gathering Information",
            },
        });
        closeModal();
        if (txStatus.status) {
            resetPayout();
            resetTransfer();
            router.push("/transactions");
        }
    };
    return (
        <MassPayoutStyled>
            {payrollStatus?.whitelisted && <ParcelPayrollBanner />}
            {payrollPreviewStep === 1 && (
                <>
                    <ModuleHeading>Due Payouts</ModuleHeading>
                    <SearchWorkflow
                        searchTerm={searchTerm}
                        setSearchTerm={setSearchTerm}
                        setWalletAddress={setWalletAddress}
                        setWalletNickName={setWalletNickName}
                    />
                    <Table
                        rows={payrollData}
                        loading={isLoading}
                        setLimit={5}
                        limit={10}
                        total={payrollData?.length}
                        count={5}
                        showReviewPage={showReviewPage}
                    />
                </>
            )}

            {payrollPreviewStep === 2 && (
                <>
                    <TitleHolder direction={"row"}>
                        <ModuleHeading>Due Payouts</ModuleHeading>
                        <SlantDivider>/</SlantDivider>
                        <MassPayoutStepNameStyled>{selectedPayroll?.name}</MassPayoutStepNameStyled>
                    </TitleHolder>

                    <TagAndBalanceBox direction={"row"}>
                        <SelectedTagsBlock
                            tags={selectedTags}
                            payoutQueueByTag={payoutQueueByTag}
                            tagInfocus={tagInfocus}
                            setTagInFocus={setTagInFocus}
                        />
                        <SafeBalanceBlock>
                            <SafeBalance>
                                <span className="side-heading">Total Balance</span>
                                <span className="balance">${formatAmount(getSafeBalance())}</span>
                            </SafeBalance>
                        </SafeBalanceBlock>
                    </TagAndBalanceBox>

                    <ReviewScreen
                        description={description}
                        setDescription={setDescription}
                        payoutQueueByTag={payoutQueueByTag}
                        tagInfocus={tagInfocus}
                        setCategory={setCategory}
                        budget={budget}
                        setBudget={setBudget}
                        category={category}
                    />
                    <CSVTableAction>
                        <Button
                            variant="outlined"
                            onClick={() => setPayrollPreviewStep(payrollPreviewStep - 1)}
                            startIcon={<ArrowBackIosIcon style={{ fontSize: "12px" }} />}
                        >
                            Back
                        </Button>

                        <Box className="flex">
                            <LoadingButton
                                loading={isTxStarted}
                                variant="contained"
                                disabled={!description.trim() || isEligibleTx === false}
                                onClick={() => handleCreateTxWithMetaTransaction()}
                            >
                                Create Transaction
                            </LoadingButton>
                        </Box>
                    </CSVTableAction>
                    <MetaTxModal
                        open={openConfirmationModal}
                        handleCloseConfirmationModal={handleCloseConfirmationModal}
                        handleConfirm={() => {
                            setOpenConfirmationModal(false);
                            handleCreateTx();
                        }}
                        variant={isGasLowEnough ? "confirm" : "highGas"}
                    />
                </>
            )}
            <Dialog open={openModal} maxWidth="xs" fullWidth onClose={() => {}}>
                <Box width="100%" textAlign="right">
                    {txStatus.status !== null && (
                        <ClearIcon
                            sx={{ color: "#636B81", my: "10px", mx: "20px", cursor: "pointer" }}
                            onClick={handleCloseModal}
                        />
                    )}
                </Box>
                <Box px={"20px"} pt={"30px"} pb={"20px"} textAlign="center">
                    {txStatus.status === null && (
                        <>
                            <CircularProgress
                                variant={"indeterminate"}
                                size={100}
                                thickness={2}
                                sx={{
                                    color: "primary",
                                }}
                                value={100}
                            />

                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {txStatus.data?.hash && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        href={`${etherscan}/tx/${txStatus.data.hash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                        target="_blank"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )}
                            <Typography fontSize="18px" fontWeight={600} sx={{ mt: "5px" }}>
                                {txStatus.data.message}
                            </Typography>
                        </>
                    )}
                    {txStatus.status === true && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-icon.png" />
                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {txStatus.data.status !== "Pending" && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        target="_blank"
                                        href={`${etherscan}/tx/${txStatus.data.txHash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )}
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "5px", mb: "20px" }}
                            >
                                {isWalletEOA ? txStatus?.data?.message : `Transaction Created`}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                            >
                                Close
                            </Button>
                        </>
                    )}
                    {txStatus.status === false && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-failed.png" />
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "20px", mb: "20px" }}
                            >
                                {txStatus?.data?.message
                                    ? txStatus?.data?.message
                                    : "Transaction Rejected"}
                            </Typography>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                            >
                                Dismiss
                            </Button>
                        </>
                    )}
                </Box>
            </Dialog>
        </MassPayoutStyled>
    );
};

export default WorkflowTransfer;
