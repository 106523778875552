import React from "react";
import Collapse from "@mui/material/Collapse";
import { Box, Typography } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import PeopleCard from "./PeopleCard";
function TagCollapse({ tag, people, deletePeople }) {
    const [open, setOpen] = React.useState(true);

    const handleClick = () => {
        setOpen(!open);
    };
    return (
        <>
            {people && people.length > 0 && (
                <>
                    <Box
                        sx={{
                            background: "#EFF0F2",
                            py: "5px",
                            px: "16px",
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: !open ? 0.3 : 0,
                            alignItems: "center",
                            cursor: "pointer",
                        }}
                        onClick={handleClick}
                    >
                        <Typography fontSize="12px" lineHeight={1} color="#636B81">
                            {tag}
                        </Typography>
                        {open ? (
                            <ArrowDropDownIcon style={{ color: "#636B81", fontSize: "1rem" }} />
                        ) : (
                            <ArrowDropUpIcon style={{ color: "#636B81", fontSize: "1rem" }} />
                        )}
                    </Box>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        {people?.map(people => {
                            return (
                                <PeopleCard
                                    key={people.address}
                                    data={people}
                                    deletePeople={deletePeople}
                                    tag={tag}
                                />
                            );
                        })}
                    </Collapse>
                </>
            )}
        </>
    );
}

export default TagCollapse;
