import styled from "@emotion/styled";

export const TransferModuleBodyStyled = styled.div`
    flex: 100%;
    overflow: auto;
`;

export const TransferBodyContainer = styled.div`
    margin: 0 auto;
    @media only screen and (min-width: 1200px) {
        max-width: 1140px;
    }

    @media only screen and (max-width: 1200px) {
        max-width: 960px;
    }

    @media only screen and (max-width: 992px) {
        max-width: 720px;
    }

    @media only screen and (max-width: 768px) {
        max-width: 540px;
    }

    @media only screen and (max-width: 576px) {
        max-width: auto;
    }
`;
