import React, { useCallback, useContext, useRef, useState, useEffect } from "react";
import {
    HeaderWrapper,
    HeaderActions,
    ImgeWrapper,
    HeaderActionBtn,
    StyledPopOver,
    HeaderSWitch,
    StyledLink,
    HeaderText,
} from "./styles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Image from "next/image";
import { Link, Typography } from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { ChainId, minifyAddress } from "src/helpers/utils/web3Utils";
import Popper from "@mui/material/Popper";
import Box from "@mui/material/Box";
import { Grow, ClickAwayListener } from "@mui/material";
//context imports
import { useActiveBaseContext } from "src/hooks";
import CopyOnClick from "src/components/common/Copy/CopyOnClick";
import { selectWalletIcon } from "src/helpers/selectWalletIcon";
import { useRouter } from "next/router";
import TransferContext from "src/contexts/TransferContext";
import { useDisconnect } from "src/queries/onboard/api";
import ParcelLogo from "assets/parcel_logo.svg";
import EthIcon from "assets/eth_icon.svg";
import PolygonIcon from "assets/polygon_icon.svg";
import NewReleaseIcon from "assets/newReleasesActive.svg";
import NewReleaseIconInactive from "assets/newReleasesInactive.svg";
import {
    activeNetworks,
    chainIds,
    findNetworkNameByWeb3ChainId,
    getBlockExplorerURL,
    getNetworkIcon,
    getNetworkName,
    getParcelFrontendAppUrl,
    networkId,
} from "src/helpers/utils/networks";
import { ContributorProfile, SwitchProfileIconDAO } from "src/components/CustomIcons/NavBarIcons";
import NetworkChangeModal from "src/components/NetworkChangeModal";
import NewRelease from "src/components/NewReleases";
import useLocalStorage from "src/hooks/useLocalStorage";
import { RELEASE_VERSION } from "src/queries/constants";
import { BaseContextTypes } from "src/contexts/BaseContextTypes";

function Header() {
    const [releaseBannerSeenStorage, setReleaseBannerSeenStorage] = useLocalStorage(
        "SEEN_RELEASE_BANNER" + RELEASE_VERSION,
        false,
    );
    const [releaseBannerLocalState, setReleaseBannerLocalState] = useState(false);

    useEffect(() => {
        if (releaseBannerLocalState != releaseBannerSeenStorage)
            setReleaseBannerLocalState(releaseBannerSeenStorage);
    }, [releaseBannerSeenStorage]);
    const router = useRouter();
    const [open, setOpen] = React.useState(false);
    const [openSwitchRole, setOpenSwitchRole] = React.useState(false);
    const { web3 } = useActiveBaseContext();
    const [role, setRole] = useState("");
    const { refetch } = useDisconnect({
        refetchOnWindowFocus: false,
        enabled: false,
    });
    const {
        disconnectWallet,
        account,
        setIsLoggedIn,
        connector,
        active,
        isNetworkChanged,
        ensName,
        setChain,
    } = web3 as BaseContextTypes;
    const { closeModal, resetTransfer }: any = useContext(TransferContext);
    const [showSwitch, setShowSwitch] = useState(true);
    const [showLogo, setShowLogo] = useState(false);
    const [drawerState, setDrawerState] = useState(false);
    const handleDisconnect = useCallback(() => {
        disconnectWallet({
            label: connector?.label,
        });
        window?.localStorage.removeItem("selectedWallet");
        closeModal();
        resetTransfer();
        window.localStorage.removeItem("parcelv2_lastLoggedInSafeAddress");
        refetch().then(() => {
            setIsLoggedIn(false);
            router.push("/");
        });
    }, [disconnectWallet, active]);

    const disconnectRef = useRef(null);
    const switchToRole = useRef(null);
    const clickAway = () => {
        setOpen(false);
    };
    const roleClickAway = () => {
        setOpenSwitchRole(false);
    };
    const toggle = () => {
        setOpen(prev => !prev);
    };
    const toggleRole = () => {
        setOpenSwitchRole(prev => !prev);
    };

    useEffect(() => {
        router?.pathname.startsWith("/contributor") ? setRole("Contributor") : setRole("DAO");
        router?.pathname.startsWith("/onboard") || router?.pathname === "/"
            ? setShowLogo(true)
            : setShowLogo(false);
        router?.pathname.includes("select_role") || router?.pathname === "/"
            ? setShowSwitch(false)
            : setShowSwitch(true);
    }, [router.pathname]);
    const renderNetworkNameAndIcon = networkId => {
        return (
            <div style={{ display: "flex" }}>
                <span>
                    <img
                        src={getNetworkIcon(networkId)}
                        style={{ height: "16px", verticalAlign: "middle" }}
                    />
                </span>
                <Box
                    ml="7px"
                    mr="10px"
                    style={{
                        alignSelf: "center",
                    }}
                >
                    {getNetworkName(networkId)}
                </Box>
            </div>
        );
    };
    const dropdownOptions = currentNetworkId => {
        return activeNetworks
            .filter(networkId => networkId !== currentNetworkId)
            .map(chainId => {
                return (
                    <ClickAwayListener
                        mouseEvent="onClick"
                        onClickAway={roleClickAway}
                        touchEvent={false}
                    >
                        <a href={getParcelFrontendAppUrl(chainId)}>
                            <StyledPopOver
                                sx={{
                                    marginTop: "0px !important",
                                }}
                                color="title.main"
                            >
                                <div style={{ display: "flex" }}>
                                    <span>
                                        <img
                                            src={getNetworkIcon(chainId)}
                                            style={{ height: "16px", verticalAlign: "middle" }}
                                        />
                                    </span>
                                    <Box
                                        ml="7px"
                                        mr="10px"
                                        style={{
                                            alignSelf: "center",
                                        }}
                                    >
                                        {getNetworkName(chainId)}
                                    </Box>
                                </div>
                            </StyledPopOver>
                        </a>
                    </ClickAwayListener>
                );
            });
    };
    const handleNetworkSwtich = async () => {
        // await connector.provider.request({
        //     method: 'wallet_switchEthereumChain',
        //     params: [
        //       {
        //         chainId: u
        //       },
        //     ],
        //   })
        await setChain({ chainId: networkId });
    };

    return (
        <HeaderWrapper>
            {isNetworkChanged && router?.pathname !== "/" && (
                <NetworkChangeModal
                    showModal={isNetworkChanged && router?.pathname !== "/"}
                    handleNetworkSwtich={handleNetworkSwtich}
                    buttonText={`Switch wallet to ${findNetworkNameByWeb3ChainId(networkId)}`}
                />
            )}
            {showLogo && (
                <div style={{ marginRight: "auto", alignSelf: "center" }}>
                    <img src={ParcelLogo.src} style={{ height: "30px", verticalAlign: "middle" }} />
                </div>
            )}
            {isNetworkChanged && router?.pathname !== "/" && (
                <HeaderText p={3} mr="8px" onClick={handleNetworkSwtich}>
                    <Typography className="account-address">
                        Switch wallet to {findNetworkNameByWeb3ChainId(networkId)}
                    </Typography>
                </HeaderText>
            )}
            {!showSwitch && (
                <Box ref={switchToRole} onClick={toggleRole} className="role-box">
                    <HeaderSWitch mr={1}>
                        {renderNetworkNameAndIcon(networkId)}
                        {openSwitchRole ? (
                            <ArrowDropUpIcon fontSize="small" />
                        ) : (
                            <ArrowDropDownIcon fontSize="small" />
                        )}
                    </HeaderSWitch>
                    <Popper
                        anchorEl={switchToRole.current}
                        open={openSwitchRole}
                        placement="bottom"
                    >
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <>{dropdownOptions(networkId)}</>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            )}

            {releaseBannerLocalState ? (
                <img
                    src={NewReleaseIconInactive.src}
                    onClick={() => setDrawerState(true)}
                    className="new-release-box"
                />
            ) : (
                <img
                    src={NewReleaseIcon.src}
                    onClick={() => setDrawerState(true)}
                    className="new-release-box"
                />
            )}

            {web3?.account && (
                <>
                    {showSwitch && (
                        <Box ref={switchToRole} onClick={toggleRole} className="role-box">
                            <HeaderSWitch mr={1}>
                                {/* <DeviceHubIcon fontSize="small" className="icon" /> */}
                                {role === "DAO" ? (
                                    <SwitchProfileIconDAO size="small" color="#25274F" />
                                ) : (
                                    <ContributorProfile size="small" color="#25274F" />
                                )}
                                <Box ml="7px" mr="10px">
                                    {role}
                                </Box>
                                {openSwitchRole ? (
                                    <ArrowDropUpIcon fontSize="small" />
                                ) : (
                                    <ArrowDropDownIcon fontSize="small" />
                                )}
                            </HeaderSWitch>
                            <Popper
                                anchorEl={switchToRole.current}
                                // className={classes.popper}
                                open={openSwitchRole}
                                placement="bottom"
                                // popperOptions={{ positionFixed: true }}
                            >
                                {({ TransitionProps }) => (
                                    <Grow {...TransitionProps}>
                                        <>
                                            <ClickAwayListener
                                                mouseEvent="onClick"
                                                onClickAway={roleClickAway}
                                                touchEvent={false}
                                            >
                                                <StyledPopOver
                                                    color="title.main"
                                                    onClick={() =>
                                                        role === "DAO"
                                                            ? router.push("/contributor/profile")
                                                            : router.push(
                                                                  `/onboard/safe_list/${account}`,
                                                              )
                                                    }
                                                >
                                                    Switch to
                                                    <Typography
                                                        component="span"
                                                        ml="3px"
                                                        fontWeight={500}
                                                        fontSize="14px"
                                                        sx={{
                                                            textTransform: "none",
                                                        }}
                                                    >
                                                        {role == "DAO" ? "contributor" : "DAO"}
                                                    </Typography>
                                                </StyledPopOver>
                                            </ClickAwayListener>
                                        </>
                                    </Grow>
                                )}
                            </Popper>
                        </Box>
                    )}
                    <HeaderActions>
                        <CopyOnClick
                            style={{ dispalay: "inline" }}
                            value={account}
                            text="Click to Copy Wallet Address"
                        >
                            <Typography className="account-address">
                                {!!ensName ? minifyAddress(ensName) : minifyAddress(account)}
                            </Typography>
                        </CopyOnClick>
                        <ImgeWrapper ml={1.5}>
                            <Image
                                src={
                                    selectWalletIcon[connector?.label || "MetaMask"] ||
                                    selectWalletIcon["MetaMask"]
                                }
                                alt="wallet icon"
                                height="16px"
                                width="16px"
                            />
                        </ImgeWrapper>
                    </HeaderActions>
                </>
            )}
            {web3?.account && (
                <Box ref={disconnectRef} onClick={toggle}>
                    <HeaderActionBtn ml={1}>
                        <MoreHorizIcon className="icon" />
                    </HeaderActionBtn>
                    <Popper
                        anchorEl={disconnectRef.current}
                        // className={classes.popper}
                        open={open}
                        placement="bottom"
                        // popperOptions={{ positionFixed: true }}
                    >
                        {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                                <>
                                    <ClickAwayListener
                                        mouseEvent="onClick"
                                        onClickAway={clickAway}
                                        touchEvent={false}
                                    >
                                        <StyledPopOver
                                            onClick={handleDisconnect}
                                            color="title.main"
                                        >
                                            Disconnect
                                        </StyledPopOver>
                                    </ClickAwayListener>
                                    <ClickAwayListener
                                        mouseEvent="onClick"
                                        onClickAway={clickAway}
                                        touchEvent={false}
                                    >
                                        <StyledLink
                                            href={`${getBlockExplorerURL(
                                                networkId,
                                            )}/address/${account}`}
                                            target="_blank"
                                        >
                                            View On Etherscan
                                        </StyledLink>
                                    </ClickAwayListener>
                                </>
                            </Grow>
                        )}
                    </Popper>
                </Box>
            )}
            <NewRelease
                drawerState={drawerState}
                setDrawerState={setDrawerState}
                setReleaseBannerSeenStorage={setReleaseBannerSeenStorage}
            />
        </HeaderWrapper>
    );
}

export default Header;
