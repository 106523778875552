import { useRef, useContext } from "react";
import { InfoOutlined } from "@mui/icons-material";
import SelectUnstyled, {
    SelectUnstyledProps,
    selectUnstyledClasses,
    SelectOption,
} from "@mui/base/SelectUnstyled";
import OptionUnstyled, { optionUnstyledClasses } from "@mui/base/OptionUnstyled";
import PopperUnstyled from "@mui/base/PopperUnstyled";
import { Box, styled } from "@mui/system";
import { Typography } from "@mui/material";
import StyledTooltip from "src/components/StyledTooltip";
import { TypographyStyled } from "./BudgetSelect";
import SquadContext from "src/contexts/SquadContext";
import SafeContext from "src/contexts/SafeContext";

const blue = {
    100: "#DAECFF",
    200: "#99CCF3",
    400: "#3399FF",
    500: "#007FFF",
    600: "#0072E5",
    900: "#003A75",
};

const grey = {
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
};

let inputElement = null;

const StyledOption = styled(OptionUnstyled)(
    ({ theme }) => `
    width:100%;

 
  list-style: none;
  padding: 8px;
  margin:4px 0px;
  border-radius: 0.45em;
  cursor: pointer;

  &:last-of-type {
    border-bottom: none;
  }

  &.${optionUnstyledClasses.selected} {
    background-color: #F5F8FE;
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.highlighted} {
    background-color: #F5F8FE;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &.${optionUnstyledClasses.highlighted}.${optionUnstyledClasses.selected} {
    background-color: #F5F8FE;
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionUnstyledClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionUnstyledClasses.disabled}) {
    background-color: #F5F8FE;
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `,
);

const StyledButton = styled("button")(
    ({ theme }) => `
  font-size: 14px;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  width:100%;
  background: #F5F6F7;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: #636B81;
  font-weight: 400;
  cursor: pointer;
 

  &:hover {
    background: ${theme.palette.mode === "dark" ? "" : grey[100]};
    border-color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &.${selectUnstyledClasses.expanded} {
    &::after {
      content: '▴';
    }
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const DisabledStyledButton = styled("button")(
    ({ theme }) => `
  font-size: 14px;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  width:100%;
  background: rgb(223, 225, 229) !important;
  border: none;
  border-radius: 4px;
  margin: 5px 0;
  padding: 10px;
  text-align: left;
  line-height: 1.5;
  color: #636B81;
  font-weight: 400;
  cursor: no-drop;
 
  &.${selectUnstyledClasses.focusVisible} {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[100]};
  }

  &::after {
    content: '▾';
    float: right;
  }
  `,
);

const StyledListbox = styled("ul")(
    ({ theme }) => `
  width: 100%;
  max-height: 250px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 14px;
  margin: 10px 0;
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  overflow: auto;
  outline: 0px;
  background: #FFFFFF;
  /* Grey/7 */
  border: 1px solid #F5F6F7;
  box-sizing: border-box;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 64px 128px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar { 
    width: 0 !important;
    display: none;
  `,
);

const StyledPopper = styled(PopperUnstyled)`
    z-index: 1;
`;

function CustomSelect(props) {
    const { disabled = false } = props;
    const RootComponent = disabled ? DisabledStyledButton : StyledButton;
    const components: SelectUnstyledProps<number>["components"] = {
        Root: RootComponent,
        Listbox: StyledListbox,
        Popper: StyledPopper,
        ...props.components,
    };
    inputElement = useRef();
    return <SelectUnstyled {...props} ref={inputElement} components={components} />;
}

function renderValue(option: SelectOption<number> | null) {
    return (
        <Typography display={"inline-block"} fontSize={14}>
            {option == null ? `Select Budget` : option.label}
        </Typography>
    );
}

function disabledRenderValue() {
    return (
        <Typography display={"inline-block"} fontSize={14} sx={{ color: "#636B81" }}>
            {`No Active Season Available`}
        </Typography>
    );
}

function renderLabel() {
    return (
        <TypographyStyled fontSize={12} color={"#636B81"} fontWeight={500}>
            {"Select Budget"}
            <StyledTooltip
                title={`Select a Budget to link this transaction. The payouts of this transaction will be included in the respective budget's analytics.`}
                placement="bottom"
            >
                <InfoOutlined />
            </StyledTooltip>
        </TypographyStyled>
    );
}

function index({ budget, setBudget, fullWidthProps = true, inputStyles = {} }) {
    const { safeStaticDetails }: any = useContext(SafeContext);
    const { budgetOptions }: any = useContext(SquadContext);
    const isChild = safeStaticDetails?.isSquadOrParent === "sqaud" && safeStaticDetails?.parentSafe;
    const isBoth =
        safeStaticDetails?.isSquadOrParent === "both" &&
        safeStaticDetails?.parentSafe &&
        safeStaticDetails?.squads?.length > 0;
    return (
        <>
            {(isChild || isBoth) && (
                <Box
                    sx={{ width: fullWidthProps ? "100%" : "50%", ...inputStyles }}
                    display={"flex"}
                    justifyContent="flex-start"
                    alignItems={"flex-start"}
                    flexDirection={"column"}
                    marginTop={"16px"}
                >
                    {renderLabel()}
                    <CustomSelect
                        value={budget}
                        onChange={value => {
                            setBudget(value);
                        }}
                        renderValue={budgetOptions.length > 0 ? renderValue : disabledRenderValue}
                        disabled={budgetOptions.length === 0}
                    >
                        {budgetOptions.length > 0 &&
                            budgetOptions.map(_item => (
                                <StyledOption key={_item.budgetId} value={_item.budgetId}>
                                    <Typography
                                        sx={{
                                            width: `${inputElement?.current?.clientWidth - 44}px`,
                                            padding: "0",
                                            fontSize: "14px",
                                        }}
                                    >
                                        {_item.name}
                                    </Typography>
                                </StyledOption>
                            ))}
                    </CustomSelect>
                </Box>
            )}
        </>
    );
}

export default index;
