import { InputBase, InputLabel, Select } from "@mui/material";
import React from "react";
import { InputComponentStyled } from "./styles/InputComponent";
const ITEM_HEIGHT = 30;
const ITEM_PADDING_TOP = 8;
import { MenuItem } from "@mui/material";

const InputComponent = props => {
    return (
        <InputComponentStyled
            style={{
                ...props.wrapperStyle,
            }}
            className="input-styled"
            error={props.error}
        >
            {props.label ? (
                <InputLabel
                    sx={{ ...props.styles, lineHeight: "17px", whiteSpace: "pre-line" }}
                    className="label-text"
                    shrink={false}
                >
                    {props.label}
                    {props.required && <span style={{ color: "red" }}>*</span>}
                </InputLabel>
            ) : null}
            {props.type === "select" ? (
                <Select
                    variant="standard"
                    value={props.value}
                    onChange={props.onChange}
                    startAdornment={props.startAdornment}
                    className="input-select"
                    displayEmpty={props.displayEmpty}
                    sx={{ ...props.styles, ...props.inputStyles }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
                            },
                        },
                        MenuListProps: {
                            style: {
                                width: "100%",
                                fontSize: "14px",
                            },
                        },
                        style: {
                            zIndex: 1600,
                            marginTop: 10,
                        },
                        variant: "menu",
                    }}
                >
                    {props.displayEmpty && (
                        <MenuItem disabled value="">
                            {props.placeholder}
                        </MenuItem>
                    )}
                    {props.options}
                </Select>
            ) : (
                <>
                    <InputBase {...props} className="input-text" style={{ ...props.inputStyles }} />
                    {props.error && props.helperText && (
                        <span className="required-field" style={{ verticalAlign: "text-top" }}>
                            {props.helperText}
                        </span>
                    )}
                </>
            )}
        </InputComponentStyled>
    );
};

export default InputComponent;
