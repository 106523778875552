import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const LoadingWrapper: any = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    flex-direction: column;

    .MuiLinearProgress-root {
        background-color: #f7f7f7;
        width: 15%;
    }
`;
