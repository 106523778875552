import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { Button, Chip, Paper } from "@mui/material";
export const StyledModal: any = styled.div``;
import theme from "styles/theme";

export const StyledDialog: any = styled(Dialog)`
    width: 100%;

    .MuiPaper-root {
        z-index: 10;
        /* max-height: 500px; */
        overflow: visible;
    }
`;

export const StyledDialogContentRequest: any = styled(DialogContent)`
    padding: 20px 28px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: visible;

    .error {
        color: #ee365a;
        font-size: 11px;
        font-weight: 400;
        line-height: 11px;
    }
`;

export const StyledDialogContent: any = styled(DialogContent)`
    padding: 20px 28px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    overflow: visible;

    .title {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }

    .subtitle {
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        color: #9998a4;
    }
    a {
        color: ${theme.palette.primary.main};
    }

    .info {
        font-size: 16px;
        font-weight: 600;
        line-height: 23px;
        text-align: center;
    }
`;

export const LinkContainer: any = styled.div`
    background: #ecf2fd;
    border-radius: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    .title {
        font-size: 14px;
        font-weight: 500;
        line-height: 1.5;
        color: ${theme.palette.primary.main};
        text-align: center;
    }
    > div:nth-of-type(1) {
        flex: 90%;
        cursor: pointer;
    }
    > div:nth-of-type(2) {
        text-align: right;
        flex: 10%;
        cursor: pointer;
    }
`;

export const FilterButton = styled(Paper)`
    box-shadow: none;
    background: #ffffff;
    border: 1px solid #e4e4ea;
    border-radius: 4px;
    font-size: 14px;
    color: #232427;
    text-transform: none;
    font-weight: 400;
    padding: 8px 18px;
    margin-right: 2rem;
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 100%;
    font-weight: 500;
    .MuiIconButton-root {
        padding: 2px;
    }

    span.selected-text {
        font-size: 15px;
        line-height: 15px;

        text-align: right;

        color: ${({ theme }: any) => theme.palette.title.light};
    }

    .MuiInputBase-root {
        padding: 0px;
        border: none;
    }

    .MuiDivider-root {
        background: #817f89;
    }

    .MuiChip-root {
        border-radius: 4px;
        border: 1px solid ${({ theme }: any) => theme.palette.border_color.main};
        cursor: pointer;
        color: #232427;
        height: 26px;
    }

    .MuiButton-root {
        padding: 3px;
        border: 1px solid ${({ theme }: any) => theme.palette.border_color.main};
        color: #232427;
        min-width: 36px;
    }
    .muibutton-root:hover {
        border: 1px solid ${({ theme }: any) => theme.palette.border_color.main};
    }

    .MuiDivider-root {
        margin: 4px 10px;
    }

    .selectedTags {
        border-radius: 50%;
        color: #ffffff;
        height: 24px;
        width: 24px;
        border: none;
        box-shadow: none;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .expand {
        height: 26px;
        &:hover {
            border: 1px solid ${({ theme }: any) => theme.palette.border_color.main};
        }
    }
`;

export const SelectedTagChip = styled(Chip)`
    border-radius: 4px;
    border: 1px solid #d6d5df;
    cursor: pointer;
    color: #232427;
    height: 26px;
`;

export const SelectionBoxWrapper = styled(Paper)`
    position: absolute;
    top: 49px;
    left: 0px;
    background: #ffffff;
    border: 1px solid #ebebeb;
    box-sizing: border-box;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 64px 128px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
    padding: 10px;
    max-height: 288px;
    overflow-y: auto;
    flex-direction: column;
    width: 100%;
    z-index: 2500;

    .MuiDivider-root {
        margin-bottom: 10px;
    }

    .create-tag {
        min-width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        color: ${({ theme }: any) => theme.palette.title.light};
        font-weight: normal;
        &:hover {
            background-color: #fafafd;
        }
        .tag {
            font-weight: 500;
            color: ${({ theme }: any) => theme.palette.title.main};
        }
    }
`;

export const SelectionBox = styled(Paper)`
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    max-height: 188px;
    overflow: scroll !important;
    box-shadow: none;
    padding-bottom: 10px;
    margin-top: 10px;
`;

export const Badge = styled(Chip)`
    padding: 9px;
    background: #ffffff;
    border: 1px solid #dfe1e5;
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    font-weight: 500;
    color: #25274f;
`;

export const SelectedBadge = styled(Chip)`
    padding: 9px;
    background: #ffffff;
    border: 1px solid ${({ theme }: any) => theme.palette.primary.main};
    box-sizing: border-box;
    border-radius: 4px;
    margin: 5px;
    cursor: pointer;
    font-size: 14px;
    line-height: 14px;
    color: ${({ theme }: any) => theme.palette.primary.main};
    font-weight: 500;

    &:hover {
        background: #ffffff;
    }
    .MuiSvgIcon-root {
        color: ${({ theme }: any) => theme.palette.primary.main};
    }
`;

export const CreateTagButton = styled(Button)`
    margin: -10px;
    padding: 10px;
    &:focus {
        background-color: #fafafd;
    }
`;
