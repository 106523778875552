import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import React from "react";
import { styled } from "@mui/material/styles";
const StyledTooltipWithArrow = styled(({ className, ...props }: any) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#25274F",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#25274F",
        color: "#FFFFFF",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: 1.5,
    },
}));

const StyledTooltipWithoutArrow = styled(({ className, ...props }: any) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: "#25274F",
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: "#25274F",
        color: "#FFFFFF",
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: 1.5,
    },
}));
function StyledTooltip({ arrow = false, children, ...props }) {
    if (arrow) {
        return <StyledTooltipWithArrow {...props}>{children}</StyledTooltipWithArrow>;
    } else {
        return <StyledTooltipWithoutArrow {...props}>{children}</StyledTooltipWithoutArrow>;
    }
}

export default StyledTooltip;
