import { Circle } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BigNumber, ethers, utils } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import InputComponent from "src/components/InputComponent";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import SafeContext from "src/contexts/SafeContext";
import { getAmountInWei } from "src/helpers/tx-helpers";
import ReviewScreenSummary from "../ReviewScreenSummary";
import ReviewTokenSummary from "../ReviewScreenToken";
import { formatAmount } from "src/helpers/utils/web3Utils";
import { nativeTokenSymbol } from "src/queries/constants";
import SelectBoxParcel from "src/components/CategorySelect/index";
import BudgetSelect from "src/components/Squads/BudgetSelect";
import SimulationBox from "src/components/common/SimulateTransaction";
import { transformDataForMassPayoutSimulation } from "src/helpers/simulationUtils";

export default function ReviewScreen({
    gasLimit,
    description,
    setDescription,
    setCategory,
    budget,
    setBudget,
    safeSdk,
}) {
    const [renderPayeeInSummary, setRenderPayeeInSummary] = useState([]);
    const [payoutQueueForSimulation, setPayoutQueueForSimulation] = useState([]);
    const [allPayeeToRender, setAllPayeeToRender] = useState([]);
    const [simulateState, setSimulateState] = useState("begins");
    const { tagInfocus, payoutQueueByTag, payable, setPayable }: any =
        useContext(MassPayoutContext);

    const {
        safeBalanceInUSD,
        rawSafeTokenData,
        setIsExceed,
        safeStaticDetails: { categories: _categories = [] } = {},
    }: any = useContext(SafeContext);
    const totalBalInUSD: any = Object.values(safeBalanceInUSD).reduce(
        (a: number, b: number) => a + b,
        0,
    );

    useEffect(() => {
        setRenderPayees();
    }, [tagInfocus, payoutQueueByTag]);

    const setRenderPayees = () => {
        const payees = payoutQueueByTag[tagInfocus];
        const allPayees: any = Object.values(payoutQueueByTag).flat();
        const payeeDetails = [];
        const allPayeeDetails = [];

        payees &&
            payees.forEach(payee => {
                payee.selectedTokens.forEach(token => {
                    const data: any = {};
                    data.address = payee.address;
                    data.username = payee.nickName;
                    data.token = token?.token?.symbol;
                    data.tokenURI = token?.token?.logoUri;
                    data.fiatConversion = token?.token?.fiatConversion;
                    data.amount = token?.amount;
                    data.fiatValue = token?.amount
                        .mul(token?.token?.fiatConversion || 0)
                        .toNumber();
                    data.tokenAddress = token.token.tokenAddress;
                    (data.tagName = payee?.tags), payeeDetails.push({ ...data });
                });
            });
        allPayees &&
            allPayees.forEach((payee): any => {
                payee.selectedTokens.forEach(token => {
                    const data: any = {};
                    data.address = payee.address;
                    data.username = payee.nickName;
                    data.token = token?.token?.symbol;
                    data.tokenURI = token?.token?.logoUri;
                    data.fiatConversion = token?.token?.fiatConversion;
                    data.amount = token?.amount;
                    data.fiatValue = token?.amount
                        .mul(token?.token?.fiatConversion || 0)
                        .toNumber();
                    data.tokenAddress = token.token.tokenAddress;
                    allPayeeDetails.push({ ...data });
                });
            });
        // console.log(allPayeeDetails, "from all payee");
        setAllPayeeToRender(allPayeeDetails);
        setRenderPayeeInSummary(payeeDetails);
    };

    useEffect(() => {
        let total = 0;
        let reStruct = Object.values(payoutQueueByTag).flat();
        reStruct.map((item: { selectedTokens: any }) => {
            if (item.selectedTokens) {
                item.selectedTokens.map(token => {
                    total = total + +token.amount.mul(token.token.fiatConversion).toNumber();
                });
            }
        });

        setPayable(total);
    }, [payoutQueueByTag]);

    useEffect(() => {
        let mapping = {};
        let payableMapping = {};
        rawSafeTokenData.map(token => {
            mapping[token.tokenAddress ? token.tokenAddress : nativeTokenSymbol] = token.balance;
        });

        Object.values(payoutQueueByTag)
            .flat()
            .map((item: { selectedTokens: any }) => {
                item.selectedTokens.map(token => {
                    payableMapping[token.token.tokenAddress]
                        ? (payableMapping[token.token.tokenAddress] = payableMapping[
                              token.token.tokenAddress
                          ].add(getAmountInWei(token.amount.toNumber(), token.token.decimals)))
                        : (payableMapping[token.token.tokenAddress] = getAmountInWei(
                              token.amount.toNumber(),
                              token.token.decimals,
                          ));
                });
            });

        Object.keys(mapping).map(item => {
            if (
                payableMapping[item] &&
                mapping[item] &&
                payableMapping[item].gt(BigNumber.from(mapping[item]))
            ) {
                setIsExceed(true);
            }
        });
    }, [payoutQueueByTag]);

    useEffect(() => {
        const payoutQueue = transformDataForMassPayoutSimulation(payoutQueueByTag);
        setPayoutQueueForSimulation(payoutQueue);
    }, [payoutQueueByTag]);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
            }}
        >
            <Box
                sx={{
                    flex: 2,
                    marginRight: "30px",
                }}
            >
                <ReviewScreenSummary payeeDetails={renderPayeeInSummary}></ReviewScreenSummary>
            </Box>
            <Box
                sx={{
                    flex: 2,
                }}
            >
                <ReviewTokenSummary payeeDetails={allPayeeToRender}></ReviewTokenSummary>
                <Box
                    sx={{
                        marginTop: "22px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"0.9rem"} color={"#636B81"} fontWeight={500}>
                            Total Amount
                        </Typography>
                        <Typography fontWeight={600} color="#25274F" fontSize="1rem">
                            {isNaN(totalBalInUSD) ? "--" : `$${formatAmount(totalBalInUSD, 2, 2)}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"0.9rem"} color={"#636B81"} fontWeight={500}>
                            Payable Amount
                        </Typography>

                        <Typography fontWeight={600} color="#EC5952" fontSize="1rem">
                            - {isNaN(payable) ? "--" : `$${formatAmount(payable, 2, 2)}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"0.9rem"} color={"#636B81"} fontWeight={500}>
                            Remaining Amount
                        </Typography>
                        <Typography fontWeight={600} fontSize={"1rem"} color="#25274F">
                            {isNaN(totalBalInUSD - payable)
                                ? "--"
                                : `$${formatAmount(totalBalInUSD - payable, 2, 2)}`}
                        </Typography>
                    </Box>
                </Box>
                {/* <Stack
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="submit-holder"
                > */}
                <SelectBoxParcel
                    label={"Category"}
                    items={_categories.filter(({ isEnabled }) => Boolean(isEnabled))}
                    onChange={setCategory}
                    fullWidthProps
                ></SelectBoxParcel>
                <BudgetSelect budget={budget} setBudget={setBudget} />
                <InputComponent
                    sx={{ background: "#F5F6F7" }}
                    wrapperStyle={{}}
                    type="text"
                    multiline
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    label={"Description"}
                    placeholder="Enter Description"
                    inputStyles={{
                        alignItems: "flex-start",
                        background: "#F5F6F7",
                        color: "#636B81",
                        border: "none",
                        height: "120px",
                        padding: "15px 15px 0",
                        lineHeight: 1.5,
                        overflowY: "scroll",
                    }}
                />

                <SimulationBox
                    simulateState={simulateState}
                    setSimulateState={setSimulateState}
                    payoutQueueForSimulation={payoutQueueForSimulation}
                />

                {/* <Divider className="divider" /> */}
                {/* </Stack> */}
                {/* <StackWrapper
                    className="gas-limit-holder"
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Typography className="summary-value" variant="h4">
                        Network Fees
                    </Typography>
                    <Typography className="gas-fee-display" variant="h4">
                        ${gasLimit.feeInUSD || 0}
                        <Circle className="divider-dot" />
                        {gasLimit.type || "Normal"}
                    </Typography>
                </StackWrapper> */}
            </Box>
        </Box>
    );
}
