import styled from "@emotion/styled";
import { Popover, Stack } from "@mui/material";
import { Box } from "@mui/system";

export const TokenBreakdownPopoverStyled = styled(Popover)`
    z-index: 1700;
    .MuiPopover-paper {
        box-shadow: none;
    }
`;

export const TokenBreakdownContainer = styled(Box)`
    background-color: #5e5c6c;
    border-radius: 4px;
    padding: 10px;
    width: 250px;
`;

export const TokenBreakdownItem: typeof Stack = styled(Stack)`
    justify-content: flex-start;
    &:not(:last-of-type) {
        margin-bottom: 10px;
    }
    p {
        margin: 0;
        font-size: 12px;
        line-height: 12px;
    }
    .name {
        color: #fff;
    }
    .symbol {
        color: ${({ theme }: any) => theme.palette.title.light};
        padding-left: 10px;
    }
    .value {
        color: #fff;
        margin-left: auto;
    }
    .value-red {
        color: #f00;
        margin-left: auto;
    }
`;
