import styled from "@emotion/styled";
export const ContributorDashboardTopbar: any = styled.div`
    display: flex;
    letter-spacing: 0px;
    text-align: left;
    align-items: center;
    .flex-column {
        display: flex;
        flex-direction: column;
        padding-left: 10px;
        flex: auto;
    }
    .flex-row {
        display: flex;
        justify-content: space-between;
        margin-top: 2px;
    }
    .contributor-name {
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        color: ${({ theme }: any) => theme.palette.title.main};
    }
    .address {
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        color: ${({ theme }: any) => theme.palette.title.main};
        margin-top: 4px;
    }

    .edit-icon {
        height: 16px;
        width: 16px;
        color: ${({ theme }: any) => theme.palette.title.light};
        vertical-align: middle;
        cursor: pointer;
    }

    .safe-avatar {
        border-radius: 50%;
    }
`;

export const ContributorDashboardProfileBox: any = styled.div`
    padding: 8px 8px 16px;
    margin: 12px;
    border-bottom: 1px solid #eff0f2;
`;
