import { request, gql } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "pages/_app";
import { Redis } from "@upstash/redis";

import {
    gnosisURL,
    GET_DAOS_BY_CONTRIBUTOR,
    ADD_CONTRIBUTOR_REQUEST,
    GET_TOKENS_BY_SAFE_OF_CONTRIBUTOR,
    baseURI,
    baseURLFileUpload,
    EDIT_WALLET,
    GET_REQUESTS_BY_CONTRIBUTOR,
    GET_REQUEST_DETAIL_BY_REQUESTID,
    UPDATE_REQUEST_STATUS_BY_ID,
    GET_TOKEN_IMAGE_BY_TOKEN_ADDRESS,
    GET_TOKEN_USD_CONVERSION_BY_TOKEN_ADDRESS,
    nativeTokenSymbol,
    GET_REQUEST_DETAIL_BY_TRANSACTIONID,
    ZERO_ADDRESS,
    FETCH_TOKEN_CONVERSION_ENDPOINT,
} from "src/queries/constants";
import fetcher from "src/queries/fetch";
import { graphQLClient } from "../graphqlClient";
import { isAddress } from "ethers/lib/utils";
import { TOKEN_PRICE_PREFIX, TRACKED_TOKENS_PREFIX } from "src/constants/cache_prefixes";
import { getNetworkIdFromGnosisUrl } from "src/helpers/gnosisUrl";
import { getCGTokenPrices, getNativeTokenPrice } from "src/helpers/coingecko";
import { networkId } from "src/helpers/utils/networks";
import { getTokenContractAddress } from "mapping/tokenAddress";

export function useGetDaoByContributor(address) {
    return useQuery(
        [GET_DAOS_BY_CONTRIBUTOR, address],
        async () => {
            const { me } = await graphQLClient(baseURI).request(
                gql`
                    query Wallet {
                        me {
                            name
                            address
                            contributedSafes {
                                whitelistedTokens
                                categories {
                                    name
                                    isEnabled
                                }
                                safeName
                                safeAddress
                                tags {
                                    tagName
                                }
                                nickName
                                assignedTags {
                                    tagName
                                }
                            }
                        }
                    }
                `,
                {},
            );

            return me;
        },
        // {
        //     select: data => {
        //         const daoNames = data?.contributedSafes.map(
        //             dao => dao.safeName,
        //         );
        //         return daoNames;
        //     },
        // },
    );
}

export function useGetTokensAndPriceBySafe(safeAddress, onSuccessTokensDetails) {
    return useQuery(
        [GET_TOKENS_BY_SAFE_OF_CONTRIBUTOR, safeAddress],
        async () =>
            await fetcher(
                `${gnosisURL}/safes/${safeAddress}/balances/usd/?trusted=false&exclude_spam=false`,
                {},
            ),
        {
            enabled: !!safeAddress,
            onSuccess: onSuccessTokensDetails,
            refetchOnWindowFocus: false,
        },
    );
}
export function useGetTokenImageByTokenAddress(tokenAddress) {
    return useQuery(
        [GET_TOKEN_IMAGE_BY_TOKEN_ADDRESS, tokenAddress],
        async () => await fetcher(`${gnosisURL}/tokens/${tokenAddress}`, {}),
        { enabled: !!tokenAddress && tokenAddress !== nativeTokenSymbol },
    );
}

export function useGetTokenUsdConversionByTokenAddress(tokenAddress) {
    return useQuery(
        [GET_TOKEN_USD_CONVERSION_BY_TOKEN_ADDRESS, tokenAddress],
        async () => {
            let fiatConversion: number | string = "0";
            let fiatBalance = "0";

            const tokenAddressContract = getTokenContractAddress(tokenAddress);

            const res = await fetch(FETCH_TOKEN_CONVERSION_ENDPOINT, {
                method: "POST",
                body: JSON.stringify({
                    tokenAddress: tokenAddressContract,
                    networkId: networkId,
                }),
                headers: {
                    "content-type": "application/json",
                },
            });
            const resData = await res.json();
            fiatConversion = resData?.value;

            return { fiatPrice: Number(fiatConversion) };
        },
        { enabled: !!tokenAddress && tokenAddress !== nativeTokenSymbol, staleTime: 30000 },
    );
}

export const TokenConversionFromGnosis = async (
    tokenAddressCalc,
    gnosisURL,
    withoutCache: boolean = false,
) => {
    // withoutCache = true;
    let redis;
    try {
        redis = new Redis({
            url: process.env.NEXT_PUBLIC_UPSTASH_REDIS_REST_URL,
            token: process.env.UPSTASH_REDIS_REST_TOKEN,
        });
    } catch (err) {
        console.log("error in Redis Constructor", err);
        redis = null;
    }
    // hardcode fiatPrice for WHITELISTED_TOKENS on goerli
    try {
        let tokenAddress = tokenAddressCalc?.toLowerCase();
        if (!isAddress(tokenAddress) || !gnosisURL) {
            return 0;
        }

        // Fetching token prices from cache

        if (redis && !withoutCache) {
            let data: any;
            try {
                // console.log("called redis to GET token conversion in api.ts");
                data = await redis.get(
                    `${TOKEN_PRICE_PREFIX}_${getNetworkIdFromGnosisUrl(gnosisURL)}_${tokenAddress}`,
                );
            } catch (err) {
                data = null;
            }

            if (data) {
                return data;
            } else {
                // add token to tokens set in redis for tracking
                try {
                    await redis.sadd(`${TRACKED_TOKENS_PREFIX}${gnosisURL}`, tokenAddress);
                } catch (err) {}
            }
        }

        // console.log("Usd Price Fetched From API");

        // const responseGetUrl = await fetch(`${gnosisURL}/tokens/${tokenAddress}/prices/usd/`, {
        //     method: "GET",
        //     headers: { Accept: "application/json; charset=UTF-8" },
        // });
        // const { fiatPrice } = await responseGetUrl.json();

        let fiatPrice;

        if (tokenAddress == ZERO_ADDRESS) {
            fiatPrice = await getNativeTokenPrice(getNetworkIdFromGnosisUrl(gnosisURL), "usd");
            console.log("fiat Price from coingecko1", fiatPrice);
        } else {
            fiatPrice = (
                await getCGTokenPrices([tokenAddress], getNetworkIdFromGnosisUrl(gnosisURL), [
                    "usd",
                ])
            )[tokenAddress]["usd"].toString();
        }

        if (fiatPrice == undefined) {
            throw new Error("fiatPrice not found");
        }

        if (redis && !withoutCache) {
            try {
                await redis.set(
                    `${TOKEN_PRICE_PREFIX}_${getNetworkIdFromGnosisUrl(gnosisURL)}_${tokenAddress}`,
                    fiatPrice,
                    {
                        ex: 30 * 60, // 30 mins
                    },
                );
            } catch (err) {}
        }

        return fiatPrice;
    } catch (error) {
        console.log("error in fiatConversion", error?.message);
        return 0;
    }
};
export function useAddContributorRequest(onSuccessAddRequest, onErrorAddRequest) {
    return useMutation(
        async ({
            safeAddress,
            tag,
            description,
            paymentType,
            attachment,
            link,
            disbursement,
            recipient,
            createdFrom,
            contributorAddress,
        }: any) => {
            const { addContributorRequest } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation AddContributorRequest($requestParams: RequestParams) {
                        addContributorRequest(requestParams: $requestParams) {
                            requestId
                        }
                    }
                `,
                {
                    requestParams: {
                        tag,
                        description,
                        attachment: attachment,
                        paymentType,
                        link,
                        disbursement,
                        recipient,
                        createdFrom,
                        contributorAddress,
                    },
                },
            );

            return addContributorRequest;
        },
        {
            mutationKey: ADD_CONTRIBUTOR_REQUEST,
            onSuccess: onSuccessAddRequest,
            onError: onErrorAddRequest,
        },
    );
}

export function useEditWallet() {
    return useMutation(
        async ({ contributorName }: any) => {
            const { editWallet } = await graphQLClient(baseURI).request(
                gql`
                    mutation EditWallet($walletParams: WalletAddresParams) {
                        editWallet(walletParams: $walletParams) {
                            address
                            name
                        }
                    }
                `,
                {
                    walletParams: {
                        name: contributorName,
                    },
                },
            );

            return editWallet;
        },
        {
            mutationKey: EDIT_WALLET,
            onSuccess: data => {
                //   console.log("successfully updated Edit Wallet", data);
                queryClient.invalidateQueries(GET_DAOS_BY_CONTRIBUTOR);
            },
        },
    );
}

export async function fileUpload(selectedFile) {
    const responseGetUrl = await fetch(baseURLFileUpload, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            type: selectedFile?.type,
            filename: selectedFile?.name,
        }),
    });
    const { file_url, url } = await responseGetUrl.json();
    const { ok } = await fetch(url, {
        method: "PUT",
        body: selectedFile,
    });
    if (!ok) throw new Error("unable to upload file");
    return file_url;
}

export function useGetRequestsByContributor(address) {
    return useQuery([GET_REQUESTS_BY_CONTRIBUTOR, address], async () => {
        const { requestsByContributor } = await graphQLClient(baseURI).request(
            gql`
                query RequestsByContributor {
                    requestsByContributor {
                        requestId
                        createdAt
                        updatedAt
                        safe {
                            safeName
                            safeAddress
                        }
                        contributorAddress
                        transactionId
                        description
                        status
                        disbursement {
                            tokenAddress
                            tokenValue
                            fiatValue
                            fixedUsd
                            logoUri
                            symbol
                        }
                        paymentType
                        link
                        attachment {
                            fileName
                            fileType
                            url
                        }
                        tag
                    }
                }
            `,
        );

        return requestsByContributor;
    });
}

export function useGetRequestById(requestId) {
    return useQuery(
        [GET_REQUEST_DETAIL_BY_REQUESTID, requestId],
        async () => {
            const { request: requestDetail } = await graphQLClient(baseURI).request(
                gql`
                    query Request($requestId: String) {
                        request(requestId: $requestId) {
                            requestId
                            safe {
                                safeAddress
                                safeName
                            }
                            link
                            tag
                            createdAt
                            attachment {
                                url
                                fileName
                                fileType
                            }
                            status
                            disbursement {
                                tokenAddress
                                tokenValue
                                fiatValue
                                fixedUsd
                            }
                            paymentType
                            description
                            contributorAddress
                            transactionId
                            createdFrom
                            recipient
                        }
                    }
                `,
                {
                    requestId,
                },
            );

            return requestDetail;
        },
        {
            enabled: !!requestId,
        },
    );
}

export function useGetRequestByTransactionId(transactionId) {
    return useQuery(
        [GET_REQUEST_DETAIL_BY_TRANSACTIONID, transactionId],
        async () => {
            const { requestsByTransactionId: requestDetail } = await graphQLClient(baseURI).request(
                gql`
                    query RequestByTransactionId($transactionId: String) {
                        requestsByTransactionId(transactionId: $transactionId) {
                            requestId
                            contributorAddress
                            contributorName
                            transactionId
                        }
                    }
                `,
                {
                    transactionId,
                },
            );

            return requestDetail;
        },
        {
            enabled: !!transactionId,
        },
    );
}

export function useUpdateContributorRequestById() {
    return useMutation(
        async ({ safeAddress, requestId, newStatus }: any) => {
            const { updateRequestStatus } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation UpdateRequestStatus($requestId: String, $newStatus: String) {
                        updateRequestStatus(requestId: $requestId, newStatus: $newStatus) {
                            requestId
                        }
                    }
                `,
                {
                    requestId,
                    newStatus: newStatus.toLowerCase(),
                },
            );

            return updateRequestStatus;
        },
        {
            mutationKey: UPDATE_REQUEST_STATUS_BY_ID,
            onSuccess: () => {
                // console.log("successfully Updated");
                queryClient.invalidateQueries(GET_REQUESTS_BY_CONTRIBUTOR);
                queryClient.invalidateQueries(GET_REQUEST_DETAIL_BY_REQUESTID);
            },
        },
    );
}
