import styled from "@emotion/styled";
import { Chip, Stack } from "@mui/material";

export const SelectedTagsBlockStyled = styled(Stack)`
    flex: 100%;
    overflow-x: scroll;
    padding-bottom: 0;
    ::-webkit-scrollbar-thumb {
        background-color: ${({ theme }: any) => theme.background_color.light};
        border-radius: 20px;
        border: 6px solid transparent;
        background-clip: content-box;
    }
    ::-webkit-scrollbar,
    ::-webkit-scrollbar-track {
        background: transparent;
    }
`;

export const SelectedTagItem: any = styled(Chip)`
    background-color: ${({ theme, selected }: any) =>
        selected ? theme.background_color.selected : "transparent"};
    color: ${({ theme, selected }: any) => (selected ? theme.palette.primary.main : "#9998A4")};
    border-radius: 4px;
    height: auto;
    padding: 7px 10px;
    font-weight: ${({ selected }: any) => (selected ? 600 : 400)};
    margin: 0 5px;
    cursor: pointer;
    .MuiChip-label {
        line-height: 1;
        font-size: 14px;
        padding: 0;
    }
    &:nth-of-type(1) {
        margin-left: 0;
    }
`;
