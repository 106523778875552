import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Autocomplete } from "@mui/material";

export const CurrencyBox = styled(Box)`
    width: 100%;
`;

export const HelperText = styled(Typography)`
    font-size: 12px;
    line-height: 12px;
    margin: 5px 0;
    color: #8b91a1;
`;

export const StyledAutoCompleteWrapper = styled(Box)`
    .label-text {
        font-size: 12px;
        line-height: 12px;
        color: ${({ theme }: any) => theme.palette.title.sub};
        margin-bottom: 8px;
        font-weight: 500;
    }
    margin-top: 20px;
    .MuiAutocomplete-paper {
        margin-top: 10px !important;
        .MuiAutocomplete-option {
            padding: 8px 12px;
            font-size: 12px;
        }
    }
`;

export const StyledAutoComplete = styled(Autocomplete)`
    .MuiFormControl-root {
        .MuiOutlinedInput-root {
            :hover {
                outline-color: red;
                border: none;
            }
            outline: none;
            padding: 8px 10px;
            height: 45px;
        }
        .MuiOutlinedInput-input {
            outline: none;
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            padding: 0;
        }
    }
`;

export const AddInfoBox = styled(Box)`
    margin-top: 21px;
    cursor: pointer;
    height: 58px;
    border: 1px solid #dfe1e5;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px;
    gap: 15px;
    white-space: nowrap;
`;

export const InfoCard = styled(Box)`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    height: 34px;

    .title {
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #25274f;
    }
    .subtitle {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #8b91a1;
    }
`;

export const AddInfoInputBox = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 21px;
    align-items: flex-start;
    padding: 16px;
    gap: 16px;
    border: 1px solid #dfe1e5;
    border-radius: 4px;

    .heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0px;
        height: 15px;
        width: 100%;
    }

    .input-row-1 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        width: 100%;
    }
`;

export const RemoveButton = styled(Box)`
    cursor: pointer;
    display: flex;
    gap: 5px;
`;

export const SelectTagWrapper = styled.div`
    width: 100%;
    .label {
        margin: 0;
        font-family: "Metropolis", sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 1.5;
        font-size: 12px;
        color: #636b81;
        font-weight: 500;
    }
`;
