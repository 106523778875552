import React, { useContext, useEffect, useRef, useState } from "react";
import {
    FilterButtonStyled,
    SelectionBoxWrapperStyled,
    TagFilterStyled,
} from "./styles/PeopleSearchBlock";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Box, Button, Chip, Divider, IconButton, Typography } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import SafeContext from "src/contexts/SafeContext";
import { useGetTags } from "src/queries/PeopleSection/api";
import {
    SelectedBadge,
    SelectionBox,
    Badge,
} from "src/components/PeopleSection/SearchFilter/styles";
import { SelectedCountChip } from "./styles/PeopleSearchBlock";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";

export default function TagFilter() {
    const [allTags, setAllTags] = useState([]);
    const [open, setOpen] = useState(false);
    const selectBox = useRef(null);
    const filterBox = useRef(null);

    const { currentSafeAddress }: any = useContext(SafeContext);

    const {
        selectedTags,
        setSelectedTags,
        resetPayout,
        isCollapsed,
        tagInfocus,
        setTagInFocus,
        payoutQueueByTag,
        setPayoutQueueByTag,
    }: any = useContext(MassPayoutContext);

    const handleTagOpen = () => {
        setOpen(!open);
    };

    const { data } = useGetTags(currentSafeAddress, "");

    useEffect(() => {
        const items = data?.map(item => item.tagName) || [];
        setAllTags(items);
    }, [data]);

    const addToSelectTag = tag => {
        const selected = [...selectedTags];
        selected.push(tag);
        setSelectedTags(selected);
    };

    const removeFromSelectTag = tag => {
        if (tag === tagInfocus) {
            setTagInFocus();
        }

        const selected = selectedTags.filter(item => item !== tag);
        setSelectedTags(selected);
        let updatedQueue = { ...payoutQueueByTag };
        delete updatedQueue[tag];
        setPayoutQueueByTag(updatedQueue);
    };

    useEffect(() => {
        if (isCollapsed) {
            setOpen(false);
        }
    }, [isCollapsed]);

    function closeDialog(ref, filterBox) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    !filterBox.current.contains(event.target)
                ) {
                    setOpen(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    closeDialog(selectBox, filterBox);
    const handleAllTagAction = clearAll => {
        if (clearAll) {
            setSelectedTags([]);
            resetPayout();
        } else {
            setSelectedTags(allTags);
        }
    };

    return (
        <TagFilterStyled>
            <FilterButtonStyled ref={filterBox} style={{ background: "#F5F6F7" }}>
                <span style={{ color: "#636B81" }}> Tags</span>

                {selectedTags.length > 0 ? (
                    <SelectedCountChip key={"count"}> {selectedTags.length}</SelectedCountChip>
                ) : (
                    <></>
                )}
                <IconButton
                    color="primary"
                    aria-label="Tags"
                    component="span"
                    style={{
                        color: "#25274F",
                    }}
                    onClick={handleTagOpen}
                >
                    <ArrowDropDownIcon />
                </IconButton>
            </FilterButtonStyled>
            <SelectionBoxWrapperStyled
                style={{
                    display: open ? "flex" : "none",
                }}
                ref={selectBox}
            >
                <SelectionBox>
                    {allTags.length ? (
                        allTags.map(tag => {
                            if (selectedTags.includes(tag)) {
                                return (
                                    <SelectedBadge
                                        onClick={() => removeFromSelectTag(tag)}
                                        label={tag}
                                        icon={<DoneIcon color="primary" />}
                                        key={tag}
                                    ></SelectedBadge>
                                );
                            } else {
                                return (
                                    <Badge
                                        onClick={() => addToSelectTag(tag)}
                                        label={tag}
                                        key={tag}
                                    ></Badge>
                                );
                            }
                        })
                    ) : (
                        <Typography padding={"20px"} color={"#636B81"} fontSize={14}>
                            No tags found. Create one while adding people
                        </Typography>
                    )}
                </SelectionBox>
                <Divider orientation="horizontal" flexItem />
                <Button
                    style={{ fontSize: 14, fontWeight: 500 }}
                    onClick={() => handleAllTagAction(selectedTags.length === allTags.length)}
                >
                    {selectedTags.length === allTags.length ? "Clear All" : "Select All"}
                </Button>
            </SelectionBoxWrapperStyled>
        </TagFilterStyled>
    );
}
