import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const ReviewScreenToken = styled(Box)`
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 0px 0px;
    padding-bottom: 46px;
    .header {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 12px 20px;
        border-bottom: 1px solid #eaeaea;
        & > div:nth-child(1) {
            width: 50%;
        }

        & > div:nth-child(2) {
            width: 50%;
        }
    }

    .body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px 17px;
        border-bottom: 1px solid #eaeaea;

        & > div:nth-child(1) {
            width: 50%;
        }

        & > div:nth-child(2) {
            width: 50%;
            & > div {
                justify-content: flex-end;
            }
        }
    }
`;

export const CollapseHeader = styled.div`
    background-color: #eff0f2;
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    cursor: pointer;
`;
