import { useContext, useState } from "react";
import {
    SimulationBoxStyled,
    SimulateButton,
    SimulationDialogBox,
} from "./styles/SimulateTransaction";
import Image from "next/image";
import Dialog from "@mui/material/Dialog";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { IconButton, Typography, Button, CircularProgress, Stack, Box } from "@mui/material";
import SimulationLogo from "/public/simulationimage.svg";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SimulationSuccessBox from "./SimulationSuccessBox";
import SimulationFailureBox from "./SimulationFailureBox";
import ErrorIcon from "@mui/icons-material/Error";
import SafeContext from "src/contexts/SafeContext";
import { useActiveBaseContext, useContract } from "src/hooks";
import addresses from "src/constants/addresses";
import ERC20ABI from "src/constants/abis/ERC20.json";
import GnosisSafeABI from "src/constants/abis/GnosisSafe.json";
import { useSimulation } from "src/hooks/useSimulation";
import { v4 } from "uuid";
import { getAddress } from "ethers/lib/utils";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import { minifyAddress } from "src/helpers/utils/web3Utils";
import { useGetContributor } from "src/queries/PeopleSection/api";
import { nativeTokenSymbol } from "src/queries/constants";
import { roundOff } from "src/helpers/utils/common";
import { ErrorOutline } from "@mui/icons-material";
import { SafeContextTypes } from "src/contexts/SafeContextTypes";

const { ZERO_ADDRESS } = addresses;

function SimulationBox({ simulateState, setSimulateState, payoutQueueForSimulation = [] }) {
    const [openModal, setOpenModal] = useState(false);
    const [currentSimulationId, setCurrentSimulationId] = useState(null);
    const [transactionInfo, setTransactionInfo] = useState([]);
    const [nativeTransfers, setNativeTransfers] = useState([]);

    const { safeSdk, tokensInSafe, currentSafeAddress } = useContext(
        SafeContext,
    ) as SafeContextTypes;

    const { data } = useGetContributor(currentSafeAddress, "", [], 1000000);

    const { library, account, safeService }: any = useActiveBaseContext().web3;
    const customToken = useContract(addresses.ZERO_ADDRESS, ERC20ABI, true, library, account);
    const proxyContract = useContract(currentSafeAddress, GnosisSafeABI, true, library, account);

    const simulation = useSimulation();

    const onCloseModal = () => {
        setOpenModal(false);
        setTransactionInfo([]);
        setNativeTransfers([]);
    };

    const simulate = async () => {
        setSimulateState("processing");
        setTransactionInfo([]);
        setNativeTransfers([]);
        const payoutQueue = payoutQueueForSimulation;

        try {
            const response = await simulation.simulate(
                safeService,
                safeSdk,
                customToken,
                payoutQueue,
                currentSafeAddress,
                proxyContract,
                account,
            );
            if (response?.error) {
                setSimulateState("failed");
                setCurrentSimulationId(response?.simulation?.id ?? undefined);
            } else {
                if (response?.simulation) {
                    if (response?.simulation?.status) {
                        setSimulateState("successful");
                        setCurrentSimulationId(response?.simulation?.id ?? undefined);
                    } else {
                        setSimulateState("failed");
                        setCurrentSimulationId(response?.simulation?.id ?? undefined);
                    }
                }
            }
        } catch (error) {
            setSimulateState("failed");
        }
    };

    const fetchSimulation = async () => {
        const response = await simulation.getSimulationById(currentSimulationId);
        const transactionInfo = response?.transaction?.transaction_info?.logs;
        const ethTransfers = response?.transaction?.transaction_info?.balance_diff;

        setTransactionInfo(transactionInfo);
        setNativeTransfers(ethTransfers);
    };

    const renderRow = info => {
        const payoutQueue = payoutQueueForSimulation;
        const token = tokensInSafe[getAddress(info.raw.address)];
        const payee = info?.inputs?.find(
            item => item?.soltype?.name === "to" || item?.soltype?.name === "dst",
        );
        const payout = info?.inputs?.find(
            item =>
                item?.soltype?.name === "value" ||
                item?.soltype?.name === "amount" ||
                item?.soltype?.name === "wad",
        );

        const contributor = data?.contributors.find(
            item => item.address?.toLowerCase() == getAddress(payee?.value)?.toLowerCase(),
        );
        const payoutQueueValue = payoutQueue.find(
            item => getAddress(payee?.value).toLowerCase() == item?.walletAddress?.toLowerCase(),
        );
        return (
            <TableRow
                key={v4()}
                sx={{
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                }}
            >
                <TableCell align="left">
                    <div className="tokenvalue">
                        <div>
                            <img
                                width={"26px"}
                                height="26px"
                                src={token.logoUri}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = UndiscoveredCoin.src;
                                }}
                            />
                        </div>
                        <div>
                            <div>
                                <Typography variant="h6">
                                    {payout && payout.value
                                        ? roundOff(
                                              (payout.value / 10 ** token.decimals).toFixed(2),
                                              token.decimals,
                                          )
                                        : "Unknown Value"}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6">{token.symbol}</Typography>
                            </div>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="left">
                    <div className="recipientsvalue">
                        <div>
                            <Typography variant="h6">To</Typography>
                        </div>
                        <div>
                            <div>
                                <Typography variant="h6">
                                    {contributor?.nickName
                                        ? contributor?.nickName
                                        : payoutQueueValue?.walletNickName || "--"}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6">{minifyAddress(payee.value)}</Typography>
                            </div>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="left">
                    <Typography variant="h6" className="decimalvalue">
                        {token.decimals}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    };

    const renderNativeRow = info => {
        const payoutQueue = payoutQueueForSimulation;
        const token = tokensInSafe[nativeTokenSymbol];
        const payee = payoutQueue.find(
            item => info?.address?.toLowerCase() == item?.walletAddress?.toLowerCase(),
        );
        if (!payee) return null;
        const payout = Math.abs(info?.original - info?.dirty);
        const contributor = data?.contributors.find(
            item => item?.address?.toLowerCase() == getAddress(payee?.walletAddress)?.toLowerCase(),
        );
        return (
            <TableRow
                key={v4()}
                sx={{
                    "&:last-child td, &:last-child th": {
                        border: 0,
                    },
                }}
            >
                <TableCell align="left">
                    <div className="tokenvalue">
                        <div>
                            <img
                                width={"26px"}
                                height="26px"
                                src={token.logoUri}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = UndiscoveredCoin.src;
                                }}
                            />
                        </div>
                        <div>
                            <div>
                                <Typography variant="h6">
                                    {roundOff(payout / 10 ** token.decimals, token.decimals)}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6">{token.symbol}</Typography>
                            </div>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="left">
                    <div className="recipientsvalue">
                        <div>
                            <Typography variant="h6">To</Typography>
                        </div>
                        <div>
                            <div>
                                <Typography variant="h6">
                                    {contributor?.nickName
                                        ? contributor?.nickName
                                        : payee?.walletNickName || "--"}
                                </Typography>
                            </div>
                            <div>
                                <Typography variant="h6">
                                    {minifyAddress(payee?.walletAddress)}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </TableCell>
                <TableCell align="left">
                    <Typography variant="h6" className="decimalvalue">
                        {token?.decimals}
                    </Typography>
                </TableCell>
            </TableRow>
        );
    };

    return (
        <>
            {simulateState === "processing" && (
                <SimulationBoxStyled style={{ justifyContent: "center" }}>
                    <CircularProgress></CircularProgress>
                </SimulationBoxStyled>
            )}
            {simulateState === "begins" && (
                <SimulationBoxStyled>
                    <div className="simulateinfo">
                        <div className="logowrapper">
                            <Image src={SimulationLogo} alt="tenderly-logo" />
                        </div>
                        <div className="simulationname">
                            <div>Simulate Transaction</div>
                            <div>
                                Powered by <span>Tenderly</span>
                            </div>
                        </div>
                    </div>
                    <div className="simulateaction">
                        <SimulateButton variant="outlined" onClick={simulate}>
                            Simulate
                        </SimulateButton>
                    </div>
                </SimulationBoxStyled>
            )}
            {simulateState === "successful" && (
                <SimulationSuccessBox
                    fetchSimulation={fetchSimulation}
                    setOpenModal={setOpenModal}
                    currentSimulationId={currentSimulationId}
                />
            )}
            {simulateState === "failed" && (
                <SimulationFailureBox currentSimulationId={currentSimulationId} />
            )}
            {/* <SimulationSuccessBox />
                <GasPriceBox />
                <CustomGasPrice /> */}
            {/* <NetworkFeesBox>
                <div className="networkdfees">Network Fees</div>
                <div className="fast">Fast</div>
            </NetworkFeesBox> */}
            <Dialog
                open={openModal}
                maxWidth="md"
                fullWidth
                onClose={() => {
                    onCloseModal();
                }}
            >
                <SimulationDialogBox>
                    <div>
                        <IconButton
                            aria-label="close"
                            size="small"
                            onClick={() => onCloseModal()}
                            disableRipple
                        >
                            <CloseIcon
                                sx={{
                                    color: "#636B81",
                                    marginLeft: "5px",
                                    height: "21px",
                                    width: "21px",
                                }}
                            />
                        </IconButton>
                    </div>
                    <div>
                        {simulateState === "successful" && (
                            <div>
                                <span className="simulatesuccess">Simulation Successful</span>
                                <span>
                                    {" "}
                                    <CheckCircleIcon
                                        sx={{
                                            color: "#27A643",
                                            marginLeft: "5px",
                                            height: "20px",
                                            width: "20px",
                                        }}
                                    />
                                </span>
                            </div>
                        )}
                        {simulateState === "failed" && (
                            <div>
                                <span className="simulatefailed">Simulation Failed</span>
                                <span>
                                    {" "}
                                    <ErrorIcon
                                        sx={{
                                            color: "#F44040",
                                            marginLeft: "5px",
                                            height: "20px",
                                            width: "20px",
                                        }}
                                    />
                                </span>
                            </div>
                        )}
                        <div>
                            <Typography className="txn-value" variant="h6">
                                The Transaction was successfully simulated. Full simulation report
                                is available
                                <span>
                                    <a
                                        href={`${process.env.NEXT_PUBLIC_TENDERLY_SIMULATION_BASE_URL}/${currentSimulationId}`}
                                        target="_blank"
                                    >
                                        {` on Tenderly`}
                                    </a>
                                </span>
                            </Typography>
                        </div>
                    </div>

                    <div>
                        <Typography className="tabletitle" variant="h4">
                            Transferring:
                        </Typography>
                        <TableContainer
                            sx={{
                                border: "1px solid rgba(0, 0, 0, 0.1)",
                                borderRadius: "12px",
                                padding: "0px 12px",
                                maxHeight: 440,
                                overflowX: "auto",
                            }}
                        >
                            <Table
                                sx={{
                                    display: "table",
                                    minWidth: 650,
                                    width: "100%",
                                    height: "100%",
                                    background: "#FFFFFF",
                                    padding: "0px 12px",
                                    tableLayout: "fixed",
                                }}
                                stickyHeader={true}
                                aria-label="simple table"
                            >
                                <TableHead
                                    sx={{
                                        background: "#FFFFFF",
                                    }}
                                >
                                    <TableRow>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                background: "#FFFFFF",
                                            }}
                                        >
                                            Token
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                background: "#FFFFFF",
                                            }}
                                        >
                                            Recipients
                                        </TableCell>
                                        <TableCell
                                            align="left"
                                            sx={{
                                                background: "#FFFFFF",
                                            }}
                                        >
                                            Decimal
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                {transactionInfo?.length > 0 || nativeTransfers?.length > 0 ? (
                                    <TableBody>
                                        {transactionInfo
                                            ?.filter(item => item.name == "Transfer")
                                            ?.map(row => renderRow(row))}
                                        {transactionInfo
                                            ?.filter(item => item.name == "ExecutionFailure")
                                            ?.map(row => (
                                                <TableRow>
                                                    <TableCell colSpan={3}>
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <ErrorOutline
                                                                sx={{
                                                                    mr: "10px",
                                                                }}
                                                                fontSize="small"
                                                            />
                                                            <Typography
                                                                color={"title.sub"}
                                                                fontSize="15px"
                                                                sx={{
                                                                    m: "0",
                                                                    lineHeight: "1",
                                                                }}
                                                            >
                                                                Some Transfers may fail during
                                                                execution
                                                            </Typography>
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        {nativeTransfers
                                            ?.filter(item => item.address != ZERO_ADDRESS)
                                            ?.map(row => renderNativeRow(row))}
                                    </TableBody>
                                ) : (
                                    <Stack
                                        direction="row"
                                        justifyContent="center"
                                        alignItems="center"
                                        mt={2}
                                        sx={{ width: "300%" }}
                                    >
                                        <CircularProgress></CircularProgress>
                                    </Stack>
                                )}
                            </Table>
                        </TableContainer>
                    </div>

                    <div>
                        <div className="tenderlypowered">
                            <Image src={SimulationLogo} alt="tenderly-logo" />
                            <span>
                                Powered by <span>Tenderly</span>
                            </span>
                        </div>
                        <Button onClick={() => setOpenModal(false)} variant="contained">
                            Done
                        </Button>
                    </div>
                </SimulationDialogBox>
            </Dialog>
        </>
    );
}

export default SimulationBox;
