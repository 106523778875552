import React, { useContext, useEffect, useState } from "react";
import { SummaryWrapper } from "./style";
import Image from "next/image";

import multitoken from "../../../assets/newMultiToken.png";

import SafeContext from "src/contexts/SafeContext";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
// import FastAverageColor from "fast-average-color";
import { Box } from "@mui/system";
import { nativeTokenSymbol } from "src/queries/constants";
import { formatAmount } from "src/helpers/utils/web3Utils";

export default function TokenSummaryPayroll({ disbursment, fromSummary = false }) {
    const { tokensInSafe }: any = useContext(SafeContext);

    const calculateUsd = disbursment => {
        const calculation = disbursment
            .map(item => item.amount * tokensInSafe[item.tokenAddress]?.fiatConversion.toFixed(2))
            .reduce((a, b) => a + b, 0)
            .toFixed(2);
        return isNaN(calculation) ? "--" : "$" + calculation;
    };

    const calculatedUsdForSummaryPage = disbursment => {
        const calculation = disbursment
            ?.map(item => item?.fiatValue)
            .reduce((a, b) => a + b, 0)
            .toFixed(2);
        return isNaN(calculation) ? "--" : "$" + calculation;
    };

    const calculateUniqueTokens = disbursment => {
        if (disbursment.length === 1) {
            return disbursment[0]?.fixedUsd ? `of ` + disbursment[0]?.token : disbursment[0]?.token;
        }
        return Array.from(new Set(disbursment.map(({ token }) => token))).join(",");
    };
    const calculateUniqueTokensDetails = disbursment => {
        if (disbursment.length === 1) {
            return disbursment[0]?.fixedUsd
                ? `of ` + disbursment[0]?.tokenSymbol
                : disbursment[0]?.tokenSymbol;
        }
        const arr = Array.from(new Set(disbursment.map(({ tokenSymbol }) => tokenSymbol)));
        if (arr.length > 2) {
            return `${arr.slice(0, 2).join(",")}...`;
        }
        return arr.join(",");
    };
    if (fromSummary) {
        return (
            <SummaryWrapper>
                {disbursment.length > 0 ? (
                    <>
                        <Box
                            className="circle"
                            sx={{
                                padding: disbursment.length > 1 ? "0px !important" : "8px",
                            }}
                        >
                            {disbursment.length > 1 ? (
                                <Image width={25} height={25} src={multitoken}></Image>
                            ) : (
                                <img
                                    src={`${disbursment?.[0]?.logoUri}`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = UndiscoveredCoin.src;
                                    }}
                                />
                            )}
                        </Box>
                        <div className="flex">
                            <span>
                                {disbursment.length > 1
                                    ? "Multiple Tokens"
                                    : `${
                                          disbursment[0].fixedUsd
                                              ? isNaN(Number(disbursment[0]?.fiatValue))
                                                  ? 0
                                                  : Number(
                                                        disbursment[0]?.fiatValue,
                                                    )?.toLocaleString(undefined, {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 2,
                                                    })
                                              : isNaN(Number(disbursment[0]?.amount))
                                              ? 0
                                              : Number(disbursment[0]?.amount)?.toLocaleString(
                                                    undefined,
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 6,
                                                    },
                                                )
                                      } ${disbursment[0].fixedUsd ? "USD" : disbursment[0].token}`}
                            </span>
                            {/* <span id="amount">
                                {disbursment.length > 1
                                    ? calculatedUsdForSummaryPage(disbursment)
                                    : isNaN(disbursment?.[0]?.fiatValue)
                                    ? "--"
                                    : "$" + disbursment?.[0]?.fiatValue.toFixed(2)}
                            </span> */}
                            <span id="amount">{calculateUniqueTokens(disbursment)}</span>
                        </div>
                    </>
                ) : null}
            </SummaryWrapper>
        );
    } else {
        return (
            <SummaryWrapper>
                {disbursment.length ? (
                    <>
                        <Box
                            className="circle"
                            sx={{
                                padding: disbursment.length > 1 ? "0px !important" : "8px",
                            }}
                        >
                            {disbursment.length > 1 ? (
                                <Image width={25} height={25} src={multitoken}></Image>
                            ) : (
                                <img
                                    src={`${tokensInSafe[disbursment[0].tokenAddress]?.logoUri}`}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = UndiscoveredCoin.src;
                                    }}
                                />
                            )}
                        </Box>
                        <div className="flex">
                            <span>
                                {disbursment.length > 1
                                    ? "Multiple Tokens"
                                    : `${
                                          disbursment[0].fixedUsd
                                              ? Number(disbursment[0]?.fiatValue)?.toLocaleString(
                                                    undefined,
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 2,
                                                    },
                                                )
                                              : isNaN(Number(disbursment[0]?.amount))
                                              ? 0
                                              : Number(disbursment[0]?.amount)?.toLocaleString(
                                                    undefined,
                                                    {
                                                        minimumFractionDigits: 0,
                                                        maximumFractionDigits: 6,
                                                    },
                                                )
                                      } ${
                                          disbursment[0].fixedUsd
                                              ? "USD"
                                              : disbursment[0].tokenAddress === nativeTokenSymbol
                                              ? nativeTokenSymbol
                                              : disbursment[0].tokenSymbol
                                      }`}
                            </span>
                            <span id="amount">{calculateUniqueTokensDetails(disbursment)}</span>
                        </div>
                    </>
                ) : null}
            </SummaryWrapper>
        );
    }
}
