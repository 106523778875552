import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState, useContext } from "react";
import InputComponent from "src/components/InputComponent";
import SafeContext from "src/contexts/SafeContext";
import { formatAmount } from "src/helpers/utils/web3Utils";
import { nativeTokenSymbol } from "src/queries/constants";
import ReviewScreenSummaryforCSV from "../ReviewScressSummaryforCSV";
import ReviewTokenSummary from "../ReviewScreenToken";
import { getAddress } from "ethers/lib/utils";
import SelectBoxParcel from "src/components/CategorySelect/index";
import BudgetSelect from "src/components/Squads/BudgetSelect";
import SimulationBox from "src/components/common/SimulateTransaction";
import TransferContext from "src/contexts/TransferContext";

export default function ReviewScreen({
    data,
    description,
    setDescription,
    addToPayoutQueueWithParams,
    setPayoutQueue,
    setCategory,
    budget,
    setBudget,
    safeSdk,
}) {
    const [simulateState, setSimulateState] = useState("begins");
    const { safeBalanceInUSD, safeStaticDetails: { categories: _categories = [] } = {} }: any =
        useContext(SafeContext);
    const { payoutQueue }: any = useContext(TransferContext);
    const totalBalInUSD: any = Object.values(safeBalanceInUSD).reduce(
        (a: number, b: number) => a + b,
        0,
    );
    useEffect(() => {
        setPayoutQueue([]);
        data?.map(item => {
            let payee = item || {};
            addToPayoutQueueWithParams({
                walletAddress: getAddress(payee.address),
                walletNickName: payee.username,
                selectedToken: payee.tokenAddress || nativeTokenSymbol,
                tokenValue: payee.amount,
                tokenSymbol: payee.symbol,
                tokenValueInUSD: payee.fiatValue,
                tokenDecimal: payee.decimals,
                tagName: payee.tagName,
                comment: payee.comment,
            });
        });
    }, []);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
            }}
        >
            <Box
                sx={{
                    flex: 3,
                    marginRight: "30px",
                }}
            >
                <ReviewScreenSummaryforCSV payeeDetails={data}></ReviewScreenSummaryforCSV>
            </Box>
            <Box
                sx={{
                    flex: 2,
                }}
            >
                <ReviewTokenSummary payeeDetails={data}></ReviewTokenSummary>
                <Box
                    sx={{
                        marginTop: "22px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"0.9rem"} color={"#9998A4"}>
                            Total Amount
                        </Typography>
                        <Typography fontWeight={600} fontSize={"1rem"} color="#232427">
                            {isNaN(totalBalInUSD) ? "--" : `$${formatAmount(totalBalInUSD, 2, 2)}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"0.9rem"} color={"#9998A4"}>
                            Payable Amount
                        </Typography>
                        <Typography fontWeight={600} fontSize={"1rem"} color="#EC5952">
                            -{" "}
                            {isNaN(data?.reduce((acc, curr) => (acc += curr.fiatValue ?? 0), 0))
                                ? "--"
                                : ` $${formatAmount(
                                      data?.reduce((acc, curr) => (acc += curr.fiatValue ?? 0), 0),
                                      2,
                                      2,
                                  )}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"0.9rem"} color={"#9998A4"}>
                            Remaining Amount
                        </Typography>
                        <Typography fontWeight={600} fontSize={"1rem"} color="#232427">
                            {isNaN(
                                totalBalInUSD -
                                    data?.reduce((acc, curr) => (acc += curr.fiatValue ?? 0), 0),
                            )
                                ? "--"
                                : `$${formatAmount(
                                      totalBalInUSD -
                                          data?.reduce(
                                              (acc, curr) => (acc += curr.fiatValue ?? 0),
                                              0,
                                          ),
                                      2,
                                      2,
                                  )}`}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="submit-holder"
                >
                    <SelectBoxParcel
                        label={"Category"}
                        items={_categories.filter(({ isEnabled }) => Boolean(isEnabled))}
                        onChange={setCategory}
                        fullWidthProps
                    ></SelectBoxParcel>
                </Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="submit-holder"
                >
                    <BudgetSelect budget={budget} setBudget={setBudget} />
                </Box>
                <InputComponent
                    sx={{ background: "#F5F6F7" }}
                    wrapperStyle={{}}
                    type="text"
                    multiline
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    label={"Description"}
                    placeholder="Enter Description"
                    inputStyles={{
                        alignItems: "flex-start",
                        background: "#F5F6F7",
                        color: "#636B81",
                        border: "none",
                        height: "120px",
                        padding: "15px 15px 0",
                        lineHeight: 1.5,
                        overflowY: "scroll",
                    }}
                />
                <SimulationBox
                    simulateState={simulateState}
                    setSimulateState={setSimulateState}
                    payoutQueueForSimulation={payoutQueue}
                />
                {/* <StackWrapper
                    className="gas-limit-holder"
                    direction="row"
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Typography className="summary-value" variant="h4">
                        Network Fees
                    </Typography>
                    <Typography className="gas-fee-display" variant="h4">
                        ${gasLimit.feeInUSD || 0}
                        <Circle className="divider-dot" />
                        {gasLimit.type || "Normal"}
                    </Typography>
                </StackWrapper> */}
            </Box>
        </Box>
    );
}
