import React, { useContext, useEffect, useState } from "react";
import { SummaryWrapper } from "./style";
import Image from "next/image";
import multitoken from "../../../assets/multitoken.png";
import { useGetBalanceUSDFromGnosis } from "src/queries/Transaction/api";
import SafeContext from "src/contexts/SafeContext";
import { NATIVE_ICON_URL, nativeTokenName, nativeTokenSymbol } from "src/queries/constants";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import TransferContext from "src/contexts/TransferContext";
import { formatAmount } from "src/helpers/utils/web3Utils";

export default function TokenRemainingSummaryPayroll({ disbursment }) {
    const [tokenMapping, setTokenMapping] = React.useState({});
    const [remaining, setRemaining] = useState(0);
    const { currentSafeAddress, safeTokenBalancesInDecimal, safeTokenBalances }: any =
        useContext(SafeContext);
    const { massPayoutStep, setIsEligibleTx }: any = useContext(TransferContext);
    const { setIsEligibleToTx }: any = useContext(MassPayoutContext);
    const { data, status } = useGetBalanceUSDFromGnosis(currentSafeAddress);
    const [totalBalanceInToken, setTotalBalanceInToken] = useState(0);

    useEffect(() => {
        const tokenBalance = safeTokenBalancesInDecimal[disbursment[0].tokenAddress];
        const decimals = tokenMapping[disbursment[0].token]?.token?.decimals;

        const totalBalanceInToken = Number(tokenBalance) / 10 ** Number(decimals);
        setTotalBalanceInToken(totalBalanceInToken);
        setRemaining(tokenBalance - disbursment[0].amount);
    }, [disbursment, safeTokenBalancesInDecimal]);

    useEffect(() => {
        if (remaining < 0 || isNaN(remaining)) {
            setIsEligibleTx(false);
        }
    }, [remaining]);

    const getBalances = async () => {
        const tokenMapping = {};
        return new Promise((res, rej) => {
            if (status === "success") {
                data.forEach(token => {
                    if (token.token) {
                        tokenMapping[token.token.symbol] = token;
                    } else {
                        tokenMapping[nativeTokenSymbol] = { ...token };
                        tokenMapping[nativeTokenSymbol].token = {
                            name: nativeTokenName,
                            symbol: nativeTokenSymbol,
                            decimals: 18,
                            logoUri: NATIVE_ICON_URL,
                        };
                    }
                });
                res(tokenMapping);
            }
        });
    };

    const setTotalBalance = async () => {
        const balances = await getBalances();
        setTokenMapping(balances);
    };

    React.useEffect(() => {
        setTotalBalance();
    }, []);

    return (
        <Box
            sx={{
                width: "100% !important",
                display: "flex",
                justifyContent: "space-between",
            }}
        >
            <Box sx={{ width: "50%" }}>
                <SummaryWrapper>
                    {disbursment.length ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <div>
                                <img
                                    width={"26px"}
                                    height="26px"
                                    src={disbursment[0].logoUri}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null;
                                        currentTarget.src = UndiscoveredCoin.src;
                                    }}
                                />
                            </div>
                            <div className="flex">
                                <span>
                                    {formatAmount(disbursment[0].amount, 0, 7)}{" "}
                                    {disbursment[0].token === ""
                                        ? nativeTokenSymbol
                                        : disbursment[0].token}
                                </span>
                                <Typography component="span" color="#9998A4">
                                    {isNaN(disbursment[0].fiatValue)
                                        ? "--"
                                        : ` $${formatAmount(disbursment[0].fiatValue, 2, 2)}`}
                                </Typography>
                            </div>
                        </div>
                    ) : null}
                </SummaryWrapper>
            </Box>
            <Box>
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                    }}
                >
                    <Typography color="#232427" fontWeight={600} fontSize="13px">
                        {isNaN(remaining) ? "--" : remaining.toLocaleString()}
                    </Typography>

                    <Typography marginLeft={"8px"} color="#9998A4" fontSize="13px">
                        {" "}
                        {disbursment[0].token === "" ? nativeTokenSymbol : disbursment[0].token}
                    </Typography>
                </Box>
                {(remaining <= 0 || isNaN(remaining)) && (
                    <Typography color="#EC5952" fontWeight={600} fontSize="13px">
                        Insufficient assets
                    </Typography>
                )}
            </Box>
        </Box>
    );
}
