import { Skeleton } from "@mui/material";
import { Row } from "../styles";

export const LoadingRow = () => (
    <Row>
        <div>
            <Skeleton variant="rectangular" width={80} height={18} />
        </div>
        <div>
            <div>
                <Skeleton variant="rectangular" width={173} height={12} />
                <Skeleton variant="rectangular" width={173} height={12} />
            </div>
        </div>
        <div>
            <Skeleton variant="rectangular" width={200} height={17} />
        </div>
        <div>
            <Skeleton variant="rectangular" width={18} height={18} />
            <Skeleton variant="rectangular" width={18} height={18} />
        </div>
    </Row>
);
