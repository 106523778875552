import React, { useContext, useEffect, useRef, useState } from "react";
import {
    Badge,
    CreateTagButton,
    FilterButton,
    SelectedBadge,
    SelectedTagChip,
    SelectionBox,
    SelectionBoxWrapper,
} from "./styles";
import { Button, Chip, CircularProgress, Divider, IconButton, InputBase } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { useAddTag, useGetTags } from "../../../queries/PeopleSection/api";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import { useQueryClient } from "react-query";
import { GET_CONTRIBUTOR, GET_TAGS } from "../../../queries/constants";
import SafeContext from "src/contexts/SafeContext";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

const TAG_NAME_LENGTH_LIMIT = 20;
export default function SearchFilterTags({
    isCollasped,
    isEdit,
    value,
    index,
    setTagsToAddContributor,
    fromTransferModule = false,
}) {
    const [allTags, setAllTags] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const [open, setOpen] = useState(false);
    const selectBox = useRef(null);
    const filterBox = useRef(null);
    const [searchedTag, setSearchedTag] = React.useState("");
    const [isFocused, setIsFocused] = React.useState(false);
    const [tags, setTags] = useState([]);

    const { currentSafeAddress }: any = useContext(SafeContext);

    const { data } = useGetTags(currentSafeAddress, searchedTag);

    const queryClient = useQueryClient();

    const addTagToSafe = useAddTag();

    useEffect(() => {
        const items = data?.map(item => item.tagName) || [];
        setTags(items);
    }, [data]);

    useEffect(() => {
        if (isEdit) {
            setSelectedTags(value);
        }
    }, [isEdit]);

    useEffect(() => {
        setSelectedTags(value);
    }, [value]);

    const handleTagOpen = () => {
        setOpen(!open);
    };

    useEffect(() => {
        setAllTags(tags);
    }, [tags]);

    const addToSelectTag = tag => {
        const selected = [...selectedTags];
        selected.push(tag);
        setTagsToAddContributor(selected, index);
        setSelectedTags(selected);
    };

    const removeFromSelectTag = tag => {
        const selected = selectedTags.filter(item => item !== tag);
        setSelectedTags(selected);
        setTagsToAddContributor(selected, index);
    };

    useEffect(() => {
        if (isCollasped) {
            setOpen(false);
        }
    }, [isCollasped]);

    const handleAllTagAction = clearAll => {
        if (clearAll) {
            setSelectedTags([]);
            setTagsToAddContributor([], index);
        } else {
            setSelectedTags(allTags);
            setTagsToAddContributor(allTags, index);
        }
    };

    function closeDialog(ref, filterBox) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (
                    ref.current &&
                    !ref.current.contains(event.target) &&
                    !filterBox.current.contains(event.target)
                ) {
                    setOpen(false);
                }
            }

            // Bind the event listener
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                // Unbind the event listener on clean up
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    closeDialog(selectBox, filterBox);

    const addTag = async () => {
        await addTagToSafe.mutateAsync({
            safeAddress: currentSafeAddress,
            tagName: searchedTag.trim(),
        });

        await queryClient.invalidateQueries(GET_TAGS);
        addToSelectTag(searchedTag.trim());
        setSearchedTag("");
    };

    const handleInputFocus = () => {
        setIsFocused(true);
        setOpen(true);
    };

    return (
        <div
            style={{
                flexGrow: 2,
                position: "relative",
                width: "100%",
            }}
        >
            <FilterButton
                ref={filterBox}
                style={{
                    background: fromTransferModule ? "#f5f6f7" : "#ffffff",
                    border: fromTransferModule ? "none" : "1px solid #e4e4ea",
                    margin: fromTransferModule ? "5px 0px" : "0px",
                }}
            >
                {!selectedTags.length || open ? (
                    <InputBase
                        sx={{
                            ml: 1,
                            flex: 1,
                            fontSize: "14px",
                        }}
                        placeholder="Add Tags"
                        inputProps={{
                            "aria-label": "Search By Name, Address/ENS",
                            form: {
                                autocomplete: "off",
                            },
                        }}
                        autoComplete="off"
                        name="name"
                        onChange={e =>
                            setSearchedTag(e.target.value.substring(0, TAG_NAME_LENGTH_LIMIT))
                        }
                        value={searchedTag}
                        onFocus={handleInputFocus}
                        onBlur={() => [setIsFocused(false)]}
                    />
                ) : null}
                {open ? (
                    <span className="selected-text">
                        {selectedTags.length} {selectedTags.length == 1 ? "tag" : "tags"} selected
                    </span>
                ) : null}
                {!open && selectedTags.length ? (
                    <>
                        <div>
                            {selectedTags.slice(0, 2).map(tag => {
                                return (
                                    <Chip
                                        label={tag}
                                        variant="outlined"
                                        onDelete={() => removeFromSelectTag(tag)}
                                        key={tag}
                                        deleteIcon={
                                            <CancelRoundedIcon style={{ fontSize: "16px" }} />
                                        }
                                        style={{
                                            margin: "0px 2px",
                                        }}
                                        sx={{ fontSize: "14px", color: "#25274F" }}
                                    />
                                );
                            })}
                        </div>
                        {selectedTags.length > 2 ? (
                            <Button
                                sx={{ fontSize: "14px", color: "#25274F" }}
                                className="expand"
                                variant="outlined"
                                onClick={handleTagOpen}
                            >
                                +{selectedTags.length - 2}
                            </Button>
                        ) : null}
                        {/* <Divider orientation="vertical" flexItem /> */}
                        <div>
                            <IconButton
                                size="small"
                                color="primary"
                                aria-label="open"
                                onClick={handleTagOpen}
                                style={{
                                    marginRight: "5px",
                                }}
                            >
                                <AddCircleOutlineOutlinedIcon sx={{ fontSize: 16 }} />
                            </IconButton>
                            <IconButton
                                size="small"
                                color="primary"
                                aria-label="delete"
                                onClick={() => setSelectedTags([])}
                            >
                                <CloseIcon sx={{ fontSize: 16 }} />
                            </IconButton>
                        </div>
                    </>
                ) : null}
            </FilterButton>
            <SelectionBoxWrapper
                style={{
                    display: open ? "flex" : "none",
                }}
                ref={selectBox}
            >
                {searchedTag.length > 0 &&
                    !tags.map(a => a.toLowerCase()).includes(searchedTag.toLowerCase()) && (
                        <CreateTagButton
                            disableFocusRipple={true}
                            disabled={searchedTag.trim().length === 0}
                            className="create-tag"
                            onClick={() => addTag()}
                        >
                            <span>
                                Create tag <span className="tag">{searchedTag.trim()}</span>
                            </span>
                            {addTagToSafe.isLoading ? <CircularProgress size={20} /> : null}
                        </CreateTagButton>
                    )}
                {tags.length > 0 ? (
                    <>
                        {}
                        <SelectionBox>
                            {tags.map(tag => {
                                if (selectedTags.includes(tag)) {
                                    return (
                                        <SelectedBadge
                                            onClick={() => removeFromSelectTag(tag)}
                                            label={tag}
                                            icon={<DoneIcon color="primary" />}
                                            key={tag}
                                        ></SelectedBadge>
                                    );
                                } else {
                                    return (
                                        <Badge
                                            onClick={() => addToSelectTag(tag)}
                                            label={tag}
                                            key={tag}
                                        ></Badge>
                                    );
                                }
                            })}
                        </SelectionBox>
                        <Button
                            disabled={allTags.length === 0}
                            onClick={() =>
                                handleAllTagAction(selectedTags.length === allTags.length)
                            }
                        >
                            {allTags.length > 0 && selectedTags.length === allTags.length
                                ? "Clear All"
                                : "Select All"}
                        </Button>
                    </>
                ) : null}
            </SelectionBoxWrapper>
        </div>
    );
}
