import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import NameAddress from "src/components/NameAddress";

import { ReviewScreenWrapper } from "./styles/ReviewScreen";

import CollapseReviewScreen from "./CollapseReviewScreen";

type Props = {
    payeeDetails: any;
    usdValueOff?: boolean;
};

export default function ReviewScreenSummaryPayroll({ payeeDetails, usdValueOff }: Props) {
    const [payees, setPayees] = useState({});

    useEffect(() => {
        const payees = {};
        payeeDetails.rows?.map(item => {
            if (payees[item?.data?.address]) {
                const disbursement: any = {};
                disbursement.token = item?.data?.token;
                disbursement.amount = item.data.amount;
                disbursement.fiatValue = item.data.fiatValue;
                payees[item?.data?.address].disbursement.push(disbursement);
                disbursement.logoUri = item?.data?.tokenURI;
                disbursement.fixedUsd = item?.data?.fixedUsd;
            } else {
                const payee: any = {};
                payee.name = item?.data?.name;
                payee.address = item?.data?.address;
                const disbursement: any = {};
                disbursement.token = item?.data?.token;
                disbursement.amount = item.data.amount;
                disbursement.logoUri = item.data.tokenURI;
                disbursement.fiatValue = item.data.fiatValue;
                disbursement.fixedUsd = item?.data?.fixedUsd;
                payee.disbursement = [disbursement];
                payees[item?.data?.address] = payee;
            }
        });
        setPayees(payees);
    }, [payeeDetails]);

    return (
        <ReviewScreenWrapper>
            <Box className="header">
                <Box>
                    <Typography color="#9998A4" fontSize="13px">
                        NAME
                    </Typography>
                </Box>
                <Box>
                    <Typography color="#9998A4" fontSize="13px">
                        TOKEN
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    minHeight: "60vh",
                    maxHeight: "60vh",
                    overflowY: "auto",
                }}
            >
                {Object.values(payees).map((item: any, it: number) => (
                    <CollapseReviewScreen key={it} item={item} usdValueOff={usdValueOff} />
                ))}
            </Box>
        </ReviewScreenWrapper>
    );
}
