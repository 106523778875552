import { Box, Button, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import SafeContext from "src/contexts/SafeContext";
import TransferContext from "src/contexts/TransferContext";
import { ModuleHeading } from "../styles/common";
import PeopleSearchBlock from "./PeopleSearchBlock";
import PeopleTableBlock from "./PeopleTableBlock";
import SelectedTagsBlock from "./SelectedTagsBlock";
import SelectedPeopleBlock from "./SelectedPeopleBlock";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CircularProgress from "@mui/material/CircularProgress";
import {
    CurrentStep,
    MassPayoutStepNameStyled,
    MassPayoutStepNumbers,
    MassPayoutStyled,
    ProgressBar,
    ProgressBarHolder,
    SafeBalance,
    SafeBalanceBlock,
    SlantDivider,
    TableSectionWrapper,
    TagAndBalanceBox,
    TitleHolder,
    TotalSteps,
    WorkflowNameContainer,
} from "./styles/MassPayout";
import Image from "next/image";
import Dialog from "@mui/material/Dialog";
import { useActiveBaseContext, useContract } from "src/hooks";
import PeopleTokenTable from "./PeopleTokenTable";
import { CSVTableAction } from "../CSVPayout/styles";
import ReviewScreen from "./ReviewScreen";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import ERC20ABI from "src/constants/abis/ERC20.json";
import MultiSendABI from "src/constants/abis/MultiSend.json";
import addresses from "src/constants/addresses";
import { useSnackbar } from "notistack";
import { useSafeSdk } from "src/hooks/useSafeSdk";
import { updateTransactionIdToBudget, useCreateTransaction } from "src/queries/Transaction/api";
const MULTISEND_ADDRESS = addresses.MULTISEND_ADDRESS;
import createMassPayout from "src/helpers/createMassPayout";
import { LoadingButton } from "@mui/lab";
import router from "next/router";
import { CheckIfLimitsAreNotConsumed } from "src/queries/Safe/api";
import MetaTxModal from "src/components/MetaTxModal";
import { getPayoutDetails } from "src/helpers/Payroll";
import { useCreateWorkflow } from "src/queries/PayrollWorkflow/api";
import CloseIcon from "@mui/icons-material/Close";
import { formatAmount, minifySafeName as formatLongName } from "src/helpers/utils/web3Utils";
import { Tooltip } from "@mui/material";
import { useUpdatePayrollImplementation } from "src/queries/PayrollWorkflow/api";
import { triggerSlackNotification } from "src/queries/Webhook/api";
import { networkId } from "src/helpers/utils/networks";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import TagSelectorBox from "./TagSelectorBox";
import ClearIcon from "@mui/icons-material/Clear";
import { etherscan } from "src/constants/endpoints";
import { sendEmail } from "src/queries/emailNotification";
import useEstimateGasContract from "src/hooks/useEstimateGasContract";

const MassPayout = () => {
    const { getGasEstimateTransactionExecution } = useEstimateGasContract();
    const {
        massPayoutStep,
        setMassPayoutStep,
        MASSPAYOUT_MAX_STEPS,
        gasLimit,
        description,
        setDescription,
        isTxStarted,
        setIsTxStarted,
        transferType,
        closeModal,
        resetTransfer,
        setCategory,
        category,
    }: any = useContext(TransferContext);
    const { enqueueSnackbar } = useSnackbar();
    const [txStatus, setTxStatus] = useState<any>({
        status: null,
        data: {
            message: "Gathering Information",
        },
    });
    const {
        ethersAdapterOwner,
        safeService,
        library,
        account,
        contributedSafes,
        isWalletEOA,
        chainId,
        isHardwareWallet,
        isGasLowEnough,
    }: any = useActiveBaseContext().web3;
    const {
        tagInfocus,
        payoutQueueByTag,
        isEligibleToTx,
        resetPayout,
        setIsEligibleToTx,
        selectedTags,
    }: any = useContext(MassPayoutContext);
    const [saveToPayroll, setSaveToPayroll] = useState(false);
    const {
        getSafeBalance,
        currentSafeAddress,
        setSafeNonce,
        nonce,
        safeStaticDetails: {
            isMetaTxEnabled = false,
            safeName = "",
            threshold = 1,
            owners: _owners = [],
        } = {},
        isDelegateAccess,
        isDelegateCanCreateTx,
        safeDetailsFromGnosis,
    }: any = useContext(SafeContext);
    const { safeSdk } = useSafeSdk(ethersAdapterOwner, currentSafeAddress);
    const { mutateAsync } = useCreateTransaction();
    //Custom or ERC20 token initialization
    const customToken = useContract(addresses.ZERO_ADDRESS, ERC20ABI, true, library, account);
    const multiSend = useContract(MULTISEND_ADDRESS, MultiSendABI, true, library, account);
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [isMetaTxLimitAllowed, setIsMetaTxLimitAllowed] = useState(false);
    const [workflowName, setWorkflowName] = useState("");
    const [workflowStatus] = useState("active");
    const [recurringType] = useState("none");
    const [openSavePayrollModal, setOpenSavePayrollModal] = useState(false);
    const [selectedDay] = useState(1);
    const [, setEditModeOn] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [disableReview, setDisableReview] = useState(false);
    const [budget, setBudget] = useState(null);
    const { mutateAsync: linkTransactionIdToBudget } = updateTransactionIdToBudget();
    const { mutateAsync: createWorkflow } = useCreateWorkflow();
    const [showTagSelectorBox, setShowTagSelectorBox] = useState(true);

    const getStepText = () => {
        switch (massPayoutStep) {
            case 1:
                return "Select Recipients";
            case 2:
                return "Set Payout";
            case 3:
                return "Review";
        }
    };

    useEffect(() => {
        CheckIfLimitsAreNotConsumed(currentSafeAddress)
            .then(data => setIsMetaTxLimitAllowed(data))
            .catch(err => setIsMetaTxLimitAllowed(false));
    }, [currentSafeAddress]);

    const handleCloseConfirmationModal = () => {
        setOpenConfirmationModal(false);
    };
    const handleCloseSavePayrollModal = e => {
        e.stopPropagation();
        setWorkflowName("");
        setSaveToPayroll(false);
        setOpenSavePayrollModal(false);
        setEditModeOn(false);
    };

    const handleCreateTxWithMetaTransaction = async () => {
        if (isMetaTxEnabled && isMetaTxLimitAllowed) {
            try {
                setIsTxStarted(true);
                const threshold = await safeSdk.getThreshold();
                if (threshold && threshold == 1) return setOpenConfirmationModal(true);
                await handleCreateTx();
            } catch (err) {
                await handleCreateTx();
            } finally {
                setIsTxStarted(false);
            }
        } else {
            handleCreateTx();
        }
    };

    const handleCreateTx = async () => {
        let txstatus = await createMassPayout(
            safeService,
            safeSdk,
            payoutQueueByTag,
            currentSafeAddress,
            customToken,
            multiSend,
            library,
            account,
            enqueueSnackbar,
            setIsTxStarted,
            closeModal,
            isMetaTxEnabled,
            isMetaTxLimitAllowed,
            setOpenModal,
            setTxStatus,
            isDelegateAccess,
            isDelegateCanCreateTx,
            isWalletEOA,
            safeDetailsFromGnosis?.version,
            chainId,
            isHardwareWallet,
            getGasEstimateTransactionExecution,
            ethersAdapterOwner,
            isGasLowEnough,
        );
        setTxStatus(txstatus);
        setSafeNonce();
        if (txstatus.status) {
            let disbursement = [];

            for (let tag in payoutQueueByTag) {
                payoutQueueByTag[tag].map(people => {
                    if (people.selectedTokens) {
                        people.selectedTokens.map(token => {
                            disbursement.push({
                                address: people.address,
                                amount: token.amount.toNumber(),
                                token: token.token.symbol,
                                fiatValue: token.token.fiatConversion * token.amount,
                                fiatCurrency: "USD",
                                tokenValue: token.amount.toNumber(),
                                tagName: tag,
                                disbursementType: category,
                            });
                        });
                    }
                });
            }

            const { addTransaction } = await mutateAsync({
                txParams: {
                    transaction: {
                        description: description.trim(),
                        paymentType: transferType,
                        nonce: txstatus.data.nonce,
                        status: txstatus.data.status,
                        transactionHash: txstatus.data.txHash,
                        transactionFees: 0,
                        TransactionDisbursementDetails: disbursement,
                    },
                },
                currentSafeAddress,
            });

            // link transaction to budget
            // budget is null by default or 0 if selected "do not link with any budget"
            if (budget) {
                await linkTransactionIdToBudget({
                    safeAddress: currentSafeAddress,
                    transactionId: addTransaction.transactionId,
                    budgetId: budget,
                });
            }

            let updatedBy = _owners.find(owner => owner.address == account);

            if (isDelegateAccess && isDelegateCanCreateTx) {
                updatedBy = contributedSafes.find(safe => safe.safeAddress == currentSafeAddress);
            }

            if (txstatus?.data?.status === "Pending") {
                const filteredOtherOwners = _owners
                    .filter(owner => owner.address != account)
                    .map(({ ownerEmail, nickName }) => {
                        return {
                            emailAddress: ownerEmail,
                            ownerName: nickName ? nickName : "Safe Owner",
                        };
                    });
                const payeesLength = Object.values(payoutQueueByTag).reduce(
                    (prev, item: any) => (prev += item?.length),
                    0,
                );
                sendEmail(
                    payeesLength,
                    safeName,
                    filteredOtherOwners,
                    description.trim(),
                    updatedBy?.nickName,
                    addTransaction.transactionId,
                );
            }

            await triggerSlackNotification({
                action: "CREATED",
                initator: account,
                txData: {
                    description,
                    paymentType: transferType,
                    organisationName: safeName,
                    threshold,
                    networkId,
                    transactionHash: txstatus.data.txHash,
                    safeAddress: currentSafeAddress,
                    nonce: txstatus.data.nonce,
                    transactionId: addTransaction.transactionId,
                    fiatValue: disbursement?.reduce((acc, curr) => (acc += curr.fiatValue), 0),
                    createdBy: account,
                    updatedBy: account,
                    createdByName: updatedBy?.nickName,
                },
            });

            if (saveToPayroll && workflowName) {
                try {
                    const scheduleValue =
                        recurringType === "none"
                            ? null
                            : recurringType === "weekly"
                            ? String(selectedDay - 1)
                            : String(selectedDay);
                    setIsTxStarted(true);
                    const params = {
                        name: workflowName,
                        status: workflowStatus,
                        payouts: getPayoutDetails(payoutQueueByTag),
                        scheduleType: recurringType,
                        scheduleValue,
                    };
                    await createWorkflow({
                        ...params,
                        safeAddress: currentSafeAddress,
                    });

                    // enqueueSnackbar(`Workflow Saved`, {
                    //     variant: "success",
                    // });
                } catch (err) {
                    // enqueueSnackbar(`Error in saving Workflow`, {
                    //     variant: "error",
                    // });
                    setTxStatus({
                        status: false,
                    });
                    console.error("error in saving workflow", err);
                }
            }

            // if (payrollId) {
            //     await updatePayroll({
            //         payrollId: payrollId,
            //         transactionId: addTransaction?.transactionId,
            //         safeAddress: currentSafeAddress,
            //     });
            // }
        } else {
            // enqueueSnackbar("Error", { variant: "error" });
        }
    };

    useEffect(() => {
        let disable = false;
        Object.values(payoutQueueByTag)
            .flat()
            .map((people: { selectedTokens: any }) => {
                if (!people.selectedTokens || people.selectedTokens.length === 0) {
                    disable = true;
                }
            });
        setDisableReview(disable);
    }, [payoutQueueByTag]);

    useEffect(() => {
        if (massPayoutStep == 2) {
            setIsEligibleToTx(true);
            return;
        }
    }, [massPayoutStep]);
    const handleCloseModal = () => {
        setOpenModal(false);
        setTxStatus({
            status: null,
            data: {
                message: "Gathering Information",
            },
        });
        if (txStatus.status) {
            closeModal();
            router.push({
                pathname: "/transactions",
                query: {
                    nonce: txStatus.data.nonce,
                    safeTxHash: txStatus.data.txHash,
                },
            });
            resetPayout();
            resetTransfer();
        }
    };
    return (
        <>
            <MassPayoutStyled>
                <TitleHolder direction={"row"}>
                    <ModuleHeading>Mass Payout</ModuleHeading>
                    <SlantDivider>/</SlantDivider>
                    <MassPayoutStepNameStyled>{getStepText()}</MassPayoutStepNameStyled>
                    <MassPayoutStepNumbers>
                        <CurrentStep>{massPayoutStep}</CurrentStep>
                        <TotalSteps>/{MASSPAYOUT_MAX_STEPS}</TotalSteps>
                    </MassPayoutStepNumbers>
                </TitleHolder>
                <ProgressBarHolder>
                    <ProgressBar currentStep={massPayoutStep} totalSteps={MASSPAYOUT_MAX_STEPS} />
                </ProgressBarHolder>
                {massPayoutStep === 1 &&
                    !(showTagSelectorBox && Object.keys(payoutQueueByTag).length === 0) && (
                        <PeopleSearchBlock />
                    )}
                <TagAndBalanceBox direction={"row"}>
                    <SelectedTagsBlock setInitial={massPayoutStep === 2} />
                    <SafeBalanceBlock>
                        <SafeBalance>
                            <span className="side-heading">Safe Balance</span>
                            <span className="balance">${formatAmount(getSafeBalance(), 2, 2)}</span>
                        </SafeBalance>
                    </SafeBalanceBlock>
                </TagAndBalanceBox>
                {massPayoutStep === 1 && (
                    <TableSectionWrapper direction="row">
                        {showTagSelectorBox && Object.keys(payoutQueueByTag).length === 0 ? (
                            <TagSelectorBox
                                hideTagSelectorBox={() => setShowTagSelectorBox(false)}
                            />
                        ) : (
                            <PeopleTableBlock />
                        )}

                        <SelectedPeopleBlock />
                    </TableSectionWrapper>
                )}
                {massPayoutStep === 2 &&
                    selectedTags.map(tag => {
                        return (
                            <PeopleTokenTable
                                tagInfocus={tag}
                                isVisible={tag === tagInfocus}
                                setDisableReview={setDisableReview}
                            />
                        );
                    })}

                {massPayoutStep === 3 && (
                    <ReviewScreen
                        description={description}
                        setDescription={setDescription}
                        gasLimit={gasLimit}
                        setCategory={setCategory}
                        budget={budget}
                        setBudget={setBudget}
                        safeSdk={safeSdk}
                    />
                )}

                {massPayoutStep === 2 || massPayoutStep === 3 ? (
                    <>
                        <CSVTableAction>
                            <Button
                                variant="outlined"
                                onClick={() => setMassPayoutStep(massPayoutStep - 1)}
                                startIcon={<ArrowBackIosIcon style={{ fontSize: "12px" }} />}
                            >
                                Back
                            </Button>

                            <Box className="flex" sx={{ justifyContent: "flex-end" }}>
                                {massPayoutStep === 3 && (
                                    <Box
                                        className="flex"
                                        sx={{
                                            marginRight: "60px",
                                        }}
                                    >
                                        {workflowName && saveToPayroll && !openSavePayrollModal ? (
                                            <WorkflowNameContainer
                                                onClick={() => {
                                                    setEditModeOn(true);
                                                    setOpenSavePayrollModal(true);
                                                }}
                                            >
                                                <div>
                                                    <CloseIcon
                                                        className="icon"
                                                        onClick={handleCloseSavePayrollModal}
                                                    />
                                                </div>
                                                <div>
                                                    <Tooltip title={workflowName}>
                                                        <div className="wf-name">
                                                            {formatLongName(workflowName)}
                                                        </div>
                                                    </Tooltip>

                                                    <div className="wf-status">
                                                        {workflowStatus}
                                                    </div>
                                                </div>
                                            </WorkflowNameContainer>
                                        ) : (
                                            <>
                                                {/* <Checkbox
                                                disabled={isTxStarted}
                                                checked={saveToPayroll}
                                                onChange={e => {
                                                    setSaveToPayroll(e.target.checked);
                                                    setOpenSavePayrollModal(e.target.checked);
                                                }}
                                            ></Checkbox>
                                            <Typography color={"#9998A4"}>
                                                Save to Payroll
                                            </Typography> */}
                                            </>
                                        )}
                                    </Box>
                                )}

                                <LoadingButton
                                    onClick={() =>
                                        massPayoutStep === 3
                                            ? handleCreateTxWithMetaTransaction()
                                            : setMassPayoutStep(3)
                                    }
                                    loading={isTxStarted}
                                    variant="contained"
                                    endIcon={<ArrowForwardIcon />}
                                    disabled={
                                        massPayoutStep === 3 &&
                                        (!description?.trim() || isEligibleToTx === false)
                                            ? true
                                            : massPayoutStep === 2 && disableReview
                                            ? true
                                            : false
                                    }
                                >
                                    {massPayoutStep === 2 ? "Review Payout" : "Create Transaction"}
                                </LoadingButton>
                            </Box>
                        </CSVTableAction>
                    </>
                ) : null}
                <MetaTxModal
                    open={openConfirmationModal}
                    handleCloseConfirmationModal={handleCloseConfirmationModal}
                    handleConfirm={() => {
                        setOpenConfirmationModal(false);
                        handleCreateTx();
                    }}
                    variant={isGasLowEnough ? "confirm" : "highGas"}
                />
                {/* <SavePayrollModal
                    open={openSavePayrollModal}
                    handleCloseConfirmationModal={handleCloseSavePayrollModal}
                    workflowName={workflowName}
                    setWorkflowName={setWorkflowName}
                    workflowStatus={workflowStatus}
                    setWorkflowStatus={setWorkflowStatus}
                    recurringType={recurringType}
                    setRecurringType={setRecurringType}
                    selectedDay={selectedDay}
                    setSelectedDay={setSelectedDay}
                    handleConfirm={() => {
                        setOpenSavePayrollModal(false);
                    }}
                    editModeOn={editModeOn}
                    startDate={startDate}
                    setStartDate={setStartDate}
                /> */}
            </MassPayoutStyled>

            <Dialog open={openModal} maxWidth="xs" fullWidth onClose={() => {}}>
                <Box width="100%" textAlign="right">
                    {txStatus.status !== null && (
                        <ClearIcon
                            sx={{ color: "#636B81", my: "10px", mx: "20px", cursor: "pointer" }}
                            onClick={handleCloseModal}
                        />
                    )}
                </Box>
                <Box px={"20px"} pt={"30px"} pb={"20px"} textAlign="center">
                    {txStatus.status === null && (
                        <>
                            <CircularProgress
                                variant={"indeterminate"}
                                size={100}
                                thickness={2}
                                sx={{
                                    color: "primary",
                                }}
                                value={100}
                            />

                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {txStatus.data?.hash && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        href={`${etherscan}/tx/${txStatus.data.hash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                        target="_blank"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )}
                            <Typography fontSize="18px" fontWeight={600} sx={{ mt: "5px" }}>
                                {txStatus.data.message}
                            </Typography>
                        </>
                    )}
                    {txStatus.status === true && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-icon.png" />
                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {txStatus?.data?.status !== "Pending" && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        target="_blank"
                                        href={`${etherscan}/tx/${txStatus.data.txHash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )}
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "5px", mb: "20px" }}
                            >
                                {isWalletEOA ? txStatus?.data?.message : `Transaction Created`}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                            >
                                Close
                            </Button>
                        </>
                    )}
                    {txStatus.status === false && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-failed.png" />
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "20px", mb: "20px" }}
                            >
                                {txStatus?.data?.message
                                    ? txStatus?.data?.message
                                    : "Transaction Rejected"}
                            </Typography>

                            <Button
                                fullWidth
                                variant="contained"
                                disabled={
                                    massPayoutStep === 3 &&
                                    (!description?.trim() || isEligibleToTx === false)
                                        ? true
                                        : massPayoutStep === 2 && disableReview
                                        ? true
                                        : false
                                }
                                onClick={handleCloseModal}
                            >
                                Dismiss
                            </Button>
                        </>
                    )}
                </Box>
            </Dialog>
        </>
    );
};

export default MassPayout;
