import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { ActionMenu } from "src/components/PeopleSection/Tables/styles";

export const QueueItemStyled = styled(Stack)`
    align-items: center;
    /* margin-bottom: 25px; */
    .nick-name,
    .usd-value {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
        margin: 0;
        color: ${({ theme }: any) => theme.palette.title.main};
        white-space: nowrap;
    }
    .nick-name {
        display: flex;
        align-items: center;

        svg {
            color: #adb1bd;
            width: 14px;
            height: 14px;
            margin-left: 6px;
            :hover {
                color: #2962ef;
            }
        }
    }
    .wallet-address,
    .token-value {
        font-size: 13px;
        font-weight: 500;
        line-height: 13px;
        margin: 5px 0 0;
        color: ${({ theme }: any) => theme.palette.title.light};
        white-space: nowrap;
    }
    .value-wrapper {
        flex: 100px;
        text-align: right;
    }
    .detail-wrapper {
        flex: 100%;
        padding-left: 12px;
    }
    .action-wrapper {
        .more-button {
            padding: 0;
            background-color: #f2f2f2;
            border-radius: 4px;
            margin-left: 20px;
            padding: 4px;
        }
        .MuiSvgIcon-root {
            font-size: 16px;
        }
    }
`;

export const ActionMenuStyled = styled(ActionMenu)`
    z-index: 1510;
    .MuiPaper-elevation {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 64px 128px rgba(0, 0, 0, 0.08);
        border-radius: 4px;

        .action {
            &:not(:nth-of-type(1)) {
                border: 1px solid #f2f3f5;
            }
        }
    }
`;

export const InfoWrapper = styled.div`
    padding: 8px 4px;
    display: flex;
    flex-direction: column;
    gap: 12px;
    min-width: 200px;

    .frame1 {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .flex {
        width: 100%;
    }

    .label {
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        color: #8b91a1;
    }

    .value {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #ffffff;
        margin-top: 4px;
    }
`;
