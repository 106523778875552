import styled from "@emotion/styled";
import { Button } from "@mui/material";

export const SimulationBoxStyled = styled.div`
    width: 100%;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    background: #ffffff;
    border: 1px solid #dfe1e5;
    border-radius: 4px;
    padding: 14px;
    & > .simulateinfo {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        & > .logowrapper {
            display: flex;
            justify-content: center;
            align-items: center;
        }
        & > .simulationname {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-family: "Metropolis";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 14px;
            color: #636b81;
            margin-left: 8px;
            & > div:nth-child(1) {
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: #636b81;
                text-align: left;
            }
            & > div:nth-child(2) {
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 12px;
                color: #8b91a1;
                text-align: left;
                margin-top: 6px;
                & > span {
                    font-weight: 600;
                }
            }
        }
    }
    & > simulateaction {
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const SimulateButton = styled(Button)`
    font-family: "Metropolis";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: right;
    color: #2962ef;
    height: 34px;
    border: 1px solid #2962ef;
    border-radius: 4px;
`;

export const NetworkFeesBox = styled.div`
    height: 46px;
    width: 100%;
    margin-top: 20px;
    background: #ffffff;
    border: 1px solid #dfe1e5;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    padding: 16px;
    & > .networkdfees {
        font-family: "Metropolis";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        color: #25274f;
    }
    & > .fast {
        font-family: "Metropolis";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        text-align: right;
        color: #2962ef;
    }
`;

export const SimulationResultBoxStyled = styled.div`
    width: 100%;
    padding: 14px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    border: 1px solid #dfe1e5;
    border-radius: 4px;
    margin-top: 20px;
    .failed-simulation {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        .failed-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .simulatefailed {
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                color: #f44040;
            }
            & > span:nth-child(2) {
                width: 25px;
                height: 25px;
            }
        }
        .failed-body {
            width: 100%;
            margin-top: 4px;
            & > h6 {
                width: 100%;
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #25274f;
                & > span {
                    color: #2962ef;
                }
            }
        }
    }
    .success-simulation {
        .success-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .simulatesuccess {
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                color: #27a643;
            }
            & > span:nth-child(2) {
                width: 25px;
                height: 25px;
            }
        }
        .success-body {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            & > h6 {
                width: 100%;
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: 20px;
                color: #25274f;
                & > span {
                    color: #2962ef;
                }
            }
        }
    }
    button {
        width: 131px;
        height: 38px;
        font-family: "Metropolis";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 14px;
        text-align: center;
        color: #2962ef;
        padding: 10px 0px;
    }
`;

export const SimulationDialogBox = styled.div`
    width: 100%;
    height: 100%;
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    & > div:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: flex-end;
        padding: 14px 18px 0px;
    }
    & > div:nth-child(2) {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 0px 18px;
        & > div:nth-child(1) {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            .simulatesuccess {
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                color: #27a643;
            }
            .simulatefailed {
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 600;
                font-size: 18px;
                line-height: 18px;
                color: #f44040;
            }
            & > span:nth-child(2) {
                width: 25px;
                height: 25px;
            }
        }
        & > div:nth-child(2) {
            & > h6 {
                width: 100%;
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #25274f;
                & > span {
                    color: #2962ef;
                }
            }
        }
    }
    & > div:nth-child(3) {
        width: 100%;
        margin-top: 20px;
        padding: 0px 18px;
        .tabletitle {
            font-family: "Metropolis";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #25274f;
            margin-bottom: 8px;
        }
        thead > tr > th {
            font-family: "Metropolis";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #8b91a1;
        }
        tbody > tr > td {
            .tokenvalue {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                & > div:nth-child(1) {
                }
                & > div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-left: 8px;
                    & > div:nth-child(1) {
                        & > h6 {
                            font-family: "Metropolis";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                            color: #25274f;
                        }
                    }
                    & > div:nth-child(2) {
                        margin-top: 6px;
                        & > h6 {
                            font-family: "Metropolis";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: 13px;
                            color: #8b91a1;
                            margin-top: 4px;
                        }
                    }
                }
            }
            .recipientsvalue {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                & > div:nth-child(1) {
                    & > h6 {
                        font-family: "Metropolis";
                        font-style: normal;
                        font-weight: 500;
                        font-size: 13px;
                        line-height: 13px;
                        color: #8b91a1;
                    }
                }
                & > div:nth-child(2) {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-left: 10px;
                    & > div:nth-child(1) {
                        & > h6 {
                            font-family: "Metropolis";
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 14px;
                            color: #25274f;
                        }
                    }
                    & > div:nth-child(2) {
                        margin-top: 6px;
                        & > h6 {
                            font-family: "Metropolis";
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 13px;
                            color: #8b91a1;
                        }
                    }
                }
            }
            .decimalvalue {
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 14px;
                color: #25274f;
            }
        }
    }
    & > div:nth-child(4) {
        margin-top: 20px;
        height: 64px;
        width: 100%;
        background: #f5f8fe;
        border-radius: 0px 0px 8px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 18px;
        .tenderlypowered {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            & > span {
                margin-left: 8px;
                font-family: "Metropolis";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: #8b91a1;
                & > span {
                    font-weight: 600;
                }
            }
        }
    }
`;
