import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

export const QuickTransferStyled = styled.div`
    .block-heading {
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
    }
    .recipient-block {
        padding: 60px 68px 0px;
        flex: 100%;
        height: 100vh;
        display: flex;
        justify-content: end;
    }
    .queue-block {
        padding: 60px 58px 0px;
        background: #f5f6f7;
        flex: 100%;
    }
    .recipient-box,
    .queue-box {
        @media only screen and (min-width: 1200px) {
            max-width: 600px;
        }
        width: 100%;
        .form-control {
            border: 1px solid #ededed;
            border-radius: 4px;
            font-size: 14px;
            line-height: 45px;
        }
        .address-field {
            font-size: 14px;
            line-height: 45px;
            .MuiOutlinedInput-root {
                padding: 0 9px;
            }
        }
        .MuiInput-root #hide {
            display: none;
        }
        .label-text {
            font-size: 12px;
            line-height: 12px;
            color: ${({ theme }: any) => theme.palette.title.sub};
            margin-bottom: 8px;
            font-weight: 500;
        }
    }

    .queue-box {
        margin-top: 16px;
        padding: 15px;
        background: #ffffff;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        max-height: 320px;
        overflow-y: scroll;
        ::-webkit-scrollbar-thumb {
            background-color: ${({ theme }: any) => theme.table.headertext};
            border-radius: 20px;
            border: 6px solid transparent;
            background-clip: content-box;
        }
        ::-webkit-scrollbar,
        ::-webkit-scrollbar-track {
            background: transparent;
        }
        .empty-holder {
            min-height: 250px;
        }
        .empty-text {
            font-weight: normal;
            font-size: 18px;
            line-height: 18px;
            text-align: center;
            color: #eaeaea;
        }
    }

    .add-queue-button {
        width: 100%;
        margin-top: 30px;
        text-transform: none;
    }
`;

export const SummaryHolder = styled(Stack)`
    @media only screen and (min-width: 1200px) {
        max-width: 600px;
    }
    margin-top: 20px;
    .summary-value {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        font-weight: 600;
    }
    .summary-value-red {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        font-weight: 600;
        color: #f00;
    }
    .submit-holder {
        .divider {
            margin: 20px 0;
            border-color: #eaeaea;
        }
        button {
            background: white;
        }
    }
    .gas-limit-holder {
        .gas-fee-display {
            font-size: 14px;
            line-height: 14px;
            margin: 0;
            font-weight: 400;
            color: ${({ theme }: any) => theme.palette.primary.main};
            display: flex;
            align-items: center;
            cursor: pointer;
        }
        .divider-dot {
            font-size: 5px;
            margin: 0 7px;
        }
    }
    .create-tx-button {
        margin-top: 20px;
        margin-bottom: 20px;
        text-transform: none;
    }
`;

export const EmptyQueueBox = styled.div`
    padding: 60px 58px 0px;
    background: #f5f6f7;
    flex: 100%;

    .empty-state-wrapper {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 10px;
        gap: 10px;
        height: 285px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }

    .empty-state-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 55px;
        gap: 12px;
        height: 104px;
    }
`;
