import styled from "@emotion/styled";

export const WhatsNewHeader: any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 16px;
    width: 350px;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    justify-content: space-between;
`;

export const WhatsNewHeaderTitle: any = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: ${({ theme }: any) => theme.palette.primary.main};
`;

export const WhatsNewBody: any = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 20px;
    background: #ffffff;
    width: 350px;
`;

export const Card: any = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px 0px 16px;
    gap: 8px;
    border-bottom: 1px solid #eff0f2;
`;

export const CardDate: any = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #636b81;
    margin-top: 10px;
`;

export const CardTitle: any = styled.div`
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    color: ${({ theme }: any) => theme.palette.title.main};
`;

export const CardSubHeading: any = styled.div`
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #636b81;
`;

export const CardBodyText: any = styled.ul`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    gap: 10px;
    background: #f5f6f7;
    border-radius: 8px;
    padding-left: 25px;
    margin: 5px 0;
`;

export const CardBodyTextContent: any = styled.li`
    max-width: 100%;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }: any) => theme.palette.title.main};
`;

export const CardBodyLink: any = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 6px 6px 11px;
    background: #f5f6f7;
    border-radius: 8px;
    width: 100%;
    gap: 10px;
    .blue-arrow {
        display: none;
    }
    :hover .blue-arrow {
        display: block;
    }
    :hover .blue-text {
        font-weight: 500;
        text-decoration-line: underline;
        color: ${({ theme }: any) => theme.palette.primary.main};
    }
    :hover {
        cursor: pointer;
    }
`;

export const CardBodyLinkContent: any = styled.a`
    display: block;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-decoration-line: underline;
    color: ${({ theme }: any) => theme.palette.title.main};
`;

export const SeeMore: any = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    color: ${({ theme }: any) => theme.palette.primary.main};
    :hover {
        cursor: pointer;
    }
`;
