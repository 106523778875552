import { request, gql } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "pages/_app";

import { baseURI, GET_PAYROLL_BY_SAFE } from "src/queries/constants";

import { graphQLClient } from "../graphqlClient";

export function useGetParollBySafe(address, status = []) {
    return useQuery([GET_PAYROLL_BY_SAFE, address, status], async () => {
        const { payrollBySafe } = await graphQLClient(baseURI, address).request(
            gql`
                query PayrollBySafe($status: [String]) {
                    payrollBySafe(status: $status) {
                        payrollId
                        name
                        status
                        scheduleType
                        scheduleValue
                        lastTransaction
                        startDate
                        payoutDetails {
                            to
                            tokenAddress
                            amount
                            tokenSymbol
                            tag
                            fixedUsd
                            fiatValue
                            recipient {
                                address
                                nickName
                                name
                                ens
                            }
                        }
                    }
                }
            `,
            {
                status,
            },
        );

        return payrollBySafe;
    });
}
