import React, { useState } from "react";
import { NavLink } from "src/components/Buttons";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import TransferContext from "src/contexts/TransferContext";
import { DaoDashboardProfileBox, DaoDetails, NavLinkList } from "./styles";
import SafeContext from "src/contexts/SafeContext";
import { formatAmount, minifyAddress, minifySafeName } from "src/helpers/utils/web3Utils";
import SafeSwitchDrawer from "../SwitchSafeDrawer";
import { Tooltip } from "@mui/material";
import { useActiveBaseContext } from "src/hooks";
import NavBarIcons from "src/components/CustomIcons/NavBarIcons";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import StampAvatar from "src/components/StampAvatar";
import NestedList from "./NestedList";

function NavSideBar() {
    const [openSquads, setOpenSquads] = useState(false);
    const [openTransactions, setOpenTransactions] = useState(false);
    const [openSwitchSafeDrawer, setOpenSwitchSafeDrawer] = useState(false);
    const router = useRouter();
    const { modalState, maximizeModal }: any = React.useContext(TransferContext);
    const {
        currentSafeAddress,
        safeBalanceInUSD,
        safeStaticDetails,
        isDelegateAccess,
        setIsDelegateAccess,
        setIsDelegateCanCreateTx,
        isDelegateCanCreateTx,
        countPendingTransactions,
        childSafes,
        childSafesMeta,
        childPendingTxnCount,
    }: any = React.useContext(SafeContext);
    const totalBalInUSD: any = Object.values(safeBalanceInUSD).reduce(
        (a: number, b: number) => a + b,
        0,
    );
    const { ethersAdapterOwner, account, contributedSafes, ownedSafes } =
        useActiveBaseContext().web3;

    if (
        !ownedSafes.find(_s => _s.safeAddress == currentSafeAddress) &&
        contributedSafes.find(_s => _s.safeAddress == currentSafeAddress) &&
        contributedSafes.find(_s => _s.safeAddress == currentSafeAddress)?.safeAccess?.hasSafeAccess
    ) {
        setIsDelegateAccess(true);
        if (
            contributedSafes.find(_s => _s.safeAddress == currentSafeAddress)?.safeAccess
                ?.isTransactionCreationAllowed
        ) {
            setIsDelegateCanCreateTx(true);
        }
    }

    const handleCloseDrawer = () => {
        setOpenSwitchSafeDrawer(false);
    };
    const allChildSafes = childSafesMeta.map(childSafe => {
        return {
            title: `${minifySafeName(childSafe.safeName)}`,
            href: `/childsafe/${childSafe.safeAddress}`,
            isactive: router.asPath === `/childsafe/${childSafe.safeAddress}`,
            pendingTxnCount: `${
                childPendingTxnCount.find(safe => safe.safeAddress == childSafe.safeAddress)
                    ?.pendingCount
            }`,
        };
    });
    const subMenuArray = [
        {
            title: `${
                safeStaticDetails?.safeName
                    ? minifySafeName(safeStaticDetails.safeName)
                    : minifyAddress(currentSafeAddress)
            }`,
            href: "/transactions",
            isactive: router.pathname === "/transactions",
            pendingTxnCount: `${countPendingTransactions}`,
        },
        ...allChildSafes,
    ];

    let totalPendingCount = childSafesMeta.reduce(
        (partialSum, childSafe) =>
            partialSum +
            childPendingTxnCount.find(safe => safe.safeAddress == childSafe.safeAddress)
                ?.pendingCount,
        countPendingTransactions,
    );

    const isChild = safeStaticDetails?.isSquadOrParent === "sqaud" && safeStaticDetails?.parentSafe;
    const isParent =
        safeStaticDetails?.isSquadOrParent === "parent" && safeStaticDetails?.squads?.length > 0;
    const isBoth =
        safeStaticDetails?.isSquadOrParent === "both" &&
        safeStaticDetails?.parentSafe &&
        safeStaticDetails?.squads?.length > 0;
    const renderSquadTabs = () => {
        if (isBoth) {
            return (
                <NestedList
                    open={openSquads}
                    setOpen={setOpenSquads}
                    title={"Squads"}
                    iconName={"squads"}
                    subMenus={[
                        {
                            title: "Overview",
                            href: "/squads/overview",
                            isactive: router.pathname === "/squads/overview",
                        },
                        {
                            title: "Your Squads",
                            href: "/squads/your-squads",
                            isactive: router.pathname === "/squads/your-squads",
                        },
                        {
                            title: "Squads You're A Part Of",
                            href: "/squads/my-parent-squads",
                            isactive: router.pathname === "/squads/my-parent-squads",
                        },
                    ]}
                />
            );
        }
        if (isChild) {
            return (
                <NavLink
                    title="Squads"
                    tagText="New"
                    icon={
                        router.pathname.includes("/squads/overview") ? (
                            <NavBarIcons name="squads" color="#2962EF" size={undefined} />
                        ) : (
                            <NavBarIcons name="squads" color="#25274F" size={undefined} />
                        )
                    }
                    href="/squads/overview"
                    isactive={router.pathname.includes("/squads/overview")}
                />
            );
        }
        if (isParent)
            return (
                <NestedList
                    open={openSquads}
                    setOpen={setOpenSquads}
                    title={"Squads"}
                    iconName={"squads"}
                    subMenus={[
                        {
                            title: "Overview",
                            href: "/squads/overview",
                            isactive: router.pathname === "/squads/overview",
                        },
                        {
                            title: "Budgets",
                            href: "/squads/budget",
                            isactive: router.pathname === "/squads/budget",
                        },
                    ]}
                />
            );
    };

    return (
        <>
            <DaoDashboardProfileBox>
                <DaoDetails>
                    <StampAvatar
                        containerStyles={{ marginRight: 0 }}
                        seed={currentSafeAddress}
                        size={40}
                    />

                    <div className="flex-column">
                        <div className="flex-row">
                            <Tooltip
                                title={`${
                                    safeStaticDetails?.safeName.length > 14
                                        ? safeStaticDetails.safeName
                                        : ""
                                }`}
                                placement="right"
                            >
                                <div className="dao-name">
                                    {safeStaticDetails?.safeName
                                        ? minifySafeName(safeStaticDetails.safeName)
                                        : "Unnamed DAO"}
                                </div>
                            </Tooltip>

                            <div className="drop-icon">
                                <KeyboardArrowDownIcon
                                    onClick={() => setOpenSwitchSafeDrawer(true)}
                                />
                            </div>
                        </div>

                        <div className="balance">
                            <Typography
                                color="title.main"
                                fontSize="16px"
                                fontWeight="700"
                                justifyContent="center"
                                display="flex"
                            >
                                ${formatAmount(totalBalInUSD, 0, 0)}
                            </Typography>
                        </div>
                    </div>
                </DaoDetails>

                {isDelegateAccess && !isDelegateCanCreateTx ? (
                    <Tooltip title="You don't have access to Create Transactions">
                        <Box>
                            <Button
                                disabled
                                fullWidth
                                color="primary"
                                variant="contained"
                                startIcon={
                                    <NavBarIcons
                                        color="white"
                                        name="nort_east_arrow"
                                        size={undefined}
                                    />
                                }
                                disableRipple={false}
                                sx={{ fontSize: "1rem", fontWeight: 600 }}
                                onClick={e => maximizeModal()}
                            >
                                {modalState === "minimized" ? "Continue Payout" : "New Transfer"}
                            </Button>
                        </Box>
                    </Tooltip>
                ) : (
                    <Box>
                        <Button
                            fullWidth
                            color="primary"
                            variant="contained"
                            startIcon={
                                <NavBarIcons
                                    color="white"
                                    name="nort_east_arrow"
                                    size={undefined}
                                />
                            }
                            disableRipple={false}
                            sx={{ fontSize: "1rem", fontWeight: 600 }}
                            onClick={e => maximizeModal()}
                        >
                            {modalState === "minimized" ? "Continue Payout" : "New Transfer"}
                        </Button>
                    </Box>
                )}
            </DaoDashboardProfileBox>

            <NavLinkList>
                <NavLink
                    title="Dashboard"
                    icon={
                        router.pathname === "/dashboard" ? (
                            <NavBarIcons name="dashboard_solid" color="#2962EF" size={undefined} />
                        ) : (
                            <NavBarIcons name="dashboard" color="#25274F" size={undefined} />
                        )
                    }
                    href="/dashboard"
                    isactive={router.pathname === "/dashboard"}
                />

                <NavLink
                    title="People"
                    icon={
                        router.pathname === "/people" ? (
                            <NavBarIcons name="people_solid" color="#2962EF" size={undefined} />
                        ) : (
                            <NavBarIcons name="people" color="#25274F" size={undefined} />
                        )
                    }
                    href="/people"
                    isactive={router.pathname === "/people"}
                />

                {childSafes?.length && childSafesMeta?.length > 0 ? (
                    <NestedList
                        open={openTransactions}
                        setOpen={setOpenTransactions}
                        title={"Transactions"}
                        iconName={"transactions"}
                        subMenus={subMenuArray}
                        pendingNumbers={totalPendingCount}
                    />
                ) : (
                    <NavLink
                        title="Transactions"
                        icon={
                            router.pathname === "/transactions" ? (
                                <NavBarIcons name="transactions" color="#2962EF" size={undefined} />
                            ) : (
                                <NavBarIcons name="transactions" color="#25274F" size={undefined} />
                            )
                        }
                        href="/transactions"
                        isactive={router.pathname === "/transactions"}
                        pendingNumbers={countPendingTransactions}
                    />
                )}
                {/* <NavLink
                    title="Child Safes"
                    icon={
                        router.pathname === "/childsafe" ? (
                            <NavBarIcons name="transactions" color="#2962EF" size={undefined} />
                        ) : (
                            <NavBarIcons name="transactions" color="#25274F" size={undefined} />
                        )
                    }
                    href="/childsafe"
                    isactive={router.pathname === "/childsafe"}
                /> */}

                <NavLink
                    title="Requests"
                    icon={
                        router.pathname.includes("/requests") ? (
                            <NavBarIcons name="requests_solid" color="#2962EF" size={undefined} />
                        ) : (
                            <NavBarIcons name="requests" color="#25274F" size={undefined} />
                        )
                    }
                    href="/requests/pending"
                    isactive={router.pathname.includes("/requests")}
                />

                <NavLink
                    title="Recurring"
                    icon={
                        router.pathname === "/recurring" ? (
                            <NavBarIcons name="recurring_solid" color="#2962EF" size={undefined} />
                        ) : (
                            <NavBarIcons name="recurring" color="#25274F" size={undefined} />
                        )
                    }
                    href="/recurring"
                    isactive={router.pathname.includes("/recurring")}
                />
                <NavLink
                    title="Integrations"
                    icon={
                        router.pathname.includes("/integration") ? (
                            <NavBarIcons
                                name="integrations_solid"
                                color="#2962EF"
                                size={undefined}
                            />
                        ) : (
                            <NavBarIcons name="integrations" color="#25274F" size={undefined} />
                        )
                    }
                    href="/integrations"
                    isactive={router.pathname.includes("/integrations")}
                />
                {renderSquadTabs()}

                <NavLink
                    title="Settings"
                    icon={
                        router.pathname === "/settings" ? (
                            <NavBarIcons color="#2962EF" name="settings_solid" size={undefined} />
                        ) : (
                            <NavBarIcons name="settings" color="#25274F" size={undefined} />
                        )
                    }
                    href="/settings"
                    isactive={router.pathname.includes("/settings")}
                    justifySelf={"end"}
                />
            </NavLinkList>

            <SafeSwitchDrawer open={openSwitchSafeDrawer} handleCloseDrawer={handleCloseDrawer} />
        </>
    );
}

export default NavSideBar;
