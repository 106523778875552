import * as React from "react";
import Paper from "@mui/material/Paper";
import { Caption, EmptyTableWrapper, TableWrapper, TableBody } from "./styles";
import { IconButton, Typography } from "@mui/material";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";
import Image from "next/image";
import noTx from "assets/no_txn.svg";
import { NormalRow } from "./NormalRow";
import { LoadingRow } from "./LoadingRow";
import { MainHeader } from "./MainHeader";

export default function BasicTable({
    rows,
    loading,
    setLimit,
    limit,
    total,
    count,
    showReviewPage,
}) {
    return (
        <TableWrapper component={Paper}>
            <MainHeader />
            <TableBody>
                {loading ? (
                    [1, 2, 3, 4, 5, 6, 7, 8].map(item => <LoadingRow key={item} />)
                ) : rows?.length ? (
                    rows.map((row, index) => {
                        return (
                            <NormalRow row={row} index={index} showReviewPage={showReviewPage} />
                        );
                    })
                ) : (
                    <EmptyTableWrapper>
                        <Image src={noTx} />
                        <Typography
                            fontSize="1.4rem"
                            color="title.main"
                            fontWeight={700}
                            lineHeight="1.75rem"
                            mr="0.7rem"
                            my="1rem"
                            sx={{
                                width: { sm: "100%", xs: "100%" },
                                textAlign: "center",
                            }}
                        >
                            No Record Found
                        </Typography>
                    </EmptyTableWrapper>
                )}
            </TableBody>
            {/* <Caption>
                <div>
                    <span className="count">
                        {count ?? 0} out of {total ?? 0}
                    </span>
                    {total > count ? (
                        <span className="loadmore">
                            View More
                            <IconButton
                                aria-label="load more"
                                size="large"
                                style={{
                                    marginLeft: "10px",
                                }}
                                onClick={() => setLimit(limit + 8)}
                            >
                                <KeyboardDoubleArrowDownIcon />
                            </IconButton>
                        </span>
                    ) : (
                        <></>
                    )}
                </div>
            </Caption> */}
        </TableWrapper>
    );
}
