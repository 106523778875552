import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import { EmptyPeopleTextWrapperStyled, EmptyTypography } from "./styles/EmptyPeopleTextWrapper";

const EmptyPeopleTextWrapper = () => {
    const { tagInfocus, selectedTags }: any = useContext(MassPayoutContext);

    return (
        <EmptyPeopleTextWrapperStyled>
            <EmptyTypography color="#D6D5DF" fontSize="20px" fontWeight={600}>
                {selectedTags.includes(tagInfocus)
                    ? "No people found for the selected tag"
                    : "Please select some tags from the dropdown above to get started"}
            </EmptyTypography>
        </EmptyPeopleTextWrapperStyled>
    );
};

export default EmptyPeopleTextWrapper;
