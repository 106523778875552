import styled from "@emotion/styled";

export const AccessibilityText: any = styled.span`
    border-radius: 4px;
    font-size: 13px;
    line-height: 13px;
    color: #8b91a1;
`;

export const Wrapper: any = styled.div`
    input::-webkit-input-placeholder {
        opacity: 1;
    }
`;
