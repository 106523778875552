import React, { useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { ImageWrapper, DrawerBodyWrapper, SafeListWrapper, FooterActionWrapper } from "./styles";

import { Divider, Typography } from "@mui/material";

import SafeContext from "src/contexts/SafeContext";
import { useGetParcelSafes } from "src/queries/onboard/api";
import useActiveBaseContext from "src/hooks/useActiveBaseContext";
import { minifyAddress } from "src/helpers/utils/web3Utils";

import { Circle, Done, DeviceHub, AccountBalanceWallet } from "@mui/icons-material";
import Link from "next/link";
import { useRouter } from "next/router";
import TransferContext from "src/contexts/TransferContext";
import NavBarIcons from "src/components/CustomIcons/NavBarIcons";
import CustomIcons from "src/components/CustomIcons";
import Image from "next/image";
import StampAvatar from "src/components/StampAvatar";
import CopyOnClick from "src/components/common/Copy/CopyOnClick";
import { LoadingButton } from "@mui/lab";
import { etherscan } from "src/constants/endpoints";
import { BaseContextTypes } from "src/contexts/BaseContextTypes";

export default function SafeSwitchDrawer({ open, handleCloseDrawer }) {
    const router = useRouter();
    const [parcelSafes, setParcelSafes] = useState([]);
    const {
        web3: { account, setIsLoggedIn, connector, disconnectWallet, active, contributedSafes },
    } = useActiveBaseContext() as { web3: BaseContextTypes };

    const { data } = useGetParcelSafes(account, {
        refetchOnWindowFocus: false,
        enabled: true,
    });

    const { currentSafeAddress, setCurrentSafeAddress }: any = React.useContext(SafeContext);

    const { closeModal, resetTransfer }: any = React.useContext(TransferContext);
    React.useEffect(() => {
        if (!!data) {
            setParcelSafes(data?.ownedSafes);
        }
    }, [data]);

    const handleSwitchSafe = safeAddress => {
        if (currentSafeAddress === safeAddress) {
            handleCloseDrawer();
        } else {
            // if (
            //     (migrationDataFromV3?.whitelistedSafes || [])
            //         .map(({ safeAddress }): any => safeAddress)
            //         .includes(safeAddress)
            // ) {
            //     const whitelistedData = migrationDataFromV3.whitelistedSafes.find(
            //         ({ safeAddress: _safeAddress }): any => _safeAddress === safeAddress,
            //     );

            //     if (whitelistedData?.enforceMigration) {
            //         console.log("enforceMigration");
            //         setRedirectToPayroll(true);
            //         return;
            //     }
            // }

            closeModal();
            setCurrentSafeAddress(safeAddress);
            router.push("/dashboard");
            handleCloseDrawer();
        }
    };
    const handleDisconnect = React.useCallback(() => {
        disconnectWallet({
            label: connector?.label,
        });
        window?.localStorage.removeItem("selectedWallet");
        closeModal();
        resetTransfer();
        setIsLoggedIn(false);
        router.push("/");
    }, [disconnectWallet, active]);

    const list = () => (
        <Box
            sx={{
                width: 400,
                pt: 3.5,
            }}
            role="presentation"
            onKeyDown={handleCloseDrawer}
            style={{ height: "100%" }}
        >
            <DrawerBodyWrapper>
                <div>
                    <div className="heading">My Safes</div>
                    <SafeListWrapper>
                        {parcelSafes?.map(({ safeAddress, safeName }) => (
                            <div key={safeAddress}>
                                <ImageWrapper onClick={() => handleSwitchSafe(safeAddress)}>
                                    <StampAvatar
                                        containerStyles={{ marginRight: 0 }}
                                        seed={safeAddress}
                                        size={40}
                                    />

                                    <div className="flex-column">
                                        <Typography color="#2962EF" fontSize={12} fontWeight={500}>
                                            Safe Owner
                                        </Typography>
                                        <div className="safe-name">{safeName}</div>
                                        <div className="subtitle">
                                            {/* <span>$37,986 </span>
                                        <Circle className="divider-dot" /> */}
                                            <span>{minifyAddress(safeAddress)}</span>
                                        </div>
                                        <div style={{ display: "flex" }}>
                                            <div style={{ display: "flex" }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <CustomIcons name="content_copy" />
                                                </div>
                                                <CopyOnClick
                                                    style={{
                                                        dispalay: "inline",
                                                        marginLeft: 4,
                                                    }}
                                                    value={safeAddress}
                                                    text="Click to Copy Safe Address"
                                                >
                                                    <span
                                                        style={{ color: "#636B81", fontSize: 11 }}
                                                    >
                                                        Copy Address
                                                    </span>
                                                </CopyOnClick>
                                            </div>

                                            <div style={{ display: "flex", marginLeft: 8 }}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <CustomIcons name="view_content" />
                                                </div>
                                                <a
                                                    href={`${etherscan}/address/${currentSafeAddress}`}
                                                    target="_blank"
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                        color: "#636B81",
                                                        fontSize: 11,
                                                        fontWeight: 400,
                                                        marginLeft: 4,
                                                    }}
                                                >
                                                    View on explorer
                                                </a>
                                            </div>
                                        </div>
                                    </div>

                                    {currentSafeAddress === safeAddress && (
                                        <div style={{ marginLeft: "auto" }}>
                                            <Done className="done-icon" />
                                        </div>
                                    )}
                                </ImageWrapper>
                            </div>
                        ))}
                        {contributedSafes?.map(({ safeAddress, safeName, safeAccess }) => {
                            if (
                                safeAccess?.hasSafeAccess &&
                                !parcelSafes.find(_s => _s.safeAddress == safeAddress)
                            ) {
                                return (
                                    <div key={safeAddress}>
                                        <ImageWrapper onClick={() => handleSwitchSafe(safeAddress)}>
                                            <StampAvatar
                                                containerStyles={{ marginRight: 0 }}
                                                seed={safeAddress}
                                                size={40}
                                            />
                                            <div className="flex-column">
                                                <Typography
                                                    color="#27A643"
                                                    fontSize={12}
                                                    fontWeight={500}
                                                >
                                                    Delegate Access
                                                </Typography>
                                                <div className="safe-name">{safeName}</div>
                                                <div className="subtitle">
                                                    {/* <span>$37,986 </span>
                                       <Circle className="divider-dot" /> */}
                                                    <span>{minifyAddress(safeAddress)}</span>
                                                </div>
                                                <div style={{ display: "flex" }}>
                                                    <div style={{ display: "flex" }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <CustomIcons name="content_copy" />
                                                        </div>
                                                        <CopyOnClick
                                                            style={{
                                                                dispalay: "inline",
                                                                marginLeft: 4,
                                                            }}
                                                            value={safeAddress}
                                                            text="Click to Copy Safe Address"
                                                        >
                                                            <span
                                                                style={{
                                                                    color: "#636B81",
                                                                    fontSize: 11,
                                                                }}
                                                            >
                                                                Copy Address
                                                            </span>
                                                        </CopyOnClick>
                                                    </div>

                                                    <div style={{ display: "flex", marginLeft: 8 }}>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                            }}
                                                        >
                                                            <CustomIcons name="view_content" />
                                                        </div>
                                                        <a
                                                            href={`${etherscan}/address/${currentSafeAddress}`}
                                                            target="_blank"
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                justifyContent: "center",
                                                                color: "#636B81",
                                                                fontSize: 11,
                                                                fontWeight: 400,
                                                                marginLeft: 4,
                                                            }}
                                                        >
                                                            View on explorer
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                            {currentSafeAddress === safeAddress && (
                                                <div style={{ marginLeft: "auto" }}>
                                                    <Done className="done-icon" />
                                                </div>
                                            )}
                                        </ImageWrapper>
                                    </div>
                                );
                            }
                        })}
                    </SafeListWrapper>
                </div>
                <FooterActionWrapper>
                    <Link href={`/onboard/safe_list/${account}`}>
                        <div className="flex-row">
                            <NavBarIcons color="#25274F" name="new_safe" size={undefined} />
                            <div>Create/Import a safe</div>
                        </div>
                    </Link>

                    <div className="flex-row" onClick={handleDisconnect}>
                        <NavBarIcons color="#25274F" name="connect_wallet" size={undefined} />

                        <div>Connect another wallet</div>
                    </div>
                </FooterActionWrapper>
            </DrawerBodyWrapper>
        </Box>
    );

    return (
        <Drawer style={{ height: "100%" }} open={open} anchor="left" onClose={handleCloseDrawer}>
            {list()}
        </Drawer>
    );
}
