import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const CSVPayoutWrapper = styled(Box)`
    padding: 50px 15px;
`;

export const WrapperCSVTable = styled(Box)`
    background: #ffffff;
    border: 1px solid #eaeaea;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    height: 500px;
    margin: 20px 0px;
`;

export const CSVTableHeader = styled(Box)`
    padding: 20px 53px;
`;

export const ErrorContainer = styled(Box)``;

export const CSVTableBody = styled(Box)`
    .scrollable-view {
        max-height: 300px;
        overflow-y: auto;
    }
    .table-header {
        font-size: 12px;
        color: #8b91a1;
    }
`;

export const CSVTableAction = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    .flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Row = styled(Box)`
    display: flex;
    align-items: center;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    margin: 0px 38px;
    padding: 10px 15px;
    color: #9998a4;
    font-weight: 500;
    > div:nth-child(1) {
        width: 5%;
    }
    > div:nth-child(2) {
        width: 27%;
    }
    > div:nth-child(3) {
        width: 20%;
    }
    > div:nth-child(4) {
        width: 17%;
    }
    > div:nth-child(5) {
        width: 16%;
    }
    > div:nth-child(6) {
        width: 20%;
    }

    &.error {
        border: 1px solid rgba(236, 89, 82, 0.3);
        background: #fdf7f7;
        border-radius: 4px;
    }
`;

export const ErrorRowWrapper = styled(Box)`
    margin: 4px 38px;
    padding: 10px 15px;

    .row {
        display: flex;
        align-items: center;
        width: 100%;
        color: #9998a4;
        font-weight: 500;
        border-bottom: 1px solid #eaeaea;
        padding-bottom: 10px;
    }
    .row > div:nth-child(1) {
        width: 5%;
    }
    .row > div:nth-child(2) {
        width: 25%;
    }
    .row > div:nth-child(3) {
        width: 25%;
    }
    .row > div:nth-child(4) {
        width: 20%;
    }
    .row > div:nth-child(5) {
        width: 20%;
    }
    .row > div:nth-child(6) {
        width: 5%;
    }

    .MuiInput-root #hide {
        display: none;
    }

    &.error {
        border: 1px solid rgba(236, 89, 82, 0.3);
        background: #fdf7f7;
        border-radius: 4px;
    }

    .MuiInputBase-root {
        background: white !important;
        height: 35px;
        MuiTypography-root {
            color: #9998a4;
        }
    }

    .name {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 8px 0px;
        margin-bottom: 8px;

        & > div:nth-child(1) {
            width: 5%;
        }

        & > div:nth-child(2) {
            width: 25%;
            margin-right: 20px;
        }

        & > div:nth-child(3) {
            width: 45%;
        }

        .MuiOutlinedInput-root {
            width: 100%;
            background: white !important;
        }
    }

    .token {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 8px 0px;

        & > div:nth-child(1) {
            width: 5%;
        }

        & > div:nth-child(2) {
            width: 25%;
            margin-right: 20px;
        }

        & > div:nth-child(3) {
            width: 22%;
            margin-right: 20px;
        }

        & > div:nth-child(4) {
            width: 21.5%;
        }
        & > div:nth-child(5) {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-left: 27px;
        }

        .MuiOutlinedInput-root {
            width: 100%;
        }
    }
`;
