import { graphQLClient } from "../graphqlClient";
import {
    GET_REQUESTS_BY_SAFE,
    BULK_UPDATE_REQUEST_STATUS_BY_IDS,
    baseURI,
    GET_REQUESTS_BY_CONTRIBUTOR,
    GET_REQUEST_DETAIL_BY_REQUESTID,
    BULK_UPDATE_REQUEST_TRANSACTION_ID,
    GET_INVITE_REQUESTS_BY_SAFE,
    UPDATE_INVITE_STATUS,
    GET_CURRENT_SAFE_DETAILS,
} from "../constants";
import { useMutation, useQuery } from "react-query";
import { gql } from "graphql-request";
import { queryClient } from "pages/_app";

export function useGetRequestsBySafe(safeAddress, status) {
    return useQuery(
        [GET_REQUESTS_BY_SAFE, safeAddress, status],
        async () => {
            const { requestsBySafe } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    query RequestsBySafe($status: String) {
                        requestsBySafe(status: $status) {
                            requestId
                            createdAt
                            updatedAt
                            safe {
                                safeName
                                safeAddress
                            }
                            contributorAddress
                            transactionId
                            description
                            recipient
                            status
                            disbursement {
                                tokenAddress
                                tokenValue
                                fixedUsd
                                fiatValue
                            }
                            paymentType
                            link
                            attachment {
                                fileName
                                fileType
                                url
                            }
                            tag
                            contributor {
                                name
                                address
                                ens
                                nickName
                            }
                            contributorName
                            createdFrom
                            transactionDetails {
                                transactionId
                                nonce
                                transactionHash
                                status
                            }
                        }
                    }
                `,
                {
                    status,
                },
            );

            return requestsBySafe;
        },
        {
            enabled: !!status,
        },
    );
}

export function useBulkUpdateContributorRequestById() {
    return useMutation(
        async ({ safeAddress, requestIds, newStatus }: any) => {
            const { updateRequestStatus } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation BulkUpdateRequestStatus($requestIds: [String], $newStatus: String) {
                        bulkUpdateRequestStatus(requestIds: $requestIds, newStatus: $newStatus) {
                            requestId
                        }
                    }
                `,
                {
                    requestIds,
                    newStatus: newStatus.toLowerCase(),
                },
            );

            return updateRequestStatus;
        },
        {
            mutationKey: BULK_UPDATE_REQUEST_STATUS_BY_IDS,
            onSuccess: () => {
                // console.log("successfully Updated");
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
                queryClient.invalidateQueries(GET_REQUESTS_BY_SAFE);
                queryClient.invalidateQueries(GET_REQUESTS_BY_CONTRIBUTOR);
                queryClient.invalidateQueries(GET_REQUEST_DETAIL_BY_REQUESTID);
                queryClient.invalidateQueries(GET_INVITE_REQUESTS_BY_SAFE);
            },
        },
    );
}

export function useBulkUpdateRequestTransactionId() {
    return useMutation(
        async ({ safeAddress, requestIds, transactionId }: any) => {
            const { bulkUpdateRequestTransactionId } = await graphQLClient(
                baseURI,
                safeAddress,
            ).request(
                gql`
                    mutation BulkUpdateRequestTransactionId(
                        $requestIds: [String]
                        $transactionId: String
                    ) {
                        bulkUpdateRequestTransactionId(
                            requestIds: $requestIds
                            transactionId: $transactionId
                        ) {
                            requestId
                        }
                    }
                `,
                {
                    requestIds,
                    transactionId,
                },
            );

            return bulkUpdateRequestTransactionId;
        },
        {
            mutationKey: BULK_UPDATE_REQUEST_TRANSACTION_ID,
            onSuccess: () => {
                // console.log("successfully Updated");
                queryClient.invalidateQueries(GET_REQUESTS_BY_SAFE);
                queryClient.invalidateQueries(GET_REQUESTS_BY_CONTRIBUTOR);
                queryClient.invalidateQueries(GET_REQUEST_DETAIL_BY_REQUESTID);
            },
        },
    );
}

export function useGetInviteRequestsBySafe(safeAddress) {
    return useQuery(
        [GET_INVITE_REQUESTS_BY_SAFE, safeAddress],
        async () => {
            const { invitesBySafe } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    query InvitesBySafe {
                        invitesBySafe {
                            inviteId
                            generatedBy
                            networkId
                            inviteName
                            inviteType
                            status
                            implementations
                            limit
                            expiresAt
                            category
                            createdAt
                            createdBy {
                                name
                                address
                                ens
                            }
                            requests {
                                requestId
                                createdAt
                                updatedAt
                                safe {
                                    safeName
                                    safeAddress
                                }
                                contributorAddress
                                transactionId
                                description
                                status
                                disbursement {
                                    tokenAddress
                                    tokenValue
                                    fixedUsd
                                    fiatValue
                                }
                                paymentType
                                link
                                attachment {
                                    fileName
                                    fileType
                                    url
                                }
                                tag
                                contributor {
                                    name
                                    address
                                    ens
                                    nickName
                                }
                                contributorName
                                createdFrom
                                updatedBy
                                updatedByName
                                recipient
                            }
                        }
                    }
                `,
                {},
            );

            return invitesBySafe;
        },
        {
            enabled: true,
        },
    );
}

export function useUpdateInviteStatus() {
    return useMutation(
        async ({ safeAddress, inviteId, status }: any) => {
            const { updateInviteStatus } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation($inviteId: String, $newStatus: String) {
                        updateInviteStatus(inviteId: $inviteId, newStatus: $newStatus) {
                            inviteId
                            inviteName
                        }
                    }
                `,
                {
                    inviteId,
                    newStatus: status,
                },
            );
            return updateInviteStatus;
        },
        {
            mutationKey: UPDATE_INVITE_STATUS,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
                queryClient.invalidateQueries(GET_INVITE_REQUESTS_BY_SAFE);
            },
        },
    );
}
