import React, { useContext, useState } from "react";
import TransferContext from "src/contexts/TransferContext";
import QueueItem from "./QueueItem";
import { PayoutQueueStyled } from "./styles/PayoutQueueStyled";
import SafeContext from "src/contexts/SafeContext";

const PayoutQueue = () => {
    const {
        payoutQueue,
        setWalletAddress,
        setWalletNickName,
        setSelectedToken,
        setTokenValue,
        setTokenValueInUSD,
        removeFromPayoutQueue,
        setIsEditOpen,
        setToken,
        setTokenSymbol,
        setTokenDecimal,
    }: any = useContext(TransferContext);
    const { tokensInSafe }: any = useContext(SafeContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [popOpenIndex, setPopOpenIndex] = useState(0);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const handleItemEdit = e => {
        e.preventDefault();
        let item = payoutQueue[popOpenIndex];
        setAnchorEl(null);
        setWalletAddress(item.walletAddress);
        setWalletNickName(item.walletNickName);
        setSelectedToken(item.selectedToken);
        setTokenValue(item.tokenValue);
        setTokenValueInUSD(item.tokenValueInUSD);
        removeFromPayoutQueue(popOpenIndex);
        setToken({ ...tokensInSafe[item.selectedToken] });
        setIsEditOpen(true);
        setTokenSymbol(tokensInSafe[item?.selectedToken]?.symbol);
        setTokenDecimal(tokensInSafe[item?.selectedToken]?.decimals);
    };
    const handleItemDelete = e => {
        e.preventDefault();
        setAnchorEl(null);
        removeFromPayoutQueue(popOpenIndex);
    };

    return (
        <PayoutQueueStyled spacing={"25px"} direction={"column"}>
            {payoutQueue.map((queueItem, q) => {
                return (
                    <QueueItem
                        anchorEl={anchorEl}
                        setAnchorEl={setAnchorEl}
                        open={open}
                        key={q}
                        index={q}
                        item={queueItem}
                        handleItemEdit={handleItemEdit}
                        handleItemDelete={handleItemDelete}
                        setPopOpenIndex={setPopOpenIndex}
                    />
                );
            })}
        </PayoutQueueStyled>
    );
};

export default PayoutQueue;
