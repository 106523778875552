import { useMutation, useQuery } from "react-query";
import { request, gql } from "graphql-request";
import { graphQLClient } from "../graphqlClient";
import { arrayify, formatUnits } from "ethers/lib/utils";
import { networkId } from "src/helpers/utils/networks";
import semverSatifies from "semver/functions/satisfies";
import {
    gnosisURL,
    nativeTokenSymbol,
    UPDATE_PAYMENT_CATEGORY_BY_NONCE,
    BACKEND_BASE_URL,
    UPDATE_TXN_DISBURSEMENT_DETAILS,
    UPDATE_TXN_ID_TO_BUDGET,
} from "src/queries/constants";
import {
    CREATE_NEW_TRANSACTION,
    baseURLForPushingMetaTransaction,
    GET_TRANSACTION_BY_ID,
} from "src/queries/constants";
import fetcher from "../fetch";
import Decimal from "decimal.js-light";
import addresses from "src/constants/addresses";
import { BigNumber } from "ethers";

const baseURI = `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`;

export const operationHeading = {
    removeOwner: "Owner Removed",
    addOwnerWithThreshold: "Owner Added",
    changeThreshold: "Policy Updated",
    swapOwner: "Owner Swapped",
};

export function operationDescription(data) {
    let desc = "";
    data.method == "removeOwner"
        ? (desc = `Removed ${data.parameters[1].value} as safeOwner`)
        : data.method == "addOwnerWithThreshold"
        ? (desc = `Added ${data.parameters[0].value} as safeOwner`)
        : data.method == "changeThreshold"
        ? (desc = `Changed required confirmations to ${data.parameters[0].value}`)
        : data.method == "swapOwner"
        ? (desc = `Swapped ${data.parameters[1].value} with ${data.parameters[2].value} as SafeOwner`)
        : (desc = "");
    return desc;
}

export function checkWalletConnect(origin) {
    try {
        return JSON.parse(origin);
    } catch (error) {
        return false;
    }
}

export function useGetPendingTransactionFromGnosis(safeAddress, options = {}, trusted = true) {
    return useQuery(
        ["get-pending-transaction-from-gnosis", safeAddress],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?executed=false${
                    trusted ? "&trusted=true" : ""
                }`,
            );
            return (await response.json()).results;
        },
        { staleTime: 10000, ...options },
    );
}

export function useGetExecutedTransactionFromGnosis(safeAddress, options = {}) {
    return useQuery(
        ["get-executed-transaction-from-gnosis", safeAddress],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?executed=true`,
            );
            return (await response.json()).results;
        },
        { staleTime: 10000, ...options },
    );
}

export function useGetAllTransactionFromGnosisByOffset(safeAddress, offset = 0, options = {}) {
    return useQuery(
        ["get-all-transaction-from-gnosis-by-offset", safeAddress, offset],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/all-transactions/?executed=true&limit=50&offset=${offset}`,
            );
            const k = await response.json();
            return { results: k.results, next: !!k.next };
        },
        {
            ...options,
        },
    );
}

export function useGetAllTransactionFromGnosis(safeAddress, options = {}) {
    return useQuery(
        ["get-all-transaction-from-gnosis", safeAddress],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/all-transactions/?limit=100&executed=true`,
            );
            return (await response.json()).results;
        },
        options,
    );
}

export function useGetTransactionDetailContributorSide(safeAddress, nonce, options = {}) {
    return useQuery(
        ["get-transaction-details-contributor", safeAddress, nonce],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?nonce=${nonce}`,
            );
            return (await response.json()).results;
        },
        options,
    );
}

export function useGetTransactionDetail(safeAddress, nonce, options = {}) {
    return useQuery(
        ["get-transaction-detail", safeAddress],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?nonce=${nonce}`,
            );
            return (await response.json()).results;
        },
        options,
    );
}

export async function checkIfTransactionExecutedOnGnosis(safeAddress, nonce) {
    try {
        const response = await fetcher(
            `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?nonce=${nonce}&executed=true`,
            {},
        );
        return response.results;
    } catch (err) {
        return false;
    }
}

export function useGetIncomingTransactionDetail(safeAddress, txHash, options = {}) {
    return useQuery(
        ["get-incoming-transaction-detail", safeAddress],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/incoming-transfers/?transaction_hash=${txHash}`,
            );
            return (await response.json()).results;
        },
        options,
    );
}

export function useGetTransactionMetaDataByNonce(safeAddress, nonce, options = {}) {
    return useQuery(
        ["get-transaction-detail", safeAddress, nonce],
        async () => {
            const { transactionBySafeAndNonce } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    query TransactionsBySafeAndNonce($safeAddress: String, $nonce: Int) {
                        transactionBySafeAndNonce(safeAddress: $safeAddress, nonce: $nonce) {
                            transactionId
                            description
                            paymentType
                            nonce
                            status
                            transactionDisbursmentDetails {
                                id
                                amount
                                fiatCurrency
                                fiatValue
                                token
                                tokenValue
                                address
                                tagName
                                comment
                                recipient {
                                    nickName
                                    ens
                                }
                                disbursementType
                            }
                            childSafeTxn {
                                nonce
                                childSafeAddress
                                safeTxnHash
                                toReject
                            }
                            linkedBudget {
                                name
                            }
                        }
                    }
                `,
                { safeAddress, nonce },
            );
            return transactionBySafeAndNonce;
        },
        options,
    );
}

export function useGetTransactionByIdShareable(transactionId, options = {}) {
    return useQuery(
        ["get-transaction-detail-by-transaction-id-unprotected", transactionId],
        async () => {
            try {
                const transaction = await fetcher(
                    `${BACKEND_BASE_URL}/transaction/${transactionId}`,
                    { credentials: "include" },
                );
                return transaction?.data || null;
            } catch (err) {
                return null;
            }
        },
        options,
    );
}
export function useGetTransactionMetaDataByTxHash(safeAddress, transactionHash, options = {}) {
    return useQuery(
        ["get-transaction-detail-by-hash", safeAddress, transactionHash],
        async () => {
            const { transactionBySafeAndTxHash } = await graphQLClient(
                baseURI,
                safeAddress,
            ).request(
                gql`
                    query TransactionBySafeAndTxHash($transactionHash: String) {
                        transactionBySafeAndTxHash(transactionHash: $transactionHash) {
                            transactionId
                            description
                            paymentType
                            nonce
                            status
                            transactionDisbursmentDetails {
                                amount
                                fiatCurrency
                                fiatValue
                                token
                                tokenValue
                                address
                                tagName
                                recipient {
                                    nickName
                                }
                                disbursementType
                                comment
                            }
                            transactionHash
                            transactionFees
                            createdBy
                            networkId
                        }
                    }
                `,
                { transactionHash },
            );
            return transactionBySafeAndTxHash;
        },
        options,
    );
}

export function useGetSafeDetailsFromGnosis(safeAddress, options = {}) {
    return useQuery(
        ["get-safe-gnosis", safeAddress],
        async () => {
            const response: any = await fetch(`${gnosisURL}/safes/${safeAddress}`);
            return await response.json();
        },
        options,
    );
}

export function useGetAddConfirmationToGnosisTransaction() {
    const mutation = useMutation(
        async ({ safeTxHash, signature }: any) => {
            const data = {
                signature,
            };

            const response: any = await fetch(
                `${gnosisURL}/multisig-transactions/${safeTxHash}/confirmations/`,
                {
                    method: "POST",
                    mode: "cors",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                },
            );
            return await response.json();
        },
        {
            mutationKey: "add-confirmation-to-transaction",
        },
    );
    return mutation;
}

export function useGetBalanceUSDFromGnosis(safeAddress) {
    return useQuery(
        ["get-balances-from-gnosis", safeAddress],
        async () => {
            const response: any = await fetch(
                `${gnosisURL}/safes/${safeAddress}/balances/usd/?trusted=false&exclude_spam=false`,
            );
            return await response.json();
        },
        {
            staleTime: 30000,
        },
    );
}

// export function useGetTransactionConfirmationDetailsBySafeTxnHash(
//     safeTransactionHash,
// ) {
//     return useQuery(
//         ["get-confirmation-from-gnosis", safeTransactionHash],
//         async () => {
//             const response: any = await fetch(
//                 `https://safe-transaction.rinkeby.gnosis.io/api/v1/safes/${safeAddress}/balances/usd/?trusted=false&exclude_spam=false`,
//             );
//             return await response.json();
//         },
//     );
// }

export function useGetTransactionMetaData(safeAddress, nonces) {
    return useQuery(["get-contributor", safeAddress, nonces], async () => {
        const { transactionsBySafeAndNonce } = await graphQLClient(baseURI, safeAddress).request(
            gql`
                query TransactionsBySafeAndNonce($safeAddress: String, $nonces: [Int]) {
                    transactionsBySafeAndNonce(safeAddress: $safeAddress, nonces: $nonces) {
                        transactionId
                        description
                        paymentType
                        nonce
                        status
                        transactionDisbursmentDetails {
                            amount
                            fiatCurrency
                            fiatValue
                            token
                            tokenValue
                            address
                            tagName
                            recipient {
                                nickName
                            }
                            comment
                        }
                    }
                }
            `,
            { safeAddress, nonces },
        );
        return transactionsBySafeAndNonce;
    });
}

export const processGnosisDisbursement = (txn, balances) => {
    const disbursement = [];

    if (new Decimal(txn.value || 0).greaterThan(0)) {
        disbursement.push({
            address: txn.to,
            amount: Number(txn.value) / 10 ** 18,
            fiatCurrency: "USD",
            fiatValue: 0,
            token: nativeTokenSymbol,
            tokenValue: Number(txn.value),
        });
    }

    if (txn.txType == "ETHEREUM_TRANSACTION") {
        txn?.transfers?.forEach(transfer =>
            transfer.tokenInfo
                ? disbursement.push({
                      address: txn.from,
                      token: txn?.transfers[0]?.tokenInfo?.symbol || "",
                      amount:
                          Number(transfer.value) /
                          10 ** (txn?.transfers[0]?.tokenInfo?.decimals || 18),
                  })
                : disbursement.push({
                      address: txn.from,
                      token: nativeTokenSymbol,
                      amount: Number(transfer.value) / 10 ** 18,
                  }),
        );
    }
    if (txn.dataDecoded) {
        const dataDecoded = txn.dataDecoded;
        if (dataDecoded.method === "transfer") {
            const token = balances.find(item => item.tokenAddress == txn.to);

            disbursement.push({
                address:
                    dataDecoded.parameters.find(
                        item => item.type === "address" || item.name === "_to",
                    )?.value || "",
                amount:
                    Number(
                        dataDecoded.parameters.find(
                            item => item.type === "uint256" || item.type === "uint",
                        )?.value,
                    ) /
                        10 ** token?.token?.decimals || 0,
                fiatCurrency: "USD",
                fiatValue: 0,
                token: token?.token?.symbol || "",
                tokenValue:
                    Number(
                        dataDecoded.parameters.find(
                            item => item.type === "uint256" || item.type === "uint",
                        )?.value,
                    ) /
                    10 ** token?.token?.decimals,
            });
        }
        if (dataDecoded.method === "multiSend") {
            const payouts = dataDecoded.parameters[0].valueDecoded;

            payouts.forEach(payout => {
                if (Number(payout.value) > 0) {
                    disbursement.push({
                        address: payout.to,
                        amount: Number(payout.value) / 10 ** 18,
                        fiatCurrency: "USD",
                        fiatValue: 0,
                        token: nativeTokenSymbol,
                        tokenValue: Number(payout.value),
                    });
                }
                if (payout.dataDecoded) {
                    if (payout.dataDecoded.method === "transfer") {
                        const token = balances.find(item => item.tokenAddress == payout.to);

                        disbursement.push({
                            address:
                                payout.dataDecoded.parameters.find(
                                    item => item.type === "address" || item.name === "_to",
                                )?.value || "",
                            amount:
                                Number(
                                    payout.dataDecoded.parameters.find(
                                        item => item.type === "uint256" || item.type === "uint",
                                    )?.value,
                                ) /
                                    10 ** token?.token?.decimals || 0,
                            fiatCurrency: "USD",
                            fiatValue: 0,
                            token: token?.token?.symbol || "",
                            tokenValue:
                                Number(
                                    payout.dataDecoded.parameters.find(
                                        item => item.type === "uint256" || item.type === "uint",
                                    )?.value,
                                ) /
                                10 ** token?.token?.decimals,
                        });
                    }
                }
            });
        }
    }
    return disbursement;
};

export const processPayrollDisbursements = txn => {
    const disbursement = [];
    if (txn.payrollTransaction) {
        if (txn.payrollTransaction?.resultType === "payment") {
            let amount = "0",
                fiatValue = "0";
            if (
                txn.payrollTransaction.tokens[0].isAmountInFiat &&
                txn.payrollTransaction?.tokens[0]?.fiatConversion
            ) {
                amount = formatUnits(
                    BigNumber.from(txn.payrollTransaction?.amount),
                    txn.payrollTransaction?.tokens[0].decimals,
                );

                fiatValue = txn.payrollTransaction?.tokens[0]?.fiatAmount.toString();
            } else {
                amount = formatUnits(
                    BigNumber.from(txn.payrollTransaction.amount),
                    txn.payrollTransaction.tokens[0].decimals || 18,
                );
                fiatValue = new Decimal(amount)
                    .mul(new Decimal(txn.payrollTransaction?.tokens[0]?.fiatConversion))
                    .toString();
            }
            disbursement.push({
                address: txn.payrollTransaction?.address,
                amount,
                amountWei: txn.payrollTransaction.amount,
                fiatCurrency: "USD",
                disbursementType: "Bill Payment",
                fiatValue,
                token: txn.payrollTransaction.tokens[0].tokenAddress,
                tokenName: txn.payrollTransaction.tokens[0].tokenName,
                tokenSymbol: txn.payrollTransaction.tokens[0].tokenSymbol,
                tokenDecimals: txn.payrollTransaction.tokens[0].decimals,
                tokenLogoUrl: txn.payrollTransaction.tokens[0].tokenLogoUrl,
                tokenValue: txn.payrollTransaction.amount,
                invoice: null,
                deal: null,
                team: null,
                name: txn.payrollTransaction?.name,
                description: txn.payrollTransaction?.notes,
                comment: txn.payrollTransaction?.notes,
            });
        } else {
            txn.payrollTransaction?.invoiceIds?.forEach(({ invoiceId }) => {
                let amountSum = formatUnits(
                    invoiceId.lineItems
                        .filter(({ status }) => status === "active")
                        .reduce(
                            (acc, item) => BigNumber.from(item.amount).add(acc),
                            BigNumber.from(0),
                        ),
                    invoiceId.lineItems[0]?.decimals || 18,
                );

                invoiceId.lineItems
                    .filter(({ status }) => status === "active")
                    .forEach(lineItem => {
                        let amount = "0",
                            fiatValue = "0";
                        if (lineItem.isAmountInFiat && invoiceId?.fiatConversion) {
                            amount = new Decimal(lineItem?.fiatAmount)
                                .dividedBy(new Decimal(invoiceId?.fiatConversion))
                                .toString();

                            fiatValue =
                                lineItem?.fiatAmount.toString() === "0"
                                    ? invoiceId.fiatValue
                                    : lineItem?.fiatAmount.toString();
                        } else {
                            amount = formatUnits(
                                BigNumber.from(lineItem?.amount),
                                lineItem?.decimals,
                            );
                            fiatValue = invoiceId.fiatValue
                                ? invoiceId.fiatValue
                                : new Decimal(amount)
                                      .mul(new Decimal(invoiceId?.fiatConversion || 0))
                                      .toString();
                        }

                        if (lineItem.fiatAmount && lineItem.fiatAmount !== "0") {
                            fiatValue = lineItem.fiatAmount.toString();
                        } else if (!(lineItem.isAmountInFiat && invoiceId?.fiatConversion)) {
                            fiatValue = (
                                (Number(invoiceId.fiatValue) * Number(amount)) /
                                Number(amountSum)
                            ).toString();
                        }

                        disbursement.push({
                            address:
                                invoiceId?.recipientAddress || invoiceId?.dealId?.walletAddress,
                            amount,
                            amountWei: lineItem?.amount,
                            disbursementType: getLineItemTag(lineItem?.name),
                            fiatCurrency: "USD",
                            fiatValue,
                            token: lineItem?.tokenAddress,
                            tokenName: lineItem?.tokenName,
                            tokenSymbol: lineItem?.tokenSymbol,
                            tokenDecimals: lineItem?.decimals,
                            tokenLogoUrl: lineItem?.tokenLogoUrl,
                            tokenValue: lineItem?.amount,
                            invoice: invoiceId,
                            deal: invoiceId?.dealId,
                            team: invoiceId?.dealId?.team,
                            name: invoiceId?.dealId?.payrollName,
                            comment: lineItem?.description,
                            description: lineItem?.description,
                        });
                    });
            });
        }
    }
    return disbursement;
};

export const getLineItemTag = name => {
    switch (name) {
        case "fixed":
            return "Fixed Payment";
        case "bonus":
            return "Bonus";
        case "expense":
            return "Expense";
        case "reimbursement":
            return "Reimbursement";
        case "miscellaneous":
            return "Miscellaneous";
        case "work-report":
            return "Work Report";

        default:
            return "";
    }
};

export const useTransactionMeta = (
    txns,
    safeAddress,
    isPending,
    isMultisig,
    raw = [],
    lastConfirmedTransactionNonce = 0,
    payrollContractAddress = null,
) => {
    return new Promise(async (res, rej) => {
        const balances = await fetcher(
            `${gnosisURL}/safes/${safeAddress}/balances/usd/?trusted=false&exclude_spam=false`,
            {},
        );

        let filteredTxns = [];
        const responseLastExecuted: any = await fetch(
            `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?executed=true&limit=1`,
        );
        const executedNonce = (await responseLastExecuted.json()).results?.[0]?.nonce || -1;
        if (isPending) {
            if (lastConfirmedTransactionNonce && lastConfirmedTransactionNonce > executedNonce) {
                filteredTxns = txns?.filter(txn => txn.nonce > lastConfirmedTransactionNonce);
            } else {
                filteredTxns = txns?.filter(txn => txn.nonce > executedNonce);
            }
        } else {
            if (lastConfirmedTransactionNonce && lastConfirmedTransactionNonce > executedNonce) {
                const response: any = await fetch(
                    `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?nonce=${lastConfirmedTransactionNonce}&limit=1`,
                );
                const confirmOnChainTransaction = (await response.json()).results?.[0];

                if (confirmOnChainTransaction) {
                    filteredTxns = [...(txns || []), confirmOnChainTransaction];
                } else {
                    filteredTxns = txns;
                }
            } else {
                filteredTxns = txns;
            }
        }

        const nonces = filteredTxns?.map(txn => txn?.nonce)?.filter(n => typeof n != "string");
        // const nonces = filteredTxns?.map(txn => txn?.nonce);
        nonces?.push(-1); // for incoming txns
        nonces?.push(-2); // for V3 Transactions
        const processedNonce = [];

        const { transactionsBySafeAndNonce } = await graphQLClient(baseURI, safeAddress).request(
            gql`
                query TransactionsBySafeAndNonce($safeAddress: String, $nonces: [Int]) {
                    transactionsBySafeAndNonce(safeAddress: $safeAddress, nonces: $nonces) {
                        transactionId
                        description
                        paymentType
                        nonce
                        status
                        transactionHash
                        transactionDisbursmentDetails {
                            amount
                            fiatCurrency
                            fiatValue
                            token
                            tokenValue
                            address
                            tagName
                            recipient {
                                nickName
                                ens
                            }
                            disbursementType
                            comment
                        }
                        childSafeTxn {
                            nonce
                            childSafeAddress
                            safeTxnHash
                            toReject
                        }
                    }
                }
            `,
            { safeAddress, nonces },
        );

        let payrollTransactions = {
            transactions: {},
        };
        try {
            payrollTransactions = await fetcher(`${BACKEND_BASE_URL}/v3/transactions`, {
                credentials: "include",
                headers: {
                    "Content-Type": "application/json",
                    "x-par-safe": safeAddress,
                },
            });
            if (!payrollTransactions.transactions) {
                payrollTransactions.transactions = {};
            }
        } catch (error) {
            console.log(error);
        }

        const rows = {};
        // to map metaData of incoming txns
        transactionsBySafeAndNonce?.map(item => {
            if (item.nonce < 0) item.nonce = item.transactionHash;
        });

        for (let tx of filteredTxns) {
            if (tx.nonce == undefined) {
                tx.nonce = tx.txHash || tx.transactionHash;
            }
            let transferTos = (tx.transfers || [])?.map(transfer => transfer.to);
            transferTos.push(tx.to);

            console.log({ [tx.nonce]: payrollContractAddress, type: tx.txType });

            if (tx.txType === "MODULE_TRANSACTION" || tx.txType === "ETHEREUM_TRANSACTION") {
                if (
                    payrollContractAddress &&
                    tx.module === addresses.ALLOWANCE_MODULE_ADDRESS &&
                    transferTos.includes(payrollContractAddress) &&
                    payrollTransactions?.transactions[tx.transactionHash]
                ) {
                    tx.isPayrollTransaction = true;
                } else {
                    continue;
                }
            }
            const metaData = transactionsBySafeAndNonce?.find(item => item.nonce === tx.nonce);
            const txn: any = rows[tx.nonce] !== undefined ? rows[tx.nonce] : {};

            if (tx.data == null && tx.value == "0") {
                txn.rejectedTx = tx;
                txn.rejectedConfirmation = tx.confirmations;
                txn.rejectedSafeTxnHash = tx.safeTxHash;
            } else {
                txn.approvedConfirmation = tx.confirmations;
                txn.approvedSafeTxnHash = tx.safeTxHash;
            }

            txn.transactionType = {};
            txn.nonce = tx?.nonce;
            txn.status = tx?.isExecuted ? "executed" : "Pending";
            const lastStatus = JSON.parse(localStorage.getItem("LAST_EXECUTED_TX"));

            if (lastStatus && lastStatus.nonce == tx.nonce) {
                if (tx.isExecuted) {
                    localStorage.removeItem("LAST_EXECUTED_TX");
                    txn.executing = false;
                } else {
                    txn.executing = true;
                }
            }

            txn.createdOn = tx?.submissionDate;

            if (tx?.isPayrollTransaction && tx?.isSuccessful) {
                tx.payrollTransaction = payrollTransactions?.transactions[tx.transactionHash];
            }

            if (tx.isExecuted) {
                if (isMultisig) {
                    txn.rejected = tx.data && tx.value == 0 ? true : false;
                } else {
                    txn.rejected = false;
                }
                txn.transactionHash = tx.transactionHash;
            }
            if (metaData && metaData.paymentType !== "Gnosis Transaction") {
                txn.transactionId = metaData.transactionId;
                txn.metaDataStatus = metaData.status;
                txn.disbursment = metaData.transactionDisbursmentDetails;
                txn.label = metaData.label ? metaData.label : "Payout";
                txn.transactionType.origin = "parcel";
                txn.transactionType.to =
                    metaData.transactionDisbursmentDetails.length > 1
                        ? `${metaData.transactionDisbursmentDetails.length} Payouts`
                        : metaData.transactionDisbursmentDetails[0]?.address;
                txn.transactionType.type = metaData.paymentType;
                txn.description = metaData.description;
                txn.transactionType.recipient =
                    metaData.transactionDisbursmentDetails.length == 1 &&
                    metaData.transactionDisbursmentDetails[0].recipient != null
                        ? metaData.transactionDisbursmentDetails[0].recipient.nickName
                        : null;
                txn.childTransaction = metaData?.childSafeTxn;
                // const disbursement = processGnosisDisbursement(tx, balances);
            } else {
                let disbursement = [];
                if (tx?.isPayrollTransaction) {
                    disbursement = processPayrollDisbursements(tx);
                } else {
                    disbursement = processGnosisDisbursement(tx, balances);
                }
                if (metaData && metaData?.transactionDisbursmentDetails.length > 0) {
                    txn.disbursment = metaData?.transactionDisbursmentDetails;
                } else {
                    txn.disbursment = disbursement || [];
                }
                // txn.disbursment = disbursement || [];

                txn.transactionType.origin = "gnosis";
                // Covered all the edge cases for gnosis transactions except rejection; don't get tx.dataDecoded in case of on-chain rejection

                let toValue;

                // IF Single ERC20 token Transfer, method is transfer
                if (tx.dataDecoded?.method == "transfer") {
                    // TO: value -> address of the recipient
                    toValue = tx.dataDecoded?.parameters?.[0]?.value;

                    // } else if (
                    //     tx.dataDecoded?.parameters?.[0]?.valueDecoded?.[0].dataDecoded?.method ==
                    //     "transfer"
                    // ) {
                    //     toValue =
                    //         tx.dataDecoded?.parameters?.[0]?.valueDecoded?.[0].dataDecoded.parameters[0]
                    //             .value;

                    // } else if (tx.dataDecoded?.parameters?.[0]?.valueDecoded?.length == 1) {
                    //     toValue = tx.dataDecoded?.parameters?.[0]?.valueDecoded?.[0].to;

                    // IF Multiple interactions involved
                } else if (tx.dataDecoded?.parameters?.[0]?.valueDecoded?.length > 1) {
                    let uniqueMethodsCalled = new Set();
                    // If multiple interactions are involved, then it is either a payout or an interaction
                    let type = "Interactions";
                    for (let i = 0; i < tx.dataDecoded?.parameters?.[0]?.valueDecoded.length; i++) {
                        uniqueMethodsCalled.add(
                            tx.dataDecoded?.parameters?.[0]?.valueDecoded[i].dataDecoded?.method ||
                                "transfer",
                        );
                    }

                    // If only one method is called and it is transfer, then it is a payout
                    if (uniqueMethodsCalled.size == 1 && uniqueMethodsCalled.has("transfer")) {
                        type = "Payouts";
                    }
                    toValue = `${tx.dataDecoded?.parameters?.[0]?.valueDecoded.length} ${type}`;
                } else {
                    toValue = tx.to;
                }

                txn.transactionType.to = toValue;

                txn.label = "Gnosis Transaction";
                txn.description = metaData?.description;
                txn.transactionType.type = metaData?.description
                    ? metaData?.paymentType
                    : "Gnosis Transaction";
                txn.childTransaction = null;
            }
            if (
                tx.dataDecoded?.method == "removeOwner" ||
                tx.dataDecoded?.method == "addOwnerWithThreshold" ||
                tx.dataDecoded?.method == "changeThreshold" ||
                tx.dataDecoded?.method == "swapOwner"
            ) {
                txn.transactionType.type = "Settings";
                txn.transactionType.recipient = operationHeading[tx.dataDecoded?.method];
                txn.description = operationDescription(tx.dataDecoded);
            }

            // for incoming txns
            if (tx.txType == "ETHEREUM_TRANSACTION" && transferTos.includes(safeAddress)) {
                txn.createdOn = tx.executionDate;
                txn.transactionType.type = "Received";
            }
            let obj = checkWalletConnect(tx.origin);
            if (obj && Object.keys(obj).length !== 0) {
                txn.transactionType.type = "WalletConnect";
                txn.transactionType.recipient = obj?.name;
                txn.description = tx?.dataDecoded?.method;
            }

            if (tx.isPayrollTransaction) {
                txn.transactionType.type = "ParcelPayroll";
                txn.transactionType.to =
                    tx.payrollTransaction?.resultType === "payment"
                        ? tx.payrollTransaction?.name
                        : `Parcel #${tx.payrollTransaction?.receiptId}`;
                txn.transactionType.recipient =
                    tx.payrollTransaction?.resultType === "payment"
                        ? tx.payrollTransaction?.name
                        : `Parcel #${tx.payrollTransaction?.receiptId}`;
                txn.description =
                    tx.payrollTransaction?.resultType === "payment"
                        ? tx.payrollTransaction?.notes
                        : `Paid out ${tx.payrollTransaction?.invoiceIds?.length || 0} contributor${
                              tx.payrollTransaction?.invoiceIds?.length > 1 ? "s" : ""
                          }`;
                txn.label =
                    tx.payrollTransaction?.resultType === "payment"
                        ? tx.payrollTransaction?.name
                        : `Parcel #${tx.payrollTransaction?.receiptId}`;
            }
            processedNonce.push(tx.nonce);
            rows[tx.nonce] = { ...txn, ...tx };
        }
        res(Object.values(rows));
    });
};

export const confirmMassPayout = async (
    safe,
    to,
    value,
    data,
    operation,
    gasToken,
    safeTxGas,
    baseGas,
    gasPrice,
    refundReceiver,
    nonce,
    executor,
    origin,
    safeTxHash,
    account,
    gnosisSafeMasterContract,
    ZERO_ADDRESS,
    isHardwareWallet,
    library,
    safeAddress,
    connector,
    addConfirmation,
) => {
    if (account) {
        try {
            let approvedSign;
            const contractTransactionHash = await gnosisSafeMasterContract.getTransactionHash(
                to,
                value,
                data,
                operation,
                safeTxGas,
                baseGas,
                gasPrice,
                gasToken,
                executor || ZERO_ADDRESS,
                nonce,
            );

            if (isHardwareWallet) {
                approvedSign = await ethSigner(account, contractTransactionHash, library);
            } else {
                approvedSign = await eip712Signer(
                    to,
                    value,
                    data,
                    operation,
                    safeTxGas,
                    baseGas,
                    gasPrice,
                    gasToken,
                    refundReceiver,
                    nonce,
                    contractTransactionHash,
                    safeAddress,
                    safe.version,
                    library,
                    account,
                    connector,
                );
            }

            if (safeTxHash) {
                await addConfirmation.mutateAsync({
                    safeTxHash,
                    signature: approvedSign.replace("0x", ""),
                });
            } else {
                const txData = {
                    // POST to gnosis
                    to,
                    value,
                    data,
                    operation,
                    gasToken,
                    safeTxGas,
                    baseGas,
                    gasPrice,
                    refundReceiver,
                    nonce,
                    contractTransactionHash,
                    sender: account,
                    transactionHash: null,
                    origin,
                    signature: approvedSign.replace("0x", ""),
                };
                // console.log(txData);
            }
        } catch (err) {
            console.error(err.message);
        }
    }
};

export let ethSigner = async function (account, safeTxHash, library) {
    return new Promise(function (resolve, reject) {
        // const digest = TypedDataUtils.encodeDigest(typedData);
        try {
            const signer = library.getSigner(account);

            signer
                .signMessage(arrayify(safeTxHash))
                .then(signature => {
                    let sigV = parseInt(signature.slice(-2), 16);
                    // Metamask with ledger returns v = 01, this is not valid for ethereum
                    // For ethereum valid V is 27 or 28
                    // In case V = 0 or 01 we add it to 27 and then add 4
                    // Adding 4 is required to make signature valid for safe contracts:
                    // https://gnosis-safe.readthedocs.io/en/latest/contracts/signatures.html#eth-sign-signature
                    switch (sigV) {
                        case 0:
                        case 1:
                            sigV += 31;
                            break;
                        case 27:
                        case 28:
                            sigV += 4;
                            break;
                        default:
                            throw new Error("Invalid signature");
                    }

                    let finalSignature = signature.slice(0, -2) + sigV.toString(16);
                    resolve(finalSignature.replace("0x", ""));
                })
                .catch(err => {
                    console.error(err);
                });
        } catch (err) {}
    });
};

let signTypedData = async function (account, typedData, library) {
    return new Promise(async function (resolve, reject) {
        // const digest = TypedDataUtils.encodeDigest(typedData);
        try {
            const signer = library.getSigner(account);

            const address = await signer.getAddress();
            const signature = await library.send("eth_signTypedData_v3", [
                address,
                JSON.stringify({
                    domain: typedData.domain,
                    types: typedData.types,
                    message: typedData.message,
                    primaryType: "SafeTx",
                }),
            ]);

            if (signature) {
                resolve(signature.replace("0x", ""));
            }
        } catch (err) {
            return reject(err);
        }
    });
};

export const eip712Signer = async (
    to,
    value,
    data,
    operation,
    safeTxGas,
    baseGas,
    gasPrice,
    gasToken,
    refundReceiver,
    nonce,
    contractTransactionHash,
    safeAddress,
    safeVersion,
    library,
    account,
    connector,
) => {
    const domain: any = {
        verifyingContract: safeAddress,
    };
    let EIP712Domain = [{ type: "address", name: "verifyingContract" }];

    if (semverSatifies(safeVersion, ">=1.3.0")) {
        // if (process.env.NEXT_PUBLIC_NETWORK_NAME === networkNames.MAINNET) {
        //     domain.chainId = "1";
        // }
        // if (process.env.NEXT_PUBLIC_NETWORK_NAME === networkNames.RINKEBY) {
        //     domain.chainId = "4";
        // }
        // if (process.env.NEXT_PUBLIC_NETWORK_NAME === networkNames.POLYGON) {
        //     domain.chainId = "137";
        // }
        domain.chainId = networkId.toString();
        EIP712Domain = [
            { type: "uint256", name: "chainId" },
            { type: "address", name: "verifyingContract" },
        ];
    }

    const types = {
        EIP712Domain,
        SafeTx: [
            { type: "address", name: "to" },
            { type: "uint256", name: "value" },
            { type: "bytes", name: "data" },
            { type: "uint8", name: "operation" },
            { type: "uint256", name: "safeTxGas" },
            { type: "uint256", name: "baseGas" },
            { type: "uint256", name: "gasPrice" },
            { type: "address", name: "gasToken" },
            { type: "address", name: "refundReceiver" },
            { type: "uint256", name: "nonce" },
        ],
    };

    const message = {
        to,
        value,
        data,
        operation,
        safeTxGas,
        baseGas,
        gasPrice,
        gasToken,
        refundReceiver,
        nonce,
    };

    const typedData = {
        domain,
        types,
        message,
    };

    let signatureBytes = "0x";

    let signature;

    try {
        signature = await signTypedData(account, typedData, library);
        return signatureBytes + signature;
    } catch (err) {
        console.error(err);
        try {
            // Metamask with ledger or trezor doesn't work with eip712
            // In this case, show a simple eth_sign signature
            if (
                connector?.name === "MetaMask"
                // && err.message.includes('Not supported on this device')
            ) {
                const signature = await ethSigner(account, contractTransactionHash, library);
                return signatureBytes + signature;
            } else {
            }
        } catch (err) {
            console.error(err);
        }
    }
};

export function useCreateTransaction() {
    return useMutation(
        async ({ txParams, currentSafeAddress }: any) => {
            const addTransaction = await graphQLClient(baseURI, currentSafeAddress).request(
                gql`
                    mutation AddTransaction($transaction: TransactionParams) {
                        addTransaction(transaction: $transaction) {
                            transactionId
                            description
                            paymentType
                            status
                            transactionHash
                            safe {
                                nickName
                                assignedTags {
                                    id
                                }
                            }
                        }
                    }
                `,
                {
                    ...txParams,
                },
            );
            return addTransaction;
        },
        {
            mutationKey: CREATE_NEW_TRANSACTION,
        },
    );
}

export async function createMetaTx(txData) {
    const responseGetUrl = await fetch(baseURLForPushingMetaTransaction, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            ...txData,
        }),
    });
    const { success, txHash } = await responseGetUrl.json();
    if (success) {
        return txHash;
    } else throw new Error("could not create meta-tx");
}
export function useUpdateTransaction() {
    return useMutation(
        async ({ txParams, currentSafeAddress }: any) => {
            const result = await graphQLClient(baseURI, currentSafeAddress).request(
                gql`
                    mutation UpdateTransactionStatus(
                        $transactionId: String
                        $newStatus: String
                        $txHash: String
                    ) {
                        updateTransactionStatus(
                            transactionId: $transactionId
                            newStatus: $newStatus
                            txHash: $txHash
                        ) {
                            transactionId
                            status
                            nonce
                        }
                    }
                `,
                {
                    ...txParams,
                },
            );
            return result;
        },
        {
            mutationKey: "UPDATE_TRANSACTION_STATUS",
        },
    );
}

export function useGetTransactionById(transactionId, options = {}) {
    return useQuery(
        [GET_TRANSACTION_BY_ID, transactionId],
        async () => {
            try {
                const { transaction } = await graphQLClient(baseURI).request(
                    gql`
                        query Transaction($transactionId: String) {
                            transaction(transactionId: $transactionId) {
                                nonce
                                safe {
                                    safeAddress
                                }
                            }
                        }
                    `,
                    {
                        transactionId,
                    },
                );
                const response: any = await fetch(
                    `${gnosisURL}/safes/${transaction.safe.safeAddress}/multisig-transactions/?nonce=${transaction.nonce}&executed=true&limit=1`,
                );
                const txHash = (await response.json()).results[0]?.transactionHash;
                return {
                    txHash,
                    nonce: transaction.nonce,
                };
            } catch (err) {
                console.error("unable to get TxHash", err);
                return null;
            }
        },
        {
            enabled: !!transactionId,
            ...options,
        },
    );
}

export function processTransactionData(
    approvedTxn,
    rejectedTxn,
    metaData,
    balances = [],
    txData = {},
) {
    return new Promise((res, rej) => {
        // console.log("metaData", metaData);
        const transactionDetails = { ...approvedTxn, ...metaData };
        if (txData && typeof txData === "object" && Object?.keys(txData).length == 0) {
            transactionDetails.approvedConfirmation = approvedTxn.confirmations;
            transactionDetails.rejectedConfirmation = rejectedTxn?.confirmations;
            transactionDetails.rejectedSafeTxnHash = rejectedTxn?.safeTxHash;
            transactionDetails.approvedSafeTxnHash = approvedTxn.safeTxHash;
            transactionDetails.rejectedTx = rejectedTxn;
            if (
                typeof metaData === "object" &&
                Object.keys(metaData || {}).length > 0 &&
                metaData?.paymentType !== "Gnosis Transaction"
            ) {
                transactionDetails.origin = "parcel";
            } else {
                transactionDetails.origin = "gnosis";
            }
            if (rejectedTxn?.isExecuted) {
                transactionDetails.isRejected = true;
            }
            transactionDetails.status =
                approvedTxn?.isExecuted || rejectedTxn?.isExecuted ? "executed" : "Pending";
        }
        transactionDetails.transactionHash =
            approvedTxn.transactionHash || rejectedTxn?.transactionHash;

        const lastStatus = JSON.parse(localStorage.getItem("LAST_EXECUTED_TX"));

        if (lastStatus && lastStatus.nonce == approvedTxn.nonce) {
            if (approvedTxn.isExecuted || rejectedTxn?.isExecuted) {
                localStorage.removeItem("LAST_EXECUTED_TX");
                transactionDetails.executing = false;
            } else {
                transactionDetails.executing = true;
            }
        }
        if (metaData?.transactionDisbursmentDetails) {
            transactionDetails.disbursment = metaData.transactionDisbursmentDetails;
        } else if (txData && typeof txData === "object" && Object?.keys(txData || {}).length > 0) {
            const disbursment = calculateDisbursment(txData);
            transactionDetails.disbursment = disbursment;
        } else {
            const disbursement = processGnosisDisbursement(approvedTxn, balances);

            transactionDetails.disbursment = disbursement;
        }
        res(transactionDetails);
    });
}

const calculateDisbursment = transactionData => {
    let disbursement = [];
    if (transactionData?.length) {
        transactionData.forEach(transfer =>
            transfer.tokenInfo
                ? disbursement.push({
                      address: transfer.from,
                      token: transfer?.tokenInfo?.symbol || "",
                      amount: Number(transfer.value) / 10 ** (transfer?.tokenInfo?.decimals || 18),
                      fiatCurrency: "USD",
                      fiatValue: 0,
                      tokenValue:
                          Number(transfer.value) / 10 ** (transfer?.tokenInfo?.decimals || 18),
                  })
                : disbursement.push({
                      address: transfer.from,
                      token: nativeTokenSymbol,
                      amount: Number(transfer.value) / 10 ** 18,
                      fiatCurrency: "USD",
                      fiatValue: 0,
                      tokenValue: Number(transfer.value) / 10 ** 18,
                  }),
        );
    }
    return disbursement;
};

export function updatePaymentTypeByTransactionNonce() {
    return useMutation(
        async ({ nonce, category, safeAddress, transactionDisbursement, type }: any) => {
            const { updatePaymentCategory } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation UpdatePaymentCategory(
                        $transactionDisbursement: [TransactionDisbursmentParams]
                        $nonce: Int
                        $category: String
                        $type: String
                    ) {
                        updatePaymentCategory(
                            transactionDisbursement: $transactionDisbursement
                            nonce: $nonce
                            category: $category
                            type: $type
                        ) {
                            transactionId
                        }
                    }
                `,
                {
                    transactionDisbursement,
                    nonce,
                    category,
                    type,
                },
            );

            return updatePaymentCategory;
        },
        {
            mutationKey: UPDATE_PAYMENT_CATEGORY_BY_NONCE,
        },
    );
}

export function updatePaymentTypeByTransactionHash() {
    return useMutation(
        async ({ transactionHash, category, safeAddress, transactionDisbursement }: any) => {
            const { updatePaymentCategoryByTxnHash } = await graphQLClient(
                baseURI,
                safeAddress,
            ).request(
                gql`
                    mutation updatePaymentCategoryByTxnHash(
                        $transactionHash: String
                        $category: String
                        $transactionDisbursement: [TransactionDisbursmentParams]
                    ) {
                        updatePaymentCategoryByTxnHash(
                            transactionHash: $transactionHash
                            category: $category
                            transactionDisbursement: $transactionDisbursement
                        ) {
                            transactionId
                        }
                    }
                `,
                {
                    transactionHash,
                    category,
                    transactionDisbursement,
                },
            );

            return updatePaymentCategoryByTxnHash;
        },
        {
            mutationKey: UPDATE_PAYMENT_CATEGORY_BY_NONCE,
        },
    );
}

// export async function postGnosisTransaction(safeAddress, transactionData) {
//     const options = {
//         method: "POST",
//         headers: {
//             "content-type": "application/json",
//         },
//         body: JSON.stringify({ ...transactionData }),
//     };
//     return await fetch(`${gnosisURL}/safes/${safeAddress}/multisig-transactions/`, options);
// }

export async function updateBackend({
    transactionId,
    networkId,
    executionStatus,
    txHash,
    safeAddress,
    walletAddress,
}) {
    const options = {
        method: "POST",
        headers: {
            "content-type": "application/json",
        },
        body: JSON.stringify({
            transactionId,
            networkId,
            executionStatus,
            txHash,
            safeAddress,
            walletAddress,
        }),
    };
    return await fetch(`${BACKEND_BASE_URL}/transaction/post-execution`, options);
}

export function updateTxnDisbursementDetails() {
    return useMutation(
        async ({ safeAddress, transactionId, disbursementId, tagName, category, comment }: any) => {
            const { updateTxnDisbursementDetails } = await graphQLClient(
                baseURI,
                safeAddress,
            ).request(
                gql`
                    mutation UpdateDisbursementDetails(
                        $disbursementId: Int
                        $tagName: String
                        $category: String
                        $comment: String
                        $transactionId: String
                    ) {
                        updateDisbursementDetails(
                            disbursementId: $disbursementId
                            tagName: $tagName
                            category: $category
                            comment: $comment
                            transactionId: $transactionId
                        ) {
                            id
                            address
                            amount
                            token
                            fiatValue
                            fiatCurrency
                            tokenValue
                            tagName
                            recipient {
                                address
                                name
                                ens
                            }
                            disbursementType
                            comment
                        }
                    }
                `,
                {
                    transactionId,
                    disbursementId,
                    tagName,
                    category,
                    comment,
                },
            );
            return updateTxnDisbursementDetails;
        },
        {
            mutationKey: UPDATE_TXN_DISBURSEMENT_DETAILS,
        },
    );
}

export function updateTransactionIdToBudget() {
    return useMutation(
        async ({ safeAddress, transactionId, budgetId }: any) => {
            const { updateTransactionIdToBudget } = await graphQLClient(
                baseURI,
                safeAddress,
            ).request(
                gql`
                    mutation UpdateTransactionIdToBudget(
                        $budgetId: String
                        $transactionId: String
                    ) {
                        updateTransactionIdToBudget(
                            budgetId: $budgetId
                            transactionId: $transactionId
                        ) {
                            budgetId
                        }
                    }
                `,
                {
                    transactionId,
                    budgetId,
                },
            );
            return updateTransactionIdToBudget;
        },
        {
            mutationKey: UPDATE_TXN_ID_TO_BUDGET,
        },
    );
}
