import { SimulationResultBoxStyled } from "./styles/SimulateTransaction";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Typography, Button } from "@mui/material";

function SimulationSuccessBox({ setOpenModal, fetchSimulation, currentSimulationId }) {
    return (
        <SimulationResultBoxStyled>
            <div className="success-simulation">
                <div className="success-header">
                    <span className="simulatesuccess">Simulation Successful</span>
                    <span>
                        {" "}
                        <CheckCircleIcon
                            sx={{
                                color: "#27A643",
                                marginLeft: "5px",
                                height: "20px",
                                width: "20px",
                            }}
                        />
                    </span>
                </div>
                <div className="success-body">
                    {!Boolean(currentSimulationId) && (
                        <Typography className="txn-value-err" variant="h6">
                            The Transaction was successfully simulated.
                        </Typography>
                    )}
                    {Boolean(currentSimulationId) && (
                        <Typography className="txn-value" variant="h6">
                            The Transaction was successfully simulated. Full simulation report is
                            available
                            <span>
                                <a
                                    href={`${process.env.NEXT_PUBLIC_TENDERLY_SIMULATION_BASE_URL}/${currentSimulationId}`}
                                    target="_blank"
                                >
                                    {` on Tenderly`}
                                </a>
                            </span>
                        </Typography>
                    )}
                </div>
            </div>
            <Button
                className="txn-value-btn"
                variant="outlined"
                onClick={e => {
                    e.preventDefault();
                    fetchSimulation();
                    setOpenModal(true);
                }}
            >
                <span>{`View Details`}</span>
            </Button>
        </SimulationResultBoxStyled>
    );
}

export default SimulationSuccessBox;
