import * as React from "react";
import Head from "next/head";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { CacheProvider } from "@emotion/react";
import theme from "../styles/theme";
import createEmotionCache from "../styles/createEmotionCache";
import "../styles/globals.css";
import { QueryClient, QueryClientProvider, Hydrate } from "react-query";
import BaseAppContextProvider from "src/contexts/baseContext";
// Client-side cache, shared for the whole session of the user in the browser.
import TransferModule from "../src/components/TransferModule";
import { TransferContextprovider } from "src/contexts/TransferContext";
import { SafeContextProvider } from "src/contexts/SafeContext";
import MassPayoutContextProvider from "src/contexts/MassPayoutContext";
import PayrollContextProvider from "src/contexts/PayrollContext";
import IntegrationContextProvider from "src/contexts/IntegrationContext";
import { ParentSquadContextProvider } from "src/contexts/ParentSquadContext";
import { ChildSafeContextProvider } from "src/contexts/ChildSafeContext";
import { Button } from "@mui/material";
import { SnackbarProvider } from "notistack";
import CancelIcon from "@mui/icons-material/Cancel";
import RootHoc from "src/components/Root/RootHoc";
import Decimal from "decimal.js-light";
import { SquadContextProvider } from "src/contexts/SquadContext";
import Script from "next/script";
import { useRouter } from "next/router";
import { GA_TRACKING_ID, GTM_TRACKING_ID, pageview } from "src/ga";
import Maintenance from "src/components/Maintenance/maintenance";
import V3MigrationAssistant from "src/components/V3MigrationAssistant";
const clientSideEmotionCache = createEmotionCache();
const notistackRef: any = React.createRef();
const onClickDismiss = key => () => {
    notistackRef.current.closeSnackbar(key);
};

Decimal.set({
    toExpNeg: -20,
});

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
        },
    },
});

export default function MyApp(props: any): JSX.Element {
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
    const router = useRouter();

    React.useEffect(() => {
        const handleRouteChange = (url: URL) => {
            /* invoke analytics function only for production */
            //   if (isProduction)
            pageview(url);
        };
        router.events.on("routeChangeComplete", handleRouteChange);
        return () => {
            router.events.off("routeChangeComplete", handleRouteChange);
        };
    }, [router.events]);

    return (
        <>
            {process.env.NEXT_PUBLIC_MAINTENANCE_MODE &&
            process.env.NEXT_PUBLIC_MAINTENANCE_MODE == "1" ? (
                <Maintenance />
            ) : (
                <>
                    <Script
                        id="google-analytics"
                        strategy="afterInteractive"
                        src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}
                    />
                    <Script id="gtag-init" strategy="afterInteractive">
                        {`window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', '${GA_TRACKING_ID}', {
          page_path: window.location.pathname,
        });
      `}
                    </Script>

                    {/* Google Tag Manager */}

                    <Script id="gtm-init" strategy="afterInteractive">
                        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${GTM_TRACKING_ID}');
      `}
                    </Script>
                    <CacheProvider value={emotionCache}>
                        <QueryClientProvider client={queryClient}>
                            <BaseAppContextProvider>
                                <SafeContextProvider>
                                    <TransferContextprovider>
                                        <MassPayoutContextProvider>
                                            <PayrollContextProvider>
                                                <IntegrationContextProvider>
                                                    <ChildSafeContextProvider>
                                                        <ParentSquadContextProvider>
                                                            <SquadContextProvider>
                                                                <Head>
                                                                    <title>
                                                                        Parcel | PaymentOS for
                                                                        Contributor Economy
                                                                    </title>
                                                                    <meta
                                                                        name="viewport"
                                                                        content="initial-scale=1, width=device-width"
                                                                    />
                                                                </Head>

                                                                <ThemeProvider theme={theme}>
                                                                    <SnackbarProvider
                                                                        maxSnack={3}
                                                                        anchorOrigin={{
                                                                            vertical: "top",
                                                                            horizontal: "right",
                                                                        }}
                                                                        ref={notistackRef}
                                                                        action={key => (
                                                                            <Button
                                                                                color="inherit"
                                                                                onClick={onClickDismiss(
                                                                                    key,
                                                                                )}
                                                                            >
                                                                                <CancelIcon />
                                                                            </Button>
                                                                        )}
                                                                    >
                                                                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                                                                        <CssBaseline />

                                                                        <V3MigrationAssistant />

                                                                        <RootHoc
                                                                            Component={Component}
                                                                            pageProps={pageProps}
                                                                        />
                                                                        <TransferModule minimizedTitle="Continue Payout" />
                                                                    </SnackbarProvider>
                                                                </ThemeProvider>
                                                            </SquadContextProvider>
                                                        </ParentSquadContextProvider>
                                                    </ChildSafeContextProvider>
                                                </IntegrationContextProvider>
                                            </PayrollContextProvider>
                                        </MassPayoutContextProvider>
                                    </TransferContextprovider>
                                </SafeContextProvider>
                            </BaseAppContextProvider>
                        </QueryClientProvider>
                    </CacheProvider>
                </>
            )}
        </>
    );
}
