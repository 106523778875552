import React, { createContext, useContext, useEffect, useState } from "react";

const ParentSquadContext = createContext({});

export const ParentSquadContextProvider = ({ children }) => {
    const [selectedSquad, setSelectedSquad] = useState({});
    return (
        <ParentSquadContext.Provider
            value={{
                selectedSquad,
                setSelectedSquad,
            }}
        >
            {children}
        </ParentSquadContext.Provider>
    );
};
export default ParentSquadContext;
