import { ethers } from "ethers";
import { ZERO_ADDRESS } from "src/queries/constants";

export const getTokenContractAddress = tokenAddress => {
    if (!ethers.utils.isAddress(tokenAddress)) {
        return ZERO_ADDRESS;
    } else {
        return tokenAddress;
    }
};
