import styled from "@emotion/styled";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";

export const EmptyPeopleTextWrapperStyled = styled(Box)`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const EmptyTypography = styled(Typography)`
    max-width: 80%;
    margin: 0 auto;
    margin-bottom: 50px;
    text-align: center;
`;
