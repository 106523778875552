import styled from "@emotion/styled";
import { Stack } from "@mui/material";
import { Box, Theme } from "@mui/system";

export const MassPayoutStyled = styled(Box)`
    padding: 50px 15px;
    .block-heading {
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
    }
    .recipient-block {
        padding-right: 15px;
        flex: 500px;
        max-width: 500px;
        margin-top: 30px;
    }
    .queue-block {
        padding-left: 15px;
        flex: 420px;
        max-width: 420px;
        margin-top: 30px;
    }
`;

export const TitleHolder: typeof Stack = styled(Stack)`
    align-items: center;
    margin-bottom: 15px;
`;

export const SlantDivider = styled(Box)`
    margin: 0 10px;
    font-size: 22px;
`;

export const MassPayoutStepNameStyled = styled(Box)`
    color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
`;

export const ProgressBarHolder = styled(Stack)`
    width: 100%;
    height: 4px;
    border-radius: 5px;
    background-color: ${({ theme }: any) => theme.background_color.light};
`;

export const ProgressBar: any = styled(Box)`
    width: ${({ currentStep, totalSteps }: any) => (currentStep / totalSteps) * 100}%;
    background-color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    height: 100%;
    transition: width 0.5s ease-in;
    border-radius: 5px;
`;

export const MassPayoutStepNumbers = styled.p`
    margin: 0;
    margin-left: auto;
`;

export const CurrentStep = styled.span`
    color: ${({ theme }: { theme: Theme }) => theme.palette.primary.main};
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
`;

export const TotalSteps = styled.span`
    font-weight: 400;
    font-size: 14px;
    line-height: 1;
    color: ${({ theme }: any) => theme.table.headertext};
`;

export const TagAndBalanceBox: typeof Stack = styled(Stack)`
    flex-wrap: nowrap;
    margin-top: 20px;
    align-items: flex-start;
`;

export const SafeBalanceBlock = styled(Box)`
    border-left: 1px solid ${({ theme }: any) => theme.border_color.main};
    margin-left: auto;
    padding-left: 13px;
    padding-top: 5px;
    padding-bottom: 5px;
`;

export const SafeBalance = styled.p`
    margin: 0;
    line-height: 1;
    .side-heading {
        font-size: 14px;
        line-height: 1;
        white-space: nowrap;
    }
    .balance {
        font-size: 18px;
        line-height: 1;
        font-weight: 600;
        margin-left: 10px;
        white-space: nowrap;
    }
`;

export const TableSectionWrapper = styled(Stack)`
    align-items: stretch;
`;

export const WorkflowNameContainer: any = styled.div`
    cursor: pointer;
    padding: 5px 14px;
    width: 200px;
    background-color: #fafafa;
    gap: 20px;
    display: flex;
    align-items: center;
    border-radius: 4px;

    .wf-name {
        font-size: 14px;
        font-weight: 600;
        line-height: 14px;
    }
    .wf-status {
        margin-top: 2px;
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
        color: #636b81;
        text-transform: capitalize;
    }
    .icon {
        vertical-align: middle;
        color: #ec5952;
        cursor: pointer;
    }
`;
