import React, { useContext, useEffect, useState } from "react";
import { CSVPayoutWrapper, CSVTableAction } from "./styles";
import LinearProgress from "@mui/material/LinearProgress";
import DropzoneScreen from "src/components/DropzoneScreen";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Header, MainBodyWrapper } from "src/components/PeopleSection/ImportPeopleDrawer/styles";
import { processCSVData } from "src/helpers/utils/common";
import SafeContext from "src/contexts/SafeContext";
import PayoutTable from "./PayoutTable";
import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";
import ReviewScreen from "./ReviewScreen";
import TransferContext from "src/contexts/TransferContext";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import createTransaction from "src/helpers/createTransaction";
import ERC20ABI from "src/constants/abis/ERC20.json";
import MultiSendABI from "src/constants/abis/MultiSend.json";
import addresses from "src/constants/addresses";
import { useActiveBaseContext, useContract } from "src/hooks";
import { updateTransactionIdToBudget, useCreateTransaction } from "src/queries/Transaction/api";
import router from "next/router";
import MetaTxModal from "src/components/MetaTxModal";
import { CheckIfLimitsAreNotConsumed } from "src/queries/Safe/api";
import { usebulkAddContributorWithTags, useGetContributor } from "src/queries/PeopleSection/api";
import CircularProgress from "@mui/material/CircularProgress";
import { formatAmount } from "src/helpers/utils/web3Utils";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import { networkId } from "src/helpers/utils/networks";
import { triggerSlackNotification } from "src/queries/Webhook/api";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import GnosisSafeABI from "src/constants/abis/GnosisSafe.json";
import Image from "next/image";
import Dialog from "@mui/material/Dialog";
import ClearIcon from "@mui/icons-material/Clear";
import { etherscan } from "src/constants/endpoints";
import multitoken from "assets/newMultiToken.png";
import * as Sentry from "@sentry/nextjs";
import { sendEmail } from "src/queries/emailNotification";
import Decimal from "decimal.js-light";
import useEstimateGasContract from "src/hooks/useEstimateGasContract";
import { useAddTag, useGetTags } from "src/queries/PeopleSection/api";

const keyPoints = [
    "Please follow the format as per the sample file. Only .csv files are supported.",
    "Multiple tokens are supported",
    "All columns except 'name' are required. The name field is a tracker to save in the transaction details",
    "An address can be paid in multiple tokens with separate entries",
];

const MULTISEND_ADDRESS = addresses.MULTISEND_ADDRESS;

export default function CSVPayout() {
    const { getGasEstimateTransactionExecution } = useEstimateGasContract();
    const [activeTab, setActiveTab] = useState(0);
    const [csvData, setCSVData] = useState([]);
    const [invalidCsvData, setInvalidCsvData] = useState(false);
    const [saveToContributor, setSaveToContributor] = useState(false);
    const [fileName, setFileName] = useState();
    const [progress, setProgress] = React.useState(1);
    const [dragActive, setDragActive] = useState(false);
    const [fileLoading, setFileLoading] = useState(false);
    const [processedData, setProcessedData] = useState({
        headerCounts: {},
        totalInUSD: 0,
        rows: [],
    });
    const [budget, setBudget] = useState(null);
    const [loading, setLoading] = useState(false);
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [isMetaTxLimitAllowed, setIsMetaTxLimitAllowed] = useState(false);
    const [balances, setBalances] = useState({});
    const [openModal, setOpenModal] = useState(false);
    const [txStatus, setTxStatus] = useState<any>({
        status: null,
        data: {
            message: "Gathering Information",
        },
    });

    const [tags, setTags] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const {
        payoutQueue,
        description,
        setDescription,
        gasLimit,
        isTxStarted,
        setIsTxStarted,
        transferType,
        closeModal,
        addToPayoutQueueWithParams,
        setPayoutQueue,
        setIsEligibleTx,
        isEligibleTx,
        setCategory,
        category,
    }: any = useContext(TransferContext);

    const {
        currentSafeAddress,
        pay,
        safeStaticDetails: {
            isMetaTxEnabled = false,
            safeName = "",
            threshold = 1,
            owners: _owners = [],
        } = {},
        isDelegateAccess,
        isDelegateCanCreateTx,
    }: any = useContext(SafeContext);

    const { data: tagsData } = useGetTags(currentSafeAddress, "");

    useEffect(() => {
        const items = tagsData?.map(item => item.tagName) || [];
        setTags(items);
    }, [tagsData]);

    const {
        safeService,
        library,
        account,
        contributedSafes,
        isWalletEOA,
        chainId,
        isHardwareWallet,
        ethersAdapterOwner,
        isGasLowEnough,
    }: any = useActiveBaseContext().web3;

    // const { safeSdk } = useSafeSdk(ethersAdapterOwner, currentSafeAddress);
    const { safeSdk }: any = useContext(SafeContext);

    const { tokensInSafe, safeTokenBalances, safeBalanceInUSD, safeDetailsFromGnosis }: any =
        useContext(SafeContext);

    const contributor = useGetContributor(currentSafeAddress, "", [], 9999999, 0);

    const totalBalInUSD: any = Object.values(safeBalanceInUSD).reduce(
        (a: number, b: number) => a + b,
        0,
    );

    //initialise mutation
    const bulkUpdateRequestStatus = usebulkAddContributorWithTags();

    const { mutateAsync } = useCreateTransaction();
    const { mutateAsync: linkTransactionIdToBudget } = updateTransactionIdToBudget();

    const customToken = useContract(addresses.ZERO_ADDRESS, ERC20ABI, true, library, account);
    const multiSend = useContract(MULTISEND_ADDRESS, MultiSendABI, true, library, account);
    const proxyContract = useContract(currentSafeAddress, GnosisSafeABI, true, library, account);

    const checkIfAllRowsAreErrorFree = () => {
        if (!!csvData) {
            return csvData?.every(({ isErrorAtRow }) => !isErrorAtRow);
        }
        return false;
    };

    const handleCloseConfirmationModal = () => {
        setOpenConfirmationModal(false);
    };

    const handleCreateTxWithMetaTransaction = async () => {
        if (isMetaTxEnabled && isMetaTxLimitAllowed) {
            try {
                setIsTxStarted(true);
                const threshold = await safeSdk.getThreshold();
                if (threshold && threshold == 1) return setOpenConfirmationModal(true);
                handleCreateTx();
            } catch (err) {
                handleCreateTx();
            } finally {
                // setIsTxStarted(false);
            }
        } else {
            handleCreateTx();
        }
    };

    const handleDrop = async (data, fileName) => {
        let balancesLocal: any = {};
        for (let token in safeTokenBalances) {
            if (balancesLocal[token]) {
                balancesLocal[token].totalUSD = safeBalanceInUSD[token];
                let currentToken: any = Object.values(tokensInSafe).find(
                    (_token: any) => _token.tokenAddress.toUpperCase() === token.toUpperCase(),
                );
                balancesLocal[token].totalValueInEther = new Decimal(safeTokenBalances[token]).div(
                    10 ** currentToken.decimals,
                );
                balancesLocal[token].symbol = currentToken.symbol;
            } else {
                balancesLocal[token] = {};
                balancesLocal[token].totalUSD = safeBalanceInUSD[token];
                let currentToken: any = Object.values(tokensInSafe).find(
                    (_token: any) => _token.tokenAddress.toUpperCase() === token.toUpperCase(),
                );
                balancesLocal[token].totalValueInEther = new Decimal(safeTokenBalances[token]).div(
                    10 ** currentToken.decimals,
                );
                balancesLocal[token].symbol = currentToken.symbol;
            }
        }

        setFileName(fileName);
        setFileLoading(true);

        // checking for at least 3 columns in the csv
        if (!data || data.length === 0 || data.some(arr => arr.length < 4)) {
            return setInvalidCsvData(true);
        }

        const _data = [];
        data.forEach(item => {
            if (
                item[0]?.trim() == "" &&
                item[1]?.trim() == "" &&
                item[2]?.trim() == "" &&
                item[3]?.trim() == "" &&
                item[4]?.trim() == ""
            ) {
            } else {
                _data.push(item);
            }
        });
        console.log({ tags });

        const rowWithTokenDetails: any = await processCSVData(
            _data,
            tokensInSafe,
            setBalances,
            balancesLocal,
            tags,
        );

        console.log({ rowWithTokenDetails });

        setCSVData(rowWithTokenDetails);
        setProgress(2);
        setActiveTab(1);
        setFileLoading(false);
        setInvalidCsvData(false);
    };

    const disableCreateTx = () => {
        return !(payoutQueue && payoutQueue.length > 0 && description.trim());
    };

    useEffect(() => {
        CheckIfLimitsAreNotConsumed(currentSafeAddress)
            .then(data => setIsMetaTxLimitAllowed(data))
            .catch(err => setIsMetaTxLimitAllowed(false));
    }, [currentSafeAddress]);

    useEffect(() => {
        if (activeTab == 1) {
            setIsEligibleTx(true);
            return;
        }
    }, [activeTab]);

    const handleCreateTx = async () => {
        let txstatus = await createTransaction(
            safeService,
            safeSdk,
            payoutQueue,
            currentSafeAddress,
            customToken,
            multiSend,
            library,
            account,
            enqueueSnackbar,
            setIsTxStarted,
            closeModal,
            isMetaTxEnabled,
            isMetaTxLimitAllowed,
            setOpenModal,
            setTxStatus,
            proxyContract,
            isDelegateAccess,
            isDelegateCanCreateTx,
            isWalletEOA,
            safeDetailsFromGnosis?.version,
            chainId,
            isHardwareWallet,
            getGasEstimateTransactionExecution,
            ethersAdapterOwner,
            isGasLowEnough,
        );
        setTxStatus(txstatus);
        if (txstatus.status) {
            // console.log(description);
            const { addTransaction } = await mutateAsync({
                txParams: {
                    transaction: {
                        description: description.trim(),
                        paymentType: "csv",
                        nonce: txstatus.data.nonce,
                        status: txstatus.data.status,
                        transactionHash: txstatus.data.txHash,
                        transactionFees: 0,
                        TransactionDisbursementDetails: payoutQueue.map(item => {
                            return {
                                address: item.walletAddress,
                                amount: item.tokenValue.toNumber(),
                                token: item.tokenSymbol ? item.tokenSymbol : item.selectedToken,
                                fiatValue: item.tokenValueInUSD,
                                fiatCurrency: "USD",
                                tokenValue: item.tokenValue.toNumber(),
                                disbursementType: category,
                                comment: item.comment,
                                tagName: item.tagName,
                            };
                        }),
                    },
                },
                currentSafeAddress,
            });

            // link transaction to budget
            // budget is null by default or 0 if selected "do not link with any budget"
            if (budget) {
                await linkTransactionIdToBudget({
                    safeAddress: currentSafeAddress,
                    transactionId: addTransaction.transactionId,
                    budgetId: budget,
                });
            }

            let updatedBy = _owners.find(owner => owner.address == account);

            if (isDelegateAccess && isDelegateCanCreateTx) {
                updatedBy = contributedSafes.find(safe => safe.safeAddress == currentSafeAddress);
            }
            if (txstatus?.data?.status === "Pending") {
                const filteredOtherOwners = _owners
                    .filter(owner => owner.address != account)
                    .map(({ ownerEmail, nickName }) => {
                        return {
                            emailAddress: ownerEmail,
                            ownerName: nickName ? nickName : "Safe Owner",
                        };
                    });

                sendEmail(
                    payoutQueue?.length,
                    safeName,
                    filteredOtherOwners,
                    description.trim(),
                    updatedBy?.nickName,
                    addTransaction.transactionId,
                );
            }
            await triggerSlackNotification({
                action: "CREATED",
                initator: account,
                txData: {
                    description,
                    paymentType: transferType,
                    organisationName: safeName,
                    threshold,
                    networkId,
                    transactionHash: txstatus.data.txHash,
                    safeAddress: currentSafeAddress,
                    nonce: txstatus.data.nonce,
                    transactionId: addTransaction.transactionId,
                    fiatValue: payoutQueue?.reduce((acc, curr) => (acc += curr.tokenValueInUSD), 0),
                    createdBy: account,
                    updatedBy: account,
                    createdByName: updatedBy?.nickName,
                },
            });
        } else {
            Sentry.captureException;
            enqueueSnackbar(txstatus?.data?.message || "Error", { variant: "error" });
        }
    };

    useEffect(() => {
        setPayoutQueue([]);
        return () => {
            setPayoutQueue([]);
        };
    }, []);

    const handleReviewContributor = async () => {
        if (saveToContributor) {
            setLoading(true);

            const {
                data: { contributors },
            } = contributor;

            const alreadyAddedAddresses = contributors.map(item => item.address) || [];

            const filteredContributor = csvData.filter(
                item => !alreadyAddedAddresses.includes(item.address),
            );

            const bulkAddParams = {};
            filteredContributor.forEach(item => {
                bulkAddParams[item.address] = {
                    name: item.username,
                    address: item.address,
                    ens: item.ens,
                    tags: [],
                };
            });

            try {
                await bulkUpdateRequestStatus.mutateAsync({
                    bulkAddParams: Object.values(bulkAddParams),
                    safeAddress: currentSafeAddress,
                });
            } catch {}

            setLoading(false);
            setActiveTab(2);
        } else {
            setActiveTab(2);
        }
    };
    const handleCloseModal = () => {
        setOpenModal(false);
        setTxStatus({
            status: null,
            data: {
                message: "Gathering Information",
            },
        });
        if (txStatus.status) {
            closeModal();
            router.push({
                pathname: "/transactions",
                query: {
                    nonce: txStatus.data.nonce,
                    safeTxHash: txStatus.data.txHash,
                },
            });
        }
    };

    const totalTokenRows = () => {
        let totalRows = 0;

        Object.entries(balances ?? {}).map(([key, value]: [string, any]) => {
            if (!isNaN(value?.totalRows)) {
                totalRows = totalRows + Number(value?.totalRows);
            }
        });

        return totalRows;
    };
    return (
        <CSVPayoutWrapper>
            <Header>
                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography
                            style={{ color: "#25274F", fontSize: 18, fontWeight: 700 }}
                            className="title"
                        >
                            CSV Payout
                        </Typography>
                        <Typography color="primary" className="subtitle">
                            {activeTab === 0
                                ? "Upload File"
                                : activeTab === 1
                                ? "Preview Upload"
                                : activeTab === 2
                                ? "Review"
                                : null}
                        </Typography>
                    </Breadcrumbs>
                </div>

                <div>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography fontWeight={600} fontSize="18px" color="primary">
                            {progress}
                        </Typography>
                        <Typography className="total" fontWeight={"bolder"}>
                            3
                        </Typography>
                    </Breadcrumbs>
                </div>
            </Header>

            <LinearProgress
                variant="determinate"
                value={progress * 33.3}
                style={{ marginTop: "16px", background: "#EFF0F2" }}
            />

            {[1, 2].includes(activeTab) ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        padding: "10px 0px",
                    }}
                >
                    <Box
                        sx={{
                            flex: "100%",
                            display: "flex",
                            padding: "5px 10px",
                        }}
                    >
                        <ArrowCircleLeftOutlinedIcon />
                        <Box
                            sx={{
                                display: "flex",
                                maxWidth: "60%",
                                overflowX: "auto",
                                justifyContent: "flex-start",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0px 10px",
                                }}
                            >
                                <Image src={multitoken} height={20} width={20} />
                                <Typography color="#9998A4" marginX={"6px"}>
                                    All
                                </Typography>
                                <Typography
                                    sx={{
                                        background: "#F2F2F2",
                                        borderRadius: "4px",
                                        height: "20px",
                                        width: "20px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                    }}
                                    color="#232427"
                                    fontSize={12}
                                >
                                    {totalTokenRows()}
                                </Typography>
                            </Box>
                            {Object.entries(balances ?? {}).map((item: any) =>
                                item[1].totalRows && item[1].totalRows > 0 ? (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "0px 10px",
                                        }}
                                    >
                                        <img
                                            src={tokensInSafe[item[0]]?.logoUri}
                                            style={{
                                                height: "20px",
                                                width: "20px",
                                                borderRadius: "50%",
                                            }}
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = UndiscoveredCoin.src;
                                            }}
                                        />
                                        <Typography color="#9998A4" marginX={"6px"}>
                                            {tokensInSafe[item[0]].symbol}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                background: "#F2F2F2",
                                                borderRadius: "4px",
                                                height: "20px",
                                                width: "20px",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                            color="#232427"
                                            fontSize={12}
                                        >
                                            {item[1].totalRows}
                                        </Typography>
                                    </Box>
                                ) : (
                                    <></>
                                ),
                            )}
                        </Box>
                        <ArrowCircleRightOutlinedIcon />
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderLeft: theme => `1px solid ${theme.border_color.main}`,
                            paddingLeft: "13px",
                        }}
                    >
                        <Typography whiteSpace={"nowrap"} fontSize={14} color="#232427">
                            Safe Balance
                        </Typography>
                        <Typography
                            marginLeft={"10px"}
                            fontWeight={600}
                            fontSize={20}
                            color="#232427"
                        >
                            ${isNaN(totalBalInUSD) ? "--" : formatAmount(totalBalInUSD, 2, 2)}
                        </Typography>
                    </Box>
                </Box>
            ) : null}

            <MainBodyWrapper>
                {activeTab === 0 ? (
                    <DropzoneScreen
                        handleDrop={handleDrop}
                        hasError={invalidCsvData}
                        keyPoints={keyPoints}
                        setDragActive={setDragActive}
                        fileName={fileName}
                        url={
                            "https://docs.google.com/spreadsheets/d/1Cg5XFMpZVjG67GqGUszT7tPYQ_Asu29q9VbA5oXWd_Y/edit#gid=0"
                        }
                        loading={fileLoading}
                    />
                ) : activeTab === 1 ? (
                    <PayoutTable
                        setActiveTab={setActiveTab}
                        fileName={fileName}
                        data={processedData}
                        setProcessedData={setProcessedData}
                        tokensInSafe={tokensInSafe}
                        safeTokenBalancesByTokenName={balances}
                        csvData={csvData}
                        setCsvData={setCSVData}
                        setBalances={setBalances}
                        tags={tags}
                    />
                ) : activeTab === 2 ? (
                    <ReviewScreen
                        data={csvData}
                        description={description}
                        setDescription={setDescription}
                        setPayoutQueue={setPayoutQueue}
                        addToPayoutQueueWithParams={addToPayoutQueueWithParams}
                        setCategory={setCategory}
                        safeSdk={safeSdk}
                        budget={budget}
                        setBudget={setBudget}
                    ></ReviewScreen>
                ) : null}
            </MainBodyWrapper>
            {activeTab === 1 ? (
                <CSVTableAction>
                    <Button
                        variant="outlined"
                        onClick={() => setActiveTab(0)}
                        startIcon={<ArrowBackIosIcon style={{ fontSize: "12px" }} />}
                    >
                        Back
                    </Button>
                    <Box className="flex">
                        {/* <Box
                            className="flex"
                            sx={{
                                marginRight: "20px",
                            }}
                        >
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <Checkbox
                                    checked={saveToContributor}
                                    onChange={e => {
                                        setSaveToContributor(e.target.checked);
                                    }}
                                    sx={{
                                        color: "#C9CCD3",
                                        "&.Mui-checked": {
                                            color: "#2962EF",
                                        },
                                        "& .MuiSvgIcon-root": { fontSize: 25 },
                                    }}
                                ></Checkbox>
                            )}
                            <Typography color={"#8B91A1"}>
                                Save to contributors for future payments
                            </Typography>
                        </Box> */}
                        <Button
                            onClick={handleReviewContributor}
                            variant="contained"
                            endIcon={null}
                            style={{ fontWeight: 600 }}
                            disabled={!(csvData.length > 0) || !checkIfAllRowsAreErrorFree()}
                        >
                            Review Payout
                        </Button>
                    </Box>
                </CSVTableAction>
            ) : null}

            {activeTab === 2 ? (
                <CSVTableAction>
                    <Button
                        variant="outlined"
                        onClick={() => setActiveTab(1)}
                        startIcon={<ArrowBackIcon />}
                    >
                        Back
                    </Button>
                    <Box className="flex">
                        <LoadingButton
                            loading={isTxStarted}
                            disabled={disableCreateTx() || isEligibleTx === false}
                            className="create-tx-button"
                            variant="contained"
                            onClick={handleCreateTxWithMetaTransaction}
                        >
                            Confirm & Proceed
                        </LoadingButton>
                        <MetaTxModal
                            open={openConfirmationModal}
                            handleCloseConfirmationModal={handleCloseConfirmationModal}
                            handleConfirm={() => {
                                setOpenConfirmationModal(false);
                                handleCreateTx();
                            }}
                            variant={isGasLowEnough ? "confirm" : "highGas"}
                        />
                    </Box>
                </CSVTableAction>
            ) : null}
            <Dialog open={openModal} maxWidth="xs" fullWidth onClose={() => {}}>
                <Box width="100%" textAlign="right">
                    {txStatus.status !== null && (
                        <ClearIcon
                            sx={{ color: "#636B81", my: "10px", mx: "20px", cursor: "pointer" }}
                            onClick={handleCloseModal}
                        />
                    )}
                </Box>
                <Box px={"20px"} pt={"30px"} pb={"20px"} textAlign="center">
                    {txStatus.status === null && (
                        <>
                            <CircularProgress
                                variant={"indeterminate"}
                                size={100}
                                thickness={2}
                                sx={{
                                    color: "primary",
                                }}
                                value={100}
                            />

                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {txStatus.data?.hash && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        href={`${etherscan}/tx/${txStatus.data.hash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                        target="_blank"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )}
                            <Typography fontSize="18px" fontWeight={600} sx={{ mt: "5px" }}>
                                {txStatus.data.message}
                            </Typography>
                        </>
                    )}
                    {txStatus.status === true && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-icon.png" />
                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {txStatus?.data?.status !== "Pending" && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        href={`${etherscan}/tx/${txStatus.data.txHash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                        target="_blank"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )}
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "5px", mb: "20px" }}
                            >
                                {isWalletEOA ? txStatus?.data?.message : `Transaction Created`}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                            >
                                Close
                            </Button>
                        </>
                    )}
                    {txStatus.status === false && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-failed.png" />
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "20px", mb: "20px" }}
                            >
                                {txStatus?.data?.message
                                    ? txStatus?.data?.message
                                    : "Transaction Rejected"}
                            </Typography>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                            >
                                Dismiss
                            </Button>
                        </>
                    )}
                </Box>
            </Dialog>
        </CSVPayoutWrapper>
    );
}
