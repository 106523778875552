import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import NameAddress from "src/components/NameAddress";
import { Row } from "./styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formatAmount, minifyAddress } from "src/helpers/utils/web3Utils";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import { nativeTokenSymbol } from "src/queries/constants";
import { formatTokenDecimals } from "src/helpers/utils/common";
import Decimal from "decimal.js-light";

export default function NormalRow({ handleSelectPeople, selectedRows, item, handleClick, index }) {
    const isChecked = selectedRows.includes(index);
    const [expandRow, setExpandRow] = useState(false);
    const expandRowWrapper = { border: "1px solid #2962EF", borderRadius: 4 };

    return (
        <div style={expandRow ? expandRowWrapper : {}}>
            <Row
                sx={{
                    borderTop: "none !important",
                    background: isChecked ? "#F5F8FE" : "none",
                }}
                style={{
                    marginTop: index === 0 ? 0 : 2,
                    borderBottom: isChecked ? "none" : "1px solid #eaeaea",
                    cursor: item.comment ? "pointer" : "default",
                }}
                onClick={() => {
                    if (item?.comment) setExpandRow(!expandRow);
                }}
            >
                <Box>
                    <Checkbox
                        onChange={e => handleSelectPeople(e, item, index)}
                        checked={isChecked}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{
                            color: "#C9CCD3",
                            "&.Mui-checked": {
                                color: "#2962EF",
                            },
                            "& .MuiSvgIcon-root": { fontSize: 25 },
                        }}
                    ></Checkbox>
                </Box>
                <Box>
                    <NameAddress
                        name={item?.username}
                        address={item?.address}
                        ens={item?.ens ? item?.ens : null}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <img
                        style={{
                            height: "20px",
                            width: "20px",
                            borderRadius: "50%",
                        }}
                        onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = UndiscoveredCoin.src;
                        }}
                        src={item?.logoUri}
                    />
                    <Typography marginLeft={"8px"} color={"#25274F"} fontWeight={400}>
                        {`${formatTokenDecimals(item?.amount, 8)} ${item.symbol} `}{" "}
                        <Typography component={"span"} fontSize={12}>
                            {item.symbol === nativeTokenSymbol
                                ? ""
                                : `(${minifyAddress(item.tokenAddress)})`}
                        </Typography>
                    </Typography>
                </Box>
                <Box>
                    <Typography marginLeft={"8px"} color={"#25274F"} fontWeight={400}>
                        {isNaN(Number(item?.fiatConversion))
                            ? "--"
                            : item?.fiatConversion < 0.01
                            ? "<$0.01"
                            : `$${formatAmount(item?.fiatConversion, 2, 2)}`}
                    </Typography>
                </Box>
                <Box>
                    <Typography marginLeft={"8px"} color={"#25274F"} fontWeight={400}>
                        {isNaN(Number(item?.fiatValue))
                            ? "--"
                            : item?.fiatValue < 0.01
                            ? "<$0.01"
                            : `$${formatTokenDecimals(
                                  new Decimal(item?.fiatValue || 0),
                                  2,
                                  false,
                              )}`}
                    </Typography>
                </Box>
                <Box display="flex" alignItems="center" style={{ flexWrap: "wrap" }}>
                    {item.tagName ? (
                        <Box
                            display="flex"
                            alignItems="center"
                            sx={{
                                border: "1px solid #DFE1E5",
                                borderRadius: "4px",

                                padding: "7px",
                                height: "29px",
                            }}
                        >
                            <Typography fontSize={"13px"} fontWeight={500} color="black">
                                {item.tagName}
                            </Typography>
                        </Box>
                    ) : (
                        <Typography marginLeft={"8px"} color={"#25274F"} fontWeight={400}>
                            No tags assigned
                        </Typography>
                    )}
                </Box>
            </Row>
            {expandRow && (
                <Row style={{ borderTop: "0px" }}>
                    <div style={{ display: "flex", width: "100%", flexDirection: "column" }}>
                        <Typography fontSize={"13px"} color={"#25274F"} fontWeight={600}>
                            Payout Comment:
                        </Typography>
                        <Typography fontSize={"14px"} color={"#636B81"} fontWeight={400}>
                            {item.comment}
                        </Typography>
                    </div>
                </Row>
            )}
        </div>
    );
}
