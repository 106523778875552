import styled from "@emotion/styled";
import {
    Button,
    Checkbox,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from "@mui/material";
import { Box } from "@mui/system";

export const PeopleTableBlockStyled = styled(Box)`
    flex: 100%;
    margin-top: 20px;
    border: 1px solid ${({ theme }: any) => theme.table.borderColor};
    border-radius: 7px;
    position: relative;
    overflow: hidden;
    @media only screen and (min-width: 768px) {
        margin-right: 15px;
        flex: 50%;
        max-width: 50%;
    }
    .MuiTableCell-root {
        background-color: #fff;
    }
`;

export const PeopleTableContainerStyled: any = styled(TableContainer)`
    padding: 0 20px;
    max-height: 500px;
`;

export const PeopleTableStyled: typeof Table = styled(Table)`
    width: 100%;
    margin-bottom: 30px;
`;

export const HeaderCell: any = styled(TableCell)`
    color: ${({ theme }: any) => theme.table.columnText};
    width: ${({ selectBox }: any) => (selectBox ? "50px" : "initial")};
    line-height: 1;
    padding: 14px;
    vertical-align: middle;
    border-bottom: 1px solid ${({ theme }: any) => theme.table.borderColor};
`;

export const SortByNameButton = styled(Button)`
    color: ${({ theme }: any) => theme.table.columnText};
    padding: 0;
    min-width: initial;
    margin-left: 10px;
    line-height: 1;
    .MuiSvgIcon-root {
        width: 0.75em;
        height: 0.75em;
        margin-bottom: 0.2em;
    }
`;

export const PeopleCheckBox: typeof Checkbox = styled(Checkbox)`
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 2px;
    color: ${({ checked, theme }: any) =>
        checked ? theme.palette.primary.main : theme.table.checkBoxColor};
`;

export const PeopleIndividualCB: typeof PeopleCheckBox = styled(PeopleCheckBox)`
    margin-left: 0;
`;

export const PeopleIndividual: typeof Stack = styled(Stack)`
    align-items: stretch;
    flex-wrap: nowrap;
`;

export const PeopleName = styled.p`
    margin: 0;
    font-weight: 600;
    color: ${({ theme }: any) => theme.palette.title.main};
    font-size: 14px;
    line-height: 1;
`;

export const PeopleAddress = styled.p`
    margin: 0;
    font-weight: 500;
    color: #8b91a1;
    font-size: 13px;
    line-height: 1;
`;

export const PeopleTableCell = styled(TableCell)`
    padding-top: 13px;
    padding-bottom: 13px;
    border-bottom: 1px solid ${({ theme }: any) => theme.table.borderColor};
    cursor: pointer;
`;

export const PeopleTableRow = styled(TableRow)`
    &:hover {
        background-color: ${({ theme, hover }: any) =>
            hover ? theme.background_color.lightprimary : theme.background_color.main};
    }
`;

export const PeopleTableBody = styled(TableBody)`
    z-index: 1;
    position: relative;

    /* height: 400px;
  margin-bottom: 20px;
  overflow-y: scroll; */
    /* ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }: any) => theme.palette.light};
    border-radius: 20px;
    border: 6px solid transparent;
    background-clip: content-box;
  }
  ::-webkit-scrollbar,
  ::-webkit-scrollbar-track {
    background: transparent;
  } */
`;

export const PeopleSelectorFooter = styled(Box)`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 20px;
    background-color: #fff;
    z-index: 2;
`;

export const AddToQueueButton = styled(Button)`
    width: 100%;
`;
