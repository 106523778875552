import styled from "@emotion/styled";
import { Typography } from "@mui/material";

export const TypographyStyled = styled(Typography)`
    display: flex;
    align-items: center;

    svg {
        color: #adb1bd;
        width: 14px;
        height: 14px;
        margin-left: 6px;
        :hover {
            color: #2962ef;
        }
    }
`;
