import React from "react";
import DockableModal from "../DockableModal";
import { TransferModuleStyled } from "./styles/TransferModule";
import TransferModuleBody from "./TransferModuleBody";
import TransferSidebar from "./TransferSidebar";

const TransferModule = props => {
    return (
        <>
            <DockableModal {...props}>
                <TransferModuleStyled>
                    <TransferSidebar />
                    <TransferModuleBody />
                </TransferModuleStyled>
            </DockableModal>
        </>
    );
};

export default TransferModule;
