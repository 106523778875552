import {
    getBlockExplorerURL,
    getGnosisRelayURL,
    getGnosisURL,
    networkId,
    networkNames,
} from "src/helpers/utils/networks";

// GNOSIS
export const gnosisSafeTransactionEndpoint = `${getGnosisURL(networkId)}/api/v1/safes/`;

export const gnosisSafeTransactionV2Endpoint = `${getGnosisRelayURL(networkId)}/api/v2/safes/`;

// GAS STATION
export const ethGasStationEndpoint = "https://ethgasstation.info/api/ethgasAPI.json?";

export const polygonGasStationEndpoint = "https://gasstation-mainnet.matic.network/v2";

export const metaTxResubmitEndpoint = "https://api.biconomy.io/api/v1/meta-tx/resubmitted?";

export const etherscan = getBlockExplorerURL(networkId);

export const transactionServiceUrl = getGnosisURL(networkId);

export const coordinapeUrl = "https://app.coordinape.com/api";
export const coordinapeAPI = "https://api.coordinape.com/api/v2";

export const corsAppURL = "https://web-production-b294.up.railway.app/";
