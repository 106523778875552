import React from "react";
import List from "@mui/material/List";
import { NavLink } from "src/components/Buttons";
import NorthEastOutlined from "@mui/icons-material/NorthEastOutlined";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useRouter } from "next/router";
import { ContributorDashboardTopbar, ContributorDashboardProfileBox } from "./styles";
import { useGetDaoByContributor } from "src/queries/ContributorRequest/api";
import { minifyAddress, minifyName } from "src/helpers/utils/web3Utils";
import EditOutlined from "@mui/icons-material/EditOutlined";

import CopyOnClick from "src/components/common/Copy/CopyOnClick";
import { useActiveBaseContext } from "src/hooks";
import NavBarIcons from "src/components/CustomIcons/NavBarIcons";
import Image from "next/image";

function ContributorNavSideBar() {
    const { account: walletAddress } = useActiveBaseContext().web3;
    const { status, data: daos, error, isFetching } = useGetDaoByContributor(walletAddress);

    const router = useRouter();

    return (
        <div>
            <ContributorDashboardProfileBox>
                <ContributorDashboardTopbar>
                    <img
                        src={`https://cdn.stamp.fyi/avatar/${walletAddress}?s=36`}
                        className="safe-avatar"
                    />

                    <div className="flex-column">
                        <div className="flex-row">
                            <div className="contributor-name">
                                {daos?.name ? minifyName(daos.name, 13) : "Unnamed"}
                            </div>

                            <EditOutlined
                                className="edit-icon"
                                onClick={() => router.push("/contributor/profile")}
                            />
                        </div>

                        <div>
                            <CopyOnClick
                                style={{ display: "inline", cursor: "pointer" }}
                                value={walletAddress}
                                text="Click to Copy Address"
                            >
                                <div className="address">{minifyAddress(walletAddress, 4, 3)}</div>
                            </CopyOnClick>
                        </div>
                    </div>
                </ContributorDashboardTopbar>

                <Box mt="18px">
                    <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        startIcon={<NorthEastOutlined />}
                        disableRipple={false}
                        sx={{ fontSize: "1rem" }}
                        onClick={() => router.push("/contributor/requests/new")}
                    >
                        New Request
                    </Button>
                </Box>
            </ContributorDashboardProfileBox>

            <List
                sx={{
                    marginTop: "16px",
                }}
            >
                <NavLink
                    title="Profile"
                    icon={
                        router.pathname === "/contributor/profile" ? (
                            <NavBarIcons
                                name="contributor_profile"
                                color="#2962EF"
                                size={undefined}
                            />
                        ) : (
                            <NavBarIcons
                                name="contributor_profile_line"
                                color="#25274F"
                                size={undefined}
                            />
                        )
                    }
                    href="/contributor/profile"
                    isactive={router.pathname === "/contributor/profile"}
                />
                <NavLink
                    title="Requests"
                    icon={
                        router.pathname === "/contributor/requests" ? (
                            <NavBarIcons
                                name="contributor_requests"
                                color="#2962EF"
                                size={undefined}
                            />
                        ) : (
                            <NavBarIcons
                                name="contributor_requests"
                                color="#25274F"
                                size={undefined}
                            />
                        )
                    }
                    href="/contributor/requests"
                    isactive={router.pathname === "/contributor/requests"}
                />
            </List>
        </div>
    );
}

export default ContributorNavSideBar;
