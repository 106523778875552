import React, { useContext } from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useGetParollBySafe } from "src/queries/Workflow/api";
import {
    ListItemButtonStyled,
    ListSubheaderStyled,
    TransferSidebarStyled,
} from "./styles/TransferSidebar";
import { UploadFileSharp, NorthEast, People, ReceiptLongOutlined } from "@mui/icons-material";
import TransferContext from "src/contexts/TransferContext";
import SafeContext from "src/contexts/SafeContext";
import CustomIcons from "src/components/CustomIcons";

export default function TransferSidebar() {
    const { transferType, setTransferType, setDescription }: any = useContext(TransferContext);
    const { currentSafeAddress }: any = useContext(SafeContext);
    const { data } = useGetParollBySafe(currentSafeAddress, ["active"]);

    return (
        <TransferSidebarStyled>
            <List
                sx={{
                    width: "100%",
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                className="sidebar-list"
            >
                <ListItemButtonStyled
                    onClick={e => {
                        setTransferType("quick");
                        transferType !== "quick" && setDescription("");
                    }}
                    selected={transferType == "quick"}
                >
                    <ListItemIcon>
                        <CustomIcons
                            name="quick_transfer"
                            color={transferType == "quick" ? "#2962EF" : "#25274F"}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Quick Transfer" />
                </ListItemButtonStyled>
                <ListItemButtonStyled
                    onClick={e => {
                        setTransferType("bulk");
                        transferType !== "bulk" && setDescription("");
                    }}
                    selected={transferType == "bulk"}
                >
                    <ListItemIcon>
                        <CustomIcons
                            name="mass_payout"
                            color={transferType == "bulk" ? "#2962EF" : "#25274F"}
                        />
                    </ListItemIcon>
                    <ListItemText primary="Mass Payout" />
                </ListItemButtonStyled>
                <ListItemButtonStyled
                    onClick={e => {
                        setTransferType("csv");
                        transferType !== "csv" && setDescription("");
                    }}
                    selected={transferType == "csv"}
                >
                    <ListItemIcon>
                        <CustomIcons
                            name="csv_payout"
                            color={transferType == "csv" ? "#2962EF" : "#25274F"}
                        />
                    </ListItemIcon>
                    <ListItemText primary="CSV Payout" />
                </ListItemButtonStyled>
                {data?.length !== 0 && (
                    <ListItemButtonStyled
                        onClick={e => {
                            setTransferType("recurring");
                            transferType !== "recurring" && setDescription("");
                        }}
                        selected={transferType == "recurring"}
                    >
                        <ListItemIcon>
                            <CustomIcons
                                name="due_payouts"
                                color={transferType == "recurring" ? "#2962EF" : "#25274F"}
                            />
                        </ListItemIcon>

                        <ListItemText primary="Due Payouts" />
                    </ListItemButtonStyled>
                )}
            </List>
        </TransferSidebarStyled>
    );
}
