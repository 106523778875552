import { Box, Button, Divider, InputAdornment, MenuItem, Stack, Typography } from "@mui/material";
import { DeleteOutlined } from "@mui/icons-material";
import React, { useContext, useEffect, useState } from "react";
import InputComponent from "src/components/InputComponent";
import TransferContext from "src/contexts/TransferContext";
import SearchContributors from "./SearchContributors";
import SafeContext from "src/contexts/SafeContext";
import { Add } from "@mui/icons-material";
import {
    CurrencyBox,
    HelperText,
    StyledAutoCompleteWrapper,
    StyledAutoComplete,
    AddInfoBox,
    InfoCard,
    AddInfoInputBox,
    RemoveButton,
    SelectTagWrapper,
} from "./styles/RecipientBlock";
import { finiteNumber, getFormattedBalanceFromUnits } from "src/helpers/utils/common";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import { useSnackbar } from "notistack";
import { formatAmount, minifyAddress } from "src/helpers/utils/web3Utils";
import { nativeTokenSymbol } from "src/queries/constants";
import * as ga from "src/ga";
import { formatUnits, getAddress } from "ethers/lib/utils";
import { useGetContributor } from "src/queries/PeopleSection/api";
import { TextField } from "@mui/material";
import { InputLabel } from "@mui/material";
import StampAvatar from "src/components/StampAvatar";
import { ethers } from "ethers";
import Decimal from "decimal.js-light";
import SelectBoxParcel from "src/components/CategorySelect/index";
import { ModuleHeading } from "../styles/common";
import SearchFilterTags from "src/components/InviteLinkModal/SearchFilterTags";

const RecipientBlock = ({ setOpenAddPeopleModal, setSimulateState }) => {
    const {
        walletAddress,
        setWalletAddress,
        walletNickName,
        setWalletNickName,
        selectedToken,
        setSelectedToken,
        tokenValue,
        setTokenValue,
        tokenValueInUSD,
        setTokenValueInUSD,
        addToPayoutQueue,
        isEditOpen,
        setTokenSymbol,
        setTokenDecimal,
        resetQuickTxForm,
        token,
        setToken,
        setTag,
        setPayoutCategory,
        setPayoutComment,
    }: any = useContext(TransferContext);
    const { enqueueSnackbar } = useSnackbar();
    const {
        tokensInSafe,
        safeTokenBalances,
        currentSafeAddress,
        safeStaticDetails: { categories: _categories = [] },
    }: any = useContext(SafeContext);
    const { data } = useGetContributor(currentSafeAddress, "", [], 100000);
    const [queueInProgress, setQueueInProgress] = useState(false);
    const [openAddInfo, setOpenAddInfo] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [errorMap, setError] = useState({
        walletAddress: null,
        walletNickName: null,
        selectedToken: null,
        tokenValue: null,
    });

    useEffect(() => {
        if (selectedTags.length) setTag(selectedTags[0]);
    }, [selectedTags]);

    useEffect(() => {
        setError({
            walletAddress: null,
            walletNickName: null,
            selectedToken: null,
            tokenValue: null,
        });
    }, [searchTerm, tokenValue, tokenValueInUSD, token]);

    const balanceOfSelectedToken = formatUnits(
        safeTokenBalances[selectedToken],
        tokensInSafe[selectedToken]?.decimals,
    );

    const handlIsError = () => {
        let isError = false;
        const newErrorMap = {
            ...errorMap,
        };
        if (!walletAddress) {
            newErrorMap.walletAddress = "Enter Wallet Address";
            isError = true;
        } else if (
            !ethers.utils.isAddress(walletAddress.trim()) &&
            !walletAddress.trim().endsWith(".eth")
        ) {
            newErrorMap.walletAddress = "Invalid wallet address";
            isError = true;
        } else {
            newErrorMap.walletAddress = null;
        }
        // if (!walletNickName) {
        //     newErrorMap.walletNickName = "Enter Person Name";
        //     isError = true;
        // } else {
        //     newErrorMap.walletNickName = null;
        // }
        if (!selectedToken) {
            newErrorMap.selectedToken = "Select a Token";
            isError = true;
        } else {
            newErrorMap.selectedToken = null;
        }
        if (!Number(tokenValue)) {
            newErrorMap.tokenValue = "Enter Token Value";
            isError = true;
        } else {
            if (Number(tokenValue) <= Number(balanceOfSelectedToken)) {
                newErrorMap.tokenValue = null;
            } else {
                newErrorMap.tokenValue = "Token Value should be less than the balance";
                isError = true;
            }
        }
        setError(newErrorMap);
        return isError;
    };

    const disableAddToQ = () => {
        return !(
            walletAddress &&
            // walletNickName &&
            selectedToken &&
            tokenValue &&
            walletAddress.length > 0 &&
            // walletNickName.length > 0 &&
            selectedToken.length > 0 &&
            tokenValue > 0 &&
            Number(tokenValue) <= Number(balanceOfSelectedToken)
        );
    };

    const handleTokenValueChange = (value, decimals) => {
        if (Number(value) >= 0) {
            let conversionValue = Number(tokensInSafe[selectedToken]?.fiatConversion);
            let decimalPlaces = value && value.toString().split(".")[1]?.length;
            if (decimalPlaces && decimalPlaces > decimals) {
                return enqueueSnackbar(`Should be 1 to ${decimals} decimals`, {
                    variant: "warning",
                });
            }
            setTokenValue(value);

            if (value) {
                setTokenValueInUSD(
                    new Decimal(value).times(new Decimal(conversionValue)).toFixed(2),
                );
            } else {
                setTokenValueInUSD("");
            }
        } else {
            setTokenValue("");
            setTokenValueInUSD("");
        }
    };

    const handleTokenValueInUSDChange = e => {
        if (tokensInSafe[selectedToken]?.fiatConversion === 0) return;
        let value = e.target.value;
        if (value && Number(value) >= 0) {
            let conversionValue = Number(tokensInSafe[selectedToken]?.fiatConversion);
            let decimalPlaces = value && value.toString().split(".")[1]?.length;
            if (decimalPlaces > 2) {
                enqueueSnackbar(`Should be  2 decimals`, {
                    variant: "warning",
                });
                return;
            }
            setTokenValueInUSD(value);
            setTokenValue(
                conversionValue == 0
                    ? 0
                    : new Decimal(value)
                          .dividedBy(new Decimal(finiteNumber(conversionValue)))
                          .toFixed(tokensInSafe[selectedToken]?.decimals),
            );
        } else {
            setTokenValue("");
            setTokenValueInUSD("");
        }
    };

    const handleAddToPayoutQueue = async e => {
        const isError = handlIsError();
        if (isError) return;
        setQueueInProgress(true);
        try {
            if (walletAddress.trim().endsWith(".eth")) {
                setWalletAddress(walletAddress.trim());
            } else {
                setWalletAddress(getAddress(walletAddress.trim()));
            }
            await addToPayoutQueue();
            setToken({
                ...tokensInSafe[nativeTokenSymbol],
            });
            setOpenAddInfo(false);
            setTag("");
            setPayoutCategory("");
            setPayoutComment("");
            setSelectedTags([]);
            setSearchTerm("");
        } catch (error) {
            console.log({ error });
            const ErrorMap = {
                ...errorMap,
            };
            ErrorMap.walletAddress = "Invalid recipient Address / ENS";
            setError(ErrorMap);
        } finally {
            setQueueInProgress(false);
        }
    };
    const handleAddAddress = e => {
        let nickName = data.contributors.filter(
            contributor => contributor.address == e.target.value,
        );

        setWalletAddress(e.target.value);
        setWalletNickName(nickName.length > 0 ? nickName[0].nickName : "");
    };

    return (
        <Box className="recipient-box">
            <ModuleHeading>Quick Transfer</ModuleHeading>
            <Stack className="transfer-form-holder" marginTop={"17px"}>
                <InputLabel className="label-text" shrink={false}>
                    Recipient Wallet Address/ENS
                </InputLabel>
                <SearchContributors
                    walletAddress={walletAddress}
                    setWalletAddress={setWalletAddress}
                    walletNickName={walletNickName}
                    setWalletNickName={setWalletNickName}
                    errorMap={errorMap}
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    setOpenAddPeopleModal={setOpenAddPeopleModal}
                />
                {/* <Divider /> */}
                {/* <InputComponent
                    sx={{
                        background: "#F5F6F7",
                        color: "#25274F",
                    }}
                    value={walletAddress}
                    onChange={e => handleAddAddress(e)}
                    placeholder="Wallet Address/ENS"
                    label={"Wallet Address/ENS"}
                    startAdornment={
                        <InputAdornment position="start">
                            <StampAvatar
                                containerStyles={{ marginRight: 0 }}
                                seed={walletAddress || "0x"}
                                size={24}
                            />
                        </InputAdornment>
                    }
                    inputStyles={{ background: "#F5F6F7", color: "#636B81", border: "none" }}
                />
                {errorMap.walletAddress && (
                    <Typography marginTop={"5px"} fontSize={12} color={"#EE365A"}>
                        {errorMap.walletAddress}
                    </Typography>
                )}
                <InputComponent
                    sx={{
                        background: "#F5F6F7",
                        color: "#636B81",
                    }}
                    value={walletNickName}
                    onChange={e => setWalletNickName(e.target.value)}
                    placeholder="Person Name"
                    label={"Person Name"}
                    inputStyles={{ background: "#F5F6F7", color: "#636B81", border: "none" }}
                />
                {errorMap.walletNickName && (
                    <Typography marginTop={"5px"} fontSize={12} color={"#EE365A"}>
                        {errorMap.walletNickName}
                    </Typography>
                )} */}
                <StyledAutoCompleteWrapper sx={{ flex: "3" }}>
                    <InputLabel className="label-text" shrink={false}>
                        Token
                    </InputLabel>
                    <StyledAutoComplete
                        disablePortal
                        isOptionEqualToValue={(option: any, value: any) =>
                            option.name === value.name
                        }
                        onChange={(event: any, value: any) => {
                            setTokenSymbol(value.symbol);
                            setTokenDecimal(value.decimals);
                            setTokenValue("");
                            setTokenValueInUSD("");

                            setSelectedToken(value.tokenAddress);
                            setToken({
                                ...value,
                            });
                        }}
                        value={token}
                        disableClearable={true}
                        options={tokensInSafe ? Object.values(tokensInSafe) : []}
                        filterOptions={(option: any, state: any) => {
                            if (state.inputValue) {
                                return option.filter(
                                    ({ name, symbol }) =>
                                        symbol
                                            .toLowerCase()
                                            .indexOf(state.inputValue.toLowerCase()) > -1 ||
                                        name.toLowerCase().indexOf(state.inputValue.toLowerCase()) >
                                            -1,
                                );
                            } else {
                                return option;
                            }
                        }}
                        getOptionLabel={(option: any) => option.name}
                        autoComplete={false}
                        // getOptionDisabled={(option: any) => {
                        //     return token.fixedUsd && option.fiatConversion === 0;
                        // }}
                        renderOption={(props, option: any) => (
                            <Box display="flex" alignItems="center" {...props} component="li">
                                <Box
                                    component="img"
                                    src={option.logoUri}
                                    width="14px"
                                    minWidth="14px"
                                    maxWidth="14px"
                                    flex={"14px"}
                                    onError={({ currentTarget }) => {
                                        currentTarget.onerror = null; // prevents looping
                                        currentTarget.src = UndiscoveredCoin.src;
                                    }}
                                />
                                {/* <Typography
                                                fontSize="14px"
                                                color="title.main"
                                                fontWeight={500}
                                                ml="6px"
                                            >
                                                {option.name}
                                            </Typography> */}
                                <Typography
                                    fontSize="14px"
                                    lineHeight={1}
                                    color="#25274F"
                                    fontWeight={400}
                                    ml="12px"
                                >
                                    {option.name}
                                </Typography>

                                <Typography
                                    fontSize="12px"
                                    lineHeight={1}
                                    color="#25274F"
                                    fontWeight={200}
                                    ml="12px"
                                >
                                    {option.symbol}
                                </Typography>

                                <div style={{ marginLeft: "auto" }}>
                                    {option.tokenAddress !== nativeTokenSymbol &&
                                        minifyAddress(option.tokenAddress)}
                                </div>
                            </Box>
                        )}
                        renderInput={params => {
                            return (
                                <TextField
                                    {...params}
                                    name="token"
                                    placeholder="Select Token"
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: "off",

                                        // disable autocomplete and autofill
                                    }}
                                    InputProps={{
                                        ...params.InputProps,
                                        startAdornment: (
                                            <>
                                                {
                                                    <Box
                                                        component="img"
                                                        src={token?.logoUri}
                                                        {...params}
                                                        width="18px"
                                                        borderRadius="50%"
                                                        mr="10px"
                                                        bgcolor="#C4C4C4"
                                                        onError={({ currentTarget }) => {
                                                            currentTarget.onerror = null; // prevents looping
                                                            currentTarget.src =
                                                                UndiscoveredCoin.src;
                                                        }}
                                                    />
                                                }
                                                {params.InputProps.startAdornment}
                                            </>
                                        ),
                                    }}
                                />
                            );
                        }}
                    />
                </StyledAutoCompleteWrapper>
                {/* <InputComponent
                    type="select"
                    value={selectedToken}
                    options={Object.values(tokensInSafe).map((token: any) => {
                        return (
                            <MenuItem
                                sx={{
                                    fontSize: "14px",
                                    height: "40px",
                                    width: "100%",
                                    justifyContent: "space-between",
                                }}
                                key={token.tokenAddress || nativeTokenSymbol}
                                value={token.tokenAddress || nativeTokenSymbol}
                                onClick={() => {
                                    setTokenSymbol(token.symbol);
                                    setTokenDecimal(token.decimals);
                                    setTokenValue("");
                                    setTokenValueInUSD("");
                                }}
                            >
                                <Box sx={{ display: "flex" }}>
                                    <img
                                        width={20}
                                        height={20}
                                        src={token.logoUri || ""}
                                        alt={selectedToken}
                                        className="token-logo"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = UndiscoveredCoin.src;
                                        }}
                                    />
                                    <Typography
                                        fontWeight={500}
                                        color="#232427"
                                        marginLeft={"10px"}
                                        style={{ color: "#636B81" }}
                                    >
                                        {" "}
                                        {token.name}
                                    </Typography>
                                    <Typography id="hide" color="#9998A4" marginLeft={"15px"}>
                                        {token.symbol}
                                    </Typography>
                                </Box>

                                {token.symbol !== nativeTokenSymbol ? (
                                    <Typography id="hide" sx={{}} fontSize={12} color="#9998A4">
                                        ({minifyAddress(token.tokenAddress)})
                                    </Typography>
                                ) : null}
                            </MenuItem>
                        );
                    })}
                    onChange={e => {
                        setSelectedToken(e.target.value);
                        setTokenValue("");
                        setTokenValueInUSD("");
                    }}
                    placeholder="Token"
                    name="token"
                    label={"Token"}
                /> */}
                <Stack
                    direction={"row"}
                    justifyContent={"space-between"}
                    spacing={2}
                    className="value-stack"
                >
                    <CurrencyBox>
                        <InputComponent
                            type="number"
                            key={tokensInSafe[selectedToken]?.symbol}
                            value={tokenValue.toString()}
                            placeholder={"0"}
                            onChange={e =>
                                handleTokenValueChange(
                                    e.target.value,
                                    tokensInSafe[selectedToken]?.decimals,
                                )
                            }
                            label={tokensInSafe[selectedToken]?.symbol}
                            flex={1}
                            inputStyles={{
                                background: "#F5F6F7",
                                color: "#636B81",
                                border: "none",
                            }}
                        />
                        <HelperText>
                            Max {formatAmount(balanceOfSelectedToken, 0, 6)}{" "}
                            {tokensInSafe[selectedToken]?.symbol}
                        </HelperText>
                        {errorMap.tokenValue && (
                            <Typography marginTop={"5px"} fontSize={12} color={"#EE365A"}>
                                {errorMap.tokenValue}
                            </Typography>
                        )}
                    </CurrencyBox>
                    <CurrencyBox>
                        <InputComponent
                            type="number"
                            key={tokensInSafe[selectedToken]?.fiatConversion}
                            value={tokenValueInUSD}
                            placeholder={"0"}
                            onChange={handleTokenValueInUSDChange}
                            label={"USD"}
                            flex={1}
                            inputStyles={{
                                background: "#F5F6F7",
                                color: "#636B81",
                                border: "none",
                            }}
                            disabled={tokensInSafe[selectedToken]?.fiatConversion === 0}
                        />
                        <HelperText>
                            Max $
                            {formatAmount(
                                +balanceOfSelectedToken *
                                    tokensInSafe[selectedToken]?.fiatConversion,
                                2,
                                2,
                            )}
                        </HelperText>
                    </CurrencyBox>
                </Stack>
                {!openAddInfo ? (
                    <AddInfoBox onClick={() => setOpenAddInfo(true)}>
                        <Add />
                        <InfoCard>
                            <Typography className="title">Add Info</Typography>
                            <Typography className="subtitle">
                                Add info to describe the payout, which will be used for reporting.{" "}
                            </Typography>
                        </InfoCard>
                    </AddInfoBox>
                ) : (
                    <AddInfoInputBox>
                        <Box className="heading">
                            <Typography fontWeight={600} color={"#25274F"}>
                                Add Info
                            </Typography>
                            <RemoveButton onClick={() => setOpenAddInfo(false)}>
                                <img src="/deleteRemixIcon.svg" />
                                <Typography
                                    fontWeight={500}
                                    fontSize={"14px"}
                                    lineHeight={"14px"}
                                    color={"#F44040"}
                                >
                                    Remove
                                </Typography>
                            </RemoveButton>
                        </Box>
                        <Box className="input-row-1">
                            <SelectTagWrapper>
                                <Typography className="label">Tag</Typography>
                                <SearchFilterTags
                                    selectedTags={selectedTags}
                                    setSelectedTags={setSelectedTags}
                                    fromTransferModule={true}
                                />
                            </SelectTagWrapper>
                            <SelectBoxParcel
                                label={"Category"}
                                items={_categories.filter(({ isEnabled }) => Boolean(isEnabled))}
                                onChange={setPayoutCategory}
                                fullWidthProps
                                inputStyles={{ marginTop: "0px", marginRight: "0px" }}
                            ></SelectBoxParcel>
                        </Box>
                        <InputComponent
                            placeholder={"Write Comment..."}
                            label={"Comment"}
                            inputStyles={{
                                background: "#F5F6F7",
                                color: "#636B81",
                                border: "none",
                            }}
                            wrapperStyle={{
                                paddingTop: "0px",
                            }}
                            onChange={e => setPayoutComment(e.target.value)}
                        />
                    </AddInfoInputBox>
                )}
                {isEditOpen && (
                    <Box display="flex" justifyContent="space-between">
                        <Button
                            //disabled={disableAddToQ()}
                            className="add-queue-button"
                            startIcon={<DeleteOutlined />}
                            variant="contained"
                            onClick={resetQuickTxForm}
                        >
                            Delete
                        </Button>
                        <Button
                            sx={{ ml: 2 }}
                            disabled={disableAddToQ() || queueInProgress}
                            className="add-queue-button"
                            //startIcon={<Add />}
                            variant="outlined"
                            onClick={e => {
                                handleAddToPayoutQueue(e);
                                setSimulateState("begins");
                            }}
                        >
                            {queueInProgress ? "Adding to Queue..." : "Confirm Edit"}
                        </Button>
                    </Box>
                )}
                {!isEditOpen && (
                    <Button
                        disabled={queueInProgress}
                        className="add-queue-button"
                        startIcon={<Add />}
                        variant="outlined"
                        onClick={e => {
                            ga.gaEvent({
                                action: "Clicked",
                                category: "Quick Transfer",
                                label: "Add to Queue",
                            });
                            handleAddToPayoutQueue(e);
                            setSimulateState("begins");
                        }}
                    >
                        {queueInProgress ? "Adding to Queue..." : "Add to queue"}
                    </Button>
                )}
            </Stack>
        </Box>
    );
};

export default RecipientBlock;
