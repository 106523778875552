import React, { useContext, useState, useEffect } from "react";
import { PayrollContext } from "src/contexts/PayrollContext";

import {
    AddToQueueButton,
    TagSelectorBoxWrapper,
    TagsBox,
    Badge,
    SelctionBox,
    SelectedBadge,
} from "./styles/TagSelectorBox";
import { useGetTags } from "src/queries/PeopleSection/api";
import SafeContext from "src/contexts/SafeContext";
import DoneIcon from "@mui/icons-material/Done";
import { Button } from "@mui/material";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";

const TagSelectorBox = ({ hideTagSelectorBox }) => {
    const { selectedTags, setSelectedTags }: any = useContext(MassPayoutContext);

    const { currentSafeAddress }: any = useContext(SafeContext);
    const [allTags, setAllTags] = useState([]);
    const { data } = useGetTags(currentSafeAddress, "");

    useEffect(() => {
        const items = data?.map(item => item.tagName) || [];
        setAllTags(items);
    }, [data]);

    const addToSelectTag = tag => {
        const selected = [...selectedTags];
        selected.push(tag);
        setSelectedTags(selected);
    };

    const removeFromSelectTag = tag => {
        const selected = selectedTags.filter(item => item !== tag);
        setSelectedTags(selected);
    };
    return (
        <TagSelectorBoxWrapper>
            <div className="heading">Select Tags</div>
            <div className="subheading">Select some tags to begin a Mass payout</div>
            <TagsBox>
                <SelctionBox>
                    {allTags.length > 0 &&
                        allTags.map(tag => {
                            if (selectedTags.includes(tag)) {
                                return (
                                    <SelectedBadge
                                        onClick={() => removeFromSelectTag(tag)}
                                        label={tag}
                                        icon={<DoneIcon color="primary" />}
                                        key={tag}
                                    ></SelectedBadge>
                                );
                            } else
                                return (
                                    <Badge
                                        onClick={() => addToSelectTag(tag)}
                                        label={tag}
                                        key={tag}
                                    ></Badge>
                                );
                        })}
                </SelctionBox>
            </TagsBox>
            <div style={{ marginTop: "10px" }}>
                <Button
                    variant="contained"
                    fullWidth
                    disabled={!selectedTags.length}
                    onClick={hideTagSelectorBox}
                >
                    Continue
                </Button>
            </div>
        </TagSelectorBoxWrapper>
    );
};

export default TagSelectorBox;
