import {
    chainIds,
    getGnosisURL,
    getNativeTokenName,
    getNativeTokenSymbol,
    networkId,
} from "src/helpers/utils/networks";
export const ALLPAYMENT_TYPES = [
    "Reimbursement",
    "Compensation",
    "Expenses",
    "Bounty",
    "Miscellaneous",
];
export const ZERO_ADDRESS = "0x0000000000000000000000000000000000000000";
export const gnosisURL = `${getGnosisURL(networkId)}/api/v1`;
export const BASE_URL_FOR_INVITE_LINK = process.env.NEXT_PUBLIC_INVITE_LINK_FRONTEND;

export const nativeTokenSymbol = getNativeTokenSymbol(networkId);
export const nativeTokenName = getNativeTokenName(networkId);

export const GET_GNOSIS_SAFES = "safes/get-gnosis-safes";
export const GET_PARCEL_SAFES = "safes/get-parcel-safes";
export const GET_SAFE_DETAILS = "safes/get-safe-details";
export const GET_GNOSIS_SAFE_DETAILS = "safes/get-gnosis-safe-details";
export const GET_TOKEN = "auth/get-jwt-token";
export const SET_NEW_SAFE = "auth/set-new-safe";
export const AUTH_TOKEN_CHECK = "auth/check-token-validation";
export const GET_CHILD_SAFES_DATA = "safes/get-child-safes-data";
// Queries
export const GET_CONTRIBUTOR = "get-contributor";
export const GET_TAGS = "get-tags";

export const GET_DAOS_BY_CONTRIBUTOR = "get-daos-by-contributor";
export const GET_TOKENS_BY_SAFE = "get-token-by-safe";
export const GET_TOKENS_BY_SAFE_OF_CONTRIBUTOR = "get-tokens-by-safe-of-contributor";
export const GET_REQUESTS_BY_CONTRIBUTOR = "get-requests-by-contributor";
export const GET_REQUEST_DETAIL_BY_REQUESTID = "get-request-detail-by-requestid";
export const GET_REQUEST_DETAIL_BY_TRANSACTIONID = "get-request-detail-by-transactionid";

export const GET_TOKEN_IMAGE_BY_TOKEN_ADDRESS = "token-image-by-token-address";
export const GET_TOKEN_USD_CONVERSION_BY_TOKEN_ADDRESS = "token-usd-conversion-by-token-address";
export const GET_CURRENT_SAFE_DETAILS = "get-current-safe-details";
export const GET_SAFE_DELEGATES = "get-safe-delegates";
export const ADD_DELEGATE = "ADD_DELEGATE";
export const ADD_DELEGATE_ON_GNOSIS = "ADD_DELEGATE_ON_GNOSIS";
export const DELETE_DELEGATE_ON_GNOSIS = "DELETE_DELEGATE_ON_GNOSIS";

export const GET_REQUESTS_BY_SAFE = "get-requests-by-safe";
export const GET_INVITE_REQUESTS_BY_SAFE = "get-invite-requests-by-safe";
export const GET_TRANSACTION_BY_ID = "get-transaction-by-id";

export const GET_PAYROLL_BY_SAFE = "get-payroll-by-safe";
export const UPDATE_PAYROLL_WORKFLOW = "update-payroll-workflow";
export const DELETE_PAYROLL_BY_ID = "delete_payroll_by_id";

export const FETCH_ACTIVE_BUDGETS_BY_SQUADS = "FETCH_ACTIVE_BUDGETS_BY_SQUADS";
export const FETCH_PENDING_BUDGETS_BY_SQUADS = "FETCH_PENDING_BUDGETS_BY_SQUADS";
export const FETCH_HISTORY_BUDGETS_BY_SQUADS = "FETCH_HISTORY_BUDGETS_BY_SQUADS";
export const FETCH_BUDGET_BY_ID = "FETCH_BUDGET_BY_ID";

export const GET_MILESTONE_BY_ID = "GET_MILESTONE_BY_ID";

export const GET_BUDGETS_BY_STATUS = "GET_BUDGETS_BY_STATUS";
export const GET_UPCOMING_MILESTONES_BYPARENT = "GET_UPCOMING_MILESTONES_BYPARENT";
export const GET_BUDGET_ACTIVITY = "GET_BUDGET_ACTIVITY";
// Mutations
export const ADD_CONTRIBUTOR = "add-contributor";
export const EDIT_CONTRIBUTOR = "edit-contributor";
export const REMOVE_CONTRIBUTOR = "remove-contributor";
export const BULK_REMOVE_CONTRIBUTOR = "bulk-remove-contributor";
export const ADD_TAG_TO_CONTRIBUTOR = "add-tag-to-contributor";
export const REMOVE_TAG_TO_CONTRIBUTOR = "remove-tag-to-contributor";
export const ADD_TAG_TO_SAFE = "add-tag-to-safe";
export const ADD_CONTRIBUTOR_REQUEST = "add-contributor-request";
export const EDIT_WALLET = "edit-wallet";
export const UPDATE_REQUEST_STATUS_BY_ID = "update-request-status-by-id";
export const BULK_UPDATE_REQUEST_STATUS_BY_IDS = "bulk-update-request-status-by-ids";
export const EDIT_WALLET_AS_SAFE_OWNER = "edit-wallet-as-safe-owner";
export const CREATE_WORKFLOW = "create-workflow";
export const UPDATE_PAYROLL_IMPLEMENTATION = "update-payroll-implementation";
export const UPDATE_PAYROLL_WORKFLOW_STATUS = "update-payroll-status";
export const UPDATE_PAYMENT_CATEGORY_BY_NONCE = "update-payment-category-by-nonce";
export const UPDATE_TXN_DISBURSEMENT_DETAILS = "update-txn-disbursement-details";
export const UPDATE_TXN_ID_TO_BUDGET = "update-txn-id-to-budget";

export const EDIT_SAFE = "edit-safe";

export const CREATE_NEW_TRANSACTION = "create-new-transaction";
export const UPDATE_NEW_TRANSACTION = "update-transaction";
export const BULK_ADD_CONTRIBUTOR_WITH_TAGS = "add-bulk-contributor-with-tags";
export const BULK_UPDATE_REQUEST_TRANSACTION_ID = "bulk-update-request-transaction-id";
export const UPDATE_INVITE_STATUS = "update-invite-status";
export const UPDATE_WHITELIST_TOKENS = "update-whitelist-tokens";
export const UPDATE_CUSTOM_CATEGORY = "update-custom-category";
export const CREATE_NEW_BUDGET_REQUEST = "create-new-budget-request";
export const ADD_NEW_MILESTONE = "add-new-milestone";
export const EDIT_MILESTONE = "edit-milestone";
export const CREATE_NEW_ACTIVITY = "CREATE_NEW_ACTIVITY";
export const UPDATE_MILESTONE_ACTIVITY = "UPDATE_MILESTONE_ACTIVITY";
export const UPDATE_MILESTONE_STATUS_BYTX = "UPDATE_MILESTONE_STATUS_BYTX";
export const UPDATE_BUDGET_STATUS = "UPDATE_BUDGET_STATUS";
export const UPDATE_BUDGET_META = "UPDATE_BUDGET_META";
export const DELETE_MILESTONE_BY_ID = "delete-milestone-by-id";
export const MARK_ACTIVITY_AS_VIEWED = "mark-activity-as-viewed";
export const UPDATE_SPAM_TOKENS = "update-spam-tokens";
export const UPDATE_SPAM_TRANSACTIONS = "update-spam-transactions";
export const UPDATE_SPAM_SETTINGS = "update-spam-settings";
export const FETCH_TOKEN_CONVERSION_ENDPOINT = "/api/fetch-tokenConversion";

//backend url
export const BACKEND_BASE_URL = process.env.NEXT_PUBLIC_BACKEND_URL;
export const baseURI = `${process.env.NEXT_PUBLIC_BACKEND_URL}/graphql`;
export const baseURLFileUpload = `${process.env.NEXT_PUBLIC_BACKEND_URL}/contributor/get-signed-url`;
export const baseURLForCheckingMetaTxLimits = `${process.env.NEXT_PUBLIC_BACKEND_URL}/utils/checklimits`;
export const baseURLForPushingMetaTransaction = `${process.env.NEXT_PUBLIC_BACKEND_URL}/meta-tx/push-transaction`;
export const metaTxResubmitEndpoint = "https://api.biconomy.io/api/v1/meta-tx/resubmitted?";
export const baseURLForSyncWithGnosis = `${process.env.NEXT_PUBLIC_BACKEND_URL}/safe/update-safe`;
export const baseURLToGenerateInviteLink = `${process.env.NEXT_PUBLIC_BACKEND_URL}/invite/generateLink`;
export const baseURLToSendEmail = `${process.env.NEXT_PUBLIC_BACKEND_URL}/utils/send-email`;
export const baseURLToSimulate = `${process.env.NEXT_PUBLIC_BACKEND_URL}/utils/simulation`;

// Logo URIs
export const NATIVE_ICON_URL =
    networkId === chainIds.POLYGON
        ? "https://storage.googleapis.com/zapper-fi-assets/tokens/polygon/0x0000000000000000000000000000000000000000.png"
        : "https://storage.googleapis.com/zapper-fi-assets/tokens/ethereum/0x0000000000000000000000000000000000000000.png";

//Integrations
export const GET_COORDINAPE_TIME = "get-coordinape-time";
export const GET_COORDINAPE_TOKEN = "get-coordinape-token";
export const GET_COORDINAPE_DATA = "get-coordinape-data";
export const GET_COORDINAPE_DATA_BY_CIRCLE = "get-coordinape-data-by-circle";
export const GET_COORDINAPE_CSV_DATA = "get-coordinape-csv-data";

export const WHITE_LISTED_SAFES_FOR_INTEGRATION = ["0xECBb0bF401DA50f2588A9DE9E9a5EBC996B130D2"];
export const PAYMENT_CATEGORIES = [
    { label: "Compensation", value: "Compensation" },
    { label: "Reimbursement", value: "Reimbursement" },
    { label: "Expenses", value: "Expenses" },
    { label: "Grant", value: "Grant" },
    { label: "Bounty", value: "Bounty" },
    { label: "Miscellaneous", value: "Miscellaneous" },
    { label: "Other", value: "Other" },
    { label: "Payroll", value: "Payroll" },
];

export const PAYMENT_TYPES = [
    "Reimbursement",
    "Compensation",
    "Expenses",
    "Bounty",
    "Miscellaneous",
];

export const RELEASE_VERSION = "V2.3.5"; // This is important we will append local storage key with RELEASE_VERSION
