import { truncate } from "./utils/web3Utils";
import { validNumberString, finiteNumber, roundOff } from "./utils/common";
import Decimal from "decimal.js-light";
const MIN_DIFF = 3;
export const getPayoutDetails = payoutQueueByTag => {
    const array = [];
    Object.entries(payoutQueueByTag).forEach(([tagName, payouts]: [string, any]) => {
        payouts.forEach(({ address, selectedTokens }) => {
            selectedTokens.forEach(({ amount, token, fixedUsd, fiatValue }) => {
                array.push({
                    to: address,
                    tag: tagName,
                    tokenSymbol: token.symbol,
                    tokenAddress: token.tokenAddress,
                    fiatValue: Number(validNumberString(fiatValue)),
                    fixedUsd: Boolean(fixedUsd),
                    amount: Number(validNumberString(amount)),
                });
            });
        });
    });
    return array;
};

export const getPayoutGroupByTag = (payouts, tokensInSafe) => {
    const payout = {};
    payouts?.forEach(
        ({ tag, to, tokenSymbol, recipient, amount, tokenAddress, fixedUsd, fiatValue }, index) => {
            if (payout[tag]) {
                const index = payout[tag].findIndex(({ address }) => address === to);
                if (index === -1)
                    payout[tag].push({
                        address: to,
                        nickName: recipient?.nickName,
                        name: recipient?.name,
                        ens: recipient?.ens,
                        selectedTokens: [
                            {
                                token: {
                                    symbol: tokenSymbol,
                                    tokenAddress,
                                    name: tokensInSafe[tokenAddress]?.name,
                                    decimals: tokensInSafe[tokenAddress]?.decimals,
                                    logoUri: tokensInSafe[tokenAddress]?.logoUri,
                                    fiatConversion: tokensInSafe[tokenAddress]?.fiatConversion,
                                },
                                errors: { amount: "", fiatValue: "", token: "" },
                                amount: fixedUsd
                                    ? tokensInSafe[tokenAddress]?.fiatConversion == 0
                                        ? 0
                                        : roundOff(
                                              new Decimal(
                                                  validNumberString(
                                                      fiatValue,
                                                      true,
                                                      tokensInSafe[tokenAddress]?.decimals || 18,
                                                  ),
                                              ).dividedBy(
                                                  new Decimal(
                                                      finiteNumber(
                                                          validNumberString(
                                                              tokensInSafe[tokenAddress]
                                                                  ?.fiatConversion,
                                                          ),
                                                      ),
                                                  ),
                                              ),
                                              tokensInSafe[tokenAddress]?.decimals,
                                          )
                                    : roundOff(
                                          new Decimal(amount),
                                          tokensInSafe[tokenAddress]?.decimals,
                                      ),
                                fiatValue: fixedUsd
                                    ? roundOff(new Decimal(fiatValue))
                                    : roundOff(
                                          new Decimal(amount).times(
                                              new Decimal(
                                                  validNumberString(
                                                      tokensInSafe[tokenAddress]?.fiatConversion,
                                                  ),
                                              ),
                                          ),
                                      ),

                                fixedUsd,
                            },
                        ],
                    });
                else
                    payout[tag][index].selectedTokens?.push({
                        token: {
                            symbol: tokenSymbol,
                            tokenAddress,
                            name: tokensInSafe[tokenAddress]?.name,
                            decimals: tokensInSafe[tokenAddress]?.decimals,
                            logoUri: tokensInSafe[tokenAddress]?.logoUri,
                            fiatConversion: tokensInSafe[tokenAddress]?.fiatConversion,
                        },
                        amount: fixedUsd
                            ? tokensInSafe[tokenAddress]?.fiatConversion == 0
                                ? 0
                                : roundOff(
                                      new Decimal(validNumberString(fiatValue)).dividedBy(
                                          new Decimal(
                                              finiteNumber(
                                                  validNumberString(
                                                      tokensInSafe[tokenAddress]?.fiatConversion,
                                                  ),
                                              ),
                                          ),
                                      ),
                                      tokensInSafe[tokenAddress]?.decimals,
                                  )
                            : roundOff(
                                  new Decimal(amount || 0),
                                  tokensInSafe[tokenAddress]?.decimals,
                              ),
                        fiatValue: fixedUsd
                            ? roundOff(new Decimal(fiatValue))
                            : roundOff(
                                  new Decimal(amount).times(
                                      new Decimal(
                                          validNumberString(
                                              tokensInSafe[tokenAddress]?.fiatConversion,
                                          ),
                                      ),
                                  ),
                              ),
                        fixedUsd,
                        errors: { amount: "", fiatValue: "", token: "" },
                    });
            } else {
                payout[tag] = [
                    {
                        address: to,
                        nickName: recipient?.nickName,
                        name: recipient?.name,
                        ens: recipient?.ens,
                        selectedTokens: [
                            {
                                token: {
                                    symbol: tokenSymbol,
                                    tokenAddress,
                                    name: tokensInSafe[tokenAddress]?.name,
                                    decimals: tokensInSafe[tokenAddress]?.decimals,
                                    logoUri: tokensInSafe[tokenAddress]?.logoUri,
                                    fiatConversion: tokensInSafe[tokenAddress]?.fiatConversion,
                                },
                                amount: fixedUsd
                                    ? tokensInSafe[tokenAddress]?.fiatConversion == 0
                                        ? 0
                                        : roundOff(
                                              new Decimal(validNumberString(fiatValue)).dividedBy(
                                                  new Decimal(
                                                      finiteNumber(
                                                          validNumberString(
                                                              tokensInSafe[tokenAddress]
                                                                  ?.fiatConversion,
                                                          ),
                                                      ),
                                                  ),
                                              ),
                                              tokensInSafe[tokenAddress]?.decimals,
                                          )
                                    : roundOff(
                                          new Decimal(amount || 0),
                                          tokensInSafe[tokenAddress]?.decimals,
                                      ),
                                fiatValue: fixedUsd
                                    ? roundOff(new Decimal(validNumberString(fiatValue)))
                                    : roundOff(
                                          new Decimal(amount || 0).times(
                                              new Decimal(
                                                  validNumberString(
                                                      tokensInSafe[tokenAddress]?.fiatConversion,
                                                  ),
                                              ),
                                          ),
                                      ),
                                fixedUsd,
                                errors: { amount: "", fiatValue: "", token: "" },
                            },
                        ],
                    },
                ];
            }
        },
    );
    return payout;
};

const withoutTime = d => {
    d.setHours(0, 0, 0, 0);
    return d;
};

const getDay = startDate => {
    return new Date(startDate).getDate();
};

const getMonthInString = startDate => {
    return new Date(startDate).toLocaleString("default", { month: "long" });
};

const getFullYear = startDate => {
    return new Date(startDate).getFullYear();
};

const nextDay = (x, startDate) => {
    const date = new Date(startDate);
    date.setDate(date.getDate() + ((x + (7 - date.getDay())) % 7));
    return date;
};

const next15Days = startDate => {
    const date = new Date(startDate);
    date.setDate(new Date(startDate).getDate() + 15);
    return date;
};
const nth = function (d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
        case 1:
            return "st";
        case 2:
            return "nd";
        case 3:
            return "rd";
        default:
            return "th";
    }
};
export const calculateDueDate = ({ lastTransaction, scheduleType, scheduleValue, startDate }) => {
    let showDue = false;
    let dueIn = null;
    let nextDue = "";
    const transactionDay = parseInt(scheduleValue);

    if (scheduleType === "monthly") {
        // Find Next occurence from start day
        let transactionDate = new Date(
            new Date().getFullYear(),
            new Date().getMonth(),
            transactionDay,
        );

        while (withoutTime(new Date(startDate)) >= transactionDate) {
            transactionDate = new Date(
                new Date().getFullYear(),
                new Date(startDate).getMonth() + 1,
                transactionDay,
            );
        }

        // If last transaction is less than 28 days from occurence, add 1 month
        if (lastTransaction) {
            const lastTransactionDate = new Date(lastTransaction);

            const lastTransactionDiff = Math.ceil(
                ((transactionDate as any) - (lastTransactionDate as any)) / (1000 * 60 * 60 * 24),
            );

            if (lastTransactionDiff <= 28) {
                transactionDate = new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() + 1,
                    transactionDay,
                );
            }
        }

        // if next occurence is with 3 days, show warning due
        const diff: any = Math.ceil(
            ((transactionDate as any) - (new Date() as any)) / (1000 * 60 * 60 * 24),
        );

        if (diff >= -MIN_DIFF && diff < MIN_DIFF) {
            showDue = true;
            dueIn = diff;
        } else {
            // else show regular due
            nextDue = `Next Due on
                                ${getDay(transactionDate)}${nth(
                getDay(transactionDate),
            )} ${getMonthInString(transactionDate)} ${getFullYear(transactionDate)}`;
        }
    }

    if (scheduleType === "weekly") {
        // Find next occurence from start day
        let transactionDate = nextDay(
            transactionDay,
            startDate > new Date() ? startDate : new Date(),
        );

        if (withoutTime(new Date(startDate)) >= transactionDate) {
            transactionDate = nextDay(transactionDay, startDate);
        } else {
            transactionDate = nextDay(transactionDay, new Date());
        }

        // If last transaction is less than 7 days from occurence, add 1 week to occurence
        if (lastTransaction) {
            const lastTransactionDate = new Date(lastTransaction);

            const lastTransactionDiff = Math.ceil(
                ((transactionDate as any) - (lastTransactionDate as any)) / (1000 * 60 * 60 * 24),
            );

            if (lastTransactionDiff <= 6) {
                let added7Days = new Date();
                added7Days.setDate(transactionDate.getDate() + 7);
                transactionDate = added7Days;
            }
        }

        // if occurence is around 3 days, show warning due
        const diff: any = Math.ceil(
            ((transactionDate as any) - (new Date() as any)) / (1000 * 60 * 60 * 24),
        );

        if (diff >= -MIN_DIFF && diff < MIN_DIFF) {
            showDue = true;
            dueIn = diff;
        } else {
            //  else show normal due
            nextDue = `Next Due on
                        ${getDay(transactionDate)}${nth(
                getDay(transactionDate),
            )} ${getMonthInString(transactionDate)} ${getFullYear(transactionDate)}
                         `;
        }
    }

    if (scheduleType === "fortnightly") {
        let transactionDate = new Date();

        if (withoutTime(new Date(startDate)) > transactionDate) {
            transactionDate = new Date(
                new Date().getFullYear(),
                new Date(startDate).getMonth(),
                new Date(startDate).getDay() + 15,
            );
        } else {
            transactionDate = next15Days(startDate);
        }

        const diff: any = Math.ceil(
            ((transactionDate as any) - (new Date() as any)) / (1000 * 60 * 60 * 24),
        );

        if (diff >= -MIN_DIFF && diff < MIN_DIFF) {
            //check if transaction already paid
            if (lastTransaction) {
                const lastTransactionDate = new Date(lastTransaction);

                const lastTransactionDiff = Math.ceil(
                    ((transactionDate as any) - (lastTransactionDate as any)) /
                        (1000 * 60 * 60 * 24),
                );

                if (lastTransactionDiff >= -MIN_DIFF && lastTransactionDiff <= MIN_DIFF) {
                    const nextTransaction = new Date(
                        new Date().getFullYear(),
                        new Date().getMonth(),
                        transactionDate.getDate() + 15,
                    );

                    nextDue = `Next Due 
                            ${getDay(nextTransaction)}${nth(
                        getDay(nextTransaction),
                    )} ${getMonthInString(nextTransaction)} ${getFullYear(nextTransaction)}
                             `;
                } else {
                    showDue = true;
                    dueIn = diff;
                }
            } else {
                showDue = true;
                dueIn = diff;
            }
        } else {
            const nextTransaction = new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                transactionDate.getDate(),
            );

            nextDue = `Next Due 
                        ${getDay(nextTransaction)}${nth(
                getDay(nextTransaction),
            )} ${getMonthInString(nextTransaction)} ${getFullYear(nextTransaction)}
                         `;
        }
    }
    return { _showDue: showDue, _dueIn: dueIn, _nextDue: nextDue };
};
