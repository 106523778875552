import Decimal from "decimal.js-light";
import { validNumberString } from "src/helpers/utils/common";

const getData = (item, tokenData, contributors, total) => {
    const p = contributors?.contributors?.find(people => {
        return people.address.toLowerCase() === item?.recipient?.address.toLowerCase();
    });
    const percentage = parseInt(item.tokens) / total;
    const {
        amount = 0,
        fixedUsd,
        token: { fiatConversion, symbol, tokenAddress, decimals },
    } = tokenData;
    let usdValue,
        tokenValue = null;
    if (fixedUsd) {
        usdValue = amount * percentage;
        tokenValue = new Decimal(fiatConversion * percentage);
    } else {
        tokenValue = new Decimal(amount * percentage);
        usdValue = amount * fiatConversion * percentage;
    }
    return {
        tag: p ? p?.tags : [],
        tokenDecimal: decimals,
        tokenSymbol: symbol,
        tokenValue: tokenValue,
        tokenValueInUSD: usdValue,
        selectedToken: tokenAddress,
        walletAddress: p ? p?.address : item?.recipient?.address || null,
        walletENS: p ? p?.ens : item?.ens || false,
        walletNickName: p ? p?.nickName : item?.recipient?.name || null,
    };
};

export const transformDataForCoordinapeSimulation = (
    recipientData = [],
    payeeList = [],
    contributors,
    tokenInputList = [],
    paymentCategory,
) => {
    const selected = recipientData.filter(r => {
        return !!(r.recipient && r.recipient.address) && !!(r.tokens > 0);
    });

    let total = selected.reduce((n, item) => n + item.tokens, 0);

    const recipientFinalList = selected.filter(r =>
        payeeList.includes(r?.recipient?.address.toLowerCase()),
    );
    const result = [];
    try {
        recipientFinalList.forEach(item => {
            tokenInputList.forEach(tokenData => {
                const d = getData(item, tokenData, contributors, total);
                result.push({
                    payoutCategory: paymentCategory,
                    payoutComment: "",
                    ...d,
                });
            });
        });
    } catch (e) {
        console.log(e);
    }
    return result;
};

export const transformDataForIntegrationSimulation = (allPayeeDetails = [], tokensInSafe = {}) => {
    return allPayeeDetails?.map(data => {
        return {
            tokenDecimal: tokensInSafe[data.tokenAddress]?.decimals,
            tokenSymbol: data?.token,
            tokenValue: data?.tokenValue,
            tokenValueInUSD: data?.fiatValue,
            selectedToken: data?.tokenAddress,
            walletAddress: data?.address,
            walletENS: null,
            walletNickName: data?.name,
            tag: [],
        };
    });
};

export const transformDataForRecurringSimulation = (
    allPayeeDetails = [],
    tokensInSafe = {},
    description,
    category,
) => {
    return allPayeeDetails.map(({ data }) => {
        return {
            tokenDecimal: tokensInSafe[data.tokenAddress]?.decimals,
            tokenSymbol: data?.token,
            tokenValue: data?.amount,
            tokenValueInUSD: data?.fiatValue,
            selectedToken: data?.tokenAddress,
            walletAddress: data.address,
            walletENS: null,
            walletNickName: data?.name,
            description,
            category,
            tag: [],
        };
    });
};

export const transformDataForMassPayoutSimulation = transactions => {
    let payoutQueue = [];
    Object.values(transactions)
        .flat()
        .forEach((item: any) => {
            if (item?.selectedTokens?.length > 0) {
                item?.selectedTokens?.forEach(_ => {
                    payoutQueue.push({
                        payoutCategory: "",
                        payoutComment: "",
                        selectedToken: _?.token?.tokenAddress,
                        tag: item?.tags,
                        tokenDecimal: _?.token?.decimals,
                        tokenSymbol: _?.token.symbol,
                        tokenValue: _?.amount,
                        tokenValueInUSD: _?.fiatValue,
                        walletAddress: item?.address,
                        walletENS: false,
                        walletNickName: item?.nickName,
                    });
                });
            }
        });
    return payoutQueue;
};

export const transformDataForPaymentRequestSimulation = (payees, tokensInSafe) => {
    const result = [];

    payees.forEach(item => {
        const {
            disbursement = [],
            recipient = "",
            tag = [],
            description = "",
            paymentType = "",
            contributor = {},
            contributorAddress,
        } = item;

        let recipientNickName,
            recipientEns = "";
        if (contributor?.address?.toLowerCase() === recipient?.toLowerCase()) {
            recipientNickName = contributor?.nickName;
            recipientEns = contributor?.ens;
        }
        disbursement.forEach(token => {
            const { fiatValue = 0, fixedUsd = false, tokenAddress = "ETH", tokenValue = 0 } = token;
            let tokenValueInUSD,
                tokenVal = null;
            if (fixedUsd) {
                tokenVal =
                    tokensInSafe[tokenAddress]?.fiatConversion == 0
                        ? tokenValue || 0
                        : new Decimal(fiatValue).div(
                              new Decimal(
                                  validNumberString(tokensInSafe[tokenAddress].fiatConversion),
                              ),
                          );
                tokenValueInUSD = Number(validNumberString(fiatValue));
            } else {
                tokenVal = new Decimal(
                    validNumberString(tokenValue, true, tokensInSafe[tokenAddress]?.decimals || 18),
                );
                tokenValueInUSD =
                    Number(tokenValue) * Number(tokensInSafe[tokenAddress]?.fiatConversion);
            }
            result.push({
                payoutCategory: paymentType,
                payoutComment: description,
                selectedToken: tokenAddress,
                tag: tag,
                tokenDecimal: tokensInSafe[tokenAddress]?.decimals,
                tokenSymbol: tokensInSafe[tokenAddress]?.symbol,
                tokenValue: tokenVal,
                tokenValueInUSD: tokenValueInUSD,
                walletAddress: recipient || contributorAddress,
                walletENS: recipientEns,
                walletNickName: recipientNickName,
            });
        });
    });

    return result;
};
