import styled from "@emotion/styled";
import { Box, Typography } from "@mui/material";

export const ProfileWrapper = styled(Box)`
    display: flex;
    align-items: center;
    margin-left: 7px;
    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 8px;
        padding: 0px 0px;

        & div {
            margin-left: 0px !important;
        }
    }

    .identicon {
        border-radius: 50%;
    }
`;

export const TwitterHolder = styled(Box)`
    display: flex;
    align-items: center;
    .twitter-icon {
        align-self: center;
    }
`;

export const NameAddressWrapper = styled(Box)`
    & > div,
    & > div > div {
        margin-left: 0 !important;
    }
`;
export const NameText = styled(Typography)`
    font-weight: 600;
    font-size: 14px;
    line-height: 1;
    color: ${({ theme }: any) => theme.palette.title.main};
`;
export const AddressText = styled(Typography)`
    font-weight: 500;
    font-size: 13px;
    line-height: 1;
    color: ${({ theme }: any) => theme.palette.title.light};
    cursor: pointer;
    margin-top: 6px;
`;

export const StampHolder = styled(Box)`
    margin-right: 10px;
    display: flex;
    align-items: center;
    flex: 37px;
    min-width: 37px;
    max-width: 37px;
`;
