import * as Yup from "yup";

export const disbursmentSchema = balance => {
    return Yup.object().shape({
        token: Yup.object().shape({
            tokenAddress: Yup.string().required("Please select token"),
        }),
        amount: Yup.number()
            .min(0.000000000000000001, "Should be greater than accepted value")
            .required("Amount is required"),
        fiatValue: Yup.number()
            .required("Amount is required")
            .max(parseInt(balance), "Should not be greater than safe balance"),
    });
};
