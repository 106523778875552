import { useCallback, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import LoadingButton from "@mui/lab/LoadingButton";
import { useActiveBaseContext } from "src/hooks";
import metmask from "../public/svg/metamask.svg";
import coinbase from "../public/svg/coinbase.svg";
import trezor from "../public/svg/trezor.svg";
import walletconnect from "../public/svg/walletconnect.svg";
import Image from "next/image";
import Typography from "@mui/material/Typography";
import OnBoardButton from "src/components/Buttons/OnBoardButton";
import { useRouter } from "next/router";
import { useRouteChange } from "src/hooks";
import { BaseContextTypes } from "src/contexts/BaseContextTypes";
import { set } from "lodash";

type SupportedWalletName =
    | "MetaMask"
    | "Coinbase Wallet"
    | "WalletConnect"
    | "Ledger"
    | "Trezor"
    | "BrowserWallet";

export default function Home() {
    const {
        account,
        setIsPrevWallet,
        isLoggedIn,
        connector,
        isPrevWallet,
        connectWallet,
        walletConnecting,
    } = useActiveBaseContext().web3 as BaseContextTypes;

    const [selectedWallet, setSelectedWallet] = useState<SupportedWalletName>();
    const { pageLoading } = useRouteChange();
    const router = useRouter();

    useEffect(() => {
        if (isLoggedIn === false || !account || isPrevWallet === false) {
            router.replace("/");
            return;
        }
    }, [isLoggedIn, account, isPrevWallet]);

    const selectWallet = (wallet: SupportedWalletName) =>
        useCallback(async () => {
            try {
                setIsPrevWallet(true);
                setSelectedWallet(wallet);
                if (pageLoading) {
                    return;
                }

                if (account) {
                    router.push(`/onboard/select_role/${account}`);
                    window.localStorage.setItem("selectedWallet", connector.label);
                    return;
                }

                if (wallet === "BrowserWallet") {
                    await connectWallet();
                } else {
                    await connectWallet({
                        autoSelect: {
                            label: wallet,
                            disableModals: true,
                        },
                    });
                }
            } catch (error) {
                console.error(error);
                setSelectedWallet(undefined);
            }
        }, [connectWallet, account]);
    useEffect(() => {
        if (account) {
            router.push(`/onboard/select_role/${account}`);
            window.localStorage.setItem("selectedWallet", connector.label);
        }
    }, [account]);

    return (
        <Box maxWidth="433px" margin="10% auto">
            <Typography fontSize="1.5rem" fontWeight="700" color="title.main" textAlign="left">
                Welcome to Parcel
            </Typography>
            <Typography fontSize="14px" color="#636B81" fontWeight={500}>
                Connect your wallet to get started
            </Typography>
            <Stack direction="column" justifyContent="flex-start" spacing={2} mt={3}>
                <LoadingButton
                    variant="contained"
                    onClick={selectWallet("MetaMask")}
                    sx={{
                        height: "41px",
                        fontSize: "1rem",
                        textTransform: "none",
                    }}
                    startIcon={<Image src={metmask} alt="metamask icon" priority />}
                    loading={walletConnecting && selectedWallet == "MetaMask"}
                >
                    Metamask
                </LoadingButton>
                <OnBoardButton
                    variant="contained"
                    onClick={selectWallet("Coinbase Wallet")}
                    sx={{
                        height: "41px",
                    }}
                    startIcon={
                        <Image src={coinbase} alt="coinbase" height="14px" width="14px" priority />
                    }
                    loading={walletConnecting && selectedWallet == "Coinbase Wallet"}
                >
                    Coinbase
                </OnBoardButton>

                <OnBoardButton
                    variant="contained"
                    onClick={selectWallet("WalletConnect")}
                    sx={{
                        height: "41px",
                    }}
                    loading={walletConnecting && selectedWallet == "WalletConnect"}
                    startIcon={
                        <Image
                            src={walletconnect}
                            alt="walletconnect icon"
                            height="14px"
                            width="15px"
                            priority
                        />
                    }
                >
                    Walletconnect
                </OnBoardButton>

                <OnBoardButton
                    variant="contained"
                    onClick={selectWallet("Trezor")}
                    sx={{
                        height: "41px",
                    }}
                    loading={walletConnecting && selectedWallet == "Trezor"}
                    startIcon={
                        <Image src={trezor} alt="trezor icon" height="14px" width="14px" priority />
                    }
                >
                    Trezor
                </OnBoardButton>
                <OnBoardButton
                    variant="contained"
                    onClick={selectWallet("BrowserWallet")}
                    sx={{
                        height: "41px",
                    }}
                    loading={walletConnecting && selectedWallet == "BrowserWallet"}
                >
                    Other Browser Wallet
                </OnBoardButton>
            </Stack>
        </Box>
    );
}
