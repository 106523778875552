import { useEffect, useState, createContext, useContext } from "react";
import { getAddress } from "@ethersproject/address";
import { ethers } from "ethers";
import { useCheckAuth } from "src/queries/onboard/api";
import router from "next/router";
import { transactionServiceUrl } from "src/constants/endpoints";
import { useDisconnect } from "src/queries/onboard/api";
import { networkId } from "src/helpers/utils/networks";
import * as Sentry from "@sentry/nextjs";
import { queryClient } from "pages/_app";
import { AUTH_TOKEN_CHECK } from "src/queries/constants";
import { initWeb3Onboard } from "src/helpers/initOnBoard";
import { useConnectWallet, useSetChain } from "@web3-onboard/react";
import { EthersAdapter } from "@safe-global/protocol-kit";
import SafeApiKit from "@safe-global/api-kit";

// interface web3Type {
//   onboard?: any;
//   account?: string;
//   chainId?: number;
//   library?: Provider;
//   connector?: string;
//   active?: boolean;
// }
interface BaseAppContextInterface {
    web3: any;
}
export const BaseAppContext = createContext({
    web3: {},
} as BaseAppContextInterface);

export default function BaseAppContextProvider({ children }) {
    // const { setCoordinapeToken }: any = useContext(IntegrationContext);
    const [address, setAddress] = useState(null);
    const [network, setNetwork] = useState(networkId);
    const [{ wallet, connecting }, connect, disconnect] = useConnectWallet();
    const [{ connectedChain }, setChain] = useSetChain();
    const [provider, setProvider] = useState(null);
    const [ethersAdapter, setEthersAdapter] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(null);
    const [isPrevWallet, setIsPrevWallet] = useState(null);
    const [onboard, setOnboard] = useState(null);
    const [safeService, setSafeService] = useState(null);
    const [isNetworkChanged, setIsNetworkChanged] = useState(false);
    //taken wrong name actually it meant is Wallet a smart Contract.
    const [isWalletEOA, setIsWalletEOA] = useState(false);
    const [isGnosisWallet, setIsGnosisWallet] = useState(false);
    const [sybilData, setSybilData] = useState({});
    const [ensName, setENSName] = useState(null);
    const {
        data,
        isError,
        refetch: refetchAuth,
    } = useCheckAuth({
        refetchOnWindowFocus: false,
        enabled: false,
    });
    const [ownedSafes, setOwnedSafes] = useState([]);
    const [contributedSafes, setContributedSafes] = useState([]);
    const { refetch: refetchDisconnect } = useDisconnect({
        refetchOnWindowFocus: false,
        enabled: false,
    });

    const [isHardwareWallet, setIsHardwareWallet] = useState(false);

    const [gasPrice, setGasPrice] = useState(null);

    useEffect(() => {
        const onboardInit = initWeb3Onboard;
        try {
            fetch("https://raw.githubusercontent.com/Uniswap/sybil-list/master/verified.json").then(
                async res => {
                    res.json().then(data => {
                        setSybilData(data);
                    });
                },
            );
        } catch (e) {
            console.error(e);
            Sentry.captureException(e);
        }

        if (window.localStorage.getItem("selectedWallet")) {
            setIsPrevWallet(true);
        } else {
            setIsPrevWallet(false);
        }
        setOnboard(onboardInit);
    }, []);

    useEffect(() => {
        if (ethersAdapter === null) return;

        const safeService = new SafeApiKit({
            txServiceUrl: transactionServiceUrl,
            ethAdapter: ethersAdapter,
        });

        setSafeService(safeService);
    }, [ethersAdapter]);

    useEffect(() => {
        handleAddress(wallet?.accounts[0]?.address);
    }, [wallet?.accounts[0]?.address]);

    useEffect(() => {
        handleNetwork(parseInt(connectedChain?.id, 16));
    }, [connectedChain?.id]);

    useEffect(() => {
        if (!wallet?.provider) {
            setProvider(null);
        } else {
            const ethersProvider = new ethers.providers.Web3Provider(wallet.provider);
            setProvider(ethersProvider);
            const ethersAdapter = new EthersAdapter({
                ethers,
                signerOrProvider: ethersProvider.getSigner(0),
            });

            setEthersAdapter(ethersAdapter);
        }
    }, [wallet]);

    useEffect(() => {
        if (network && wallet && wallet.provider) {
            const ethersProvider = new ethers.providers.Web3Provider(wallet.provider);
            const ethersAdapter = new EthersAdapter({
                ethers: ethers,
                signerOrProvider: ethersProvider.getSigner(0),
            });

            setEthersAdapter(ethersAdapter);
            setProvider(ethersProvider);
        }
    }, [network]);

    useEffect(() => {
        const previouslySelectedWallet = window.localStorage.getItem("selectedWallet");
        if (onboard && previouslySelectedWallet) {
            connect({ autoSelect: { label: previouslySelectedWallet, disableModals: false } });
        }
    }, [onboard]);

    useEffect(() => {
        if (provider && address) {
            refetchAuth();
        }
    }, [provider, address]);

    useEffect(() => {
        if (isError) {
            // console.log(error, "Here");
            setIsLoggedIn(false);

            return;
        }

        if (data) {
            setIsLoggedIn(true);
            setOwnedSafes(data.data.me.ownedSafes);
            setContributedSafes(data.data.me.contributedSafes);
        }
    }, [data, isError]);

    useEffect(() => {
        if (wallet) {
            if (wallet?.label === "Ledger" || wallet?.label === "Trezor") {
                setIsHardwareWallet(true);
            } else {
                setIsHardwareWallet(false);
            }
        }
    }, [wallet]);

    const handleAddress = async account => {
        setAddress(prev => {
            if (prev === null || prev === undefined) return account;
            if (prev === account) return account;
            refetchDisconnect().then(() => {
                queryClient.invalidateQueries(AUTH_TOKEN_CHECK);
                window.localStorage.removeItem("selectedWallet");
                window.localStorage.removeItem("parcelv2_lastLoggedInSafeAddress");
                window.localStorage.removeItem("LAST_WALLET");
                setIsLoggedIn(false);
                if (router?.pathname?.startsWith("/shareable-transaction")) {
                    refetchDisconnect().then(() => queryClient.invalidateQueries(AUTH_TOKEN_CHECK));
                } else {
                    router.push("/");
                }
            });
            return account;
        });
        // setAddress(account);
    };
    const handleNetwork = async network => {
        if (network && networkId !== network) {
            setIsNetworkChanged(true);
            return;
        } else {
            setIsNetworkChanged(false);
        }

        setNetwork(prev => {
            // console.log(prev, account, "from acc");
            if (prev === null || prev === undefined) return network;
            if (prev === network) return network;
            window.localStorage.removeItem("selectedWallet");
            window.localStorage.removeItem("parcelv2_lastLoggedInSafeAddress");
            window.localStorage.removeItem("LAST_WALLET");
            refetchDisconnect().then(() => {
                setIsLoggedIn(false);
                if (router?.pathname?.startsWith("/shareable-transaction")) {
                    queryClient.invalidateQueries(AUTH_TOKEN_CHECK);
                } else {
                    router.push("/");
                }
            });
            return network;
        });
        setNetwork(network);
    };

    const getLabelName = async () => {
        try {
            const name = await provider.lookupAddress(address);
            setENSName(name);
        } catch (err) {
            setENSName(null);
            console.error(err);
        }
    };

    useEffect(() => {
        if (!!address) {
            getLabelName();
        }
    }, [address]);

    useEffect(() => {
        if (provider) {
            const getGasPrice = async () => {
                const gasPrice = await provider.getGasPrice();
                setGasPrice(ethers.utils.formatUnits(gasPrice, "gwei"));
            };
            getGasPrice();
            const interval = setInterval(() => getGasPrice(), 30000);
            return () => clearInterval(interval);
        }
    }, [provider]);

    return (
        <BaseAppContext.Provider
            value={{
                web3: {
                    account: address ? getAddress(address) : undefined,
                    ensName: ensName,
                    chainId: network,
                    library: provider,
                    ethersAdapterOwner: ethersAdapter,
                    connector: wallet,
                    balance: wallet?.accounts[0]?.balance || 0,
                    active: address && wallet?.accounts[0]?.balance ? true : false,
                    isLoggedIn,
                    setIsLoggedIn,
                    isPrevWallet,
                    setIsPrevWallet,
                    safeService,
                    ownedSafes,
                    refetchAuth,
                    isNetworkChanged,
                    setIsNetworkChanged,
                    contributedSafes,
                    setIsWalletEOA,
                    isWalletEOA,
                    sybilData,
                    isHardwareWallet,
                    setIsGnosisWallet,
                    isGnosisWallet,
                    refetchDisconnect,
                    gasPrice,
                    isGasLowEnough: !!gasPrice && +gasPrice <= 50, // 50 Gwei
                    connectWallet: connect,
                    walletConnecting: connecting,
                    disconnectWallet: disconnect,
                    setChain,
                },
            }}
        >
            {children}
        </BaseAppContext.Provider>
    );
}
