import React, { useState, useEffect, memo } from "react";
import { useRouter } from "next/router";
export default function useRouteChange() {
    const [pageLoading, setPageLoading] = useState<boolean>(false);

    const router = useRouter();
    useEffect(() => {
        const handleStart = () => {
            setPageLoading(true);
        };
        const handleStop = () => {
            setPageLoading(false);
        };
        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleStop);
        router.events.on("routeChangeError", handleStop);
        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleStop);
            router.events.off("routeChangeError", handleStop);
        };
    }, [router]);
    return { pageLoading };
}
