import { Popover, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import SafeContext from "src/contexts/SafeContext";
import TransferContext from "src/contexts/TransferContext";
import { getFormattedBalanceFromUnits } from "src/helpers/utils/common";
import { formatAmount } from "src/helpers/utils/web3Utils";
import {
    TokenBreakdownContainer,
    TokenBreakdownItem,
    TokenBreakdownPopoverStyled,
} from "./styles/TokenBreakdownPopover";

const TokenBreakdown = ({ open, anchor }) => {
    const { payoutQueue }: any = useContext(TransferContext);
    const { tokensInSafe, safeTokenBalances }: any = useContext(SafeContext);
    const balanceOfSelectedToken = selectedToken =>
        getFormattedBalanceFromUnits(
            safeTokenBalances[selectedToken],
            tokensInSafe[selectedToken].decimals,
        );
    const getTokenItems = () => {
        let tokenWise = {};
        payoutQueue.forEach(payout => {
            if (tokenWise[payout.selectedToken]) {
                tokenWise[payout.selectedToken].total = tokenWise[payout.selectedToken].total.plus(
                    payout.tokenValue,
                );
            } else {
                tokenWise[payout.selectedToken] = {
                    name: tokensInSafe[payout.selectedToken].name,
                    symbol: tokensInSafe[payout.selectedToken].symbol,
                    total: payout.tokenValue,
                    balance: balanceOfSelectedToken(payout.selectedToken),
                };
            }
        });

        return (
            <Stack>
                {Object.values(tokenWise).map((token: any, t: number) => (
                    <TokenBreakdownItem direction={"row"} key={t}>
                        <p className="name">{token.name}</p>
                        <p className="symbol">{token.symbol}</p>
                        <p
                            className={
                                token.total.greaterThan(token.balance) ? "value-red" : "value"
                            }
                        >
                            {formatAmount(token.total, 0, 3)}
                        </p>
                    </TokenBreakdownItem>
                ))}
            </Stack>
        );
    };

    return (
        <TokenBreakdownPopoverStyled
            sx={{
                pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center",
            }}
            disableRestoreFocus
        >
            <TokenBreakdownContainer>
                <>{getTokenItems()}</>
            </TokenBreakdownContainer>
        </TokenBreakdownPopoverStyled>
    );
};

export default TokenBreakdown;
