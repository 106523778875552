import router from "next/router";
import React, { createContext, useContext, useEffect, useState } from "react";

export const IntegrationContext = createContext({});
import { useActiveBaseContext } from "src/hooks";
import { useGetIntegrationsList } from "src/queries/ExternalAccess";
import SafeContext from "./SafeContext";

const staticIntegrations = [
    {
        appToken: null,
        description:
            "View your Coordinape circles and create payouts for completed Epochs. Click here to begin.",
        integrationId: null,
        integrationName: "Coordinape",
        logoUri: "/svg/logo-Coordinape.svg",
        paymentType: "Coordinape Payout",
        slug: "Coordinape",
    },
];

// const staticIntegrations = [];

const IntegrationContextProvider = ({ children }) => {
    const { account } = useActiveBaseContext().web3;

    // START : Coordinape Integration Logic & State
    const [integrationData, setIntegrationData] = useState();
    // const [selectedIntegration, setSelectedIntegration] = useState("");
    const [coordinapeToken, setCoordinapeToken] = useState<any>();
    const [payoutQueue, setPayoutQueue] = useState([]);
    // END : Coordinape Integration Logic & State

    // START : Integrations 2.2 Logic & State

    const { currentSafeAddress }: any = useContext(SafeContext);
    const integrationsListQuery = useGetIntegrationsList();

    const [integrationsList, setIntegrationsList] = useState(staticIntegrations);

    const [selectedIntegration, setSelectedIntegration] = useState({});

    useEffect(() => {
        if (window && window.localStorage.getItem("LAST_WALLET")) {
            account === window.localStorage.getItem("LAST_WALLET");
        }
        return () => {
            window.localStorage.removeItem("LAST_WALLET");
        };
    }, []);

    useEffect(() => {
        if (integrationsListQuery.isSuccess) {
            setIntegrationsList([...integrationsList, ...integrationsListQuery.data]);
        }
    }, [integrationsListQuery.data]);

    useEffect(() => {
        if (account && currentSafeAddress) integrationsListQuery.refetch();
    }, [account, currentSafeAddress]);

    // END : Integrations 2.2 Logic & State

    return (
        <IntegrationContext.Provider
            value={{
                LIST_OF_INTEGRATIONS: integrationsList,
                selectedIntegration,
                setSelectedIntegration,
                integrationData,
                setIntegrationData,
                coordinapeToken,
                setCoordinapeToken,
                payoutQueue,
                setPayoutQueue,
            }}
        >
            {children}
        </IntegrationContext.Provider>
    );
};

export default IntegrationContextProvider;
