import { Button, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useRef, useState, useEffect } from "react";
import { EmptyQueueBox, QuickTransferStyled } from "./styles/QuickTransfer";
import SimulationBox from "src/components/common/SimulateTransaction";
import InputComponent from "../../InputComponent";
import { SummaryHolder } from "./styles/QuickTransfer";
import PayoutQueue from "../PayoutQueue";
import TransferContext from "src/contexts/TransferContext";
import RecipientBlock from "./RecipientBlock";
import TokenBreakdown from "./TokenBreakdown";
import { useActiveBaseContext, useContract } from "src/hooks";
import SafeContext from "src/contexts/SafeContext";
import createTransaction from "src/helpers/createTransaction";
import ERC20ABI from "src/constants/abis/ERC20.json";
import MultiSendABI from "src/constants/abis/MultiSend.json";
import addresses from "src/constants/addresses";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import { updateTransactionIdToBudget, useCreateTransaction } from "src/queries/Transaction/api";
import router from "next/router";
import { useQueryClient } from "react-query";
const MULTISEND_ADDRESS = addresses.MULTISEND_ADDRESS;
import MetaTxModal from "src/components/MetaTxModal";
import { CheckIfLimitsAreNotConsumed } from "src/queries/Safe/api";
import { getFormattedBalanceFromUnits } from "src/helpers/utils/common";
import { useBulkUpdateRequestTransactionId } from "src/queries/DaoRequest/api";
import { formatAmount } from "src/helpers/utils/web3Utils";
import { triggerSlackNotification } from "src/queries/Webhook/api";
import { networkId } from "src/helpers/utils/networks";
import Image from "next/image";
import Dialog from "@mui/material/Dialog";
import CircularProgress from "@mui/material/CircularProgress";
import ClearIcon from "@mui/icons-material/Clear";
import GnosisSafeABI from "src/constants/abis/GnosisSafe.json";
import { etherscan } from "src/constants/endpoints";
import SelectBoxParcel from "src/components/CategorySelect/index";
import { useSafeSdk } from "src/hooks/useSafeSdk";
import { sendEmail } from "src/queries/emailNotification";
import BudgetSelect from "src/components/Squads/BudgetSelect";
import useEstimateGasContract from "src/hooks/useEstimateGasContract";
import AddPeopleModal from "./AddPeopleModal";

const QuickTransfer = () => {
    const { getGasEstimateTransactionExecution } = useEstimateGasContract();
    const { enqueueSnackbar } = useSnackbar();
    const [openConfirmationModal, setOpenConfirmationModal] = React.useState(false);
    const [openModal, setOpenModal] = useState(false);
    const {
        walletAddress,
        payoutQueue,
        description,
        setDescription,
        isTxStarted,
        setIsTxStarted,
        transferType,
        closeModal,
        setCategory,
        category,
    }: any = useContext(TransferContext);

    const {
        ethersAdapterOwner,
        safeService,
        library,
        account,
        contributedSafes,
        isWalletEOA,
        chainId,
        isHardwareWallet,
        isGasLowEnough,
    }: any = useActiveBaseContext().web3;
    const {
        currentSafeAddress,
        tokensInSafe,
        safeTokenBalances,
        safeSdk,
        safeStaticDetails: {
            isMetaTxEnabled,
            safeName,
            threshold,
            owners: _owners,
            categories: _categories = [],
        },
        isDelegateAccess,
        isDelegateCanCreateTx,
        safeDetailsFromGnosis,
    }: any = useContext(SafeContext);
    const { safeSdk: backupSafeSDK } = useSafeSdk(ethersAdapterOwner, currentSafeAddress);
    const { mutateAsync } = useCreateTransaction();
    const [tokenBreakdownOpen, toggleTokenBreakdown] = useState(false);
    const [isMetaTxLimitAllowed, setIsMetaTxLimitAllowed] = useState(false);
    const [errorInAmount, setErrorInAmount] = useState(false);
    const [openAddPeopleModal, setOpenAddPeopleModal] = useState(false);
    const [budget, setBudget] = useState(null);
    const [txStatus, setTxStatus] = useState<any>({
        status: null,
        data: {
            message: "Gathering Information",
        },
    });
    const [simulateState, setSimulateState] = useState("begins");
    const proxyContract = useContract(currentSafeAddress, GnosisSafeABI, true, library, account);
    const queryClient = useQueryClient();
    const totalInUSDRef = useRef();

    //initialise mutation
    const { mutateAsync: updateRequestTransactionId } = useBulkUpdateRequestTransactionId();

    const { mutateAsync: linkTransactionIdToBudget } = updateTransactionIdToBudget();

    useEffect(() => {
        CheckIfLimitsAreNotConsumed(currentSafeAddress)
            .then(data => setIsMetaTxLimitAllowed(data))
            .catch(err => setIsMetaTxLimitAllowed(false));
    }, [currentSafeAddress]);

    //Custom or ERC20 token initialization
    const customToken = useContract(addresses.ZERO_ADDRESS, ERC20ABI, true, library, account);
    const multiSend = useContract(MULTISEND_ADDRESS, MultiSendABI, true, library, account);
    const disableCreateTx = () => {
        return !(payoutQueue && payoutQueue.length > 0 && description.trim() && !errorInAmount);
    };
    // useEffect(() => {
    //     console.log(safeSdk);
    // }, [safeSdk]);

    const getTotalInUSD = () => {
        return formatAmount(
            payoutQueue.reduce((sum, item) => sum + item.tokenValueInUSD, 0),
            2,
            2,
        );
    };

    const handleCloseConfirmationModal = () => {
        setOpenConfirmationModal(false);
    };

    useEffect(() => {
        setErrorInAmount(false);
    }, [payoutQueue]);

    const balanceOfSelectedToken = selectedToken =>
        getFormattedBalanceFromUnits(
            safeTokenBalances[selectedToken],
            tokensInSafe[selectedToken].decimals,
        );

    const checkAmount: any = () => {
        let tokenDetails = {};
        let error = false;
        payoutQueue.forEach(payout => {
            if (tokenDetails[payout.selectedToken]) {
                tokenDetails[payout.selectedToken].total = tokenDetails[
                    payout.selectedToken
                ].total.plus(payout.tokenValue);
            } else {
                tokenDetails[payout.selectedToken] = {
                    name: tokensInSafe[payout.selectedToken].name,
                    symbol: tokensInSafe[payout.selectedToken].symbol,
                    total: payout.tokenValue,
                    balance: balanceOfSelectedToken(payout.selectedToken),
                };
            }
        });
        Object.values(tokenDetails).forEach((token: any) => {
            if (token.total.greaterThan(token.balance)) {
                enqueueSnackbar(`Amount of ${token.symbol} is more than balance`, {
                    variant: "error",
                });
                error = true;
                setErrorInAmount(true);
            }
        });
        return error;
    };

    const handleCreateTxWithMetaTransaction = async () => {
        if (checkAmount()) {
            return;
        }
        if (isMetaTxEnabled && isMetaTxLimitAllowed) {
            try {
                setIsTxStarted(true);
                const threshold = await safeSdk.getThreshold();
                if (threshold && threshold == 1) return setOpenConfirmationModal(true);
                await handleCreateTx();
            } catch (err) {
                await handleCreateTx();
            } finally {
                setIsTxStarted(false);
            }
        } else {
            handleCreateTx();
        }
    };

    const handleCreateTx = async () => {
        let txstatus = await createTransaction(
            safeService,
            safeSdk || backupSafeSDK,
            payoutQueue,
            currentSafeAddress,
            customToken,
            multiSend,
            library,
            account,
            enqueueSnackbar,
            setIsTxStarted,
            closeModal,
            isMetaTxEnabled,
            isMetaTxLimitAllowed,
            setOpenModal,
            setTxStatus,
            proxyContract,
            isDelegateAccess,
            isDelegateCanCreateTx,
            isWalletEOA,
            safeDetailsFromGnosis?.version,
            chainId,
            isHardwareWallet,
            getGasEstimateTransactionExecution,
            ethersAdapterOwner,
            isGasLowEnough,
        );
        setTxStatus(txstatus);
        // console.log("from texsatus", txstatus);
        if (txstatus.status) {
            const { addTransaction } = await mutateAsync({
                txParams: {
                    transaction: {
                        description: description.trim(),
                        paymentType: transferType,
                        nonce: txstatus.data.nonce,
                        status: txstatus.data.status,
                        transactionHash: txstatus.data.txHash,
                        transactionFees: 0,
                        TransactionDisbursementDetails: payoutQueue.map(item => {
                            return {
                                address: item.walletAddress,
                                amount: item.tokenValue.toNumber(),
                                token: item.tokenSymbol ? item.tokenSymbol : item.selectedToken,
                                fiatValue: item.tokenValueInUSD,
                                fiatCurrency: "USD",
                                tokenValue: item.tokenValue.toNumber(),
                                tagName: item?.tag,
                                disbursementType: item.payoutCategory || category,
                                comment: item.payoutComment,
                            };
                        }),
                    },
                },
                currentSafeAddress,
            });

            // link transaction to budget
            // budget is null by default or 0 if selected "do not link with any budget"
            if (budget) {
                await linkTransactionIdToBudget({
                    safeAddress: currentSafeAddress,
                    transactionId: addTransaction.transactionId,
                    budgetId: budget,
                });
            }

            let updatedBy = _owners.find(owner => owner.address == account);

            if (isDelegateAccess && isDelegateCanCreateTx) {
                updatedBy = contributedSafes.find(safe => safe.safeAddress == currentSafeAddress);
            }

            if (txstatus?.data?.status === "Pending") {
                const filteredOtherOwners = _owners
                    .filter(owner => owner.address != account)
                    .map(({ ownerEmail, nickName }) => {
                        return {
                            emailAddress: ownerEmail,
                            ownerName: nickName ? nickName : "Safe Owner",
                        };
                    });

                sendEmail(
                    payoutQueue?.length,
                    safeName,
                    filteredOtherOwners,
                    description.trim(),
                    updatedBy?.nickName,
                    addTransaction.transactionId,
                );
            }
            await triggerSlackNotification({
                action: "CREATED",
                initator: account,
                txData: {
                    description,
                    paymentType: transferType,
                    organisationName: safeName,
                    threshold,
                    networkId,
                    transactionHash: txstatus.data.txHash,
                    safeAddress: currentSafeAddress,
                    nonce: txstatus.data.nonce,
                    transactionId: addTransaction.transactionId,
                    fiatValue: payoutQueue?.reduce((acc, curr) => (acc += curr.tokenValueInUSD), 0),
                    createdBy: account,
                    updatedBy: account,
                    createdByName: updatedBy?.nickName,
                },
            });

            const requestIDsToUpdate = payoutQueue
                ?.map(({ requestId }) => requestId)
                .filter(Boolean);

            if (requestIDsToUpdate?.length > 0) {
                updateRequestTransactionId({
                    safeAddress: currentSafeAddress,
                    requestIds: requestIDsToUpdate,
                    transactionId: addTransaction.transactionId,
                });
            }

            queryClient.invalidateQueries("get-executed-transaction-from-gnosis");
            queryClient.invalidateQueries("get-pending-transaction-from-gnosis");
        } else {
            // enqueueSnackbar("Error", { variant: "error" });
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        setTxStatus({
            status: null,
            data: {
                message: "Gathering Information",
            },
        });
        if (txStatus.status) {
            closeModal();
            router.push({
                pathname: "/transactions",
                query: {
                    nonce: txStatus.data.nonce,
                    safeTxHash: txStatus.data.txHash,
                },
            });
        }
    };
    return (
        <QuickTransferStyled>
            <Stack direction="row">
                <Box className="recipient-block">
                    {/* <ModuleHeading>Quick Transfer</ModuleHeading> */}
                    {/* <Typography variant="h4" className="block-heading">
                        Recipient
                    </Typography> */}
                    <RecipientBlock
                        setOpenAddPeopleModal={setOpenAddPeopleModal}
                        setSimulateState={setSimulateState}
                    />
                </Box>
                {payoutQueue.length ? (
                    <Box className="queue-block">
                        <>
                            <Typography variant="h4" className="block-heading">
                                Queue
                            </Typography>
                            <Box className="queue-box">
                                <PayoutQueue />
                            </Box>
                        </>

                        <SummaryHolder>
                            <Stack
                                direction="row"
                                alignItems={"center"}
                                justifyContent={"space-between"}
                            >
                                <Typography className="summary-value" variant="h4">
                                    Total USD
                                </Typography>
                                <Typography
                                    ref={totalInUSDRef}
                                    className={
                                        errorInAmount ? "summary-value-red" : "summary-value"
                                    }
                                    variant="h4"
                                    onMouseEnter={e => toggleTokenBreakdown(true)}
                                    onMouseLeave={e => toggleTokenBreakdown(false)}
                                >
                                    ${getTotalInUSD()}
                                </Typography>
                                {payoutQueue.length > 0 ? (
                                    <TokenBreakdown
                                        anchor={totalInUSDRef.current}
                                        open={tokenBreakdownOpen}
                                    />
                                ) : (
                                    <></>
                                )}
                            </Stack>
                            {payoutQueue.find(payout => payout.payoutCategory == "") && (
                                <Stack
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "flex-start",
                                    }}
                                    className="submit-holder"
                                >
                                    <SelectBoxParcel
                                        label={
                                            "Some of your payouts do not have categories to them. Select a category to apply to the remaining payouts."
                                        }
                                        items={_categories.filter(({ isEnabled }) =>
                                            Boolean(isEnabled),
                                        )}
                                        fullWidthProps
                                        inputStyles={{ marginRight: "0px" }}
                                        onChange={setCategory}
                                    ></SelectBoxParcel>
                                </Stack>
                            )}
                            <InputComponent
                                sx={{ background: "#F5F6F7" }}
                                wrapperStyle={{}}
                                type="text"
                                multiline
                                value={description}
                                onChange={e => setDescription(e.target.value)}
                                label={"Description"}
                                placeholder="Enter Description"
                                inputStyles={{
                                    alignItems: "flex-start",
                                    background: "#FFFFFF",
                                    color: "#636B81",
                                    border: "none",
                                    height: "120px",
                                    padding: "15px 15px 0",
                                    lineHeight: 1.5,
                                    overflowY: "scroll",
                                }}
                            />
                            <Stack
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "flex-start",
                                }}
                                className="submit-holder"
                            >
                                <BudgetSelect budget={budget} setBudget={setBudget} />
                            </Stack>
                            {/* <Divider className="divider" /> */}

                            {/* <Stack
                            className="gas-limit-holder"
                            direction="row"
                            alignItems={"center"}
                            justifyContent={"space-between"}
                          >
                            <Typography className="summary-value" variant="h4">
                              Network Fees
                            </Typography>
                            <Typography className="gas-fee-display" variant="h4">
                              ${gasLimit.feeInUSD || 0}
                              <Circle className="divider-dot" />
                              {gasLimit.type || "Normal"}
                            </Typography>
                          </Stack> */}
                            <SimulationBox
                                simulateState={simulateState}
                                setSimulateState={setSimulateState}
                                payoutQueueForSimulation={payoutQueue}
                            />
                            <LoadingButton
                                style={{
                                    color: disableCreateTx() ? "#8B91A1" : "white",
                                    background: disableCreateTx() ? "#DFE1E5" : "#2962EF",
                                }}
                                loading={isTxStarted}
                                disabled={disableCreateTx()}
                                className="create-tx-button"
                                variant="contained"
                                onClick={handleCreateTxWithMetaTransaction}
                            >
                                Create Transaction
                            </LoadingButton>
                            <MetaTxModal
                                open={openConfirmationModal}
                                handleCloseConfirmationModal={handleCloseConfirmationModal}
                                handleConfirm={() => {
                                    setOpenConfirmationModal(false);
                                    handleCreateTx();
                                }}
                                variant={isGasLowEnough ? "confirm" : "highGas"}
                            />
                        </SummaryHolder>
                    </Box>
                ) : (
                    <EmptyQueueBox>
                        <Typography
                            fontWeight={600}
                            fontSize={"14px"}
                            lineHeight={"14px"}
                            color={"#25274F"}
                        >
                            Queue
                        </Typography>
                        <Box className="empty-state-wrapper">
                            <Box className="empty-state-content">
                                <img src="/icon _Info_.svg" />
                                <Typography
                                    fontSize={"16px"}
                                    fontWeight={500}
                                    lineHeight={"24px"}
                                    textAlign={"center"}
                                    color={"#636B81"}
                                >
                                    To create a transaction, add one or more recipients to the
                                    queue.
                                </Typography>
                            </Box>
                        </Box>
                    </EmptyQueueBox>
                )}
            </Stack>
            <Dialog open={openModal} maxWidth="xs" fullWidth onClose={() => {}}>
                <Box width="100%" textAlign="right">
                    {txStatus.status !== null && (
                        <ClearIcon
                            sx={{ color: "#636B81", my: "10px", mx: "20px", cursor: "pointer" }}
                            onClick={handleCloseModal}
                        />
                    )}
                </Box>
                <Box px={"20px"} pt={"30px"} pb={"20px"} textAlign="center">
                    {txStatus.status === null && (
                        <>
                            <CircularProgress
                                variant={"indeterminate"}
                                size={100}
                                thickness={2}
                                sx={{
                                    color: "primary",
                                }}
                                value={100}
                            />

                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {/* {txStatus.data?.hash && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        href={`${etherscan}/tx/${txStatus.data.hash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                        target="_blank"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )} */}
                            <Typography fontSize="18px" fontWeight={600} sx={{ mt: "5px" }}>
                                {txStatus.data.message}
                            </Typography>
                        </>
                    )}
                    {txStatus.status === true && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-icon.png" />
                            {/* <Typography fontSize="16px" fontWeight={400} sx={{ mt: "20px" }}>
                                Creating Transaction
                            </Typography> */}
                            {txStatus.data.status !== "Pending" && (
                                <Box marginTop="10px" marginBottom="10px">
                                    <Typography
                                        component="a"
                                        target="_blank"
                                        href={`${etherscan}/tx/${txStatus.data.txHash}`}
                                        fontSize="18px"
                                        fontWeight={600}
                                        color="primary"
                                    >
                                        View on explorer
                                    </Typography>
                                </Box>
                            )}
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "5px", mb: "20px" }}
                            >
                                {isWalletEOA ? txStatus?.data?.message : `Transaction Created`}
                            </Typography>
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                            >
                                Close
                            </Button>
                        </>
                    )}
                    {txStatus.status === false && (
                        <>
                            <Image height={"100px"} width={"100px"} src="/tx-failed.png" />
                            <Typography
                                fontSize="18px"
                                fontWeight={600}
                                sx={{ mt: "20px", mb: "20px" }}
                            >
                                {txStatus?.data?.message
                                    ? txStatus?.data?.message
                                    : "Transaction Rejected"}
                            </Typography>

                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleCloseModal}
                            >
                                Dismiss
                            </Button>
                        </>
                    )}
                </Box>
            </Dialog>
            <AddPeopleModal
                openAddPeopleModal={openAddPeopleModal}
                setOpenAddPeopleModal={setOpenAddPeopleModal}
                walletAddress={walletAddress}
                currentSafeAddress={currentSafeAddress}
            ></AddPeopleModal>
        </QuickTransferStyled>
    );
};

export default QuickTransfer;
