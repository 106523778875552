import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { CustomNavLink, NumberCircle } from "./styles";
import Link from "next/link";
import theme from "styles/theme";
type NavLinkProps = {
    title: string;
    icon?: JSX.Element;
    href: string;
    isactive: boolean;
    justifySelf?: string;
    tagText?: string;
    pendingNumbers?: number;
    mb?: any;
};

const Tag = ({ text }) => {
    return (
        <div
            style={{
                borderRadius: 5,
                height: 20,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: 8,
                paddingLeft: 5,
                paddingRight: 5,
                background: "#F1A252",
            }}
        >
            <span style={{ color: "white", fontWeight: 600, fontSize: 10 }}>{text}</span>
        </div>
    );
};

function NavLink({
    title,
    icon,
    href,
    isactive,
    justifySelf,
    tagText,
    pendingNumbers,
    mb,
}: NavLinkProps) {
    return (
        <CustomNavLink isactive={isactive} justifySelf={justifySelf || "start"} mb={mb}>
            <Link href={href}>
                <ListItem button key={title}>
                    <ListItemIcon style={{ width: 20 }}>{icon}</ListItemIcon>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                        }}
                    >
                        <ListItemText primary={title} />

                        {tagText && (
                            <ListItemText>
                                <Tag text={tagText} />
                            </ListItemText>
                        )}
                    </div>

                    <div>
                        {pendingNumbers > 0 && (
                            <ListItemText style={{ marginLeft: "auto" }}>
                                <NumberCircle
                                    badgeContent={pendingNumbers}
                                    color="warning"
                                ></NumberCircle>
                            </ListItemText>
                        )}
                    </div>
                </ListItem>
            </Link>
        </CustomNavLink>
    );
}

export default NavLink;
