import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";

import TokenRemainingSummaryPayroll from "src/components/TokenRemainingSummaryPayroll";
import TotalByToken from "src/components/Recurring/CreateWorkflowSideDrawer/ReviewSummary/TotalByToken";
import TotalByUsd from "src/components/Recurring/CreateWorkflowSideDrawer/ReviewSummary/TotalByUsd";

import { ReviewScreenToken, CollapseHeader } from "./styles/ReviewTokenScreen";
import Collapse from "@mui/material/Collapse";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function ReviewTokenSummaryPayroll({ payeeDetails, fromPayrollCreation = false }) {
    const [disbursement, setDisbursment] = useState({});
    const [openAmountByToken, setOpenAmountByToken] = useState(true);
    const [openAmountByUsd, setOpenAmountByUsd] = useState(true);

    const [disbursementByToken, setDisbursmentByToken] = useState({});
    const [disbursementByUsd, setDisbursmentByUsd] = useState({});

    useEffect(() => {
        const disbursement = {};
        payeeDetails?.rows.map(item => {
            if (disbursement[item?.data?.tokenAddress]) {
                disbursement[item?.data?.tokenAddress].amount += Number(item.data.amount);
                disbursement[item?.data?.tokenAddress].fiatValue += Number(item.data.fiatValue);
            } else {
                const payout: any = {};
                payout.token = item?.data?.token;
                payout.amount = Number(item.data.amount);
                payout.fiatValue = Number(item.data.fiatValue);
                payout.logoUri = item.data.tokenURI;
                payout.tokenAddress = item.data.tokenAddress || item.data.token;
                disbursement[item?.data?.tokenAddress] = payout;
            }
        });
        // console.log(disbursement, "dis");
        setDisbursment(disbursement);
    }, [payeeDetails]);

    //calculate price by token
    useEffect(() => {
        const disbursement = {};
        payeeDetails?.rows.map(item => {
            if (disbursement[item?.data?.tokenAddress]) {
                disbursement[item?.data?.tokenAddress].amount += item?.data?.fixedUsd
                    ? 0
                    : Number(item.data.amount);
                disbursement[item?.data?.tokenAddress].fiatValue += Number(item.data.fiatValue);
            } else {
                const payout: any = {};
                payout.token = item?.data?.token;
                payout.amount = item?.data?.fixedUsd ? 0 : Number(item.data.amount);
                payout.fiatValue = Number(item.data.fiatValue);
                payout.logoUri = item.data.tokenURI;
                payout.tokenName = item.data.tokenName;
                payout.tokenAddress = item.data.tokenAddress || item.data.token;
                disbursement[item?.data?.tokenAddress] = payout;
                // disbursement[item?.data?.tokenName] = item?.data?.tokenName || item?.data?.token;
            }
        });
        // console.log(disbursement, "dis");
        setDisbursmentByToken(disbursement);
    }, [payeeDetails]);

    //calculate price by USD
    useEffect(() => {
        const disbursement = {};
        payeeDetails?.rows.map(item => {
            if (disbursement[item?.data?.tokenAddress]) {
                disbursement[item?.data?.tokenAddress].amount += Number(item.data.amount);
                disbursement[item?.data?.tokenAddress].fiatValue += item?.data?.fixedUsd
                    ? Number(item.data.fiatValue)
                    : 0;
            } else {
                const payout: any = {};
                payout.token = item?.data?.token;
                payout.amount = Number(item.data.amount);
                payout.fiatValue = item?.data?.fixedUsd ? Number(item.data.fiatValue) : 0;
                payout.logoUri = item.data.tokenURI;
                payout.tokenName = item.data.tokenName;
                payout.tokenAddress = item.data.tokenAddress || item.data.token;
                disbursement[item?.data?.tokenAddress] = payout;
            }
        });

        setDisbursmentByUsd(disbursement);
    }, [payeeDetails]);

    if (fromPayrollCreation) {
        let disByTokenValues = Object.values(disbursementByToken).filter(
            (item: any) => item.amount > 0,
        );
        let disByUSDValues = Object.values(disbursementByUsd).filter(
            (item: any) => item.fiatValue > 0,
        );
        return (
            <ReviewScreenToken>
                <Typography
                    marginTop={"17px"}
                    pl="20px"
                    color="#25274F"
                    fontSize={18}
                    fontWeight={600}
                >
                    Review Summary
                </Typography>
                <Box className="header">
                    <Box>
                        <Typography color="#8B91A1" fontSize="13px">
                            TOKEN
                        </Typography>
                    </Box>
                    <Box>
                        <Typography textAlign={"right"} color="#8B91A1" fontSize="13px">
                            PAYOUT VALUE
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        minHeight: "40vh",
                        maxHeight: "40vh",
                        overflowY: "auto",
                    }}
                >
                    {disByTokenValues.length > 0 ? (
                        <>
                            <CollapseHeader
                                onClick={() => setOpenAmountByToken(!openAmountByToken)}
                            >
                                <Typography lineHeight={"1.5"} fontSize={"13px"}>
                                    Payouts by token values
                                </Typography>
                                {!openAmountByToken && <ArrowDropDownIcon />}
                                {openAmountByToken && <ArrowDropUpIcon />}
                            </CollapseHeader>
                            {disByTokenValues.map(item => (
                                <Box
                                    sx={{
                                        padding: "0px 20px",
                                    }}
                                >
                                    <Collapse in={openAmountByToken} timeout="auto" unmountOnExit>
                                        <TotalByToken disbursment={[item]}></TotalByToken>
                                    </Collapse>
                                </Box>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}

                    {disByUSDValues.length > 0 ? (
                        <>
                            <CollapseHeader onClick={() => setOpenAmountByUsd(!openAmountByUsd)}>
                                <Typography lineHeight={"1.5"} fontSize={"13px"}>
                                    Payouts by USD values
                                </Typography>
                                {!openAmountByUsd && <ArrowDropDownIcon />}
                                {openAmountByUsd && <ArrowDropUpIcon />}
                            </CollapseHeader>
                            {disByUSDValues.map(item => (
                                <Box
                                    sx={{
                                        padding: "0px 20px",
                                    }}
                                >
                                    <Collapse in={openAmountByUsd} timeout="auto" unmountOnExit>
                                        <TotalByUsd disbursment={[item]}></TotalByUsd>
                                    </Collapse>
                                </Box>
                            ))}
                        </>
                    ) : (
                        <></>
                    )}
                </Box>
            </ReviewScreenToken>
        );
    } else {
        return (
            <ReviewScreenToken>
                <Typography
                    marginTop={"17px"}
                    pl="20px"
                    color="#25274F"
                    fontSize={18}
                    fontWeight={600}
                >
                    Review Summary
                </Typography>
                <Box className="header">
                    <Box>
                        <Typography color="#8B91A1" fontSize="13px">
                            TOKEN AMOUNT
                        </Typography>
                    </Box>
                    <Box>
                        <Typography textAlign={"right"} color="#8B91A1" fontSize="13px">
                            REMAINING AMOUNT
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        minHeight: "30vh",
                        maxHeight: "30vh",
                        overflowY: "auto",
                    }}
                >
                    {Object.values(disbursement).map(item => (
                        <Box
                            className="body"
                            sx={{
                                justifyContent: "flex-start !important",
                                padding: "0px 20px",
                            }}
                        >
                            <TokenRemainingSummaryPayroll
                                disbursment={[item]}
                            ></TokenRemainingSummaryPayroll>
                        </Box>
                    ))}
                </Box>
            </ReviewScreenToken>
        );
    }
}
