import React, { useContext, useEffect } from "react";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import TransferContext from "src/contexts/TransferContext";
import { SelectedTagItem, SelectedTagsBlockStyled } from "./styles/SelectedTagsBlock";

const SelectedTagsBlock = ({ setInitial }) => {
    const { selectedTags, tagInfocus, setTagInFocus, peopleByTag, payoutQueueByTag }: any =
        useContext(MassPayoutContext);

    useEffect(() => {
        if (setInitial) setTagInFocus(selectedTags[0]);
    }, [setInitial]);

    const { massPayoutStep }: any = useContext(TransferContext);

    return (
        <SelectedTagsBlockStyled direction={"row"}>
            {selectedTags.map((tag, t) => {
                if (massPayoutStep > 1 && !payoutQueueByTag[tag]) {
                    return <></>;
                }
                return (
                    <SelectedTagItem
                        onClick={e => setTagInFocus(tag)}
                        selected={tagInfocus === tag}
                        key={t}
                        label={
                            tagInfocus === tag
                                ? `${tag} (${
                                      massPayoutStep != 1
                                          ? payoutQueueByTag[tag]?.length
                                          : peopleByTag[tag]?.length || 0
                                  })`
                                : `${tag}`
                        }
                    />
                );
            })}
        </SelectedTagsBlockStyled>
    );
};

export default SelectedTagsBlock;
