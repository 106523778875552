import { GraphQLClient } from "graphql-request";

export const graphQLClient = (endpoint, currentSafeAddress = null) => {
    return new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
        headers: {
            ...(currentSafeAddress ? { "x-par-safe": currentSafeAddress } : {}),
        },
    });
};

export const graphQLCoordinapeClient = (endpoint, token) => {
    return new GraphQLClient(endpoint, {
        credentials: "include",
        mode: "cors",
        headers: {
            authorization: `Bearer ${token}`,
        },
    });
};

export const graphQLSnapshotClient = endpoint => {
    return new GraphQLClient(endpoint, {
        credentials: "include",
        headers: {
            "Content-Type": "application/json",
            proxy: endpoint,
        },
    });
};
