import React from "react";
import { useActiveBaseContext } from "src/hooks";
import Home from "pages/index";
import PrimaryLayout from "src/layouts/PrimaryLayout";
import { useRouter } from "next/router";
import { LoadingWrapper } from "./styles";
import ParcelLogo from "assets/parcel_logo.svg";
import { Box, LinearProgress, Typography } from "@mui/material";
import IntegrationContextProvider from "src/contexts/IntegrationContext";
import ShareableTransactionContextProvider from "src/contexts/ShareableTransactionContext";
import Lottie from "react-lottie";
import animationData from "assets/lottie/PayrollLoaderLottie.json";

function RootHoc({ Component, pageProps }) {
    const { isLoggedIn, library, onboard, isPrevWallet, wallet } = useActiveBaseContext().web3;
    const router = useRouter();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
        },
    };

    // if (redirectToPayroll) {
    //     return (
    //         <Box
    //             display={"flex"}
    //             flexDirection={"column"}
    //             height={"100vh"}
    //             alignItems={"center"}
    //             justifyContent={"center"}
    //         >
    //             <Box sx={{ pointerEvents: "none" }}>
    //                 <Lottie options={defaultOptions} height={240} width={240} />
    //             </Box>
    //             <Typography
    //                 fontWeight={700}
    //                 fontSize={"14px"}
    //                 lineHeight={"21px"}
    //                 textAlign={"center"}
    //                 color={"#73768E"}
    //             >
    //                 Redirecting you to a new and faster payroll experience
    //             </Typography>
    //         </Box>
    //     );
    // }

    if (router?.pathname?.startsWith("/shareable-transaction")) {
        return (
            <ShareableTransactionContextProvider>
                <Component {...pageProps} />;
            </ShareableTransactionContextProvider>
        );
    }

    if (router?.pathname === "/" || router?.pathname.startsWith("/onboard/select_role")) {
        return (
            <PrimaryLayout>
                <Component {...pageProps} />
            </PrimaryLayout>
        );
    }
    if (onboard && library && isLoggedIn) {
        return router.pathname.startsWith("/integrations") ? (
            <IntegrationContextProvider>
                <PrimaryLayout>
                    <Component {...pageProps} />
                </PrimaryLayout>
            </IntegrationContextProvider>
        ) : (
            <PrimaryLayout>
                <Component {...pageProps} />
            </PrimaryLayout>
        );
    }
    if (isPrevWallet === null) {
        return (
            <LoadingWrapper>
                <img src={ParcelLogo.src} style={{ height: "40px", verticalAlign: "middle" }} />
                <LinearProgress
                    variant="indeterminate"
                    style={{ marginTop: "16px", borderRadius: "5px" }}
                />
            </LoadingWrapper>
        );
    }
    if (wallet) {
        return router.pathname.startsWith("/integrations") ? (
            <IntegrationContextProvider>
                <PrimaryLayout>
                    <Component {...pageProps} />
                </PrimaryLayout>
            </IntegrationContextProvider>
        ) : (
            <PrimaryLayout>
                <Component {...pageProps} />
            </PrimaryLayout>
        );
    }
    if (isPrevWallet === false) {
        return <Home {...pageProps} />;
    }
    if (isLoggedIn === null) {
        return (
            <LoadingWrapper>
                <img src={ParcelLogo.src} style={{ height: "40px", verticalAlign: "middle" }} />
                <LinearProgress
                    variant="indeterminate"
                    style={{ marginTop: "16px", borderRadius: "5px" }}
                />
            </LoadingWrapper>
        );
    }
    if (isPrevWallet === true && (isLoggedIn === true || isLoggedIn === false)) {
        return router.pathname.startsWith("/integrations") ? (
            <IntegrationContextProvider>
                <PrimaryLayout>
                    <Component {...pageProps} />
                </PrimaryLayout>
            </IntegrationContextProvider>
        ) : (
            <PrimaryLayout>
                <Component {...pageProps} />
            </PrimaryLayout>
        );
    }
    // if (isPrevWallet === true && isLoggedIn === false) {
    //     return (
    //         <PrimaryLayout>
    //             <Component {...pageProps} />
    //         </PrimaryLayout>
    //     );
    // }
    return <Home {...pageProps} />;
}

export default RootHoc;
