import React, { useCallback, useEffect } from "react";
import {
    DropZoneWrapper,
    ImageWrapper,
    DropFileText,
    StyledButton,
    Overlays,
    DragContainer,
    ButtonWrapper,
} from "./styles";
import * as csv from "csv";
import FileAdd from "assets/fileadd.svg";
import Image from "next/image";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { useDropzone } from "react-dropzone";
import FileError from "assets/fileuploaderror.svg";
import CSVIcon from "assets/csv_file.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { Box } from "@mui/system";
import { Link, Typography } from "@mui/material";
import CustomIcons from "src/components/CustomIcons";

const DropFile = props => {
    const [moreThan1FileUploaded, setMoreThan1FileUploded] = React.useState(false);
    const onDrop = useCallback(
        (acceptedFiles, fileRejections) => {
            //clearing the state
            setMoreThan1FileUploded(false);
            try {
                if (!!fileRejections[0] || fileRejections.length > 1) {
                    if (fileRejections.length > 1) {
                        return setMoreThan1FileUploded(true);
                    }
                    const fileName = fileRejections[0].file.name;
                    return props.handleDrop(undefined, fileName);
                }

                const reader: any = new FileReader();
                reader.onload = () => {
                    csv.parse(reader.result, (err, data) => {
                        const fileName = acceptedFiles[0].path;

                        props.handleDrop(data ? data : undefined, fileName);
                    });
                };
                reader.readAsBinaryString(acceptedFiles[0]);
            } catch (err) {
                console.error(err);
                props.handleDrop(undefined, "Invalid file");
            }
        },
        [props],
    );
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        maxFiles: 1,
        accept: ".csv",
        onDrop,
        maxSize: 20 * 1000 * 1000, // 20mb max
        noDragEventsBubbling: false,
    });

    useEffect(() => {
        if (isDragActive) {
            props.setDragActive(true);
        } else {
            props.setDragActive(false);
        }
    }, [isDragActive]);
    return (
        <DropZoneWrapper
            dropactive={isDragActive}
            hasError={props.hasError || moreThan1FileUploaded}
        >
            {isDragActive && <Overlays />}
            <DragContainer {...getRootProps()}>
                <div>
                    <ImageWrapper hasError={props.hasError || moreThan1FileUploaded}>
                        {props.hasError ? (
                            <Image src={FileError} />
                        ) : props.loading ? (
                            <CircularProgressWithLabel />
                        ) : (
                            <Image src={FileAdd} />
                        )}
                    </ImageWrapper>
                </div>
                <div>
                    <DropFileText>
                        <input {...getInputProps()} />

                        {props.hasError || moreThan1FileUploaded ? (
                            <>
                                <Typography
                                    color="red"
                                    fontSize={14}
                                    marginBottom={"10px"}
                                    textAlign={"center"}
                                >
                                    {props.hasError
                                        ? `The file format is not valid please download`
                                        : `Please drop single file.`}
                                    <Link
                                        sx={{
                                            marginLeft: "8px",
                                        }}
                                        href={props.url}
                                        target={"_blank"}
                                        onClick={e => e.stopPropagation()}
                                    >
                                        sample sheet
                                    </Link>
                                </Typography>
                                {props.hasError && (
                                    <Typography textAlign={"center"}>{props?.fileName}</Typography>
                                )}
                            </>
                        ) : props.loading ? (
                            <>{props.fileName}</>
                        ) : (
                            <>
                                {" "}
                                Drop your .xls, .csv file OR{" "}
                                <span className="browse-file">Browse</span> file
                            </>
                        )}
                    </DropFileText>
                </div>
                <ButtonWrapper>
                    {props.hasError ? (
                        <StyledButton startIcon={<CustomIcons name="re_upload" color="#2962EF" />}>
                            <span> Re-Upload </span>
                        </StyledButton>
                    ) : props.loading ? (
                        <></>
                    ) : (
                        <a href={props.url} target="_blank">
                            <StyledButton
                                startIcon={<DownloadOutlinedIcon />}
                                onClick={event => event.stopPropagation()}
                            >
                                Download Sample Sheet
                            </StyledButton>
                        </a>
                    )}
                </ButtonWrapper>
            </DragContainer>
        </DropZoneWrapper>
    );
};

function CircularProgressWithLabel() {
    return (
        <Box id="loader" sx={{ m: 1, position: "relative" }}>
            <Image src={CSVIcon} />
            <CircularProgress
                size={125}
                sx={{
                    position: "absolute",
                    top: -37,
                    left: -42,
                    zIndex: 1,
                }}
            />
        </Box>
    );
}

export default DropFile;
