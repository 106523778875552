import styled from "@emotion/styled";
import { Box, Stack, Typography } from "@mui/material";

export const ReviewScreenWrapper = styled(Box)`
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 0px 20px;
    padding-bottom: 46px;
    .header {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 17px 20px;
        border-bottom: 1px solid #eaeaea;
        & > div:nth-of-type(1) {
            width: 50%;
        }

        & > div:nth-of-type(2) {
            width: 50%;
        }
    }

    .body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 17px;
        border-bottom: 1px solid #eaeaea;
        & > div:nth-child(1) {
            width: 50%;
        }

        & > div:nth-child(2) {
            width: 50%;
        }
    }

    .gas-limit-holder {
    }
`;

export const StackWrapper = styled(Stack)`
    padding: 20px 0px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    margin-top: 20px;

    .gas-fee-display {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        font-weight: 400;
        color: ${({ theme }: any) => theme.palette.primary.main};
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .divider-dot {
        font-size: 5px;
        margin: 0 7px;
    }
    .summary-value {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        font-weight: 600;
    }
`;

export const CollapseBodyWrapper: any = styled.div`
    display: flex;
    border-bottom: ${({ isLastIndex }: any) => (isLastIndex ? "none" : "1px solid #e6e7ea")};
    padding: 8px 0px;
    font-size: 14px;
`;

export const TokenImg = styled.img`
    width: 20px;
    height: 20px;
    margin-left: 10px;
`;
