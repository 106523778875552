import React from "react";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { Button } from "@mui/material";

function DeleteModal({ setOpen, open, count, tag, handleBulkDelete }) {
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                zIndex: 10000,
            }}
            fullWidth
        >
            <DialogTitle
                style={{ color: "#25274F", fontSize: 18, fontWeight: 600 }}
                id="alert-dialog-title"
            >
                {"Delete Confirmation"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    style={{ color: "#636B81", fontSize: 16, fontWeight: 400 }}
                    id="alert-dialog-description"
                >
                    Are you sure you want to delete <strong>{count}</strong> row
                    {count > 1 ? "s" : ""} from <strong>{tag}</strong> Tag ?
                </DialogContentText>
            </DialogContent>
            <DialogActions
                style={{
                    background: "#F5F8FE",
                    padding: 10,
                }}
            >
                <Button
                    variant="contained"
                    style={{
                        color: "#636B81",
                        background: "white",
                        border: "1px solid #DFE1E5",
                        fontSize: 14,
                    }}
                    onClick={() => setOpen(!open)}
                >
                    Cancel
                </Button>
                <Button
                    style={{ fontSize: 14 }}
                    variant="contained"
                    color="primary"
                    onClick={handleBulkDelete}
                    autoFocus
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteModal;
