import {
    Box,
    Checkbox,
    CircularProgress,
    IconButton,
    InputAdornment,
    MenuItem,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import NameAddress from "src/components/NameAddress";
import { ErrorRowWrapper, Row } from "./styles";
import InputComponent from "src/components/InputComponent";
import TransferContext from "src/contexts/TransferContext";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { formatAddress, isTagInSafe } from "src/helpers/utils/common";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import { formatAmount, minifyAddress } from "src/helpers/utils/web3Utils";
import _ from "lodash";
import { nativeTokenSymbol } from "src/queries/constants";
import SafeContext from "src/contexts/SafeContext";
import Decimal from "decimal.js-light";
import { useSnackbar } from "notistack";

export default function ErrorRow({
    handleSelectPeople,
    selectedRows,
    item,
    handleClick,
    tokensInSafe,
    safeTokenBalancesByTokenName,
    setCsvData,
    data,
    index,
    setBalances,
    tags,
    handleRemoveRow,
}) {
    const { enqueueSnackbar } = useSnackbar();
    const { selectedToken, setSelectedToken, setTokenSymbol }: any = useContext(TransferContext);
    const { safeTokenBalancesInDecimal }: any = useContext(SafeContext);
    const [name, setName] = useState("");
    const [address, setAddress] = useState("");
    const [token, setToken] = useState("");
    const [tokenAddress, setTokenAddress] = useState("");
    const [isTokenAvailable, setisTokenAvailable] = useState("");
    const [isAddressError, setisAddressError] = useState("");
    const [enableAmountField, setEnableAmountField] = useState(false);
    const [isBalanceError, setisBalanceError] = useState("");
    const [amount, setAmount] = useState<any>(0);
    const [totalBalances, setTotalBalances] = useState({});
    const [loading, setLoading] = useState(false);
    const [focus, setFocus] = useState(false);
    const [isPriceDiscoveryError, setIsPriceDiscoveryError] = useState("");

    React.useEffect(() => {
        setName(item.username);
        setAddress(item.address);
        setAmount(item.amount);
        setToken(item.symbol || item.token);
        setTokenAddress(item.tokenAddress);
        setisTokenAvailable(item.isTokenAvailable);
        setisBalanceError(item.isBalanceError);
        setisAddressError(item.isAddressError);
        setTotalBalances(safeTokenBalancesByTokenName);
        setIsPriceDiscoveryError(item.isFiatConversionMissing);
    }, []);

    // React.useEffect(() => {}, [item]);
    React.useEffect(() => {
        if (focus) {
            const _balances = _.cloneDeep(totalBalances);
            _balances[tokenAddress].totalTokenConsumed = _balances[tokenAddress].totalTokenConsumed
                ? _balances[tokenAddress].totalTokenConsumed -
                  (Number(item.amount) > 0 ? Number(item.amount) : 0)
                : 0;
            _balances[tokenAddress].totalUSDConsumed = _balances[tokenAddress].totalUSDConsumed
                ? _balances[tokenAddress].totalUSDConsumed - item.fiatValue
                : 0;
            setTotalBalances(_balances);
        }
    }, [focus]);

    const handleAmountChanged = e => {
        const amount = Number(e.target.value);
        const amountString = e.target.value;
        if (amount >= 0 && amountString) {
            let decimalPlaces = amountString && amountString.toString().split(".")[1]?.length;
            const decimals = tokensInSafe[tokenAddress].decimals;
            if (decimalPlaces && decimalPlaces > decimals) {
                enqueueSnackbar(`Should be 1 to ${decimals} decimals`, {
                    variant: "warning",
                });
                return;
            } else {
                setAmount(e.target.value);
            }
        } else {
            setAmount("");
        }

        if (amount > 0) {
            const _balances = _.cloneDeep(totalBalances);
            _balances[tokenAddress].totalTokenConsumed = amount;

            _balances[tokenAddress].totalUSDConsumed =
                Number(amount) * tokensInSafe[tokenAddress]?.fiatConversion ?? 0;
            setTotalBalances(_balances);
        }
    };

    const handleSave = async () => {
        if (!address || !tokenAddress) {
            return;
        }

        setLoading(true);

        let isStillError = false;
        if (Number(amount) > 0) {
            if (
                totalBalances[tokenAddress].totalTokenConsumed >
                totalBalances[tokenAddress].totalValueInEther
            ) {
                isStillError = true;
                setisBalanceError(
                    `Insufficient Balance, Max ${token} : ${safeTokenBalancesInDecimal[tokenAddress]} ${token}`,
                );
            } else {
                item.isBalanceError = null;
            }
        } else {
            isStillError = true;
            if (Number(amount) < 0) setisBalanceError("Amount shoud be greater than 0");
            else setisBalanceError("Amount is required");
        }

        if (address) {
            if (!address) {
                item.isAddressError = "Address is required";
                isStillError = true;
                setisAddressError("Address is required");
            }
            if (address && item.isAddressError) {
                const resolvedAddress = await formatAddress(address);
                if (!resolvedAddress.isAddress && !resolvedAddress.isENS) {
                    isStillError = true;
                    item.isAddressError = "Address is Still invalid";
                    setisAddressError("Address/ENS is Still invalid");
                }
            }
        }

        if (!isTagInSafe(item.tagName, tags)) {
            isStillError = true;
            setLoading(false);
            return enqueueSnackbar("Invalid tags. Add tags which are already in the safe", {
                variant: "error",
            });
        }

        if (item.token && item.isTokenAvailable) {
            if (!safeTokenBalancesByTokenName[tokenAddress]) {
                isStillError = true;
                item.isTokenAvailable = "token not valid";
                setisTokenAvailable("token not valid");
            }
        }

        if (!isStillError) {
            const newData: any = _.cloneDeep(data);
            newData[index].username = name;
            const resolvedAddress = await formatAddress(address);
            newData[index].address = resolvedAddress.to;
            newData[index].ens = resolvedAddress.ens;
            newData[index].amount = new Decimal(amount || 0);
            newData[index].token = token;
            newData[index].tokenAddress = tokenAddress;
            newData[index].fiatValue = Number(amount) * tokensInSafe[tokenAddress]?.fiatConversion;
            newData[index].isErrorAtRow = false;
            newData[index].isTokenAvailable = null;
            newData[index].isAddressError = null;
            newData[index].isBalanceError = null;
            newData[index].fiatConversion = tokensInSafe[tokenAddress]?.fiatConversion;
            newData[index].logoUri = tokensInSafe[tokenAddress]?.logoUri;
            newData[index].decimals = tokensInSafe[tokenAddress]?.decimals;
            newData[index].symbol = tokensInSafe[tokenAddress]?.symbol;
            const _balanceToUpdate = _.cloneDeep(safeTokenBalancesByTokenName);
            if (_balanceToUpdate[tokenAddress]) {
                if (item.tokenAddress) {
                    if (item.tokenAddress.toLowerCase() !== tokenAddress.toLowerCase()) {
                        _balanceToUpdate[item.tokenAddress].totalRows = _balanceToUpdate[
                            item.tokenAddress
                        ].totalRows
                            ? _balanceToUpdate[item.tokenAddress].totalRows - 1
                            : 0;
                        _balanceToUpdate[tokenAddress].totalRows = _balanceToUpdate[tokenAddress]
                            .totalRows
                            ? _balanceToUpdate[tokenAddress].totalRows + 1
                            : 1;
                    }
                } else {
                    _balanceToUpdate[tokenAddress].totalRows = _balanceToUpdate[tokenAddress]
                        .totalRows
                        ? _balanceToUpdate[tokenAddress].totalRows + 1
                        : 1;
                }

                setBalances(_balanceToUpdate);
            }
            setCsvData(newData);
        }

        setLoading(false);
    };

    useEffect(() => {
        if (item && item.data && item.data.tokenAddress) setSelectedToken(item.data.tokenAddress);
    }, [item]);

    const setTokenBalance = (selectedTokenAddress = null, isTokenChanged = false) => {
        const _balances = _.cloneDeep(totalBalances);
        _balances[selectedTokenAddress || tokenAddress].totalTokenConsumed = _balances[
            selectedTokenAddress || tokenAddress
        ].totalTokenConsumed
            ? _balances[selectedTokenAddress || tokenAddress].totalTokenConsumed + Number(amount)
            : Number(amount);

        if (isTokenChanged) {
            if (_balances[item.tokenAddress]) {
                _balances[item.tokenAddress].totalRows = _balances[item.tokenAddress].totalRows
                    ? _balances[selectedTokenAddress].totalRows + 1
                    : 0;
            }

            _balances[selectedTokenAddress].totalRows = _balances[selectedTokenAddress].totalRows
                ? _balances[selectedTokenAddress].totalRows + 1
                : 1;
        }

        _balances[selectedTokenAddress || tokenAddress].totalUSDConsumed = _balances[
            selectedTokenAddress || tokenAddress
        ].totalTokenConsumed
            ? _balances[selectedTokenAddress || tokenAddress].totalUSDConsumed +
              Number(amount) * item.fiatConversion
            : Number(amount) * item.fiatConversion;

        setTotalBalances(_balances);
    };

    return (
        <ErrorRowWrapper className="error">
            <Box className="row">
                <Box>
                    <Checkbox
                        onChange={e => handleSelectPeople(e, item, index)}
                        checked={selectedRows.includes(index)}
                        inputProps={{ "aria-label": "controlled" }}
                    ></Checkbox>
                </Box>
                <Box>
                    <NameAddress
                        name={item?.username}
                        address={item?.address}
                        ens={item?.ens ? item?.ens : null}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    {item.symbol ? (
                        <>
                            <img
                                style={{
                                    height: "20px",
                                    width: "20px",
                                    borderRadius: "50%",
                                }}
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = UndiscoveredCoin.src;
                                }}
                                src={item?.logoUri}
                            />
                            <Typography marginLeft={"8px"} color={"#232427"} fontWeight={500}>
                                {`${item?.amount} ${item.symbol} `}{" "}
                                <Typography component={"span"} fontSize={12}>
                                    {item.symbol === nativeTokenSymbol
                                        ? ""
                                        : `(${minifyAddress(item.tokenAddress)})`}
                                </Typography>
                            </Typography>
                        </>
                    ) : null}
                </Box>
                <Box>
                    <Typography marginLeft={"8px"} color={"#232427"} fontWeight={500}>
                        {isNaN(Number(item?.fiatConversion))
                            ? "--"
                            : `$${formatAmount(item?.fiatConversion, 2, 2)}`}
                    </Typography>
                </Box>
                <Box>
                    <Typography marginLeft={"8px"} color={"#232427"} fontWeight={500}>
                        {isNaN(Number(item?.fiatValue))
                            ? "--"
                            : `$${formatAmount(item?.fiatValue, 2, 2)}`}
                    </Typography>
                </Box>
                <Box sx={{ alignSelf: "flex-end" }}>
                    {/* <IconButton
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                            background: "#F2F2F2",
                            borderRadius: "4px",
                        }}
                    >
                        <MoreVertIcon color="disabled"></MoreVertIcon>
                    </IconButton> */}
                </Box>
            </Box>
            <Box className="name">
                <Box></Box>
                <Box>
                    <InputComponent
                        styles={{
                            background: "white",
                            paddingTop: "0px",
                            height: "34px",
                        }}
                        wrapperStyle={{
                            paddingTop: "0px",
                        }}
                        placeholder="Enter Name (Optional)"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            "aria-label": "weight",
                        }}
                        value={name}
                        disabled={name == "" ? true : false}
                        onChange={e => setName(e.target.value)}
                    />
                </Box>
                <Box>
                    <InputComponent
                        styles={{
                            background: "white",
                            paddingTop: "0px",
                            height: "34px",
                        }}
                        wrapperStyle={{
                            paddingTop: "0px",
                        }}
                        placeholder="Add Address"
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            "aria-label": "weight",
                        }}
                        value={address}
                        disabled={isAddressError ? false : true}
                        onChange={e => setAddress(e.target.value)}
                    />
                    {isAddressError ? (
                        <Typography marginTop={"4px"} color="#EC5952">
                            {isAddressError}
                        </Typography>
                    ) : null}
                </Box>
            </Box>
            <Box className="token">
                <Box></Box>
                <Box>
                    <InputComponent
                        type="select"
                        value={tokenAddress}
                        styles={{
                            background: "white",
                            paddingTop: "0px",
                            height: "34px",
                        }}
                        wrapperStyle={{
                            paddingTop: "0px",
                        }}
                        options={Object.values(tokensInSafe).map((token: any) => {
                            return (
                                <MenuItem
                                    sx={{
                                        fontSize: "14px",
                                        height: "40px",
                                        width: "100%",
                                        justifyContent: "space-between",
                                    }}
                                    key={token.tokenAddress || nativeTokenSymbol}
                                    value={token.tokenAddress || nativeTokenSymbol}
                                    onClick={() => {
                                        if (token.tokenAddress) {
                                            setisTokenAvailable(null);
                                            setEnableAmountField(true);
                                            setToken(token.symbol);
                                            setTokenAddress(token.tokenAddress);
                                            setTokenBalance(token.tokenAddress, true);
                                        }
                                    }}
                                >
                                    <Box sx={{ display: "flex" }}>
                                        <img
                                            width={20}
                                            height={20}
                                            src={token.logoUri || ""}
                                            alt={selectedToken}
                                            className="token-logo"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null; // prevents looping
                                                currentTarget.src = UndiscoveredCoin.src;
                                            }}
                                        />
                                        <Typography
                                            fontWeight={500}
                                            color="#232427"
                                            marginLeft={"10px"}
                                        >
                                            {" "}
                                            {token.name}
                                        </Typography>
                                        <Typography id="hide" color="#9998A4" marginLeft={"15px"}>
                                            {token.symbol}
                                        </Typography>
                                    </Box>

                                    {token.symbol !== nativeTokenSymbol ? (
                                        <Typography
                                            id="hide"
                                            marginLeft={"12px"}
                                            fontSize={12}
                                            color="#9998A4"
                                        >
                                            ({minifyAddress(token.tokenAddress)})
                                        </Typography>
                                    ) : null}
                                </MenuItem>
                            );
                        })}
                        onChange={e => {
                            setToken(tokensInSafe[e.target.value].symbol);
                            setSelectedToken(e.target.value);
                        }}
                        placeholder="Token"
                        name="token"
                    />
                    {isTokenAvailable ? (
                        <Typography marginTop={"4px"} color="#EC5952">
                            {isTokenAvailable}
                        </Typography>
                    ) : null}
                </Box>
                <Box>
                    <InputComponent
                        type="number"
                        styles={{
                            background: "white",
                            paddingTop: "0px",
                            height: "34px",
                        }}
                        wrapperStyle={{
                            paddingTop: "0px",
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            "aria-label": "weight",
                        }}
                        placeholder="0"
                        endAdornment={
                            <InputAdornment
                                sx={{
                                    paddingRight: "8px",
                                }}
                                position="end"
                            >
                                {item.isTokenAvailable ? "" : token}
                            </InputAdornment>
                        }
                        value={amount}
                        disabled={
                            !tokenAddress
                                ? true
                                : isBalanceError ||
                                  isTokenAvailable ||
                                  enableAmountField ||
                                  isPriceDiscoveryError
                                ? false
                                : true
                        }
                        onChange={e => handleAmountChanged(e)}
                        onFocus={e => setFocus(true)}
                    />
                    {isBalanceError ? (
                        <Typography marginTop={"4px"} color="#EC5952">
                            {isBalanceError}
                        </Typography>
                    ) : null}
                </Box>
                <Box>
                    <InputComponent
                        styles={{
                            background: "white",
                            paddingTop: "0px",
                            height: "34px",
                        }}
                        wrapperStyle={{
                            paddingTop: "0px",
                        }}
                        aria-describedby="outlined-weight-helper-text"
                        inputProps={{
                            "aria-label": "weight",
                        }}
                        placeholder="0"
                        endAdornment={
                            <InputAdornment
                                sx={{
                                    paddingRight: "8px",
                                }}
                                position="end"
                            >
                                USD
                            </InputAdornment>
                        }
                        disabled={true}
                        value={
                            !tokenAddress
                                ? "--"
                                : isNaN(Number(amount) * tokensInSafe[tokenAddress]?.fiatConversion)
                                ? "--"
                                : (Number(amount) * tokensInSafe[tokenAddress]?.fiatConversion)
                                      .toFixed(2)
                                      .toLocaleString()
                        }
                    />
                    {isPriceDiscoveryError ? (
                        <Typography marginTop={"4px"} color="#EC5952">
                            {isPriceDiscoveryError}
                        </Typography>
                    ) : null}
                </Box>
                <Box>
                    {loading ? (
                        <CircularProgress
                            sx={{
                                margin: "0px 8px",
                                height: "24px",
                                width: "24px",
                            }}
                        />
                    ) : (
                        <IconButton onClick={handleSave}>
                            <CheckIcon color="success"></CheckIcon>
                        </IconButton>
                    )}
                    <IconButton onClick={() => handleRemoveRow(item, index)}>
                        <CloseIcon color="error"></CloseIcon>
                    </IconButton>
                </Box>
            </Box>
        </ErrorRowWrapper>
    );
}
