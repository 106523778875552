import Safe from "@safe-global/protocol-kit";
import { useState, useEffect } from "react";

export const useSafeSdk = (ethAdapter, safeAddress) => {
    const [safeSdk, setSafeSdk] = useState<any>(null);
    useEffect(() => {
        createSafeSdkInstance();
    }, [safeAddress, ethAdapter]);
    const createSafeSdkInstance = async () => {
        if (ethAdapter !== null && safeAddress) {
            const safeSdk: Safe = await Safe.create({
                ethAdapter: ethAdapter,
                safeAddress,
                isL1SafeMasterCopy: true,
            });
            setSafeSdk(safeSdk);
        }
    };
    return { safeSdk };
};
