import styled from "@emotion/styled";
import { Stack, Typography } from "@mui/material";

export const SearchContributorsStyled = styled.div`
    width: 100%;
    position: relative;
    .input-text {
        font-size: 14px;
        line-height: 1;
        width: 100%;
        border: 1px solid ${({ theme }: any) => theme.textbox.border};
        padding: 0 8px;
        height: 45px;
        align-items: center;
        border-radius: 4px;
    }
    .search-results {
        background: #ffffff;
        border: 1px solid #ebebeb;
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 64px 128px rgba(0, 0, 0, 0.08);
        border-radius: 4px;
        margin-top: 6px;
        position: absolute;
        z-index: 1501;
        width: 100%;
        padding-bottom: 0px;
        .nickname {
            color: #25274f;
        }
        .address {
            color: #8b91a1;
        }
    }
`;

export const KeyboardShortcut: any = styled.span`
    font-size: 12px;
    color: #5e5c6c;
    white-space: nowrap;
`;

export const SearchResult = styled(Stack)`
    padding: 10px 12px;
    height: 40px;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: #efefef;
    }
    .identicon {
        border-radius: 50%;
        margin-right: 6px;
    }
    .nickname {
        font-size: 13px;
        line-height: 13px;
    }
    .address {
        font-size: 13px;
        line-height: 13px;
        text-align: right;
        color: ${({ theme }: any) => theme.palette.title.light};
        margin-left: auto;
    }
`;

export const EmptyStateText: typeof Typography = styled(Typography)`
    color: #8b91a1;
    font-size: 13px;
`;

export const SearchLoader = styled(Stack)`
    align-items: center;
    justify-content: center;
    padding: 10px 0;
    color: ${({ theme }: any) => theme.table.headertext};
`;
