import styled from "@emotion/styled";
import { Box, Stack } from "@mui/material";

export const ReviewScreenWrapper = styled(Box)`
    width: 100%;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 0px 20px;
    padding-bottom: 46px;
    .header {
        display: flex;
        justify-content: start;
        align-items: center;
        padding: 17px 20px;
        border-bottom: 1px solid #eaeaea;
        & > div:nth-child(1) {
            width: 35%;
        }

        & > div:nth-child(2) {
            width: 35%;
        }
        & > div:nth-child(3) {
            width: 30%;
        }
    }

    .body {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 13px 17px;
        border-bottom: 1px solid #eaeaea;
        & > div:nth-child(1) {
            width: 35%;
        }

        & > div:nth-child(2) {
            width: 35%;

            text-align: right;
        }
        & > div:nth-child(3) {
            width: 30%;
        }
    }

    .gas-limit-holder {
    }
`;

export const StackWrapper = styled(Stack)`
    padding: 20px 0px;
    border-top: 1px solid #eaeaea;
    border-bottom: 1px solid #eaeaea;
    margin-top: 20px;

    .gas-fee-display {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        font-weight: 400;
        color: ${({ theme }: any) => theme.palette.primary.main};
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    .divider-dot {
        font-size: 5px;
        margin: 0 7px;
    }
    .summary-value {
        font-size: 14px;
        line-height: 14px;
        margin: 0;
        font-weight: 600;
    }
`;
