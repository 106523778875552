import styled from "@emotion/styled";

export const DockableModalStyled: any = styled.div`
    .overlay {
        display: ${({ modalState }: any) => (modalState === "maximized" ? "block" : "none")};
        position: fixed;
        z-index: 1499;
        background-color: rgba(0, 0, 0, 0.65);
        width: 100vw;
        height: 100vh;
        top: 0;
        left: 0;
        animation: fadein 0.2s;
    }
    .window {
        position: fixed;
        top: 50%;
        right: 50%;
        width: 100%;
        height: 100%;
        transform: translate(50%, -50%);
        background-color: white;
        border-radius: 0px;
        z-index: 1500;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
        overflow: hidden;
        transition: top 0.75s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
            right 0.75s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
            transform 0.75s 0s cubic-bezier(0.1, 1.2, 0.3, 1),
            width 0.75s 0s cubic-bezier(0.1, 1.2, 0.3, 1), opacity 0.3s;
        .minimized-control {
            display: none;
        }
        .control-menu {
            position: absolute;
            right: 0;
            top: 0;
            padding: 0;
            .control-btn {
                /* //padding: 10px 10px; */
                margin: 0;
            }
        }
        .window-content {
            height: 100%;
        }
    }

    .minimized {
        .window {
            top: 100%;
            right: 0;
            transform: translate(-100px, -45px);
            width: 300px;

            &:hover {
                opacity: 0.75;
            }

            .maximize {
                top: 0;
                opacity: 1;
                height: 45px;
                transition: opacity 0.3s 0.5s, top 0s 0.5s;
            }
            .control-menu {
                display: none;
            }
            .minimized-control {
                display: block;
                background-color: ${({ theme }: any) => theme.palette.primary.main};
                height: 100%;
                text-align: center;
                padding-top: 12px;
                cursor: pointer;
                p {
                    margin: 0;
                    text-align: center;
                    color: #fff;
                    font-size: 18px;
                    cursor: pointer;
                }
            }
        }
    }

    .text {
        position: fixed;
        top: 50%;
        left: 50%;
        color: white;
        transform: translate(350px, -200px);
        z-index: 10;
        padding-left: 20px;

        .title {
            font-size: 20px;
            line-height: 20px;
            display: block;
            margin-bottom: 5px;
        }

        .name {
            display: block;
            opacity: 0.5;
        }
    }
`;
