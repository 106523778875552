import { queryClient } from "pages/_app";
import { useQuery, useMutation } from "react-query";
import { gql } from "graphql-request";
import * as Sentry from "@sentry/nextjs";
import {
    baseURI,
    GET_CURRENT_SAFE_DETAILS,
    GET_TOKENS_BY_SAFE,
    gnosisURL,
    EDIT_WALLET_AS_SAFE_OWNER,
    EDIT_SAFE,
    baseURLForCheckingMetaTxLimits,
    GET_SAFE_DELEGATES,
    ADD_DELEGATE,
    ADD_DELEGATE_ON_GNOSIS,
    DELETE_DELEGATE_ON_GNOSIS,
    baseURLForSyncWithGnosis,
    UPDATE_WHITELIST_TOKENS,
    UPDATE_CUSTOM_CATEGORY,
    GET_CHILD_SAFES_DATA,
    GET_PARCEL_SAFES,
    UPDATE_SPAM_TOKENS,
    UPDATE_SPAM_TRANSACTIONS,
    UPDATE_SPAM_SETTINGS,
} from "../constants";
import { graphQLClient } from "../graphqlClient";
import fetcher from "../fetch";

export function useGetTokensAndPriceBySafe(safeAddress, options = {}) {
    return useQuery(
        [GET_TOKENS_BY_SAFE, safeAddress],
        async () => {
            if (safeAddress === undefined || safeAddress === null) {
                throw Error("Invalid Safe Address");
            }

            return await fetcher(
                `${gnosisURL}/safes/${safeAddress}/balances/usd/?trusted=false&exclude_spam=false`,
                {},
            );
        },
        { enabled: !!safeAddress, ...options },
    );
}
// export function useGetChildSafes(address,{option}) {
//     return useQuery(["get-child-safe", address], async () => {
//         if (address === undefined || safeAddress === null) {
//             throw Error("Invalid Safe Address");
//         }
//     });
// }
export function useGetCurrentSafeDetails(safeAddress, options = {}) {
    return useQuery([GET_CURRENT_SAFE_DETAILS, safeAddress], async () => {
        if (safeAddress) {
            const { safe: safe } = await graphQLClient(baseURI).request(
                gql`
                    query Safe($safeAddress: String) {
                        safe(safeAddress: $safeAddress) {
                            safeAddress
                            safeName
                            threshold
                            networkId
                            activeSeason {
                                seasonId
                                name
                                startDate
                                endDate
                                status
                                budgetConstraint {
                                    token
                                    tokenAddress
                                    amount
                                    fiatValue
                                    isFixedUSD
                                }
                                approvedBudgetDisbursement {
                                    token
                                    tokenAddress
                                    amount
                                    fiatValue
                                }
                            }
                            owners {
                                address
                                ens
                                nickName
                                ownerEmail
                            }
                            parentSafe {
                                safeName
                                safeAddress
                            }
                            isSquadOrParent
                            squads {
                                safeName
                                safeAddress
                            }
                            isMetaTxEnabled
                            whitelistedTokens
                            enableSpam
                            spamTokens
                            spamTransactions
                            categories {
                                name
                                isEnabled
                            }
                            safeStats {
                                pendingPaymentRequests
                                approvedPaymenttRequests
                                upcomingRecurringPayments
                            }
                        }
                    }
                `,
                {
                    safeAddress,
                },
            );
            return safe;
        } else {
            return;
        }
    });
}

export function useGetCurrentSafeDelegates(safeAddress) {
    return useQuery([GET_SAFE_DELEGATES, safeAddress], async () => {
        if (safeAddress) {
            const { delegates } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    query Delegates {
                        delegates {
                            address
                            safeAccess
                            nickName
                            ens
                        }
                    }
                `,
            );
            return delegates;
        } else {
            return;
        }
    });
}

export function useAddDelegates() {
    const mutation = useMutation(
        async ({ safeAddress, address, safeAccess }: any) => {
            const { editContributorAccess } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation editContributorAccess($address: String!, $safeAccess: SafeAccess) {
                        editContributorAccess(address: $address, safeAccess: $safeAccess) {
                            safeAddress
                        }
                    }
                `,
                { address, safeAccess },
            );
            return editContributorAccess;
        },
        {
            mutationKey: ADD_DELEGATE,
            onSuccess: data => {
                // console.log("successfully updated safe", data);
                queryClient.invalidateQueries(GET_SAFE_DELEGATES);
            },
        },
    );
    return mutation;
}

export function useAddDelegateOnGnosis() {
    return useMutation(
        async ({ safe, delegate, delegator, signature, label }: any) => {
            const response = await fetcher(`${gnosisURL}/delegates/`, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    safe,
                    delegate,
                    delegator,
                    signature,
                    label,
                }),
            });
            return response;
        },
        {
            mutationKey: ADD_DELEGATE_ON_GNOSIS,
        },
    );
}

export function useDeleteDelegateOnGnosis() {
    return useMutation(
        async ({ delegate, delegator, signature }: any) => {
            const response = await fetcher(`${gnosisURL}/delegates/${delegate}`, {
                method: "DELETE",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify({
                    delegate,
                    delegator,
                    signature,
                }),
            });
            return response;
        },
        {
            mutationKey: DELETE_DELEGATE_ON_GNOSIS,
        },
    );
}

export function useEditSafe() {
    return useMutation(
        async ({ safeName, safeAddress, networkId, threshold }: any) => {
            const { editSafe } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation EditSafe($newSafeParams: SafeParams) {
                        editSafe(newSafeParams: $newSafeParams) {
                            safeName
                        }
                    }
                `,
                {
                    newSafeParams: {
                        safeAddress,
                        safeName,
                        networkId,
                        threshold,
                    },
                },
            );
            return editSafe;
        },
        {
            mutationKey: EDIT_SAFE,
            onSuccess: data => {
                // console.log("successfully updated safe", data);
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
                queryClient.invalidateQueries(GET_PARCEL_SAFES);
            },
        },
    );
}

export function useEditWalletAsSafeOwner() {
    return useMutation(
        async ({ safeAddress, address, nickName, email }: any) => {
            const { editWalletAsSafeOwner } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation EditWalletAsSafeOwner(
                        $address: String!
                        $nickName: String
                        $email: String
                    ) {
                        editWalletAsSafeOwner(
                            address: $address
                            nickName: $nickName
                            email: $email
                        ) {
                            nickName
                            owners {
                                name
                                nickName
                                address
                                ownerEmail
                            }
                        }
                    }
                `,
                {
                    address,
                    nickName,
                    email,
                },
            );
            return editWalletAsSafeOwner;
        },
        {
            mutationKey: EDIT_WALLET_AS_SAFE_OWNER,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
            },
        },
    );
}

export async function CheckIfLimitsAreNotConsumed(safeAddress) {
    try {
        const responseGetUrl = await fetch(baseURLForCheckingMetaTxLimits, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({
                userAddress: safeAddress,
            }),
        });

        const { responseCode, allowed } = await responseGetUrl.json();

        return responseCode === 200 && allowed;
    } catch (err) {
        console.error("error in fetching limits", err.message);
        Sentry.captureException(err);
        return false;
    }
}

export async function syncUpWithGnosis(safeAddress, networkId) {
    try {
        const responseGetUrl = await fetch(baseURLForSyncWithGnosis, {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify({
                safeAddress,
                networkId,
            }),
        });

        const { success } = await responseGetUrl.json();

        return Boolean(success);
    } catch (err) {
        console.error("error in updating database with gnosis", err.message);
        Sentry.captureException(err);
        return false;
    }
}

export function useUpdateWhitelistTokens() {
    return useMutation(
        async ({ safeAddress, tokenAddress }: any) => {
            const { updateWhitelistedTokens } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation($tokenAddress: [String]) {
                        updateWhitelistedTokens(tokenAddress: $tokenAddress) {
                            safeAddress
                            whitelistedTokens
                        }
                    }
                `,
                {
                    tokenAddress,
                },
            );
            return updateWhitelistedTokens;
        },
        {
            mutationKey: UPDATE_WHITELIST_TOKENS,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
            },
        },
    );
}

export function useUpdateSpamTokens() {
    return useMutation(
        async ({ safeAddress, tokenAddress }: any) => {
            const data = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation($tokenAddress: [String]) {
                        updateSpamTokens(tokenAddress: $tokenAddress) {
                            safeAddress
                        }
                    }
                `,
                {
                    tokenAddress,
                },
            );
            return data;
        },
        {
            mutationKey: UPDATE_SPAM_TOKENS,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
            },
        },
    );
}

export function useUpdateSpamTransaction() {
    return useMutation(
        async ({ safeAddress, transactionHashOrNonce }: any) => {
            const data = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation($transactionHashOrNonce: [String]) {
                        updateSpamTransactions(transactionHashOrNonce: $transactionHashOrNonce) {
                            safeAddress
                        }
                    }
                `,
                {
                    transactionHashOrNonce,
                },
            );
            return data;
        },
        {
            mutationKey: UPDATE_SPAM_TRANSACTIONS,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
            },
        },
    );
}

export function useUpdateSpamSettings() {
    return useMutation(
        async ({ safeAddress, enableSpam }: any) => {
            const data = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation($enableSpam: Boolean) {
                        updateEnableSpam(enableSpam: $enableSpam) {
                            safeAddress
                        }
                    }
                `,
                {
                    enableSpam,
                },
            );
            return data;
        },
        {
            mutationKey: UPDATE_SPAM_SETTINGS,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
            },
        },
    );
}

export function useUpdateCustomCategories() {
    return useMutation(
        async ({ safeAddress, categories }: any) => {
            const { updateCategories } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation($categories: [CategoryParams]) {
                        updateCategories(categories: $categories) {
                            safeAddress
                            categories {
                                name
                                isEnabled
                            }
                        }
                    }
                `,
                {
                    categories,
                },
            );
            return updateCategories;
        },
        {
            mutationKey: UPDATE_CUSTOM_CATEGORY,
            onSuccess: () => {
                queryClient.invalidateQueries(GET_CURRENT_SAFE_DETAILS);
            },
        },
    );
}

export function useGetChildSafesMetaData(safeAddresses) {
    return useQuery([GET_CHILD_SAFES_DATA, safeAddresses], async () => {
        if (safeAddresses.length) {
            const data = await graphQLClient(baseURI).request(
                gql`
                    query NestedSafes($safeAddresses: [String]) {
                        nestedSafes(safeAddresses: $safeAddresses) {
                            safeName
                            safeAddress
                        }
                    }
                `,
                {
                    safeAddresses,
                },
            );
            return data;
        } else {
            return;
        }
    });
}
