import React, { useState } from "react";
import { UploadFileScreenWrapper, ThingsToRemember, KeyPointsWrapper } from "./styles";
import DropFile from "./DropFile";

export default function DropzoneScreen({
    handleDrop,
    hasError,
    keyPoints,
    fileName,
    url,
    setDragActive,
    loading = false,
}) {
    return (
        <UploadFileScreenWrapper>
            <DropFile
                handleDrop={handleDrop}
                hasError={hasError}
                fileName={fileName}
                url={url}
                setDragActive={setDragActive}
                loading={loading}
            />
            <div className="file-size">Max. File Size: 20MB</div>

            <ThingsToRemember>
                <div className="heading">Things to keep in mind</div>
                <KeyPointsWrapper>
                    <ul className="points">
                        {keyPoints.map((item, idx) => (
                            <li key={idx}>
                                {idx + 1}. {item}
                            </li>
                        ))}
                    </ul>
                </KeyPointsWrapper>
            </ThingsToRemember>
        </UploadFileScreenWrapper>
    );
}
