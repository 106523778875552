import { gnosisSafeTransactionEndpoint } from "src/constants/endpoints";
import { gnosisURL } from "src/queries/constants";
import { networkNames } from "src/helpers/utils/networks";
import * as Sentry from "@sentry/nextjs";

export const getGasEstimateBackup = async (safeAddress, postData) => {
    // console.log({ safeAddress, postData, currentCallCount, safeTransactionCallCount });
    if (process.env.NEXT_PUBLIC_NETWORK_NAME === networkNames.POLYGON) {
        const BASE_GAS = (postData.data?.length || 1) * 30 + 56000;

        try {
            const estimateResponse = await fetch(
                `${gnosisSafeTransactionEndpoint}${safeAddress}/multisig-transactions/estimations/`,
                {
                    method: "POST",
                    body: JSON.stringify(postData),
                    headers: {
                        "content-type": "application/json",
                    },
                },
            );

            const estimateResult = await estimateResponse.json();
            const gasCalc = Number(estimateResult?.safeTxGas || 0) + Number(BASE_GAS);
            return gasCalc;
        } catch (err) {
            Sentry.captureException(err);
            return 0;
        }
    } else {
        let estimateResponse;
        const BASE_GAS = (postData.data?.length || 1) * 30 + 56000;

        try {
            estimateResponse = await fetch(
                `${gnosisURL}/safes/${safeAddress}/multisig-transactions/estimations/`,
                {
                    method: "POST",
                    body: JSON.stringify(postData),
                    headers: {
                        "content-type": "application/json",
                    },
                },
            );

            const estimateResult = await estimateResponse.json();
            const gasCalc =
                Number(estimateResult?.safeTxGas || 0) +
                Number(estimateResult?.baseGas ?? BASE_GAS);
            return gasCalc;
        } catch (err) {
            Sentry.captureException(err);
            return 0;
        }
    }
};
