import { createContext, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/nextjs";
import {
    NATIVE_ICON_URL,
    nativeTokenName,
    nativeTokenSymbol,
    gnosisURL,
    ZERO_ADDRESS,
    FETCH_TOKEN_CONVERSION_ENDPOINT,
} from "src/queries/constants";
export const ShareableTransactionContext = createContext({});
import { useGetSafeDetailsFromGnosis } from "src/queries/Transaction/api";
import { useGetTokensAndPriceBySafe } from "src/queries/Safe/api";
import { TokenConversionFromGnosis } from "src/queries/ContributorRequest/api";
import { getAmountFromWei } from "src/helpers/tx-helpers";
import fetcher from "src/queries/fetch";
import { useActiveBaseContext } from "src/hooks";
import Safe from "@safe-global/protocol-kit";
import { BaseContextTypes } from "./BaseContextTypes";
import { getTokenContractAddress } from "mapping/tokenAddress";
import { networkId } from "src/helpers/utils/networks";
import { weiToString } from "src/helpers/bignumberUtils";
import Decimal from "decimal.js-light";
export default function ShareableTransactionContextProvider({ children }) {
    const { ethersAdapterOwner } = useActiveBaseContext().web3 as BaseContextTypes;
    const [safeSdk, setSafeSdk] = useState<Safe>(null);
    const [currentSafeAddress, setCurrentSafeAddress] = useState("");
    const [rawSafeTokenData, setRawSafeTokenData] = useState([]);
    const [tokensInSafe, setTokensInSafe] = useState({
        [nativeTokenSymbol]: {
            name: nativeTokenName,
            symbol: nativeTokenSymbol,
            logoUri: NATIVE_ICON_URL,
            decimals: 18,
            tokenAddress: nativeTokenSymbol,
            fiatConversion: 1,
        },
    });
    const [safeTokenBalancesByTokenName, setSafeTokenBalancesByTokenName] = useState({});
    const [safeTokenBalances, setSafeTokenBalances] = useState({});
    const [safeBalanceInUSD, setSafeBalanceInUSD] = useState({ [nativeTokenSymbol]: 0 });
    const [safeTokenBalancesInDecimal, setSafeTokenBalancesInDecimal] = useState({});

    const [safeStaticDetails, setSafeStaticDetails] = useState<any>({
        owners: [],
        isMetaTxEnabled: false,
        isSafeOwner: false,
    });

    const [listOfGnosisSafeOwner, setListOfGnosisSafeOwner] = useState([]);

    const safe = useGetSafeDetailsFromGnosis(currentSafeAddress, {
        enabled: Boolean(currentSafeAddress),
    });

    const { data, isSuccess, isError, isLoading } = useGetTokensAndPriceBySafe(currentSafeAddress, {
        enabled: Boolean(currentSafeAddress),
        cacheTime: 0,
        retry: false,
        refetchOnMount: false,
        retryOnMount: false,
    });

    useEffect(() => {
        if (isError) {
            return;
        }
        async function setUpToken() {
            if (data && isSuccess && !isError && !isLoading) {
                let newData = await Promise.all(
                    data?.map(async token => {
                        try {
                            const tokenAddressContract = token?.token
                                ? getTokenContractAddress(token?.tokenAddress)
                                : ZERO_ADDRESS;
                            const decimals = token?.token ? token?.token?.decimals : 18;
                            let fiatConversion = "0";
                            let fiatBalance = "0";

                            const res = await fetch(FETCH_TOKEN_CONVERSION_ENDPOINT, {
                                method: "POST",
                                body: JSON.stringify({
                                    tokenAddress: tokenAddressContract,
                                    networkId: networkId,
                                }),
                                headers: {
                                    "content-type": "application/json",
                                },
                            });
                            const resData = await res.json();
                            fiatConversion = resData?.value;

                            const tokenAmount = token?.balance;

                            const tokenAmountInDecimals = weiToString(tokenAmount || 0, decimals);
                            fiatBalance = new Decimal(tokenAmountInDecimals)
                                ?.mul(fiatConversion)
                                ?.toString();

                            return {
                                ...token,
                                fiatConversion,
                                fiatBalance,
                            };
                        } catch (err) {
                            return {
                                ...token,
                            };
                        }
                    }),
                );

                newData && newData.length > 0 && setRawSafeTokenData(newData);

                if (Array.isArray(newData)) {
                    resolvingTokensInSafe(newData);
                }

                Array.isArray(newData) &&
                    setSafeTokenBalances(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] =
                                item.balance;
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeTokenBalancesByTokenName(
                        newData?.reduce((obj, item) => {
                            obj[item.token ? item.token?.symbol : nativeTokenSymbol] = {
                                balance: Number(item.balance),
                                decimal: item.token ? item.token?.decimals : 18,
                                logoURI: item.token ? item.token?.logoUri : NATIVE_ICON_URL,
                                fiatConversion: item.fiatConversion,
                                fiatBalance: item.fiatBalance,
                                address: item.tokenAddress,
                            };
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeTokenBalancesInDecimal(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] =
                                getAmountFromWei(
                                    item.balance,
                                    item.tokenAddress ? item.token.decimals : 18,
                                );
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeBalanceInUSD(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] = Number(
                                item.fiatBalance,
                            );
                            return obj;
                        }, {}),
                    );
            }
        }
        setUpToken();
    }, [data, isSuccess, isError, isLoading, currentSafeAddress]);

    useEffect(() => {
        let gnosisOwner = [];

        const filterListOfOwners = async owners => {
            for await (const owner of owners) {
                try {
                    const isOwner = await fetcher(`${gnosisURL}/safes/${owner?.address}`, {});
                    if (isOwner?.address) {
                        gnosisOwner.push(owner?.address);
                    }
                } catch (err) {}
            }
            setListOfGnosisSafeOwner(gnosisOwner);
        };
        if (safeStaticDetails?.owners?.length > 0) {
            filterListOfOwners(safeStaticDetails?.owners);
        }
    }, [safeStaticDetails]);

    const resolvingTokensInSafe = async data => {
        let tokenData = await Promise.all(
            data.map(async token => {
                if (token.token) {
                    return {
                        ...token.token,
                        tokenAddress: token.tokenAddress,
                        fiatConversion: Number(token.fiatConversion),
                    };
                } else {
                    return {
                        name: nativeTokenName,
                        symbol: nativeTokenSymbol,
                        logoUri: NATIVE_ICON_URL,
                        decimals: 18,
                        tokenAddress: nativeTokenSymbol,
                        fiatConversion: Number(token.fiatConversion),
                    };
                }
            }),
        );
        let result = tokenData.reduce((agg, tokenItem) => {
            agg[tokenItem.tokenAddress] = tokenItem;
            return agg;
        }, {});
        setTokensInSafe(result);
    };

    const createSafeSDK = async (ethAdapter, safeAddress) => {
        if (ethAdapter !== null && safeAddress) {
            try {
                const safeSdk: Safe = await Safe.create({
                    ethAdapter: ethAdapter,
                    safeAddress,
                    isL1SafeMasterCopy: true,
                });
                setSafeSdk(safeSdk);
            } catch (err) {
                console.log("catch error in creating sdk");
                console.error(err);
                Sentry.captureException(err);
            }
        } else {
            console.log("catch while before creating sdk");
            console.log({ ethAdapter, safeAddress });
            Sentry.captureException("No Adapter or Safe Address to create Safe SDK");
        }
    };

    useEffect(() => {
        async function initialiseSafeSdk() {
            if (!safeSdk || (await safeSdk.getAddress()) !== currentSafeAddress) {
                if (ethersAdapterOwner !== null && currentSafeAddress) {
                    console.log("initialise sdk");
                    createSafeSDK(ethersAdapterOwner, currentSafeAddress);
                }
            }
        }
        initialiseSafeSdk();
    }, [currentSafeAddress, ethersAdapterOwner, safeSdk]);

    useEffect(() => {
        const intervalId = setInterval(async () => {
            try {
                //assign interval to a variable to clear it.
                if (!safeSdk || (await safeSdk.getAddress()) !== currentSafeAddress) {
                    if (ethersAdapterOwner !== null && currentSafeAddress) {
                        console.log("reinitialise sdk in interval");
                        createSafeSDK(ethersAdapterOwner, currentSafeAddress);
                    }
                } else {
                    clearInterval(intervalId);
                }
            } catch (err) {
                console.log("catching errorr", err);
            }
        }, 2000);
        return () => clearInterval(intervalId);
    });
    return (
        <ShareableTransactionContext.Provider
            value={{
                currentSafeAddress,
                setCurrentSafeAddress,
                rawSafeTokenData,
                safe,
                tokensInSafe,
                safeTokenBalancesByTokenName,
                safeTokenBalances,
                safeBalanceInUSD,
                safeTokenBalancesInDecimal,
                listOfGnosisSafeOwner,
                setSafeStaticDetails,
                safeStaticDetails,
                safeSdk,
            }}
        >
            {children}
        </ShareableTransactionContext.Provider>
    );
}
