import { LoadingButton } from "@mui/lab";
import styled from "@emotion/styled";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";

import theme from "styles/theme";
export const IconButton = styled(Button)``;

export const CustomNavLink: any = styled.div`
    margin-top: ${({ justifySelf }: any) => (justifySelf === "end" ? "auto" : 0)};
    position: relative;
    .shadow {
        height: 25px;
        width: 3px;
        border-radius: 0px 1.52426px 1.52426px 0px;
        position: absolute;
        left: -10px;
        top: 8px;
    }
    .newTag {
        height: 25px;
        width: 20%;
        border-radius: 0px 1.52426px 1.52426px 0px;
        position: absolute;
        left: 120px;
        top: 8px;
    }
    .newBadge {
        padding: 5px;
        background-color: #f1a252;
        color: #ffffff;
        border-radius: 5px;
        margin-left: 8px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 10px;
    }
    .MuiButtonBase-root.MuiListItem-root {
        display: flex;
        width: 100%;
        > div:nth-of-type(3) {
            margin-left: auto;
        }
        border-radius: 4px;
        margin-bottom: ${(props: any) => (props.mb ? "0px" : "15px")};
        background-color: ${(props: any) => (props.isactive ? "transparent" : "transparent")};
        .MuiListItemIcon-root {
            color: ${(props: any) =>
                props.isactive ? props.theme.palette.primary.main : props.theme.palette.title.main};
            min-width: 0;
            margin-right: 10px;
            .MuiSvgIcon-root {
                width: 0.9em;
                height: 0.9em;
            }
        }
        .MuiListItemText-root {
            color: ${(props: any) =>
                props.isactive ? props.theme.palette.primary.main : props.theme.palette.title.main};
            .MuiTypography-root {
                font-weight: ${(props: any) => (props.isactive ? 600 : 500)};
                line-height: 18px;
                font-size: 14px;
            }
        }
    }
`;

export const StyledOnBoardBtn = styled(LoadingButton)`
    box-shadow: none;
    text-transform: none;
    font-size: 16px;
    border: 1px solid #ededed;
    background-color: ${theme.background_color.main};
    color: ${theme.palette.title.main};
    &:hover {
        box-shadow: none;
        background-color: ${theme.background_color.main};
        color: ${theme.palette.title.main};
    }
    &:active {
        box-shadow: none;
        background-color: ${theme.background_color.main};
        color: ${theme.palette.title.main};
    }
`;

export const PrimaryButton: any = styled(Button)`
    text-transform: capitalize;
    box-shadow: none;
    background-color: ${({ disabled }: any) => (disabled ? "#EFF0F2" : null)};
`;

export const StyledLoadingButtonOutlined: any = styled(LoadingButton)`
    /* font-size: 14px; */
    font-weight: 600;
    border: 1px solid #2962ef;
    /* line-height: 14px;
    padding: 7.5px; */
`;

export const NumberCircle = styled(Badge)`
    .MuiBadge-colorWarning {
        background-color: #f1a252;
    }
    color: #ffffff;
    font-size: 10px;
    font-weight: 500;
    line-height: 10px;
    padding: 2px;
`;
