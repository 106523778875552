import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useContext } from "react";

const V3MigrationAssistant = () => {
    return (
        <Box
            sx={{
                width: "100%",
                background: "linear-gradient(90.05deg, #2962EF 0%, #25A8F3 100%)",
                height: "37px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <Typography
                sx={{
                    color: "#FFFFFF",
                    fontWeight: 700,
                    fontSize: "14px",
                    lineHeight: "21px",
                }}
            >
                📣 📣 We will be sunsetting V2 on March 31st, 2024. Reach out to us via{" "}
                <a
                    href={`https://discord.com/invite/S35FpHJM4Y`}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                        textDecoration: "underline",
                    }}
                >
                    Discord
                </a>{" "}
                to seamlessly transition to V3 📣 📣
            </Typography>
        </Box>
    );
};

export default V3MigrationAssistant;
