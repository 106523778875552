import { Close } from "@mui/icons-material";
import { Button, Stack } from "@mui/material";
import React, { useContext } from "react";
import { DockableModalStyled } from "./styles";
//import MinimizeCustom from "../common/MinimizeCustom";
import MinimizeIcon from "@mui/icons-material/Minimize";
import TransferContext from "src/contexts/TransferContext";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";

const DockableModal = ({ children, minimizedTitle }) => {
    const { modalState, maximizeModal, minimizeModal, closeModal }: any =
        useContext(TransferContext);
    const { resetPayout }: any = useContext(MassPayoutContext);
    return (
        <DockableModalStyled modalState={modalState}>
            <div className="overlay"></div>

            {modalState !== "closed" ? (
                <div className={modalState}>
                    <div className="window">
                        <div className="minimized-control" onClick={e => maximizeModal()}>
                            <p>{minimizedTitle}</p>
                        </div>
                        <div className="control-menu">
                            <Stack spacing={2} direction="row" alignItems={"center"}>
                                <Button
                                    className={"control-btn"}
                                    onClick={e => minimizeModal()}
                                    variant="text"
                                    color="inherit"
                                >
                                    <MinimizeIcon
                                        fontSize="medium"
                                        sx={{
                                            transform: "translateY(-7px)",
                                        }}
                                    />
                                </Button>

                                <Button
                                    className={"control-btn"}
                                    variant="text"
                                    color="inherit"
                                    onClick={e => {
                                        resetPayout();
                                        closeModal();
                                    }}
                                >
                                    <Close fontSize="medium" />
                                </Button>
                            </Stack>
                        </div>
                        <div className="window-content">{children}</div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </DockableModalStyled>
    );
};

DockableModal.defaultProps = {
    modalState: "minimized",
};

export default DockableModal;
