import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { StyledModal, DialogActionsStyled, StyledDialog, StyledDialogContent } from "./styles";
import Image from "next/image";
import MetaTxImg from "assets/MetaTx.svg";
import HighGas from "assets/HighGas.svg";
import ParcelLogo from "assets/parcel_logo.svg";

const BootstrapDialogTitle = props => {
    const { children, onClose, ...other } = props;

    return (
        <DialogTitle sx={{ m: 0, pt: 6, pl: 3.5, pb: 1.5 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: "absolute",
                        right: 20,
                        top: 16,
                        width: 30,
                        height: 30,
                        color: "#5E5C6C",
                    }}
                >
                    <CloseIcon style={{ width: 20, height: 20 }} />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function MetaTxModal({
    handleCloseConfirmationModal,
    open,
    handleConfirm,
    variant = "confirm",
}) {
    if (variant === "confirm")
        return (
            <StyledModal>
                <StyledDialog
                    onClose={handleCloseConfirmationModal}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        onClose={handleCloseConfirmationModal}
                    ></BootstrapDialogTitle>
                    <StyledDialogContent>
                        <div>
                            <Image src={MetaTxImg} className="metatx-img" />
                        </div>

                        <div className="title">Thank you for partnering with us to scale ops.</div>
                        <div className="subtitle">
                            This transaction is sponsored by{" "}
                            <img
                                src={ParcelLogo.src}
                                style={{
                                    marginLeft: "2px",
                                    verticalAlign: "middle",
                                }}
                            />
                        </div>
                    </StyledDialogContent>
                    <DialogActionsStyled>
                        <Button
                            className="cancel-btn"
                            autoFocus
                            variant="outlined"
                            onClick={handleCloseConfirmationModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="confirm-btn"
                            color="primary"
                            variant="contained"
                            disableRipple={false}
                            onClick={handleConfirm}
                        >
                            Confirm
                        </Button>
                    </DialogActionsStyled>
                </StyledDialog>
            </StyledModal>
        );
    if (variant == "highGas") {
        return (
            <StyledModal>
                <StyledDialog
                    onClose={handleCloseConfirmationModal}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        onClose={handleCloseConfirmationModal}
                    ></BootstrapDialogTitle>
                    <StyledDialogContent>
                        <div>
                            <Image src={HighGas} className="metatx-img" />
                        </div>
                        <div className="title">Gas Price seems to have spiked on Ethereum</div>
                        <div className="subtitle">
                            You can continue now by paying the gas fee or try again later
                        </div>
                    </StyledDialogContent>
                    <DialogActionsStyled>
                        <Button
                            className="cancel-btn"
                            autoFocus
                            variant="outlined"
                            onClick={handleCloseConfirmationModal}
                        >
                            Try later
                        </Button>
                        <Button
                            className="confirm-btn"
                            color="primary"
                            variant="contained"
                            disableRipple={false}
                            onClick={handleConfirm}
                        >
                            Continue, I’ll pay gas
                        </Button>
                    </DialogActionsStyled>
                </StyledDialog>
            </StyledModal>
        );
    } else
        return (
            <StyledModal>
                <StyledDialog
                    onClose={handleCloseConfirmationModal}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <BootstrapDialogTitle
                        onClose={handleCloseConfirmationModal}
                    ></BootstrapDialogTitle>
                    <StyledDialogContent>
                        <div className="title">
                            Pro Tip : You could make your transactions gasless if created from
                            Parcel{" "}
                        </div>
                    </StyledDialogContent>
                    <DialogActionsStyled>
                        <Button
                            className="cancel-btn"
                            autoFocus
                            variant="outlined"
                            onClick={handleCloseConfirmationModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            className="confirm-btn"
                            color="primary"
                            variant="contained"
                            disableRipple={false}
                            onClick={handleConfirm}
                        >
                            Continue with Gas
                        </Button>
                    </DialogActionsStyled>
                </StyledDialog>
            </StyledModal>
        );
}
