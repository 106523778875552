import { useState } from "react";
import { Add, SortSharp } from "@mui/icons-material";
import { Box, Stack, TableCell, TableHead, TableRow, Typography, IconButton } from "@mui/material";
import React, { useContext, useEffect } from "react";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import { minifyAddress } from "src/helpers/utils/web3Utils";

import {
    AddToQueueButton,
    HeaderCell,
    PeopleAddress,
    PeopleCheckBox,
    PeopleIndividual,
    PeopleIndividualCB,
    PeopleName,
    PeopleSelectorFooter,
    PeopleTableBlockStyled,
    PeopleTableBody,
    PeopleTableCell,
    PeopleTableContainerStyled,
    PeopleTableRow,
    PeopleTableStyled,
    SortByNameButton,
} from "./styles/PeopleTableBlock";
import IndeterminateCheckBoxIcon from "@mui/icons-material/IndeterminateCheckBox";
import * as ga from "src/ga";
import EmptyPeopleTextWrapper from "./EmptyPeopleTextWrapper";
import Image from "next/image";
import SortIcon from "assets/sort.svg";
import StampAvatar from "src/components/StampAvatar";
import CopyOnClick from "src/components/common/Copy/CopyOnClick";

const PeopleTableBlock = () => {
    const {
        peopleInTable,
        selectedPeopleByTag,
        tagInfocus,
        setSelectedPeopleByTag,
        peopleByTag,
        payoutQueueByTag,
        setPayoutQueueByTag,
        setSearchString,
        setPeopleInTable,
    }: any = useContext(MassPayoutContext);

    const [nameColSortOrder, setNameColSortOrder] = useState("");

    const toggleSelectAllInTable = () => {
        // Add all people in table to selection
        if ((selectedPeopleByTag[tagInfocus] || []).length > 0) {
            setSelectedPeopleByTag({
                ...selectedPeopleByTag,
                [tagInfocus]: [],
            });
        } else {
            setSelectedPeopleByTag({
                ...selectedPeopleByTag,
                [tagInfocus]: peopleInTable.map(m => m.address),
            });
        }
    };

    const disableAddToQ = () => {
        // Check if Add to queue button can be enabled
        return !((selectedPeopleByTag[tagInfocus] || []).length > 0);
    };

    const addToPayoutQueue = () => {
        // Add selectedPeople[tagInFocus] to Payout Queue
        let curselected: Array<string> = selectedPeopleByTag[tagInfocus] || [];
        let toAdd = [];
        (peopleByTag[tagInfocus] || []).forEach(user => {
            if (
                !payoutQueueByTag[tagInfocus]?.some(people => people.address === user.address) &&
                curselected.includes(user.address)
            ) {
                toAdd.push(user);
            }
        });

        setPayoutQueueByTag({
            ...payoutQueueByTag,
            [tagInfocus]: [...(payoutQueueByTag[tagInfocus] || []), ...toAdd],
        });
        setSelectedPeopleByTag([]);
        setSearchString("");
    };

    const togglePeopleSelection = address => {
        // Add a contributor to selection
        let selected = [];
        if ((selectedPeopleByTag[tagInfocus] || []).includes(address)) {
            selected = selectedPeopleByTag[tagInfocus] || [];
            selected.splice(selected.indexOf(address), 1);
        } else {
            selected = selectedPeopleByTag[tagInfocus] || [];
            selected.push(address);
        }

        setSelectedPeopleByTag({
            ...selectedPeopleByTag,
            [tagInfocus]: selected,
        });
    };

    const sortTableByName = () => {
        const newRows = [...peopleInTable];
        if (nameColSortOrder === "asc") {
            newRows.sort((r1, r2) => r2.nickName.localeCompare(r1.nickName));
            setNameColSortOrder("des");
        } else {
            newRows.sort((r1, r2) => r1.nickName.localeCompare(r2.nickName));
            setNameColSortOrder("asc");
        }
        setPeopleInTable(newRows);
    };

    const calIfInQueue = (tagInfocus, row) => {
        return payoutQueueByTag?.[tagInfocus]?.some(r => r?.address === row?.address);
    };

    return (
        <PeopleTableBlockStyled>
            {peopleInTable.length === 0 ? (
                <EmptyPeopleTextWrapper />
            ) : (
                <>
                    <PeopleTableContainerStyled component={Box}>
                        <PeopleTableStyled stickyHeader aria-label="sticky table">
                            <TableHead>
                                <TableRow>
                                    <HeaderCell selectBox>
                                        {/* Select All */}
                                        <PeopleCheckBox
                                            checkedIcon={<IndeterminateCheckBoxIcon />}
                                            focusRipple={false}
                                            disableFocusRipple
                                            aria-label="Select All"
                                            onChange={toggleSelectAllInTable}
                                            disabled={
                                                payoutQueueByTag?.[tagInfocus]?.length ===
                                                peopleInTable?.length
                                            }
                                            checked={
                                                peopleInTable.length > 0 &&
                                                peopleInTable.length ===
                                                    (selectedPeopleByTag[tagInfocus] || []).length
                                            }
                                        />
                                    </HeaderCell>
                                    <HeaderCell align="left">
                                        <span className="cell-text">NAME</span>
                                        <IconButton size="small" onClick={sortTableByName}>
                                            <Image src={SortIcon} />
                                        </IconButton>

                                        {/* <SortByNameButton>
                                    <SortSharp />
                                </SortByNameButton> */}
                                    </HeaderCell>
                                </TableRow>
                            </TableHead>
                            <PeopleTableBody>
                                {peopleInTable.map(row => {
                                    let isChecked = (
                                        selectedPeopleByTag[tagInfocus] || []
                                    ).includes(row.address);
                                    const isAlredyInQueue = calIfInQueue(tagInfocus, row);
                                    isChecked = isAlredyInQueue ? false : isChecked;

                                    return (
                                        <PeopleTableRow
                                            key={row.address}
                                            hover={!isAlredyInQueue}
                                            onClick={
                                                isAlredyInQueue
                                                    ? () => {}
                                                    : e => togglePeopleSelection(row.address)
                                            }
                                        >
                                            <PeopleTableCell
                                                style={{
                                                    background: isChecked ? "#F5F8FE" : "none",
                                                }}
                                                component="td"
                                                scope="row"
                                                onClick={
                                                    isAlredyInQueue
                                                        ? () => {}
                                                        : e => togglePeopleSelection(row.address)
                                                }
                                            >
                                                {isAlredyInQueue ? (
                                                    <PeopleIndividualCB checked={false} disabled />
                                                ) : (
                                                    <PeopleIndividualCB
                                                        onClick={
                                                            isAlredyInQueue
                                                                ? () => {}
                                                                : e =>
                                                                      togglePeopleSelection(
                                                                          row.address,
                                                                      )
                                                        }
                                                        checked={isChecked}
                                                    />
                                                )}
                                            </PeopleTableCell>
                                            <PeopleTableCell
                                                style={{
                                                    background: isChecked ? "#F5F8FE" : "none",
                                                }}
                                                component="td"
                                                scope="row"
                                            >
                                                <PeopleIndividual direction={"row"}>
                                                    <StampAvatar seed={row.address} size={36} />

                                                    <Stack
                                                        justifyContent={"space-between"}
                                                        direction={"column"}
                                                    >
                                                        <PeopleName>{row.nickName}</PeopleName>
                                                        <PeopleAddress>
                                                            <CopyOnClick
                                                                style={{ display: "inline" }}
                                                                value={row.address}
                                                                text="Click to copy address"
                                                            >
                                                                {minifyAddress(row.address)}
                                                            </CopyOnClick>
                                                        </PeopleAddress>
                                                    </Stack>
                                                </PeopleIndividual>
                                            </PeopleTableCell>
                                        </PeopleTableRow>
                                    );
                                })}
                                <div style={{ height: 100 }}></div>
                            </PeopleTableBody>
                        </PeopleTableStyled>
                    </PeopleTableContainerStyled>
                    <PeopleSelectorFooter>
                        <AddToQueueButton
                            disabled={disableAddToQ()}
                            className="add-queue-button"
                            startIcon={<Add />}
                            variant="outlined"
                            onClick={() => {
                                ga.gaEvent({
                                    action: "Clicked",
                                    category: "Mass Payout",
                                    label: "Add to Queue",
                                });
                                addToPayoutQueue();
                            }}
                            style={{ fontSize: 14 }}
                        >
                            Add to queue
                        </AddToQueueButton>
                    </PeopleSelectorFooter>
                </>
            )}
        </PeopleTableBlockStyled>
    );
};

export default PeopleTableBlock;
