import { SimulationResultBoxStyled } from "./styles/SimulateTransaction";
import ErrorIcon from "@mui/icons-material/Error";
import { Typography } from "@mui/material";

function SimulationFailureBox({ currentSimulationId }) {
    return (
        <SimulationResultBoxStyled>
            <div className="failed-simulation">
                <div className="failed-header">
                    <span className="simulatefailed">Simulation Failed</span>
                    <span>
                        {" "}
                        <ErrorIcon
                            sx={{
                                color: "#F44040",
                                marginLeft: "5px",
                                height: "20px",
                                width: "20px",
                            }}
                        />
                    </span>
                </div>
                <div className="failed-body">
                    {!Boolean(currentSimulationId) && (
                        <Typography className="txn-value-err" variant="h6">
                            Transaction simulation failed.
                        </Typography>
                    )}
                    {Boolean(currentSimulationId) && (
                        <Typography className="txn-value-err" variant="h6">
                            Transaction simulation failed. Full simulation report is available
                            <span>
                                <a
                                    href={`${process.env.NEXT_PUBLIC_TENDERLY_SIMULATION_BASE_URL}/${currentSimulationId}`}
                                    target="_blank"
                                >
                                    {` on Tenderly`}
                                </a>
                            </span>
                        </Typography>
                    )}
                </div>
            </div>
        </SimulationResultBoxStyled>
    );
}

export default SimulationFailureBox;
