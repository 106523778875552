import { baseURLToSendEmail } from "src/queries/constants";
import { networkId } from "src/helpers/utils/networks";

export async function sendEmail(
    payees,
    safe_name,
    toAddress,
    txdescription,
    safe_owner,
    transactionId,
) {
    fetch(baseURLToSendEmail, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            toAddress,
            safe_name,
            payees,
            txdescription,
            safe_owner,
            transactionId,
            networkId,
        }),
    });
}
