import Image from "next/image";
import React from "react";
import mainImage from "../../../assets/maintenance.svg";

export default function Maintenance() {
    return (
        <html>
            <head>
                <title>Parcel - We'll back shortly</title>
                <link rel="icon" type="image/png" href="favicon.png" />

                <style>body .container .title .subtitle</style>
            </head>
            <body
                style={{
                    height: "97vh",
                    fontFamily: "Metropolis",
                    backgroundColor: "#f5f6f7",
                }}
            >
                <div
                    className="container"
                    style={{
                        display: "flex",
                        height: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <Image src={mainImage} alt="Maintenance Image" />
                    <div
                        className="title"
                        style={{
                            marginTop: "32px",
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "18px",
                            marginBottom: "12px",
                            textAlign: "center",
                        }}
                    >
                        Site under maintenance
                    </div>
                    <div className="subtitle">
                        We are performing some maintenance on our systems. We’ll be back up shortly.
                    </div>
                    <div
                        className="subtitle"
                        style={{
                            marginTop: "5px",
                            fontSize: "12px",
                            fontWeight: 400,
                            lineHeight: "12px",
                            color: "#636b81",
                            textAlign: "center",
                        }}
                    >
                        Thank you for your patience.
                    </div>
                </div>
            </body>
        </html>
    );
}
