import * as Sentry from "@sentry/nextjs";

export async function triggerSlackNotification(params) {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(params);

    var requestOptions: any = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
    };

    try {
        const result = await fetch(process.env.NEXT_PUBLIC_SLACKWEBHOOK_URL, requestOptions);
        return result;
    } catch (e) {
        console.error(e, "error");
        Sentry.captureException(e);
    }
}
