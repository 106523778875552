import Image from "next/image";

type Props = {
    size: number;
    seed: string;
    containerStyles?: any;
};

const StampAvatar = ({ seed, size, containerStyles = { marginRight: 8 } }: Props) => {
    return (
        <div style={{ ...containerStyles }}>
            <div
                style={{
                    width: size,
                    height: size,
                    borderRadius: "50%",
                    overflow: "hidden",
                }}
            >
                <img src={`https://cdn.stamp.fyi/avatar/${seed}?s=${size}`} />
            </div>
        </div>
    );
};

export default StampAvatar;
