import styled from "@emotion/styled";

export const UploadFileScreenWrapper = styled.div`
    width: 100%;

    .file-size {
        margin-top: 5px;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        text-align: right;
        margin-left: auto;
        color: #8b91a1;
    }
`;
export const ThingsToRemember: any = styled.div`
    margin-top: 7px;
    .heading {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 23px;
        color: #25274f;
    }
`;

export const KeyPointsWrapper: any = styled.div`
    .points {
        list-style: none;
        padding: 0;
        margin: 0;
        > li:nth-of-type(1) {
            margin-top: 10px;
        }
        > li {
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 31px;
            color: #8b91a1;
        }
    }
`;
