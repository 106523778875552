import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

import TokenRemainingSummary from "../TokenRemainingSummary";
import { ReviewScreenToken } from "./styles/ReviewTokenScreen";
import Decimal from "decimal.js-light";

export default function ReviewTokenSummary({ payeeDetails, height = "" }) {
    const [disbursement, setDisbursment] = useState({});

    useEffect(() => {
        const disbursement = {};
        // console.log(payeeDetails, "from payee");
        payeeDetails.map(item => {
            if (disbursement[item?.tokenAddress]) {
                disbursement[item?.tokenAddress].amount += new Decimal(item.amount).toNumber();
                disbursement[item?.tokenAddress].fiatValue += Number(item.fiatValue);
            } else {
                const payout: any = {};
                payout.token = item?.symbol || item.token;
                payout.amount = new Decimal(item.amount || 0).toNumber();
                payout.fiatValue = Number(item.fiatValue);
                payout.logoUri = item.logoUri || item.tokenURI;
                payout.tokenAddress = item.tokenAddress || item.symbol || item.token;
                disbursement[item?.tokenAddress] = payout;
            }
        });
        // console.log(disbursement, "dis");
        setDisbursment(disbursement);
    }, [payeeDetails]);
    useEffect(() => {}, []);

    return (
        <ReviewScreenToken>
            <Typography marginTop={"17px"} color="#232427" fontSize={18} fontWeight={600}>
                Review Summary ({Object.keys(disbursement).length} token
                {Object.keys(disbursement).length > 1 ? "s" : ""})
            </Typography>
            <Box className="header">
                <Box>
                    <Typography color="#9998A4" fontSize="13px" fontWeight={500}>
                        TOKEN AMOUNT
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        textAlign={"right"}
                        color="#9998A4"
                        fontSize="13px"
                        fontWeight={500}
                    >
                        REMAINING AMOUNT
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    minHeight: height,
                    maxHeight: height ? height : "331px",
                    overflowY: "auto",
                }}
            >
                {Object.values(disbursement).map(item => (
                    <Box
                        className="body"
                        sx={{
                            justifyContent: "flex-start !important",
                        }}
                    >
                        <TokenRemainingSummary disbursment={[item]}></TokenRemainingSummary>
                    </Box>
                ))}
            </Box>
        </ReviewScreenToken>
    );
}
