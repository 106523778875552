import React from "react";

const DashboardIcon = ({ size, color }) => (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M15.833 16.5006H4.16634C3.94533 16.5006 3.73337 16.4128 3.57709 16.2565C3.4208 16.1002 3.33301 15.8883 3.33301 15.6673V8.16725H0.833008L9.43884 0.343918C9.59227 0.204316 9.79224 0.126953 9.99967 0.126953C10.2071 0.126953 10.4071 0.204316 10.5605 0.343918L19.1663 8.16725H16.6663V15.6673C16.6663 15.8883 16.5785 16.1002 16.4223 16.2565C16.266 16.4128 16.054 16.5006 15.833 16.5006ZM4.99967 14.8339H14.9997V6.63142L9.99967 2.08642L4.99967 6.63142V14.8339ZM6.66634 11.5006H13.333V13.1673H6.66634V11.5006Z"
            fill={color}
        />
    </svg>
);

const DashboardIconSolid = ({ size, color }) => (
    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M16.6663 15.6668C16.6663 15.8878 16.5785 16.0997 16.4223 16.256C16.266 16.4123 16.054 16.5001 15.833 16.5001H4.16634C3.94533 16.5001 3.73337 16.4123 3.57709 16.256C3.4208 16.0997 3.33301 15.8878 3.33301 15.6668V8.16676H0.833008L9.43884 0.343429C9.59227 0.203828 9.79224 0.126465 9.99967 0.126465C10.2071 0.126465 10.4071 0.203828 10.5605 0.343429L19.1663 8.16676H16.6663V15.6668ZM6.66634 11.5001V13.1668H13.333V11.5001H6.66634Z"
            fill="#2962EF"
        />
    </svg>
);

const PeopleIcon = ({ size, color }) => (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.99967 11.6673V13.334C5.67359 13.334 4.40182 13.8608 3.46414 14.7984C2.52646 15.7361 1.99967 17.0079 1.99967 18.334H0.333008C0.333008 16.5659 1.03539 14.8702 2.28563 13.6199C3.53587 12.3697 5.23156 11.6673 6.99967 11.6673ZM6.99967 10.834C4.23717 10.834 1.99967 8.59648 1.99967 5.83398C1.99967 3.07148 4.23717 0.833984 6.99967 0.833984C9.76217 0.833984 11.9997 3.07148 11.9997 5.83398C11.9997 8.59648 9.76217 10.834 6.99967 10.834ZM6.99967 9.16732C8.84134 9.16732 10.333 7.67565 10.333 5.83398C10.333 3.99232 8.84134 2.50065 6.99967 2.50065C5.15801 2.50065 3.66634 3.99232 3.66634 5.83398C3.66634 7.67565 5.15801 9.16732 6.99967 9.16732ZM14.8713 16.694L16.1247 17.9473L14.9463 19.1257L13.693 17.8723C12.9941 18.2844 12.1689 18.4274 11.3721 18.2746C10.5753 18.1218 9.86163 17.6836 9.36478 17.0422C8.86794 16.4008 8.62208 15.6003 8.67329 14.7906C8.7245 13.9809 9.06926 13.2176 9.64295 12.6439C10.2166 12.0702 10.9799 11.7255 11.7896 11.6743C12.5993 11.6231 13.3999 11.8689 14.0413 12.3658C14.6827 12.8626 15.1208 13.5763 15.2736 14.3731C15.4265 15.1699 15.2834 15.9951 14.8713 16.694ZM11.9997 16.6673C12.4417 16.6673 12.8656 16.4917 13.1782 16.1792C13.4907 15.8666 13.6663 15.4427 13.6663 15.0006C13.6663 14.5586 13.4907 14.1347 13.1782 13.8221C12.8656 13.5096 12.4417 13.334 11.9997 13.334C11.5576 13.334 11.1337 13.5096 10.8212 13.8221C10.5086 14.1347 10.333 14.5586 10.333 15.0006C10.333 15.4427 10.5086 15.8666 10.8212 16.1792C11.1337 16.4917 11.5576 16.6673 11.9997 16.6673Z"
            fill={color}
        />
    </svg>
);

export const PeopleIconSolid = ({ size, color = "#2962EF" }) => (
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M6.99967 11.6667V18.3334H0.333008C0.333008 16.5653 1.03539 14.8696 2.28563 13.6193C3.53587 12.3691 5.23156 11.6667 6.99967 11.6667ZM6.99967 10.8334C4.23717 10.8334 1.99967 8.59587 1.99967 5.83337C1.99967 3.07087 4.23717 0.833374 6.99967 0.833374C9.76217 0.833374 11.9997 3.07087 11.9997 5.83337C11.9997 8.59587 9.76217 10.8334 6.99967 10.8334ZM14.8713 16.6934L16.1247 17.9467L14.9463 19.125L13.693 17.8717C12.9941 18.2838 12.1689 18.4268 11.3721 18.274C10.5753 18.1212 9.86163 17.683 9.36478 17.0416C8.86794 16.4002 8.62208 15.5996 8.67329 14.7899C8.7245 13.9802 9.06926 13.217 9.64295 12.6433C10.2166 12.0696 10.9799 11.7249 11.7896 11.6737C12.5993 11.6224 13.3999 11.8683 14.0413 12.3651C14.6827 12.862 15.1208 13.5757 15.2736 14.3725C15.4265 15.1693 15.2834 15.9945 14.8713 16.6934ZM11.9997 16.6667C12.4417 16.6667 12.8656 16.4911 13.1782 16.1785C13.4907 15.866 13.6663 15.4421 13.6663 15C13.6663 14.558 13.4907 14.1341 13.1782 13.8215C12.8656 13.509 12.4417 13.3334 11.9997 13.3334C11.5576 13.3334 11.1337 13.509 10.8212 13.8215C10.5086 14.1341 10.333 14.558 10.333 15C10.333 15.4421 10.5086 15.866 10.8212 16.1785C11.1337 16.4911 11.5576 16.6667 11.9997 16.6667Z"
            fill={color}
        />
    </svg>
);

const TransactionIcon = ({ size, color = "#25274F" }) => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.00033 4.66667H5.66783L5.66699 14.6667H4.00033V4.66667H0.666992L4.83366 0.5L9.00033 4.66667ZM17.3337 11.3333L13.167 15.5L9.00033 11.3333H12.3337V1.33333H14.0003V11.3333H17.3337Z"
            fill={color}
        />
    </svg>
);

const RequestIcon = ({ size, color }) => (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.6667 17.3327H1.33333C1.11232 17.3327 0.900358 17.2449 0.744078 17.0886C0.587797 16.9323 0.5 16.7204 0.5 16.4993V1.49935C0.5 1.27834 0.587797 1.06637 0.744078 0.910093C0.900358 0.753813 1.11232 0.666016 1.33333 0.666016H14.6667C14.8877 0.666016 15.0996 0.753813 15.2559 0.910093C15.4122 1.06637 15.5 1.27834 15.5 1.49935V16.4993C15.5 16.7204 15.4122 16.9323 15.2559 17.0886C15.0996 17.2449 14.8877 17.3327 14.6667 17.3327ZM13.8333 15.666V2.33268H2.16667V15.666H13.8333ZM4.66667 4.83268H11.3333V6.49935H4.66667V4.83268ZM4.66667 8.16602H11.3333V9.83268H4.66667V8.16602ZM4.66667 11.4993H8.83333V13.166H4.66667V11.4993Z"
            fill={color}
        />
    </svg>
);

const RequestIconSolid = ({ size, color }) => (
    <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.6667 17.3332H1.33333C1.11232 17.3332 0.900358 17.2454 0.744078 17.0891C0.587797 16.9328 0.5 16.7209 0.5 16.4998V1.49984C0.5 1.27882 0.587797 1.06686 0.744078 0.910582C0.900358 0.754301 1.11232 0.666504 1.33333 0.666504H14.6667C14.8877 0.666504 15.0996 0.754301 15.2559 0.910582C15.4122 1.06686 15.5 1.27882 15.5 1.49984V16.4998C15.5 16.7209 15.4122 16.9328 15.2559 17.0891C15.0996 17.2454 14.8877 17.3332 14.6667 17.3332ZM4.66667 4.83317V6.49984H11.3333V4.83317H4.66667ZM4.66667 8.1665V9.83317H11.3333V8.1665H4.66667ZM4.66667 11.4998V13.1665H8.83333V11.4998H4.66667Z"
            fill="#2962EF"
        />
    </svg>
);

const RecurringIcon = ({ size, color }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.50033 0.833008V2.49967H12.5003V0.833008H14.167V2.49967H17.5003C17.7213 2.49967 17.9333 2.58747 18.0896 2.74375C18.2459 2.90003 18.3337 3.11199 18.3337 3.33301V16.6663C18.3337 16.8874 18.2459 17.0993 18.0896 17.2556C17.9333 17.4119 17.7213 17.4997 17.5003 17.4997H2.50033C2.27931 17.4997 2.06735 17.4119 1.91107 17.2556C1.75479 17.0993 1.66699 16.8874 1.66699 16.6663V3.33301C1.66699 3.11199 1.75479 2.90003 1.91107 2.74375C2.06735 2.58747 2.27931 2.49967 2.50033 2.49967H5.83366V0.833008H7.50033ZM16.667 3.99971H3.33366V15.833H16.667V3.99971ZM5.83366 3.99971H3.33366V2.63301H16.667V3.99971H14.167H12.5003H7.50033H5.83366Z"
            fill={color}
        />
        <path
            d="M6.8937 6.89211C7.6917 6.09461 8.78688 5.59961 10.0031 5.59961C12.4357 5.59961 14.4004 7.56861 14.4004 9.99961C14.4004 12.4306 12.4357 14.3996 10.0031 14.3996C7.95036 14.3996 6.23879 12.9971 5.74898 11.0996H6.8937C7.34498 12.3811 8.56674 13.2996 10.0031 13.2996C11.8248 13.2996 13.3052 11.8201 13.3052 9.99961C13.3052 8.17911 11.8248 6.69961 10.0031 6.69961C9.08957 6.69961 8.27506 7.07911 7.68069 7.67861L9.4528 9.44961H5.60039V5.59961L6.8937 6.89211Z"
            fill={color}
        />
    </svg>
);

const RecurringIconSolid = ({ size, color }) => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M6.50033 2.50016V0.833496H4.83366V2.50016H1.50033C1.27931 2.50016 1.06735 2.58796 0.91107 2.74424C0.75479 2.90052 0.666992 3.11248 0.666992 3.3335V16.6668C0.666992 16.8878 0.75479 17.0998 0.91107 17.2561C1.06735 17.4124 1.27931 17.5002 1.50033 17.5002H16.5003C16.7213 17.5002 16.9333 17.4124 17.0896 17.2561C17.2459 17.0998 17.3337 16.8878 17.3337 16.6668V3.3335C17.3337 3.11248 17.2459 2.90052 17.0896 2.74424C16.9333 2.58796 16.7213 2.50016 16.5003 2.50016H13.167V0.833496H11.5003V2.50016H6.50033ZM9.41986 5.8335C8.15292 5.8335 7.0121 6.34912 6.18085 7.17985L4.83366 5.8335V9.84391H8.84658L7.00064 7.99912C7.61977 7.37464 8.46822 6.97933 9.41986 6.97933C11.3174 6.97933 12.8595 8.52047 12.8595 10.4168C12.8595 12.3132 11.3174 13.8543 9.41986 13.8543C7.92361 13.8543 6.65094 12.8976 6.18085 11.5627H4.98844C5.49866 13.5392 7.28154 15.0002 9.41986 15.0002C11.9537 15.0002 14.0003 12.9491 14.0003 10.4168C14.0003 7.88454 11.9537 5.8335 9.41986 5.8335Z"
            fill="#2962EF"
        />
    </svg>
);

const SettingsIcon = ({ size, color }) => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99967 0.833984L15.9163 5.41732V14.584L7.99967 19.1673L0.0830078 14.584V5.41732L7.99967 0.833984ZM7.99967 2.75982L1.74967 6.37815V13.6231L7.99967 17.2415L14.2497 13.6231V6.37815L7.99967 2.75982ZM7.99967 13.334C7.11562 13.334 6.26777 12.9828 5.64265 12.3577C5.01753 11.7326 4.66634 10.8847 4.66634 10.0007C4.66634 9.1166 5.01753 8.26875 5.64265 7.64363C6.26777 7.01851 7.11562 6.66732 7.99967 6.66732C8.88373 6.66732 9.73158 7.01851 10.3567 7.64363C10.9818 8.26875 11.333 9.1166 11.333 10.0007C11.333 10.8847 10.9818 11.7326 10.3567 12.3577C9.73158 12.9828 8.88373 13.334 7.99967 13.334ZM7.99967 11.6673C8.4417 11.6673 8.86562 11.4917 9.17819 11.1792C9.49075 10.8666 9.66634 10.4427 9.66634 10.0007C9.66634 9.55862 9.49075 9.1347 9.17819 8.82214C8.86562 8.50958 8.4417 8.33398 7.99967 8.33398C7.55765 8.33398 7.13372 8.50958 6.82116 8.82214C6.5086 9.1347 6.33301 9.55862 6.33301 10.0007C6.33301 10.4427 6.5086 10.8666 6.82116 11.1792C7.13372 11.4917 7.55765 11.6673 7.99967 11.6673Z"
            fill={color}
        />
    </svg>
);

const SettingsIconSolid = ({ size, color }) => (
    <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.99967 0.833008L15.9163 5.41634V14.583L7.99967 19.1663L0.0830078 14.583V5.41634L7.99967 0.833008ZM7.99967 12.4997C8.66272 12.4997 9.2986 12.2363 9.76744 11.7674C10.2363 11.2986 10.4997 10.6627 10.4997 9.99967C10.4997 9.33663 10.2363 8.70075 9.76744 8.23191C9.2986 7.76307 8.66272 7.49967 7.99967 7.49967C7.33663 7.49967 6.70075 7.76307 6.23191 8.23191C5.76307 8.70075 5.49967 9.33663 5.49967 9.99967C5.49967 10.6627 5.76307 11.2986 6.23191 11.7674C6.70075 12.2363 7.33663 12.4997 7.99967 12.4997Z"
            fill="#2962EF"
        />
    </svg>
);

const NorthEastArrowIcon = ({ size = 10, color }) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 10 9"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M7.66895 2.276L1.93095 8.014L0.988281 7.07133L6.72561 1.33333H1.66895V0H9.00228V7.33333H7.66895V2.276Z"
            fill={color}
        />
    </svg>
);

const NewSafeIcon = ({ size, color }) => (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M3.92046 5.325C4.06502 5.85403 4.37946 6.32088 4.81539 6.65365C5.25131 6.98643 5.78454 7.16669 6.33296 7.16667H9.6663C10.648 7.16674 11.5981 7.51336 12.3493 8.14542C13.1004 8.77749 13.6043 9.65443 13.7721 10.6217C14.3377 10.8062 14.819 11.1863 15.1295 11.6937C15.4401 12.2012 15.5596 12.8027 15.4665 13.3903C15.3735 13.9779 15.074 14.5131 14.6219 14.8998C14.1697 15.2864 13.5946 15.4992 12.9996 15.5C12.4176 15.5004 11.8536 15.2978 11.405 14.927C10.9563 14.5561 10.6511 14.0404 10.542 13.4687C10.4329 12.8969 10.5267 12.305 10.8072 11.795C11.0878 11.2851 11.5375 10.8889 12.0788 10.675C11.9342 10.146 11.6198 9.67912 11.1839 9.34634C10.7479 9.01357 10.2147 8.83331 9.6663 8.83333H6.33296C5.43121 8.83466 4.55357 8.54212 3.83296 8V10.6417C4.38921 10.8383 4.85804 11.2252 5.1566 11.734C5.45515 12.2428 5.5642 12.8409 5.46447 13.4223C5.36474 14.0038 5.06265 14.5313 4.61161 14.9116C4.16056 15.2918 3.58959 15.5004 2.99963 15.5004C2.40967 15.5004 1.8387 15.2918 1.38765 14.9116C0.936604 14.5313 0.634516 14.0038 0.534787 13.4223C0.435057 12.8409 0.544108 12.2428 0.842663 11.734C1.14122 11.2252 1.61005 10.8383 2.1663 10.6417V5.35833C1.61378 5.16326 1.1473 4.78036 0.848257 4.27647C0.549217 3.77258 0.436606 3.17968 0.530075 2.60124C0.623543 2.02279 0.917156 1.49553 1.35968 1.11147C1.8022 0.727401 2.36553 0.510913 2.95137 0.49978C3.53721 0.488648 4.10836 0.683578 4.56516 1.05056C5.02195 1.41753 5.33538 1.93326 5.45076 2.50773C5.56613 3.08221 5.47613 3.67895 5.19645 4.19384C4.91677 4.70873 4.46517 5.10907 3.92046 5.325Z"
            fill={color}
        />
    </svg>
);

const ConnectWalletIcon = ({ size, color }) => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14.0003 3.83333H16.5003C16.7213 3.83333 16.9333 3.92113 17.0896 4.07741C17.2459 4.23369 17.3337 4.44565 17.3337 4.66667V14.6667C17.3337 14.8877 17.2459 15.0996 17.0896 15.2559C16.9333 15.4122 16.7213 15.5 16.5003 15.5H1.50033C1.27931 15.5 1.06735 15.4122 0.91107 15.2559C0.75479 15.0996 0.666992 14.8877 0.666992 14.6667V1.33333C0.666992 1.11232 0.75479 0.900358 0.91107 0.744078C1.06735 0.587797 1.27931 0.5 1.50033 0.5H14.0003V3.83333ZM2.33366 5.5V13.8333H15.667V5.5H2.33366ZM2.33366 2.16667V3.83333H12.3337V2.16667H2.33366ZM11.5003 8.83333H14.0003V10.5H11.5003V8.83333Z"
            fill={color}
        />
    </svg>
);

export const ContributorProfile = ({ size, color }) => (
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.333008 18.3333C0.333008 16.5651 1.03539 14.8694 2.28563 13.6192C3.53587 12.369 5.23156 11.6666 6.99967 11.6666C8.76778 11.6666 10.4635 12.369 11.7137 13.6192C12.964 14.8694 13.6663 16.5651 13.6663 18.3333H0.333008ZM6.99967 10.8333C4.23717 10.8333 1.99967 8.59575 1.99967 5.83325C1.99967 3.07075 4.23717 0.833252 6.99967 0.833252C9.76217 0.833252 11.9997 3.07075 11.9997 5.83325C11.9997 8.59575 9.76217 10.8333 6.99967 10.8333Z"
            fill={color}
        />
    </svg>
);

export const ContributorRequests = ({ size, color }) => (
    <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.00033 4.66667H5.66783L5.66699 14.6667H4.00033V4.66667H0.666992L4.83366 0.5L9.00033 4.66667ZM17.3337 11.3333L13.167 15.5L9.00033 11.3333H12.3337V1.33333H14.0003V11.3333H17.3337Z"
            fill={color}
        />
    </svg>
);

export const ProfileIcon = ({ size, color }) => (
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M10.75 10.7373L7.75 7.7373V5.3523C8.62 5.0373 9.25 4.2123 9.25 3.2373C9.25 1.9923 8.245 0.987305 7 0.987305C5.755 0.987305 4.75 1.9923 4.75 3.2373C4.75 4.2123 5.38 5.0373 6.25 5.3523V7.7373L3.25 10.7373H0.25V14.4873H4V12.1998L7 9.0498L10 12.1998V14.4873H13.75V10.7373H10.75Z"
            fill={color}
        />
    </svg>
);

export const ProfileIconLine = ({ size, color }) => (
    <svg width="14" height="19" viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.333008 18.3335C0.333008 16.5654 1.03539 14.8697 2.28563 13.6195C3.53587 12.3692 5.23156 11.6668 6.99967 11.6668C8.76778 11.6668 10.4635 12.3692 11.7137 13.6195C12.964 14.8697 13.6663 16.5654 13.6663 18.3335H11.9997C11.9997 17.0074 11.4729 15.7356 10.5352 14.798C9.59753 13.8603 8.32576 13.3335 6.99967 13.3335C5.67359 13.3335 4.40182 13.8603 3.46414 14.798C2.52646 15.7356 1.99967 17.0074 1.99967 18.3335H0.333008ZM6.99967 10.8335C4.23717 10.8335 1.99967 8.596 1.99967 5.8335C1.99967 3.071 4.23717 0.833496 6.99967 0.833496C9.76217 0.833496 11.9997 3.071 11.9997 5.8335C11.9997 8.596 9.76217 10.8335 6.99967 10.8335ZM6.99967 9.16683C8.84134 9.16683 10.333 7.67516 10.333 5.8335C10.333 3.99183 8.84134 2.50016 6.99967 2.50016C5.15801 2.50016 3.66634 3.99183 3.66634 5.8335C3.66634 7.67516 5.15801 9.16683 6.99967 9.16683Z"
            fill="#25274F"
        />
    </svg>
);

export const SwitchProfileIconDAO = ({ size, color }) => (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M2.73696 3.85956C2.8526 4.28278 3.10416 4.65626 3.4529 4.92248C3.80163 5.18871 4.22822 5.33291 4.66696 5.33289H7.33362C8.11897 5.33295 8.8791 5.61025 9.48 6.1159C10.0809 6.62155 10.484 7.3231 10.6183 8.09689C11.0708 8.24451 11.4558 8.5486 11.7042 8.95455C11.9527 9.3605 12.0482 9.84172 11.9738 10.3118C11.8994 10.7819 11.6598 11.21 11.2981 11.5194C10.9364 11.8287 10.4762 11.999 10.0003 11.9996C9.53465 11.9999 9.08347 11.8378 8.72455 11.5411C8.36564 11.2445 8.12149 10.8319 8.0342 10.3745C7.94691 9.9171 8.02195 9.44358 8.24638 9.03559C8.47081 8.6276 8.83057 8.31071 9.26362 8.13956C9.14798 7.71633 8.89642 7.34286 8.54768 7.07663C8.19895 6.81041 7.77236 6.66621 7.33362 6.66623H4.66696C3.94555 6.66729 3.24344 6.43325 2.66696 5.99956V8.11289C3.11195 8.27016 3.48702 8.57969 3.72586 8.98676C3.96471 9.39384 4.05195 9.87224 3.97216 10.3374C3.89238 10.8026 3.65071 11.2246 3.28987 11.5288C2.92903 11.833 2.47226 11.9999 2.00029 11.9999C1.52832 11.9999 1.07155 11.833 0.710708 11.5288C0.349869 11.2246 0.108199 10.8026 0.0284153 10.3374C-0.0513681 9.87224 0.0358723 9.39384 0.274716 8.98676C0.51356 8.57969 0.888628 8.27016 1.33362 8.11289V3.88623C0.891607 3.73016 0.518423 3.42385 0.279191 3.02074C0.0399595 2.61762 -0.0501291 2.1433 0.0246458 1.68055C0.0994207 1.21779 0.334311 0.795988 0.688328 0.488735C1.04235 0.181481 1.49301 0.00829071 1.96168 -0.000615175C2.43036 -0.00952106 2.88728 0.146423 3.25271 0.440005C3.61815 0.733588 3.86889 1.14617 3.96119 1.60575C4.05349 2.06533 3.98149 2.54272 3.75775 2.95464C3.534 3.36655 3.17272 3.68682 2.73696 3.85956Z"
            fill="#25274F"
        />
    </svg>
);

export const IntegrationIcon = ({ size, color }) => (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.83301 14.0003V15.667H12.833V17.3337H7.83301C7.39098 17.3337 6.96706 17.1581 6.6545 16.8455C6.34194 16.5329 6.16634 16.109 6.16634 15.667V14.0003H3.66634C2.78229 14.0003 1.93444 13.6491 1.30932 13.024C0.684197 12.3989 0.333008 11.551 0.333008 10.667V4.83366C0.333008 4.61265 0.420805 4.40068 0.577086 4.2444C0.733366 4.08812 0.945327 4.00033 1.16634 4.00033H3.66634V0.666992H5.33301V4.00033H8.66634V0.666992H10.333V4.00033H12.833C13.054 4.00033 13.266 4.08812 13.4223 4.2444C13.5785 4.40068 13.6663 4.61265 13.6663 4.83366V10.667C13.6663 11.551 13.3152 12.3989 12.69 13.024C12.0649 13.6491 11.2171 14.0003 10.333 14.0003H7.83301ZM3.66634 12.3337H10.333C10.775 12.3337 11.199 12.1581 11.5115 11.8455C11.8241 11.5329 11.9997 11.109 11.9997 10.667V8.16699H1.99967V10.667C1.99967 11.109 2.17527 11.5329 2.48783 11.8455C2.80039 12.1581 3.22431 12.3337 3.66634 12.3337ZM11.9997 5.66699H1.99967V6.50033H11.9997V5.66699ZM6.99967 11.0837C6.77866 11.0837 6.5667 10.9959 6.41042 10.8396C6.25414 10.6833 6.16634 10.4713 6.16634 10.2503C6.16634 10.0293 6.25414 9.81735 6.41042 9.66107C6.5667 9.50479 6.77866 9.41699 6.99967 9.41699C7.22069 9.41699 7.43265 9.50479 7.58893 9.66107C7.74521 9.81735 7.83301 10.0293 7.83301 10.2503C7.83301 10.4713 7.74521 10.6833 7.58893 10.8396C7.43265 10.9959 7.22069 11.0837 6.99967 11.0837Z"
            fill={color}
        />
    </svg>
);

export const IntegrationIconSolid = ({ size, color }) => (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.83301 14.0003V15.667H12.833V17.3337H7.83301C7.39098 17.3337 6.96706 17.1581 6.6545 16.8455C6.34194 16.5329 6.16634 16.109 6.16634 15.667V14.0003H3.66634C2.78229 14.0003 1.93444 13.6491 1.30932 13.024C0.684197 12.3989 0.333008 11.551 0.333008 10.667V7.33366H13.6663V10.667C13.6663 11.551 13.3152 12.3989 12.69 13.024C12.0649 13.6491 11.2171 14.0003 10.333 14.0003H7.83301ZM10.333 4.00033H12.833C13.054 4.00033 13.266 4.08812 13.4223 4.2444C13.5785 4.40068 13.6663 4.61265 13.6663 4.83366V6.50033H0.333008V4.83366C0.333008 4.61265 0.420805 4.40068 0.577086 4.2444C0.733366 4.08812 0.945327 4.00033 1.16634 4.00033H3.66634V0.666992H5.33301V4.00033H8.66634V0.666992H10.333V4.00033ZM6.99967 11.0837C7.22069 11.0837 7.43265 10.9959 7.58893 10.8396C7.74521 10.6833 7.83301 10.4713 7.83301 10.2503C7.83301 10.0293 7.74521 9.81735 7.58893 9.66107C7.43265 9.50479 7.22069 9.41699 6.99967 9.41699C6.77866 9.41699 6.5667 9.50479 6.41042 9.66107C6.25414 9.81735 6.16634 10.0293 6.16634 10.2503C6.16634 10.4713 6.25414 10.6833 6.41042 10.8396C6.5667 10.9959 6.77866 11.0837 6.99967 11.0837Z"
            fill={color}
        />
    </svg>
);
export const Squads = ({ size, color = "#25274F" }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8.33333 1.66669C8.79333 1.66669 9.16667 2.04002 9.16667 2.50002V5.83335C9.16667 6.29335 8.79333 6.66669 8.33333 6.66669H6.66667V8.33335H10.8333V7.50002C10.8333 7.04002 11.2067 6.66669 11.6667 6.66669H16.6667C17.1267 6.66669 17.5 7.04002 17.5 7.50002V10.8334C17.5 11.2934 17.1267 11.6667 16.6667 11.6667H11.6667C11.2067 11.6667 10.8333 11.2934 10.8333 10.8334V10H6.66667V15H10.8333V14.1667C10.8333 13.7067 11.2067 13.3334 11.6667 13.3334H16.6667C17.1267 13.3334 17.5 13.7067 17.5 14.1667V17.5C17.5 17.96 17.1267 18.3334 16.6667 18.3334H11.6667C11.2067 18.3334 10.8333 17.96 10.8333 17.5V16.6667H5.83333C5.37333 16.6667 5 16.2934 5 15.8334V6.66669H3.33333C2.87333 6.66669 2.5 6.29335 2.5 5.83335V2.50002C2.5 2.04002 2.87333 1.66669 3.33333 1.66669H8.33333ZM15.8333 15H12.5V16.6667H15.8333V15ZM15.8333 8.33335H12.5V10H15.8333V8.33335ZM7.5 3.33335H4.16667V5.00002H7.5V3.33335Z"
            fill={color}
        />
    </svg>
);

const NavBarIcons = ({ name, color, size }) => {
    if (name === "dashboard") return <DashboardIcon size={size} color={color} />;
    if (name === "dashboard_solid") return <DashboardIconSolid size={size} color={color} />;
    if (name === "people") return <PeopleIcon size={size} color={color} />;
    if (name === "people_solid") return <PeopleIconSolid size={size} color={color} />;
    if (name === "transactions") return <TransactionIcon size={size} color={color} />;
    if (name === "requests") return <RequestIcon size={size} color={color} />;
    if (name === "requests_solid") return <RequestIconSolid size={size} color={color} />;
    if (name === "recurring") return <RecurringIcon size={size} color={color} />;
    if (name === "recurring_solid") return <RecurringIconSolid size={size} color={color} />;
    if (name === "settings") return <SettingsIcon size={size} color={color} />;
    if (name === "settings_solid") return <SettingsIconSolid size={size} color={color} />;
    if (name === "nort_east_arrow") return <NorthEastArrowIcon size={size} color={color} />;
    if (name === "new_safe") return <NewSafeIcon size={size} color={color} />;
    if (name === "connect_wallet") return <ConnectWalletIcon size={size} color={color} />;
    if (name === "contributor_profile") return <ContributorProfile size={size} color={color} />;
    if (name === "contributor_profile_line") return <ProfileIconLine size={size} color={color} />;
    if (name === "contributor_requests") return <ContributorRequests size={size} color={color} />;
    if (name === "profile") return <ProfileIcon size={size} color={color} />;
    if (name === "integrations") return <IntegrationIcon size={size} color={color} />;
    if (name === "integrations_solid") return <IntegrationIconSolid size={size} color={color} />;
    if (name === "squads") return <Squads size={size} color={color} />;
    return null;
};

export default NavBarIcons;
