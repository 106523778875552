import React, { useCallback, useEffect, useState } from "react";
//import PropTypes from "prop-types";
import { Tooltip } from "@mui/material";
import copy from "copy-to-clipboard";

export default function CopyOnClick({
    children,
    value,
    text,
    copiedMessage = `Copied `,
    disabled = false,
    ...passThrough
}) {
    const [copied, setCopied] = useState(false);

    useEffect(() => {
        let timer;
        if (copied) {
            timer = setTimeout(() => setCopied(false), 1500);
        }

        return () => clearTimeout(timer);
    }, [copied]);

    const onClickCopy = useCallback(
        event => {
            event.stopPropagation();
            copy(value);
            setCopied(true);
        },
        [value],
    );

    if (disabled) {
        return <React.Fragment>{children}</React.Fragment>;
    }
    return (
        <div {...passThrough}>
            <Tooltip
                title={copied ? copiedMessage : `${text ? text : "Click to Copy"}`}
                placement="bottom-start"
            >
                <div onClick={onClickCopy} {...passThrough}>
                    {children}
                </div>
            </Tooltip>
        </div>
    );
}

// CopyOnClick.propTypes = {
//     id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
//     tooltip: PropTypes.any.isRequired,
//     // value: PropTypes.any.isRequired,
// };
