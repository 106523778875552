import React, { useState, useContext, useEffect } from "react";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import PeopleCard from "../SelectedPeople/PeopleCard";
import { Button, Box, Typography, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import Collapse from "@mui/material/Collapse";
import TokenSelectCollapse from "./TokenSelectCollapse";
import SafeContext from "src/contexts/SafeContext";
import { useSnackbar } from "notistack";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import Popover from "@mui/material/Popover";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { formatTokenDecimals } from "src/helpers/utils/common";
import Decimal from "decimal.js-light";

function Rows({ index, data, selectedRows, setSelectedRows, setDisableReview }) {
    const { enqueueSnackbar } = useSnackbar();
    const { tagInfocus, payoutQueueByTag, setPayoutQueueByTag }: any =
        useContext(MassPayoutContext);
    const { tokensInSafe }: any = useContext(SafeContext);
    const [open, setOpen] = useState(false);
    const [noOfTokens, setNoOfTokens] = useState([1]);
    const [fiatConversion, setFiatConversion] = useState(0);
    const [edit, setEdit] = useState([]);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleAction = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
    };

    const handleClose = event => {
        setAnchorEl(null);
        event.stopPropagation();
    };

    const actionOpen = Boolean(anchorEl);
    const id = actionOpen ? "simple-popover-1" : undefined;
    const handleClick = event => {
        setOpen(!open);
        event.stopPropagation();
    };
    const handleSelected = event => {
        if (selectedRows.some(item => item.address === data.address)) {
            let newSelected = selectedRows.filter(item => item.address !== data.address);
            setSelectedRows(newSelected);
        } else {
            setSelectedRows([...selectedRows, data]);
        }
        event.stopPropagation();
    };

    const isSelected = selectedRows.some(item => item.address === data.address);

    const handleTokenRow = () => {
        let tokens = Object.values(tokensInSafe) ?? [];
        if (tokens.length == noOfTokens.length) {
            enqueueSnackbar(`Cannot add more than ${noOfTokens.length} entries`, {
                variant: "error",
            });
            return;
        } else {
            setNoOfTokens([...noOfTokens, Math.random()]);
        }
    };
    const handleRemoveToken = () => {
        if (noOfTokens.length < 1) {
            return;
        } else {
            setNoOfTokens(new Array(noOfTokens.length - 1).fill(0));
        }
    };
    useEffect(() => {
        if (data && data.selectedTokens) {
            setNoOfTokens([]);
        }
        if (data && data.selectedTokens && data.selectedTokens.length === 0) {
            setNoOfTokens([1]);
        }
    }, [data]);

    const handleSubmit = (values, isTokenSelected, index) => {
        const { address } = data;

        // Convert to Decimal before saving to context
        let newValues = { ...values, amount: new Decimal(values.amount || 0) };

        const newQueue = payoutQueueByTag[tagInfocus].map(people => {
            if (people.address === address) {
                return isTokenSelected
                    ? {
                          ...people,
                          selectedTokens: people.selectedTokens
                              ? [
                                    ...people.selectedTokens.slice(0, index),
                                    newValues,
                                    ...people.selectedTokens.slice(
                                        index + 1,
                                        people.selectedTokens.length,
                                    ),
                                ]
                              : [newValues],
                      }
                    : {
                          ...people,
                          selectedTokens: people.selectedTokens
                              ? [...people.selectedTokens, newValues]
                              : [newValues],
                      };
            } else {
                return people;
            }
        });

        setPayoutQueueByTag({
            ...payoutQueueByTag,
            [tagInfocus]: [...newQueue],
        });

        setEdit(edit.filter(i => i !== index));
    };

    const handleDeleteFromPayout = event => {
        let newQueue = payoutQueueByTag[tagInfocus].filter(
            people => people.address !== data.address,
        );
        if (newQueue.length === 0) {
            let updated = { ...payoutQueueByTag };
            delete updated[tagInfocus];
            setPayoutQueueByTag(updated);
        } else {
            setPayoutQueueByTag({
                ...payoutQueueByTag,
                [tagInfocus]: newQueue,
            });
        }
        setSelectedRows(selectedRows.filter(item => item.address !== data.address));
        setAnchorEl(null);
        event.stopPropagation();
    };

    return (
        <>
            <TableRow
                hover
                //sx={{ "& > *": { borderBottom: "none" } }}
                role="checkbox"
                tabIndex={-1}
                onClick={handleClick}
                style={{ background: isSelected ? "#F5F8FE" : "none", cursor: "pointer" }}
            >
                <TableCell padding="checkbox">
                    <Checkbox
                        sx={{ color: "#CAC9D6" }}
                        onClick={handleSelected}
                        checked={isSelected}
                    />
                </TableCell>
                <TableCell key={data.address}>
                    <PeopleCard
                        data={{
                            nickName: data.nickName,
                            address: data.address,
                        }}
                        icon={false}
                        margin={false}
                    />
                </TableCell>
                <TableCell key={index}>
                    {data && data.selectedTokens?.length > 0 ? (
                        <Box display="flex" alignItems="center">
                            <Box
                                component="img"
                                src={
                                    data.selectedTokens.length === 1
                                        ? data.selectedTokens[0].token.logoUri
                                        : "/multiToken.png"
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null; // prevents looping
                                    currentTarget.src = UndiscoveredCoin.src;
                                }}
                                width="24px"
                                height="24px"
                                mr="10px"
                            />
                            <Box>
                                <Typography fontSize="14px" fontWeight={600} color="title.main">
                                    {data.selectedTokens.length === 1
                                        ? data.selectedTokens[0].token.name
                                        : "Multi Tokens"}
                                </Typography>
                                {data.selectedTokens.length === 1 && (
                                    <Typography fontSize="13px" fontWeight={500} color="#8B91A1">
                                        {formatTokenDecimals(data.selectedTokens[0].amount, 4)}{" "}
                                        {data.selectedTokens[0].token.symbol}
                                    </Typography>
                                )}
                            </Box>
                        </Box>
                    ) : (
                        <Button
                            style={{ fontSize: 14 }}
                            variant="text"
                            color="primary"
                            startIcon={<AddIcon />}
                            disabled={open}
                        >
                            Add Token
                        </Button>
                    )}
                </TableCell>
                <TableCell align="right">
                    <IconButton
                        aria-label="more-vertical"
                        aria-describedby={id}
                        onClick={handleAction}
                    >
                        <MoreVertIcon
                            sx={{
                                background: "#F2F2F2",
                                color: "#5E5C6C",
                                borderRadius: "4px",
                            }}
                            fontSize="medium"
                        />
                    </IconButton>
                    <Popover
                        id={id}
                        open={actionOpen}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <Button
                            variant="text"
                            color="inherit"
                            sx={{
                                fontWeight: 400,
                            }}
                            onClick={handleDeleteFromPayout}
                        >
                            Delete
                        </Button>
                    </Popover>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin="8px auto" width="85%">
                            {data &&
                                data.selectedTokens &&
                                data.selectedTokens.map((tokens, index) => {
                                    // const key = uuid();
                                    return (
                                        <TokenSelectCollapse
                                            key={index}
                                            handleRemoveToken={handleRemoveToken}
                                            isDelete={false}
                                            address={data.address}
                                            isTokenSelected={true}
                                            data={tokens}
                                            index={index}
                                            uid={index}
                                            handleSubmit={handleSubmit}
                                            fiatConversion={fiatConversion}
                                            setFiatConversion={setFiatConversion}
                                            edit={edit}
                                            setEdit={setEdit}
                                            setDisableReview={setDisableReview}
                                        />
                                    );
                                })}
                            {noOfTokens.map((_, index) => (
                                <TokenSelectCollapse
                                    key={index}
                                    handleRemoveToken={handleRemoveToken}
                                    isDelete={
                                        data &&
                                        data.selectedTokens &&
                                        data.selectedTokens.length > 0
                                            ? true
                                            : index !== 0
                                            ? true
                                            : false
                                    }
                                    address={data.address}
                                    isTokenSelected={false}
                                    index={index}
                                    handleSubmit={handleSubmit}
                                    fiatConversion={fiatConversion}
                                    setFiatConversion={setFiatConversion}
                                    setDisableReview={setDisableReview}
                                />
                            ))}

                            <Button
                                variant="text"
                                color="primary"
                                startIcon={<AddIcon />}
                                onClick={handleTokenRow}
                                disabled={
                                    (data &&
                                        data.selectedTokens &&
                                        data.selectedTokens.length + noOfTokens.length >=
                                            Object.values(tokensInSafe).length) ||
                                    noOfTokens?.length == 1
                                }
                            >
                                Add more
                            </Button>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

export default Rows;
