import Decimal from "decimal.js-light";
import { formatUnits, getAddress, parseEther } from "ethers/lib/utils";
import { getERC20Contract } from "src/helpers/createTransaction";
import { nativeTokenSymbol } from "src/queries/constants";
import { getAmountInWei } from "src/helpers/tx-helper";
import { networkId } from "src/helpers/utils/networks";
import { getSimulation, simulateAPI } from "src/queries/simulate";
import { getGasEstimate } from "src/helpers/getGasEstimate";
import { getNextPendingNonce } from "src/helpers/tx-helpers";
import Safe from "@safe-global/protocol-kit";

const operation = 1; // DELEGATECALL

export const useSimulation = () => {
    async function simulate(
        safeService,
        safeSdk,
        customToken,
        transactions,
        currentSafeAddress,
        proxyContract,
        account,
    ) {
        try {
            const { data: transaction, signatures } = await createTransactionObject(
                safeService,
                safeSdk,
                customToken,
                transactions,
                currentSafeAddress,
            );

            const sigs = generateSignaturesFromTxConfirmations(signatures, account);

            const { safeTxGas, baseGas } = getGasEstimate(
                currentSafeAddress,
                {
                    safe: currentSafeAddress,
                    to: transaction.to,
                    value: transaction.value,
                    data: transaction.data,
                    operation,
                    gasToken: transaction.gasToken,
                    // gasToken,
                },
                0,
            );

            const gasLimit = Number(safeTxGas) + Number(baseGas) + 21000;

            const input = proxyContract.interface.encodeFunctionData("execTransaction", [
                transaction.to,
                transaction.value,
                transaction.data,
                transaction.operation,
                transaction.safeTxGas,
                transaction.baseGas,
                transaction.gasPrice,
                transaction.gasToken,
                transaction.refundReceiver,
                sigs,
            ]);

            const resp: any = await simulateAPI(
                networkId.toString(),
                currentSafeAddress,
                account,
                input,
                gasLimit,
                transaction.gasPrice.toString(),
                true,
                true,
                "quick",
            );
            return resp?.json();
        } catch (error) {
            return error;
        }
    }

    async function getSimulationById(id) {
        const resp: any = await getSimulation(id);
        return resp?.json();
    }

    return { simulate, getSimulationById };
};

const createTransactionObject = async (
    safeService,
    safeSdk: Safe,
    customToken,
    transactions,
    currentSafeAddress,
) => {
    const setTransactions: any = await Promise.all(
        transactions?.map(async tx => {
            if (tx.selectedToken === nativeTokenSymbol) {
                return {
                    to: getAddress(tx.walletAddress),
                    value: formatUnits(
                        parseEther(`${new Decimal(tx.tokenValue).toFixed(18)}`),
                        "wei",
                    ),
                    data: "0x",
                    operation: 0,
                };
            } else {
                const erc20 = getERC20Contract(tx.selectedToken, customToken);

                if (!erc20) {
                    throw new Error("ERC20 token undefined");
                }
                let decimals = await erc20.decimals();
                return {
                    to: tx.selectedToken,
                    value: 0,
                    operation: 0,
                    data: erc20.interface.encodeFunctionData("transfer", [
                        getAddress(tx.walletAddress),
                        getAmountInWei(`${new Decimal(tx.tokenValue).toFixed(decimals)}`, decimals),
                    ]),
                };
            }
        }),
    );
    // Use Custom calculated Nonce
    const nonce = await getNextPendingNonce(currentSafeAddress, safeSdk);
    // const nonce = await safeService.getNextNonce(currentSafeAddress);

    const options = {
        nonce,
    };

    const safeTransaction = await safeSdk.createTransaction({
        safeTransactionData: {
            ...setTransactions,
        },
        options,
    });

    return safeTransaction;
};

export const EMPTY_DATA = "0x";

export const getPreValidatedSignatures = (
    from: string,
    initialString: string = EMPTY_DATA,
): string => {
    return `${initialString}000000000000000000000000${from.replace(
        EMPTY_DATA,
        "",
    )}000000000000000000000000000000000000000000000000000000000000000001`;
};

const generateSignaturesFromTxConfirmations = (confirmations, preApprovingOwner) => {
    if (confirmations.length > 0) {
        const combinedSignature = confirmations
            .sort((a, b) => (a.owner.toLowerCase() > b.owner.toLowerCase() ? 1 : -1))
            .reduce((prev, item) => (prev += item.signature.replace("0x", "")), "0x");
        return combinedSignature;
    } else {
        return getPreValidatedSignatures(preApprovingOwner);
    }
};
