import Safe from "@safe-global/protocol-kit";
import { ethers } from "ethers";
import fromExponential from "from-exponential";
import { gnosisURL, metaTxResubmitEndpoint } from "src/queries/constants";

export const getAmountFromWei = (tokenAmount: any, decimals: any, precision = undefined): any => {
    let amount = tokenAmount;
    if (typeof tokenAmount === "string") {
        if (tokenAmount.includes("e")) {
            amount = fromExponential(tokenAmount);
        } else {
            amount = tokenAmount.split(".")[0];
        }
    }
    if (precision) {
        return Number(ethers.utils.formatUnits(amount, decimals)).toFixed(precision);
    }
    return Number(ethers.utils.formatUnits(amount, decimals));
};

export const getAmountInWei = (tokenAmount, decimals) => {
    let amount = tokenAmount;
    if (typeof tokenAmount === "string") {
        const [whole, fraction] = tokenAmount.split(".");
        if (fraction) {
            amount = whole + "." + fraction.slice(0, decimals);
        } else {
            amount = whole;
        }
    } else if (typeof tokenAmount === "number") {
        amount = tokenAmount.toFixed(decimals);
    }

    return ethers.utils.parseUnits(amount, decimals);
};

export const getFinalMetaTransactionStatus = async (metaTxHash, networkId) => {
    const resp = await fetch(
        `${metaTxResubmitEndpoint}transactionHash=${metaTxHash}&networkId=${networkId}`,
    );
    const result = await resp.json();
    if (result.data.newStatus === "CONFIRMED") {
        return { hash: result.data.newHash, status: "CONFIRMED" };
    } else if (result.data.newStatus === "FAILED") {
        return { hash: null, status: "FAILED" };
    } else {
        return {};
    }
};

export const getFinalMetaTransactionHash = async (metaTxHash, networkId) => {
    try {
        const resp = await fetch(
            `${metaTxResubmitEndpoint}transactionHash=${metaTxHash}&networkId=${networkId}`,
        );
        const result = await resp.json();

        if (result.data.newStatus === "CONFIRMED") {
            return result.data.newHash;
        } else {
            return false;
        }
    } catch {
        return false;
    }
};

export const getNextPendingNonce = async (safeAddress: string, safeSdk: Safe) => {
    const safeNonce = await safeSdk.getNonce();
    if (safeAddress === "") {
        throw new Error("Invalid Safe address");
    }
    let pendingTransactions: any = await fetch(
        `${gnosisURL}/safes/${safeAddress}/multisig-transactions/?executed=false&trusted=true&nonce__gte=${safeNonce}`,
    ).then(res => res.json());

    if (
        pendingTransactions &&
        pendingTransactions.results &&
        pendingTransactions.results.length > 0
    ) {
        const nonces = pendingTransactions.results.map(tx => tx.nonce);
        const lastNonce = Math.max(...nonces);
        return lastNonce + 1;
    } else {
        return safeNonce;
    }
};
