import { useContext, useEffect } from "react";
import { BigNumber } from "ethers";
import React, { useState } from "react";
import { createContext } from "react";
import { useRouter } from "next/router";
import { nativeTokenSymbol } from "src/queries/constants";
import { getAddress } from "ethers/lib/utils";
import { formatAddress, validNumberString } from "src/helpers/utils/common";
import SafeContext from "./SafeContext";
import Decimal from "decimal.js-light";
const TransferContext = createContext({});

export const TransferContextprovider = ({ children }) => {
    //  ENUM quick, bulk, csv
    const { tokensInSafe }: any = useContext(SafeContext);
    const router = useRouter();
    const [transferType, setTransferType] = useState("quick");
    const [walletAddress, setWalletAddress] = useState("");
    const [walletNickName, setWalletNickName] = useState("");
    const [selectedToken, setSelectedToken] = useState(nativeTokenSymbol);
    const [tokenSymbol, setTokenSymbol] = useState(nativeTokenSymbol);
    const [tokenDecimal, setTokenDecimal] = useState(18);
    const [tokenValue, setTokenValue] = useState("");
    const [tokenValueInUSD, setTokenValueInUSD] = useState("");
    const [payoutQueue, setPayoutQueue] = useState([]);
    const [description, setDescription] = useState("");
    const [modalState, setModalState] = useState("closed");
    const [isEditOpen, setIsEditOpen] = useState(false);
    const [isTxStarted, setIsTxStarted] = useState(false);
    const [isEligibleTx, setIsEligibleTx] = useState(true);
    const [category, setCategory] = useState("");
    const [tag, setTag] = useState("");
    const [payoutCategory, setPayoutCategory] = useState("");
    const [payoutComment, setPayoutComment] = useState("");
    const [gasLimit, setGasLimit] = useState({
        fee: BigNumber.from(150 * (10 ^ 9)),
        feeInUSD: 55.98,
        type: "Fast",
        time: "45 sec",
        icon: "🚀",
    });
    const [token, setToken] = useState({
        ...tokensInSafe[nativeTokenSymbol],
    });

    // Mass Payout States
    const MASSPAYOUT_MAX_STEPS = 3;
    const [massPayoutStep, setMassPayoutStep] = useState(1);
    const [payrollPreviewStep, setPayrollPreviewStep] = useState(1);

    const maximizeModal = () => {
        setModalState("maximized");
    };
    const minimizeModal = () => {
        setModalState("minimized");
    };
    const closeModal = () => {
        resetTransfer();
        setModalState("closed");
    };

    const resetTransfer = () => {
        setTransferType("quick");
        setWalletAddress("");
        setWalletNickName("");
        setSelectedToken(nativeTokenSymbol);
        setTokenValue("");
        setTokenSymbol(nativeTokenSymbol);
        setTokenValueInUSD("");
        setPayoutQueue([]);
        setDescription("");
        setIsEditOpen(false);
        setMassPayoutStep(1);
        setIsEligibleTx(true);
        setPayrollPreviewStep(1);
        setTokenSymbol(nativeTokenSymbol);
        setTag("");
        setPayoutCategory("");
        setPayoutComment("");

        setToken({ ...tokensInSafe[nativeTokenSymbol] });
    };
    const resetQuickTxForm = () => {
        setWalletAddress("");
        setWalletNickName("");
        setTokenSymbol(nativeTokenSymbol);
        setSelectedToken(nativeTokenSymbol);
        setTokenSymbol(nativeTokenSymbol);
        setTokenValue("");
        setTokenValueInUSD("");
        setIsEditOpen(false);
        setTag("");
        setPayoutCategory("");
        setPayoutComment("");
    };

    const addToPayoutQueue = async () => {
        let WAResolved = await formatAddress(walletAddress.trim());

        if (!WAResolved.fullAddress) throw new Error("Invalid Address / ENS");

        let payout = {
            walletAddress: WAResolved.fullAddress,
            walletNickName,
            selectedToken,
            tokenValue: new Decimal(validNumberString(tokenValue, true, tokenDecimal)),
            tokenValueInUSD: Number(validNumberString(tokenValueInUSD)),
            tokenSymbol,
            tokenDecimal,
            walletENS: WAResolved.isENS ? WAResolved.ens : false,
            tag,
            payoutCategory,
            payoutComment,
        };

        setPayoutQueue([payout, ...payoutQueue]);
        setWalletAddress("");
        setWalletNickName("");
        setTokenValue("");
        setSelectedToken(nativeTokenSymbol);
        setTokenSymbol(nativeTokenSymbol);
        setTokenValueInUSD("");
        setIsEditOpen(false);
        setTokenDecimal(18);
        setTokenSymbol(nativeTokenSymbol);
        setToken({ ...tokensInSafe[nativeTokenSymbol] });
        setTag("");
        setPayoutCategory("");
        setPayoutComment("");
    };

    const addToPayoutQueueWithParams = ({
        walletAddress,
        walletNickName,
        selectedToken,
        tokenValue,
        tokenValueInUSD,
        tokenSymbol,
        requestId,
        tokenDecimal,
        tagName,
        comment,
    }) => {
        setPayoutQueue(payoutQueue => [
            ...payoutQueue,
            {
                requestId,
                walletAddress: getAddress(walletAddress),
                walletNickName,
                selectedToken,
                tokenValue,
                tokenValueInUSD,
                tokenSymbol,
                tokenDecimal,
                tagName,
                comment,
            },
        ]);
    };

    const removeFromPayoutQueue = index => {
        let alteredQueue = [...payoutQueue];
        alteredQueue.splice(index, 1);
        setPayoutQueue(alteredQueue);
    };

    const updateFromQueue = index => {
        let newPayout = {
            walletAddress: getAddress(walletAddress),
            walletNickName,
            selectedToken,
            tokenValue,
            tokenValueInUSD,
        };
        let alteredQueue = [...payoutQueue];
        alteredQueue.splice(index, 1, newPayout);
        setPayoutQueue(alteredQueue);
    };

    useEffect(() => {
        router.events.on("routeChangeComplete", pathname => {
            if (pathname.startsWith("/contributor") || pathname.startsWith("/onboard")) {
                closeModal();
            }
        });
        return () => {
            router.events.off("routeChangeComplete", closeModal);
        };
    }, [router.events]);

    return (
        <TransferContext.Provider
            value={{
                transferType,
                setTransferType,
                walletAddress,
                setWalletAddress,
                walletNickName,
                setWalletNickName,
                selectedToken,
                setSelectedToken,
                tokenValue,
                setTokenValue,
                tokenDecimal,
                setTokenDecimal,
                tokenValueInUSD,
                setTokenValueInUSD,
                addToPayoutQueue,
                removeFromPayoutQueue,
                updateFromQueue,
                description,
                setDescription,
                gasLimit,
                setGasLimit,
                payoutQueue,
                setPayoutQueue,
                modalState,
                maximizeModal,
                minimizeModal,
                closeModal,
                resetTransfer,
                MASSPAYOUT_MAX_STEPS,
                massPayoutStep,
                setMassPayoutStep,
                isEditOpen,
                setIsEditOpen,
                isTxStarted,
                setIsTxStarted,
                tokenSymbol,
                setTokenSymbol,
                addToPayoutQueueWithParams,
                payrollPreviewStep,
                setPayrollPreviewStep,
                isEligibleTx,
                setIsEligibleTx,
                resetQuickTxForm,
                setCategory,
                category,
                token,
                setToken,
                tag,
                setTag,
                payoutCategory,
                setPayoutCategory,
                payoutComment,
                setPayoutComment,
            }}
        >
            {children}
        </TransferContext.Provider>
    );
};

export default TransferContext;
