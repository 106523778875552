import styled from "@emotion/styled";
import { ListItemButton, ListSubheader } from "@mui/material";
import { Box } from "@mui/system";

export const TransferSidebarStyled = styled(Box)`
    width: 310px;
    padding-top: 20px;
    border-right: 1px solid rgba(234, 234, 234, 1);
    height: 100%;
    background: #ffffff;
`;

export const ListSubheaderStyled = styled(ListSubheader)`
    font-size: 11px;
    line-height: 11px;
    color: ${({ theme }: any) => theme.palette.title.sub};
    margin-bottom: 15px;
`;

export const ListItemButtonStyled = styled(ListItemButton)`
    height: 44px;
    margin: 10px;

    .MuiListItemText-root {
        margin: 0;
    }
    .MuiListItemText-primary {
        font-size: 14px;
        line-height: 18px;
        font-weight: 400;
        transition: all 0.1s ease-in-out;
        color: ${({ theme, selected }: any) => (selected ? theme.palette.primary.main : "inherit")};
    }
    .MuiListItemIcon-root {
        min-width: auto;
        max-width: 15px;
        margin-right: 10px;
        color: ${({ theme, selected }: any) => (selected ? theme.palette.primary.main : "inherit")};
    }
    .MuiSvgIcon-root {
        width: 1rem;
        height: 1rem;
        transition: all 0.1s ease-in-out;
    }
    &.Mui-selected {
        background-color: #dae4fc;
        border-radius: 4px;
        span {
            font-weight: 600;
        }
    }
`;
