import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import { Box } from "@mui/system";
import {
    FilterButton,
    SelectionBoxWrapper,
} from "src/components/PeopleSection/SearchFilter/styles";

export const PeopleSearchStyled = styled(Box)`
    margin-top: 20px;
`;

export const SelectedCountChip = styled.div`
    background-color: #2962ef;
    margin: 0 5px 0 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 13px;
    color: white;
    border-radius: 50%;
    border: none;
    width: 22px;
    height: 22px;
`;

export const TagFilterStyled = styled(Box)`
    position: relative;
    .MuiPaper-root {
        margin-left: 0;
        margin-right: 0;
        border: none;
    }
`;

export const FilterButtonStyled = styled(FilterButton)`
    padding-left: 12px;
    padding-right: 5px;
    width: auto;
`;
export const SelectionBoxWrapperStyled = styled(SelectionBoxWrapper)`
    left: 0;
    width: 455px;
`;
