import styled from "@emotion/styled";

export const SummaryWrapper = styled.div`
    display: flex;

    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 8px;
        padding: 0px 25px 0px 0px;
        white-space: nowrap;
        max-width: 50%;

        span:nth-of-type(1) {
            font-weight: 600;
            font-size: 14px;
            color: #232427;
        }
        span:nth-of-type(2) {
            font-weight: 500;
            font-size: 13px;
            color: ${({ theme }: any) => theme.palette.title.light};
        }
    }

    .identicon {
        border-radius: 50%;
    }
    .circle {
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            max-width: 26px;
        }
    }
    #amount {
        font-weight: normal;
        font-size: 13px;
        line-height: 13px;
        color: #ec5952;
    }
`;
