import { ethers } from "ethers";
import moment from "moment";
import { useGetBalanceUSDFromGnosis } from "src/queries/Transaction/api";
import { minifyAddress } from "./web3Utils";
const url = `https://mainnet.infura.io/v3/${process.env.NEXT_PUBLIC_INFURA_TOKEN}`;
// Use Mainnet provider since ENS resolver doesn't work on other networks
const provider = new ethers.providers.JsonRpcProvider(url);
import { nativeTokenName, nativeTokenSymbol, NATIVE_ICON_URL } from "src/queries/constants";
import { getAddress } from "ethers/lib/utils";
import Decimal from "decimal.js-light";
import { formatAmount } from "./web3Utils";

export const formatAddress = async to => {
    if (await ethers.utils.isAddress(to)) {
        return {
            address: minifyAddress(to),
            isAddress: true,
            to: to,
            fullAddress: to,
        };
    } else {
        let ensAddress = "";
        try {
            ensAddress = await provider.resolveName(to);
        } catch (error) {}
        if (ethers.utils.isAddress(ensAddress)) {
            return {
                address: minifyAddress(ensAddress),
                isAddress: false,
                isENS: true,
                ens: to,
                to: ensAddress,
                fullAddress: ensAddress,
            };
        } else {
            return {
                isAddress: false,
                isENS: false,
                to: to,
            };
        }
    }
};

export const formatDate = date => {
    return moment(date).format("MMM Do YYYY");
};

export const formatDateFromEpoch = date => {
    return moment(date * 1000).format("MMM Do YYYY");
};

export const getFormatedBalances = async () => {
    const tokenMapping = {};
    const { data, status } = useGetBalanceUSDFromGnosis(
        "0x885d3f0C01A82D6B209063ce93E4853904580FA8",
    );

    return new Promise((res, rej) => {
        if (status === "success") {
            data.forEach(token => {
                if (token.token) {
                    tokenMapping[token.token.symbol] = token;
                } else {
                    tokenMapping[nativeTokenSymbol] = { ...token };
                    tokenMapping[nativeTokenSymbol].token = {
                        name: nativeTokenName,
                        symbol: nativeTokenSymbol,
                        decimals: 18,
                        logoUri: NATIVE_ICON_URL,
                    };
                }
            });
            res(tokenMapping);
        }
    });
};

export const getFormattedBalanceFromUnits = (value: number, decimals: number = 18) => {
    return value / Math.pow(10, decimals);
};

export const isTagInSafe = (tagName, tags) => {
    if (!tagName) return true;
    return tags.some(k => {
        return k === tagName;
    });
};

export const processCSVData = (data, tokensInSafe, setBalances, balances, tags = []) => {
    if (data.length) {
        return new Promise(async (res, rej) => {
            const formattedData = [];

            for (let index = 1; index < data.length; index++) {
                let isErrorAtRow = false;

                const row: any = {
                    username: data[index][0],
                    address: data[index][1],
                    amount:
                        data[index][2] !== ""
                            ? new Decimal(data[index][2].trim().split(",").join(""))
                            : 0,
                    token: data[index][3],
                    amountInString: data[index][2],
                    amountInUSD: data[index][4],
                    tagName: data[index][5],
                    comment: data[index][6],
                };
                const formatedAddress = await formatAddress(data[index][1]);
                if (!formatedAddress.isAddress && !formatedAddress.isENS) {
                    row.isAddressError = "Address/ENS is not Valid";
                    isErrorAtRow = true;
                }
                row.address = formatedAddress.to;
                row.ens = formatedAddress.isENS ? formatedAddress.ens : null;
                row.isErrorAtRow = isErrorAtRow;
                formattedData.push(row);
            }

            const rowWithTokenDetails = [];
            const _balances = { ...balances };
            formattedData.forEach(row => {
                let isTokenAddress = false;
                let isErrorAtRow = false;

                try {
                    isTokenAddress = ethers.utils.isAddress(row.token);
                } catch (error) {
                    isTokenAddress = false;
                }
                if (!isTokenAddress) {
                    let currentToken: any = Object.values(tokensInSafe).find(
                        (token: any) => token.symbol.toUpperCase() === row.token.toUpperCase(),
                    );
                    if (!currentToken) {
                        const _data = { ...row };
                        _data.isTokenAvailable = "Token not available in the safe";
                        _data.isErrorAtRow = true;
                        rowWithTokenDetails.push(_data);
                    } else {
                        const _data = { ...row, ...(currentToken || {}) };

                        if (
                            _data.amountInUSD !== "" &&
                            _data.amountInUSD !== undefined &&
                            _data.amount == 0
                        ) {
                            _data.fiatValue = Number(_data.amountInUSD.split(",").join(""));
                            if (currentToken.fiatConversion === 0) {
                                _data.isErrorAtRow = true;
                                _data.isFiatConversionMissing =
                                    "Price discovery unavailable for this token. Please provide token value";
                            } else {
                                _data.amount = new Decimal(
                                    _data.fiatValue / currentToken.fiatConversion,
                                );
                            }
                        } else {
                            _data.fiatValue = _data.amount.mul(_data.fiatConversion).toNumber();
                        }

                        if (!_data.isFiatConversionMissing) {
                            _balances[_data.tokenAddress].totalUSDConsumed = _balances[
                                _data.tokenAddress
                            ].totalUSDConsumed
                                ? _balances[_data.tokenAddress].totalUSDConsumed + _data.fiatValue
                                : 0 + _data.fiatValue;

                            _balances[_data.tokenAddress].totalTokenConsumed = _balances[
                                _data.tokenAddress
                            ].totalTokenConsumed
                                ? _balances[_data.tokenAddress].totalTokenConsumed.plus(
                                      _data.amount.greaterThan(0) ? _data.amount : new Decimal(0),
                                  )
                                : _data.amount.greaterThan(0)
                                ? _data.amount
                                : new Decimal(0);
                            _balances[_data.tokenAddress].totalRows = _balances[_data.tokenAddress]
                                .totalRows
                                ? _balances[_data.tokenAddress].totalRows + 1
                                : 1;

                            if (
                                _balances[_data.tokenAddress].totalValueInEther
                                    .minus(_balances[_data.tokenAddress].totalTokenConsumed)
                                    .lessThan(0)
                            ) {
                                _data.isBalanceError = "Insufficient Balance";
                                _data.isErrorAtRow = true;
                            }
                            if (_data.amount.lessThanOrEqualTo(0)) {
                                _data.isBalanceError = "Token amount should be greater than 0";
                                _data.isErrorAtRow = true;
                            }

                            if (
                                _data.amountInString.toString().split(".")[1] &&
                                _data.amountInString.toString().split(".")[1].length >
                                    _data.decimals
                            ) {
                                _data.isBalanceError = `Amount Decimals cannot exceed ${_data.decimals}`;
                                _data.isErrorAtRow = true;
                            }
                        }

                        if (!isTagInSafe(row.tagName, tags)) _data.isErrorAtRow = true;

                        rowWithTokenDetails.push(_data);
                    }
                } else {
                    let currentToken: any = {};
                    try {
                        currentToken = tokensInSafe[getAddress(row.token)];
                    } catch (error) {}
                    if (!currentToken) {
                        const _data = { ...row };
                        _data.isTokenAvailable = "Token not available in the safe";
                        _data.isErrorAtRow = true;
                        rowWithTokenDetails.push(_data);
                    } else {
                        const _data = { ...row, ...(currentToken || {}) };
                        if (_data.amountInUSD !== "" && _data.amount == 0) {
                            _data.fiatValue = Number(_data.amountInUSD.split(",").join(""));
                            if (currentToken.fiatConversion === 0) {
                                _data.isErrorAtRow = true;
                                _data.isFiatConversionMissing =
                                    "Price discovery unavailable for this token. Please provide token value";
                            } else {
                                _data.amount = new Decimal(
                                    _data.fiatValue / currentToken.fiatConversion,
                                );
                            }
                        } else {
                            _data.fiatValue = _data.amount.mul(_data.fiatConversion).toNumber();
                        }

                        if (!_data.isFiatConversionMissing) {
                            _balances[_data.tokenAddress].totalUSDConsumed = _balances[
                                _data.tokenAddress
                            ].totalUSDConsumed
                                ? _balances[_data.tokenAddress].totalUSDConsumed + _data.fiatValue
                                : 0 + _data.fiatValue;

                            _balances[_data.tokenAddress].totalTokenConsumed = _balances[
                                _data.tokenAddress
                            ].totalTokenConsumed
                                ? _balances[_data.tokenAddress].totalTokenConsumed.plus(
                                      _data.amount.greaterThan(0) ? _data.amount : new Decimal(0),
                                  )
                                : _data.amount.greaterThan(0)
                                ? _data.amount
                                : new Decimal(0);
                            _balances[_data.tokenAddress].totalRows = _balances[_data.tokenAddress]
                                .totalRows
                                ? _balances[_data.tokenAddress].totalRows + 1
                                : 1;

                            if (
                                _balances[_data.tokenAddress].totalValueInEther
                                    .minus(_balances[_data.tokenAddress].totalTokenConsumed)
                                    .lessThan(0)
                            ) {
                                _data.isBalanceError = "Insufficient Balance";
                                _data.isErrorAtRow = true;
                            }
                        }

                        rowWithTokenDetails.push(_data);
                    }
                }
            });

            setBalances(_balances);
            res(rowWithTokenDetails);
        });
    }
};

export const convertArrayToObject = (array, key, addItem = false) => {
    const initialValue = {};
    if (addItem)
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: item,
            };
        }, initialValue);
    else
        return array.reduce((obj, item) => {
            return {
                ...obj,
                [item[key]]: true,
            };
        }, initialValue);
};

export const validateEmail = email => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        );
};

export const ucFirst = (string = "") => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const formatTokenDecimals = (
    value: Decimal = new Decimal(0),
    decimalplaces: number = 4,
    showCaret: boolean = true,
    withDollar = false,
) => {
    let calculateShowCaret = showCaret && new Decimal(value).greaterThan(0);
    return `${withDollar ? "$" : ""}${
        new Decimal(value).lessThan(1 / Math.pow(10, decimalplaces)) && calculateShowCaret
            ? "<"
            : ""
    }${value.toFixed(
        Math.min(new Decimal(value).decimalPlaces(), decimalplaces),
        new Decimal(value).exponent() < -1 * decimalplaces ? Decimal.ROUND_UP : Decimal.ROUND_FLOOR,
    )}`;
};

export const numberWithCommas = x => {
    let strx = x.toString().split(".");
    return `${strx[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",")}${strx[1] ? `.${strx[1]}` : ""}`;
};

export const validNumberString = (x, returnDecimal = false, returnDecimalPlaces = 18) => {
    if (Boolean(x)) {
        const checkIfValidNumber = Number(x);
        if (isNaN(checkIfValidNumber)) {
            return "0";
        } else {
            return returnDecimal
                ? new Decimal(x).toFixed(returnDecimalPlaces || 18)
                : Number(x).toString();
        }
    } else {
        return "0";
    }
};

export function finiteNumber(x) {
    if (x == "0" || x == 0) {
        return 1;
    } else {
        return x;
    }
}

export function roundOff(x, decimals = 2) {
    if (x) {
        let decimalPlaces = x && x.toString().split(".")[1]?.length;
        if (decimalPlaces && decimalPlaces > decimals) {
            return new Decimal(x)?.toFixed(decimals);
        } else return new Decimal(x).toFixed(decimalPlaces);
    } else {
        return "0";
    }
}
