import { createContext, useContext, useEffect, useState } from "react";
import {
    NATIVE_ICON_URL,
    nativeTokenName,
    nativeTokenSymbol,
    gnosisURL,
    ZERO_ADDRESS,
    FETCH_TOKEN_CONVERSION_ENDPOINT,
} from "src/queries/constants";

import { TokenConversionFromGnosis } from "src/queries/ContributorRequest/api";
import { useGetTokensAndPriceBySafe, useGetCurrentSafeDetails } from "src/queries/Safe/api";
import SafeContext from "./SafeContext";
import {
    useFetchActiveBudgetsBySquads,
    useFetchHistoryBudgetsBySquads,
    useFetchPendingBudgetsBySquads,
} from "src/queries/Budget/api";
import { getTokenContractAddress } from "mapping/tokenAddress";
import { networkId } from "src/helpers/utils/networks";
import { weiToString } from "src/helpers/bignumberUtils";
import Decimal from "decimal.js-light";

const SquadContext = createContext({});
export const SquadContextProvider = ({ children }) => {
    const [isSeasonFixedUsd, setIsSeasonFixedUsd] = useState(false);
    const [budgetOptions, setBudgetOptions] = useState([]);
    const { currentSafeAddress, safeStaticDetails }: any = useContext(SafeContext);
    const [parentSafeAddress, setParentSafeAddress] = useState("");
    const [tokensInSafe, setTokensInSafe] = useState({
        [nativeTokenSymbol]: {
            name: nativeTokenName,
            symbol: nativeTokenSymbol,
            logoUri: NATIVE_ICON_URL,
            decimals: 18,
            tokenAddress: nativeTokenSymbol,
            fiatConversion: 1,
        },
    });

    const [tokensForRequest, setTokensForRequest] = useState({});

    const [parentSafeStaticDetails, setParentSafeStaticDetails] = useState<any>({
        activeSeason: null,
        parentSafe: null,
        isSquadOrParent: null,
    });

    const { data: activeBudgets, isSuccess: activeBudgetSuccess } = useFetchActiveBudgetsBySquads(
        currentSafeAddress,
        {
            enabled: Boolean(currentSafeAddress),
        },
    );

    const { data: historyBudgets, isSuccess: historyBudgetSuccess } =
        useFetchHistoryBudgetsBySquads(currentSafeAddress, {
            enabled: Boolean(currentSafeAddress),
        });

    useEffect(() => {
        if (safeStaticDetails?.parentSafe?.safeAddress) {
            setParentSafeAddress(safeStaticDetails?.parentSafe?.safeAddress);
        }
    }, [safeStaticDetails?.parentSafe?.safeAddress]);

    const { data, isSuccess, isError, isLoading } = useGetTokensAndPriceBySafe(parentSafeAddress, {
        enabled: Boolean(parentSafeAddress),
        cacheTime: 0,
        retry: false,
        refetchOnMount: false,
        retryOnMount: false,
    });
    useEffect(() => {
        if (isError) {
            return;
        }
        async function setUpToken() {
            if (data && isSuccess && !isError && !isLoading) {
                let newData = await Promise.all(
                    data?.map(async token => {
                        try {
                            const tokenAddressContract = token?.token
                                ? getTokenContractAddress(token?.tokenAddress)
                                : ZERO_ADDRESS;
                            const decimals = token?.token ? token?.token?.decimals : 18;
                            let fiatConversion = "0";
                            let fiatBalance = "0";

                            const res = await fetch(FETCH_TOKEN_CONVERSION_ENDPOINT, {
                                method: "POST",
                                body: JSON.stringify({
                                    tokenAddress: tokenAddressContract,
                                    networkId: networkId,
                                }),
                                headers: {
                                    "content-type": "application/json",
                                },
                            });
                            const resData = await res.json();
                            fiatConversion = resData?.value;

                            const tokenAmount = token?.balance;

                            const tokenAmountInDecimals = weiToString(tokenAmount || 0, decimals);
                            fiatBalance = new Decimal(tokenAmountInDecimals)
                                ?.mul(fiatConversion)
                                ?.toString();

                            return {
                                ...token,
                                fiatConversion,
                                fiatBalance,
                            };
                        } catch (err) {
                            return {
                                ...token,
                            };
                        }
                    }),
                );

                if (Array.isArray(newData)) {
                    resolvingTokensInSafe(newData);
                }
            }
        }
        setUpToken();
    }, [data, isSuccess, isError, isLoading, parentSafeAddress]);

    const safeDetails: any = useGetCurrentSafeDetails(parentSafeAddress);

    useEffect(() => {
        if (safeDetails.isError) {
            return;
        }
        if (safeDetails.data) setParentSafeStaticDetails(safeDetails.data);
    }, [safeDetails]);

    useEffect(() => {
        if (parentSafeStaticDetails?.activeSeason && tokensInSafe) {
            let tempObj = {};

            const seasonAllowedTokens = (
                parentSafeStaticDetails?.activeSeason?.budgetConstraint || []
            ).map(({ tokenAddress }) => tokenAddress);

            for (const property in tokensInSafe) {
                if (seasonAllowedTokens.includes(property)) {
                    tempObj[property] = tokensInSafe[property];
                }
            }
            setIsSeasonFixedUsd(
                parentSafeStaticDetails?.activeSeason?.budgetConstraint?.[0]?.isFixedUSD || false,
            );
            setTokensForRequest(tempObj);
        }
    }, [parentSafeStaticDetails, tokensInSafe]);

    useEffect(() => {
        if (activeBudgetSuccess || historyBudgetSuccess) {
            try {
                const mySet = new Set();
                if (parentSafeStaticDetails?.activeSeason) {
                    mySet.add({ budgetId: 0, name: "Do not link with any Budget" });
                    activeBudgets?.length > 0 &&
                        activeBudgets.forEach(item => {
                            const { budgetId, name } = item;
                            mySet.add({ budgetId, name });
                        });
                    const { seasonId: sId = null } = parentSafeStaticDetails?.activeSeason;
                    historyBudgets?.length > 0 &&
                        historyBudgets.forEach(item => {
                            const {
                                budgetId,
                                name,
                                seasonDetails: { seasonId },
                            } = item;
                            if (seasonId === sId) {
                                mySet.add({ budgetId, name });
                            }
                        });
                }
                if (mySet.size > 0) {
                    setBudgetOptions(Array.from(mySet));
                } else {
                    setBudgetOptions([]);
                }
            } catch (err) {
                console.error(err);
                setBudgetOptions([]);
            }
        }
    }, [activeBudgets, historyBudgets, parentSafeStaticDetails?.activeSeason]);

    const resolvingTokensInSafe = async data => {
        let tokenData = await Promise.all(
            data.map(async token => {
                if (token.token) {
                    return {
                        ...token.token,
                        tokenAddress: token.tokenAddress,
                        fiatConversion: Number(token.fiatConversion),
                    };
                } else {
                    return {
                        name: nativeTokenName,
                        symbol: nativeTokenSymbol,
                        logoUri: NATIVE_ICON_URL,
                        decimals: 18,
                        tokenAddress: nativeTokenSymbol,
                        fiatConversion: Number(token.fiatConversion),
                    };
                }
            }),
        );
        let result = tokenData.reduce((agg, tokenItem) => {
            agg[tokenItem.tokenAddress] = tokenItem;
            return agg;
        }, {});
        setTokensInSafe(result);
    };

    return (
        <SquadContext.Provider
            value={{
                tokensInSafe,
                parentSafeAddress,
                parentSafeStaticDetails,
                tokensForRequest,
                isSeasonFixedUsd,
                budgetOptions,
            }}
        >
            {children}
        </SquadContext.Provider>
    );
};

export default SquadContext;
