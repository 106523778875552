import { Circle, PersonAddAlt1Outlined, Search } from "@mui/icons-material";
import {
    CircularProgress,
    InputAdornment,
    InputBase,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
    EmptyStateText,
    KeyboardShortcut,
    SearchContributorsStyled,
    SearchLoader,
    SearchResult,
} from "./styles/SearchContributors";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { useDebounce } from "src/helpers/utils/debounce";
import { useGetContributor } from "../../../queries/PeopleSection/api";
import { minifyAddress } from "src/helpers/utils/web3Utils";
import SafeContext from "src/contexts/SafeContext";
import theme from "styles/theme";
import Image from "next/image";
import StampAvatar from "src/components/StampAvatar";
import { ethers } from "ethers";
import { LoadingButton } from "@mui/lab";

const SearchContributors = ({
    walletAddress,
    setWalletAddress,
    walletNickName,
    setWalletNickName,
    errorMap,
    searchTerm,
    setSearchTerm,
    setOpenAddPeopleModal,
}) => {
    const [searchInFocus, setSearchInFocus] = useState(false);
    const [showResults, toggleResults] = useState(false);
    const searchBox = useRef(null);
    // const debouncedSearchTerm = useDebounce(searchTerm, 500);
    const { currentSafeAddress }: any = useContext(SafeContext);
    const { data, isFetching } = useGetContributor(currentSafeAddress, searchTerm, [], 1000000);

    useEffect(() => {
        const handleCommandF = (e: any) => {
            if (e.key === "f" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                searchBox?.current?.childNodes[1]?.focus();
            }
            if (e.key === "Escape") {
                searchBox?.current?.childNodes[1]?.blur();
            }
        };

        document.addEventListener("keydown", handleCommandF);

        return () => {
            document.removeEventListener("keydown", handleCommandF);
        };
    }, []);

    useEffect(() => {
        if (searchTerm.length >= 1) {
            toggleResults(true);
        } else {
            toggleResults(false);
        }
    }, [searchTerm]);

    const selectResult = contributor => {
        toggleResults(false);
        setWalletAddress(contributor.ens ? contributor.ens : contributor.address);
        setWalletNickName(contributor.nickName);
        setSearchTerm("");
    };

    const handleInputChange = e => {
        setWalletAddress("");
        setWalletNickName("");
        setSearchTerm(e.target.value);
        if (
            ethers.utils.isAddress(e.target.value.trim()) ||
            e.target.value.trim().endsWith(".eth")
        ) {
            let nickName = data?.contributors.filter(
                contributor => contributor.address == e.target.value,
            );
            setWalletAddress(e.target.value);
            setWalletNickName(nickName?.length > 0 ? nickName[0]?.nickName : "");
        }
    };

    return (
        <SearchContributorsStyled className="input-styled">
            <InputBase
                sx={{
                    background: "#F5F6F7",
                    "&.input-text": {
                        border: "none",
                    },
                    color: "#636B81",
                }}
                ref={searchBox}
                className="input-text"
                placeholder="Enter or search by Name, Address/ENS"
                inputProps={{ "aria-label": "Enter or search by Name, Address/ENS" }}
                startAdornment={
                    <InputAdornment position="start">
                        {walletAddress &&
                        (ethers.utils.isAddress(walletAddress) ||
                            walletAddress?.trim().endsWith(".eth")) ? (
                            <Circle sx={{ fontSize: "16px" }} />
                        ) : (
                            <Image width="16px" height="16px" src="/search-line.svg" />
                        )}
                    </InputAdornment>
                }
                endAdornment={
                    (ethers.utils.isAddress(walletAddress) ||
                        walletAddress?.trim().endsWith(".eth")) &&
                    !(data?.contributors || []).length &&
                    !isFetching ? (
                        <LoadingButton
                            variant="contained"
                            onClick={() => setOpenAddPeopleModal(true)}
                            sx={{
                                height: "24px",
                                width: "59px",
                                fontSize: "12px",
                                textTransform: "none",
                            }}
                            startIcon={<PersonAddAlt1Outlined style={{ fontSize: "16px" }} />}
                            // loading={pageLoading && selectedWallet == "MetaMask"}
                        >
                            Add
                        </LoadingButton>
                    ) : (
                        <KeyboardShortcut style={{ color: "#636B81" }}>
                            {searchInFocus ? "ESC" : "Cmd + F"}
                        </KeyboardShortcut>
                    )
                }
                onFocus={e => setSearchInFocus(true)}
                onBlur={e => setSearchInFocus(false)}
                value={walletNickName ? walletNickName : walletAddress ? walletAddress : searchTerm}
                onChange={e => handleInputChange(e)}
            />
            {walletNickName && (
                <Typography marginTop={"5px"} fontSize={"12px"} color={"#8B91A1"}>
                    Wallet Address: {walletAddress}
                </Typography>
            )}
            {errorMap.walletAddress && (
                <Typography marginTop={"5px"} fontSize={12} color={"#EE365A"}>
                    {errorMap.walletAddress}
                </Typography>
            )}
            <ClickAwayListener onClickAway={() => toggleResults(false)}>
                {showResults ? (
                    <Stack className="search-results" maxHeight={400} overflow="scroll">
                        {isFetching ? (
                            <SearchLoader>
                                <CircularProgress size={15} color="inherit" />
                            </SearchLoader>
                        ) : (data?.contributors || []).length > 0 ? (
                            (data?.contributors || []).map((contributor, c) => {
                                return (
                                    <SearchResult
                                        key={c}
                                        onClick={e => selectResult(contributor)}
                                        direction={"row"}
                                    >
                                        <StampAvatar
                                            containerStyles={{ display: "flex", marginRight: 5 }}
                                            seed={contributor.address}
                                            size={24}
                                        />

                                        <Typography className="nickname">
                                            {contributor.nickName || "Unnamed"}
                                        </Typography>
                                        <Typography className="address">
                                            {minifyAddress(contributor.address)}
                                        </Typography>
                                    </SearchResult>
                                );
                            })
                        ) : (
                            <SearchResult>
                                <EmptyStateText>No Record Found</EmptyStateText>
                            </SearchResult>
                        )}
                    </Stack>
                ) : (
                    <></>
                )}
            </ClickAwayListener>
        </SearchContributorsStyled>
    );
};

export default SearchContributors;
