import styled from "@emotion/styled";

export const SummaryWrapper = styled.div`
    display: flex;

    .flex {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        margin-left: 8px;
        padding: 0px 0px;

        span:nth-of-type(1) {
            font-weight: 600;
            font-size: 14px;
            line-height: 14px;
            color: #25274f;
        }
        span:nth-of-type(2) {
            font-weight: 400;
            font-size: 13px;
            color: ${({ theme }: any) => theme.palette.title.light};
        }
    }

    .identicon {
        border-radius: 50%;
    }
    .circle {
        height: 33px;
        width: 33px;
        padding: 3px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px;
        img {
            max-width: 26px;
        }
    }
    #amount {
        font-weight: 500;
        font-size: 13px;
        line-height: 13px;
        color: #8b91a1;
        margin-top: 6px;
    }
`;
