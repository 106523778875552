import React, { useState, useContext, useEffect } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import { Typography } from "@mui/material";
import { Box, Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useSnackbar } from "notistack";
import { LoadingButton } from "@mui/lab";
import SafeContext from "src/contexts/SafeContext";
import TokenSelectCollapse from "./MassPayout/PeopleTokenTable/TokenSelectCollapse";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import { ethers, utils } from "ethers";
import { getAmountInWei } from "src/helpers/tx-helper";
import { nativeTokenSymbol } from "src/queries/constants";
function SetTokenModal({ setOpen, open, count, tag, maxValueErr, setMaxValueErr }) {
    const { enqueueSnackbar } = useSnackbar();
    const { tagInfocus, payoutQueueByTag, setPayoutQueueByTag }: any =
        useContext(MassPayoutContext);
    const { tokensInSafe, safeTokenBalances, rawSafeTokenData }: any = useContext(SafeContext);
    const [noOfTokens, setNoOfTokens] = useState([1]);
    const [selectedTokens, setSelectedTokens] = useState([]);
    const [fiatConversion, setFiatConversion] = useState(0);
    const handleTokenRow = () => {
        let tokens = Object.values(tokensInSafe) ?? [];
        if (tokens.length == noOfTokens.length) {
            enqueueSnackbar(`Cannot add more than ${noOfTokens.length} entries`, {
                variant: "error",
            });
            return;
        } else {
            setNoOfTokens([...noOfTokens, Math.random()]);
        }
    };
    const handleRemoveToken = () => {
        if (noOfTokens.length < 1) {
            return;
        } else {
            setNoOfTokens(new Array(noOfTokens.length - 1).fill(0));
        }
    };
    const handleSubmit = (values, index, isTokenSelected) => {
        if (isTokenSelected) {
            let updatedTokens = [
                ...selectedTokens.slice(0, index),
                ...selectedTokens.slice(index + 1, selectedTokens.length),
                values,
            ];
            setSelectedTokens(updatedTokens);
        } else {
            setSelectedTokens([...selectedTokens, values]);
        }

        setNoOfTokens([]);
    };
    const deleteModalToken = index => {
        let updatedTokens = [
            ...selectedTokens.slice(0, index),
            ...selectedTokens.slice(index + 1, selectedTokens.length),
        ];
        setSelectedTokens(updatedTokens);
    };
    const handleAddToken = () => {
        let updatedPayoutQueue = {
            ...payoutQueueByTag,
            [tagInfocus]: payoutQueueByTag[tagInfocus].map(item => {
                return {
                    ...item,
                    selectedTokens: selectedTokens,
                };
            }),
        };
        setPayoutQueueByTag(updatedPayoutQueue);
        setOpen(false);
    };

    const handleAmountChange = (
        e,
        setFieldError,
        values,
        setValues,
        setFieldTouched,
        fiatConversion,
    ) => {
        let error = false;
        if (e.target.value) {
            const value =
                values.token.tokenAddress === nativeTokenSymbol
                    ? utils.formatUnits(utils.parseEther(`${e.target.value}`), "wei")
                    : getAmountInWei(`${e.target.value}`, 18).toString();
            rawSafeTokenData.map(item => {
                if (
                    item.token === null &&
                    values.token.tokenAddress === nativeTokenSymbol &&
                    ethers.BigNumber.from(value).gt(ethers.BigNumber.from(item.balance))
                ) {
                    setFieldError("amount", "Reached Max");
                    setFieldTouched("amount", true);
                    setMaxValueErr(true);
                    error = true;
                    return;
                }
                if (
                    item.token != null &&
                    item.token.symbol == values.token.symbol &&
                    ethers.BigNumber.from(value).gt(ethers.BigNumber.from(item.balance))
                ) {
                    setFieldError("amount", "Reached Max");
                    setFieldTouched("amount", true);
                    setMaxValueErr(true);
                    error = true;
                    return;
                }
                if (error) {
                    console.error("Entered", error);
                    setMaxValueErr(true);
                } else {
                    setValues({
                        ...values,
                        amount: e.target.value,
                        fiatValue: (values.amount * fiatConversion).toFixed(4),
                    });
                    setMaxValueErr(false);
                }
            });
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={"lg"}>
            <DialogTitle>Set Token</DialogTitle>
            <Box px="30px" pb="20px">
                <Typography fontWeight={400} color="title.main" fontSize="16px">
                    You can set the tokens for <strong>{count}</strong> selected people of{" "}
                    <strong>{tag}</strong> Tag.
                </Typography>
                <Box mt="20px">
                    {selectedTokens?.map((tokens, index) => {
                        return (
                            <TokenSelectCollapse
                                key={Math.random()}
                                handleRemoveToken={handleRemoveToken}
                                isDelete={false}
                                isTokenSelected={true}
                                data={tokens}
                                index={index}
                                isFromModal={true}
                                handleSubmit={handleSubmit}
                                deleteModalToken={deleteModalToken}
                                amountChange={handleAmountChange}
                                fiatConversion={fiatConversion}
                                setFiatConversion={setFiatConversion}
                            />
                        );
                    })}
                    {noOfTokens.map((_, index) => {
                        return (
                            <TokenSelectCollapse
                                key={index}
                                handleRemoveToken={handleRemoveToken}
                                isDelete={index !== 0}
                                isTokenSelected={false}
                                index={index}
                                isFromModal={true}
                                handleSubmit={handleSubmit}
                                deleteModalToken={deleteModalToken}
                                amountChange={handleAmountChange}
                                fiatConversion={fiatConversion}
                                setFiatConversion={setFiatConversion}
                            />
                        );
                    })}
                </Box>
                <Button
                    variant="text"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleTokenRow}
                    sx={{ marginTop: "20px" }}
                    disabled={selectedTokens.length === Object.values(tokensInSafe).length}
                >
                    Add more
                </Button>
                {/* {maxValueErr && <Typography color="red">value Exceeding max value</Typography>} */}
                <Box display="flex" justifyContent="flex-end" mt="20px" alignItems="center">
                    <Typography fontSize="13px" color="title.main" width="70%">
                        Note: Any token values set before will be overridden
                    </Typography>
                    <Box display="flex" alignItems="center">
                        <LoadingButton
                            color="inherit"
                            variant="contained"
                            onClick={() => setOpen(!open)}
                            sx={{ mr: "20px" }}
                        >
                            Cancel
                        </LoadingButton>
                        <LoadingButton
                            variant="contained"
                            onClick={handleAddToken}
                            // disabled={maxValueErr}
                        >
                            Confirm
                        </LoadingButton>
                    </Box>
                </Box>
            </Box>
        </Dialog>
    );
}

export default SetTokenModal;
