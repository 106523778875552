import { networkNames } from "src/helpers/utils/networks";

// rinkeby
const RINKEBY_PROXY_FACTORY_ADDRESS = "0x76E2cFc1F5Fa8F6a5b3fC4c8F4788F0116861F9B";
const RINKEBY_GNOSIS_SAFE_ADDRESS = "0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F";
const RINKEBY_DAI_ADDRESS = "0xc3dbf84Abb494ce5199D5d4D815b10EC29529ff8";
const RINKEBY_USDC_ADDRESS = "0x472d88e5246d9bF2AB925615fc580336430679Ae";
const RINKEBY_USDT_ADDRESS = "0x897886a7d886349723Bf485502d8513CeC3350Ba";
const RINKEBY_WETH_ADDRESS = "0xc778417e063141139fce010982780140aa0cd5ab";
const RINKEBY_MULTISEND_ADDRESS = "0x8D29bE29923b68abfDD21e541b9374737B49cdAD";
const RINKEBY_UNISWAP_ROUTER_ADDRESS = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
const RINKEBY_ALLOWANCE_MODULE_ADDRESS = "0xCFbFaC74C26F8647cBDb8c5caf80BB5b32E43134";
const RINKEBY_AUTOMATION_MODULE_ADDRESS = "0x86FA93f8504D490091572CD9cE2Fa5D5d3E4A332";

// mainnet
const MAINNET_PROXY_FACTORY_ADDRESS = "0x76E2cFc1F5Fa8F6a5b3fC4c8F4788F0116861F9B";
const MAINNET_GNOSIS_SAFE_ADDRESS = "0x34CfAC646f301356fAa8B21e94227e3583Fe3F5F";
const MAINNET_USDC_ADDRESS = "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48";
const MAINNET_USDT_ADDRESS = "0xdac17f958d2ee523a2206206994597c13d831ec7";
const MAINNET_DAI_ADDRESS = "0x6b175474e89094c44da98b954eedeac495271d0f";
const MAINNET_WETH_ADDRESS = "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2";
const MAINNET_MULTISEND_ADDRESS = "0x8D29bE29923b68abfDD21e541b9374737B49cdAD";
const MAINNET_UNISWAP_ROUTER_ADDRESS = "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D";
const MAINNET_ALLOWANCE_MODULE_ADDRESS = "0xCFbFaC74C26F8647cBDb8c5caf80BB5b32E43134";
const MAINNET_AUTOMATION_MODULE_ADDRESS = "0xd2cc03400fe558e5a33da42eff2bee755b380b8d";

//polygon
const POLYGON_PROXY_FACTORY_ADDRESS = "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2";
const POLYGON_MULTISEND_ADDRESS = "0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761";
const POLYGON_GNOSIS_SAFE_ADDRESS = "0x3E5c63644E683549055b9Be8653de26E0B4CD36E";
// const POLYGON_AUTOMATION_MODULE_ADDRESS =
//   '0x1e5bE0d0FF7AAdde56523188D3C0684352516b0D';

// goerli
// const GOERLI_PROXY_FACTORY_ADDRESS = "0xa6b71e26c5e0845f74c812102ca7114b6a896ab2";
// const GOERLI_GNOSIS_SAFE_ADDRESS = "0xf12F6471c26F1dFa5Ca456D4a00662912B8F89e2";
// const GOERLI_MULTISEND_ADDRESS = "0x8D29bE29923b68abfDD21e541b9374737B49cdAD";

const GOERLI_PROXY_FACTORY_ADDRESS = "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2";
// safe version 1.3.0
const GOERLI_GNOSIS_SAFE_ADDRESS = "0xd9Db270c1B5E3Bd161E8c8503c55cEABeE709552";
const GOERLI_MULTISEND_ADDRESS = "0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761";

//optimism
const OPTIMISM_PROXY_FACTORY_ADDRESS = "0xC22834581EbC8527d974F8a1c97E1bEA4EF910BC";
const OPTIMISM_GNOSIS_SAFE_ADDRESS = "0xfb1bffC9d739B8D520DaF37dF666da4C687191EA";
const OPTIMISM_MULTISEND_ADDRESS = "0x998739BFdAAdde7C933B942a68053933098f9EDa";

//optimism
const ARBITRUM_PROXY_FACTORY_ADDRESS = "0xa6B71E26C5e0845f74c812102Ca7114b6a896AB2";
const ARBITRUM_GNOSIS_SAFE_ADDRESS = "0x3E5c63644E683549055b9Be8653de26E0B4CD36E";
const ARBITRUM_MULTISEND_ADDRESS = "0xA238CBeb142c10Ef7Ad8442C6D1f9E89e07e7761";

const addresses = {
    [networkNames.MAINNET]: {
        PROXY_FACTORY_ADDRESS: MAINNET_PROXY_FACTORY_ADDRESS,
        GNOSIS_SAFE_ADDRESS: MAINNET_GNOSIS_SAFE_ADDRESS,
        DAI_ADDRESS: MAINNET_DAI_ADDRESS,
        USDC_ADDRESS: MAINNET_USDC_ADDRESS,
        USDT_ADDRESS: MAINNET_USDT_ADDRESS,
        WETH_ADDRESS: MAINNET_WETH_ADDRESS,
        MULTISEND_ADDRESS: MAINNET_MULTISEND_ADDRESS,
        UNISWAP_ROUTER_ADDRESS: MAINNET_UNISWAP_ROUTER_ADDRESS,
        ALLOWANCE_MODULE_ADDRESS: MAINNET_ALLOWANCE_MODULE_ADDRESS,
        AUTOMATION_MODULE_ADDRESS: MAINNET_AUTOMATION_MODULE_ADDRESS,
        ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
        OTHER_ZERO_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    },
    [networkNames.RINKEBY]: {
        PROXY_FACTORY_ADDRESS: RINKEBY_PROXY_FACTORY_ADDRESS,
        GNOSIS_SAFE_ADDRESS: RINKEBY_GNOSIS_SAFE_ADDRESS,
        DAI_ADDRESS: RINKEBY_DAI_ADDRESS,
        USDC_ADDRESS: RINKEBY_USDC_ADDRESS,
        USDT_ADDRESS: RINKEBY_USDT_ADDRESS,
        WETH_ADDRESS: RINKEBY_WETH_ADDRESS,
        MULTISEND_ADDRESS: RINKEBY_MULTISEND_ADDRESS,
        UNISWAP_ROUTER_ADDRESS: RINKEBY_UNISWAP_ROUTER_ADDRESS,
        ALLOWANCE_MODULE_ADDRESS: RINKEBY_ALLOWANCE_MODULE_ADDRESS,
        AUTOMATION_MODULE_ADDRESS: RINKEBY_AUTOMATION_MODULE_ADDRESS,
        ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
        OTHER_ZERO_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
    },
    [networkNames.POLYGON]: {
        PROXY_FACTORY_ADDRESS: POLYGON_PROXY_FACTORY_ADDRESS,
        GNOSIS_SAFE_ADDRESS: POLYGON_GNOSIS_SAFE_ADDRESS,
        MULTISEND_ADDRESS: POLYGON_MULTISEND_ADDRESS,
        ALLOWANCE_MODULE_ADDRESS: "0x1Fb403834C911eB98d56E74F5182b0d64C3b3b4D",
        ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
        OTHER_ZERO_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        // AUTOMATION_MODULE_ADDRESS: POLYGON_AUTOMATION_MODULE_ADDRESS,

        /* not used now
      DAI_ADDRESS: RINKEBY_DAI_ADDRESS,
      USDC_ADDRESS: RINKEBY_USDC_ADDRESS,
      USDT_ADDRESS: RINKEBY_USDT_ADDRESS,
      WETH_ADDRESS: RINKEBY_WETH_ADDRESS,
      UNISWAP_ROUTER_ADDRESS: RINKEBY_UNISWAP_ROUTER_ADDRESS 
    */
    },

    [networkNames.GOERLI]: {
        PROXY_FACTORY_ADDRESS: GOERLI_PROXY_FACTORY_ADDRESS,
        GNOSIS_SAFE_ADDRESS: GOERLI_GNOSIS_SAFE_ADDRESS,
        MULTISEND_ADDRESS: GOERLI_MULTISEND_ADDRESS,
        ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
        OTHER_ZERO_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        ALLOWANCE_MODULE_ADDRESS: "0xCFbFaC74C26F8647cBDb8c5caf80BB5b32E43134",
    },

    [networkNames.OPTIMISM]: {
        PROXY_FACTORY_ADDRESS: OPTIMISM_PROXY_FACTORY_ADDRESS,
        GNOSIS_SAFE_ADDRESS: OPTIMISM_GNOSIS_SAFE_ADDRESS,
        MULTISEND_ADDRESS: OPTIMISM_MULTISEND_ADDRESS,
        ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
        OTHER_ZERO_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        ALLOWANCE_MODULE_ADDRESS: "0x964275bba107088968AC71584D168dA1D5F10D1a",
    },

    [networkNames.ARBITRUM]: {
        PROXY_FACTORY_ADDRESS: ARBITRUM_PROXY_FACTORY_ADDRESS,
        GNOSIS_SAFE_ADDRESS: ARBITRUM_GNOSIS_SAFE_ADDRESS,
        MULTISEND_ADDRESS: ARBITRUM_MULTISEND_ADDRESS,
        ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
        OTHER_ZERO_ADDRESS: "0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE",
        ALLOWANCE_MODULE_ADDRESS: "0xb55e84cB8fb33E43A9d84eDeb324a4080341d064",
    },
};
const requiredNetworkName = process.env.NEXT_PUBLIC_NETWORK_NAME;
export default addresses[requiredNetworkName];
