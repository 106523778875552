import {
    DeleteOutlined,
    InfoOutlined,
    ModeEditOutlineOutlined,
    MoreVert,
} from "@mui/icons-material";
import { IconButton, Tooltip, Typography } from "@mui/material";
import React, { useContext } from "react";
import { ActionMenuStyled, InfoWrapper, QueueItemStyled } from "./styles/QueueItem";
import { formatAmount, minifyAddress } from "src/helpers/utils/web3Utils";
import SafeContext from "src/contexts/SafeContext";
import StampAvatar from "src/components/StampAvatar";
import { formatTokenDecimals } from "src/helpers/utils/common";
import StyledTooltip from "src/components/StyledTooltip";

const QueueItem = ({
    anchorEl,
    open,
    index,
    item,
    setAnchorEl,
    handleItemEdit,
    handleItemDelete,
    setPopOpenIndex,
}) => {
    const { tokensInSafe, safeTokenBalancesByTokenName }: any = useContext(SafeContext);

    const handleMoreClick = e => {
        setAnchorEl(e.currentTarget);
        setPopOpenIndex(index);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setPopOpenIndex(null);
    };

    const toolTipBox = () => {
        return (
            <InfoWrapper>
                <div className="frame1">
                    <div className="flex">
                        <div className="label">Tag</div>
                        <div className="value">{item?.tag || "--"}</div>
                    </div>
                    <div className="flex">
                        <div className="label">Category</div>
                        <div className="value">{item?.payoutCategory || "--"}</div>
                    </div>
                </div>
                <div>
                    <div className="label">Comment</div>
                    <div className="value">{item?.payoutComment || "--"}</div>
                </div>
            </InfoWrapper>
        );
    };

    return (
        <QueueItemStyled direction={"row"}>
            <StampAvatar containerStyles={{ marginRight: 0 }} seed={item.walletAddress} size={24} />
            <div className="detail-wrapper">
                <Typography className="nick-name">
                    {item.walletNickName || "Unnamed Recipient"}
                    <StyledTooltip title={toolTipBox()}>
                        <InfoOutlined />
                    </StyledTooltip>
                </Typography>
                <Typography className="wallet-address">
                    {item.walletENS ? item.walletENS : minifyAddress(item.walletAddress)}
                </Typography>
            </div>

            <div className="value-wrapper">
                <Typography className="usd-value">
                    {item.tokenValueInUSD < 0.01 &&
                    safeTokenBalancesByTokenName[item.tokenSymbol] &&
                    safeTokenBalancesByTokenName[item.tokenSymbol].fiatConversion != 0 // Non strict comparision since conversion value is always string
                        ? "<"
                        : ""}{" "}
                    ${formatAmount(item.tokenValueInUSD, 2, 2)}
                </Typography>
                <Typography className="token-value">
                    {formatTokenDecimals(item.tokenValue, 4)}{" "}
                    {tokensInSafe[item.selectedToken].symbol}
                </Typography>
            </div>
            <div className="action-wrapper">
                <IconButton
                    className="more-button"
                    aria-label="more option"
                    size="small"
                    onClick={handleMoreClick}
                    id={index}
                >
                    <MoreVert />
                </IconButton>
                <ActionMenuStyled
                    open={open}
                    anchorEl={anchorEl}
                    className="action-menu"
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                >
                    <div className="action" onClick={handleItemEdit}>
                        <ModeEditOutlineOutlined />
                        <span>Edit</span>
                    </div>
                    <div className="action" onClick={handleItemDelete}>
                        <DeleteOutlined />
                        <span>Delete</span>
                    </div>
                </ActionMenuStyled>
            </div>
        </QueueItemStyled>
    );
};

export default QueueItem;
