import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import { IconButton, TextField, Typography } from "@mui/material";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import { disbursmentSchema } from "src/helpers/form/disbursmentSchema";
import { StyledAutoComplete, StyledInputField, StyledAutoCompleteWrapper } from "./styles";
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import SafeContext from "src/contexts/SafeContext";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import { useSnackbar } from "notistack";
import { formatAmount } from "src/helpers/utils/web3Utils";
import Decimal from "decimal.js-light";
import { formatTokenDecimals } from "src/helpers/utils/common";

function TokenSelectCollapse({
    handleRemoveToken,
    isDelete,
    address = "",
    isTokenSelected,
    data = {},
    index,
    handleSubmit,
    isFromModal = false,
    deleteModalToken,
    amountChange,
    edit,
    setEdit,
    uid,
    setDisableReview,
}: // fiatConversion,
// setFiatConversion,
any) {
    const { tokensInSafe, getSafeBalance }: any = useContext(SafeContext);
    const {
        tagInfocus,
        payoutQueueByTag,
        setPayoutQueueByTag,
        selectedData,
        setSelectedData,
    }: any = useContext(MassPayoutContext);
    const { enqueueSnackbar } = useSnackbar();
    // const [edit.includes(uid), setedit.includes(uid)] = useState(false);
    const [fiatConversion, setFiatConversion] = useState();
    const [selectedURI, setSelectedURI] = useState();
    const [fiatValue, setFiatValue] = useState();
    const handleToken = (_, value, setValues, values) => {
        if (value.fiatConversion) {
            setFiatConversion(value.fiatConversion);
        }
        setSelectedURI(value.logoUri);
        setSelectedData({
            token: value,
        });
        setValues({
            ...values,
            token: value,
            fiatValue: "",
            amount: "",
        });
    };
    const handleEdit = () => {
        // console.log("entered", { edit, uid });
        setEdit([...edit, uid]);
        setDisableReview(true);
    };
    const handleCloseEdit = () => {
        setEdit(edit.filter(id => id != uid));
    };
    const handleDeleteToken = () => {
        const newQueue = payoutQueueByTag[tagInfocus].map(people => {
            if (people.address === address) {
                return {
                    ...people,
                    selectedTokens: [
                        ...people.selectedTokens.slice(0, index),
                        ...people.selectedTokens.slice(index + 1, people.selectedTokens.length),
                    ],
                };
            } else {
                return people;
            }
        });

        setPayoutQueueByTag({
            ...payoutQueueByTag,
            [tagInfocus]: [...newQueue],
        });
    };
    // useEffect(() => {
    //     // console.log("edit", edit);
    // }, [edit]);
    return (
        // <Box my={isTokenSelected && !edit.includes(uid) ? "0px" : "20px"}>
        <Box sx={{ my: "12px" }} key={index}>
            <Formik
                initialValues={{
                    token: isTokenSelected
                        ? data.token
                        : {
                              tokenAddress: "",
                              name: "",
                          },
                    amount: isTokenSelected ? data.amount : "",
                    fiatValue: isTokenSelected ? data.fiatValue : "",
                }}
                validationSchema={disbursmentSchema(getSafeBalance())}
                onSubmit={values => {
                    isFromModal
                        ? handleSubmit(values, index, isTokenSelected)
                        : handleSubmit(values, isTokenSelected, index);
                }}
            >
                {({
                    values,
                    handleChange,
                    setValues,
                    errors,
                    touched,
                    handleSubmit,
                    setFieldError,
                    setFieldTouched,
                }: any) => {
                    return (
                        // <Form>
                        <Box
                            display="flex"
                            alignItems="flex-start"
                            borderBottom={
                                isTokenSelected && !edit.includes(uid) && "1px solid #EAEAEA"
                            }
                        >
                            {isTokenSelected && !edit.includes(uid) && (
                                <Box display="flex" alignItems="flex-start" width="30%">
                                    <Box
                                        component="img"
                                        src={data.token.logoUri}
                                        width="20px"
                                        borderRadius="50%"
                                        mr="10px"
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = UndiscoveredCoin.src;
                                        }}
                                    />
                                    <Typography color="title.main" fontSize="14px" fontWeight={500}>
                                        {data.token.name}
                                    </Typography>
                                </Box>
                            )}
                            {(!isTokenSelected || edit.includes(uid)) && (
                                <StyledAutoCompleteWrapper sx={{ width: "30%" }}>
                                    <StyledAutoComplete
                                        disablePortal
                                        id="combo-box-demo"
                                        filterOptions={(option: any, state: any) => {
                                            if (state.inputValue) {
                                                return option.filter(
                                                    ({ name, symbol }) =>
                                                        symbol
                                                            .toLowerCase()
                                                            .indexOf(
                                                                state.inputValue.toLowerCase(),
                                                            ) > -1 ||
                                                        name
                                                            .toLowerCase()
                                                            .indexOf(
                                                                state.inputValue.toLowerCase(),
                                                            ) > -1,
                                                );
                                            } else {
                                                return option;
                                            }
                                        }}
                                        isOptionEqualToValue={(option: any, value: any) =>
                                            option.name === value.name
                                        }
                                        onChange={(event: any, value: any) => {
                                            // handleToken(event, value, setValues, values);
                                            if (value.fiatConversion) {
                                                setFiatConversion(value.fiatConversion);
                                            }
                                            setSelectedURI(value.logoUri);
                                            setSelectedData({
                                                token: value,
                                            });
                                            setValues({
                                                ...values,
                                                token: value,
                                                fiatValue: "",
                                                amount: "",
                                            });
                                        }}
                                        value={values.token}
                                        disableClearable={true}
                                        options={tokensInSafe ? Object.values(tokensInSafe) : []}
                                        getOptionLabel={(option: any) => option.name}
                                        autoComplete={false}
                                        sx={{ width: "100%" }}
                                        renderOption={(props, option: any) => (
                                            <Box
                                                display="flex"
                                                // p="14px"
                                                alignItems="center"
                                                {...props}
                                                component="li"
                                            >
                                                <Box
                                                    component="img"
                                                    src={option.logoUri}
                                                    width="20px"
                                                    minWidth="20px"
                                                    maxWidth="20px"
                                                    flex={"20px"}
                                                    onError={({ currentTarget }) => {
                                                        currentTarget.onerror = null; // prevents looping
                                                        currentTarget.src = UndiscoveredCoin.src;
                                                    }}
                                                />
                                                <Typography
                                                    fontSize="14px"
                                                    color="title.main"
                                                    fontWeight={500}
                                                    ml="6px"
                                                >
                                                    {option.name}
                                                </Typography>
                                                <Typography
                                                    fontSize="14px"
                                                    color="#636B81"
                                                    fontWeight={400}
                                                    ml="12px"
                                                >
                                                    {option.symbol}
                                                </Typography>
                                            </Box>
                                        )}
                                        renderInput={params => {
                                            return (
                                                <TextField
                                                    {...params}
                                                    name="token"
                                                    placeholder="Select Token"
                                                    error={
                                                        touched?.token?.tokenAddress &&
                                                        Boolean(errors?.token?.tokenAddress)
                                                    }
                                                    helperText={
                                                        touched?.token?.tokenAddress &&
                                                        errors?.token?.tokenAddress
                                                    }
                                                    inputProps={{
                                                        ...params.inputProps,
                                                        autoComplete: "off",

                                                        // disable autocomplete and autofill
                                                    }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: (
                                                            <>
                                                                {Object.keys(selectedData.token)
                                                                    .length > 0 ? (
                                                                    <Box
                                                                        component="img"
                                                                        src={
                                                                            isTokenSelected
                                                                                ? values.token
                                                                                      .logoUri
                                                                                : selectedURI
                                                                        }
                                                                        {...params}
                                                                        width="20px"
                                                                        borderRadius="50%"
                                                                        mr="10px"
                                                                        bgcolor="#C4C4C4"
                                                                        onError={({
                                                                            currentTarget,
                                                                        }) => {
                                                                            currentTarget.onerror =
                                                                                null; // prevents looping
                                                                            currentTarget.src =
                                                                                UndiscoveredCoin.src;
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <Box
                                                                        component="div"
                                                                        bgcolor="#C4C4C4"
                                                                        {...params}
                                                                        width="20px"
                                                                        borderRadius="50%"
                                                                        mr="10px"
                                                                    />
                                                                )}
                                                                {params.InputProps.startAdornment}
                                                            </>
                                                        ),
                                                    }}
                                                />
                                            );
                                        }}
                                    />
                                </StyledAutoCompleteWrapper>
                            )}

                            {isTokenSelected && !edit.includes(uid) && (
                                <Box display="flex" alignItems="center" width={"30%"} ml={"20px"}>
                                    <Typography color="title.main" fontSize="14px">
                                        {formatTokenDecimals(new Decimal(data.amount || 0), 6)}
                                    </Typography>
                                    <Typography color="#636B81" fontSize="14px" ml="5px">
                                        {data.token.symbol}
                                    </Typography>
                                </Box>
                            )}
                            {isTokenSelected && !edit.includes(uid) && (
                                <Box display="flex" alignItems="center" width={"30%"} ml={"20px"}>
                                    <Typography color="title.main" fontSize="14px">
                                        {data.token.fiatConversion && data.token.fiatConversion > 0
                                            ? formatTokenDecimals(new Decimal(values.fiatValue), 2)
                                            : "--"}
                                    </Typography>
                                    <Typography color="#636B81" fontSize="14px" ml="5px">
                                        {data.token.fiatConversion && data.token.fiatConversion > 0
                                            ? "USD"
                                            : ""}
                                    </Typography>
                                </Box>
                            )}
                            {(!isTokenSelected || edit.includes(uid)) && (
                                <Box width={"30%"} ml={"20px"}>
                                    <StyledInputField
                                        variant="outlined"
                                        placeholder="Enter Amount"
                                        type="number"
                                        inputProps={{
                                            step: "0.1",
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                <Box>
                                                    <Typography>
                                                        {values.token.symbol || ""}
                                                    </Typography>
                                                </Box>
                                            ),
                                        }}
                                        value={values.amount}
                                        required={false}
                                        name="amount"
                                        disabled={!values.token.tokenAddress}
                                        onChange={e => {
                                            let fiatConv = values.token.fiatConversion;
                                            if (isFromModal) {
                                                amountChange(
                                                    e,
                                                    setFieldError,
                                                    values,
                                                    setValues,
                                                    setFieldTouched,
                                                    fiatConv,
                                                );
                                            } else {
                                                if (
                                                    e.target.value &&
                                                    e.target.value.toString().split(".")[1]
                                                        ?.length > values.token.decimals
                                                ) {
                                                    enqueueSnackbar(
                                                        `Should be 1 to ${values.token.decimals} decimals`,
                                                        {
                                                            variant: "warning",
                                                        },
                                                    );
                                                    return;
                                                }
                                                fiatConv !== undefined && fiatConv > 0
                                                    ? setValues({
                                                          ...values,
                                                          amount: e.target.value,
                                                          fiatValue: new Decimal(
                                                              e.target.value || 0,
                                                          )
                                                              .mul(fiatConv)
                                                              .toFixed(2),
                                                      })
                                                    : setValues({
                                                          ...values,
                                                          amount: e.target.value,
                                                          fiatValue: 0,
                                                      });
                                            }
                                        }}
                                        fullWidth
                                        error={touched.amount && Boolean(errors.amount)}
                                        helperText={touched.amount && errors.amount}
                                    />
                                </Box>
                            )}

                            {(!isTokenSelected || edit.includes(uid)) && (
                                <Box width={"30%"} ml={"20px"}>
                                    <StyledInputField
                                        variant="outlined"
                                        placeholder="0.00"
                                        type="number"
                                        inputProps={{
                                            step: "1",
                                        }}
                                        disabled={
                                            !values.token.tokenAddress ||
                                            values.token.fiatConversion == 0
                                        }
                                        onChange={e => {
                                            let fiatConv = values.token.fiatConversion;
                                            if (
                                                e.target.value &&
                                                e.target.value.toString().split(".")[1]?.length > 2
                                            ) {
                                                enqueueSnackbar(`Should not exceed 2 decimals`, {
                                                    variant: "warning",
                                                });
                                                return;
                                            }
                                            setValues({
                                                ...values,
                                                amount: new Decimal(e.target.value || 0).div(
                                                    fiatConv,
                                                ),
                                                fiatValue: e.target.value,
                                            });
                                            // handleChange(e);
                                        }}
                                        value={values.fiatValue}
                                        required={false}
                                        InputProps={{
                                            endAdornment: (
                                                <Box>
                                                    <Typography>USD</Typography>
                                                </Box>
                                            ),
                                        }}
                                        error={touched.fiatValue && Boolean(errors.fiatValue)}
                                        helperText={touched.fiatValue && errors.fiatValue}
                                        name="fiatValue"
                                        fullWidth
                                    />
                                </Box>
                            )}

                            <Box flex="10%" />

                            {!isTokenSelected || edit.includes(uid) ? (
                                <IconButton
                                    sx={{ cursor: "pointer", mr: "20px" }}
                                    onClick={e => {
                                        handleSubmit();
                                        setDisableReview(false);
                                        // e.stopPropagation();
                                    }}
                                >
                                    <DoneIcon sx={{ color: "#6BBC93" }} />
                                </IconButton>
                            ) : (
                                <IconButton
                                    sx={{ cursor: "pointer", mr: "20px" }}
                                    onClick={handleEdit}
                                >
                                    <CreateOutlinedIcon sx={{ color: "#25274F" }} />
                                </IconButton>
                            )}

                            {!isTokenSelected || edit.includes(uid) ? (
                                <IconButton
                                    onClick={isDelete ? handleRemoveToken : handleCloseEdit}
                                >
                                    <CloseIcon sx={{ color: "#EC5952" }} />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={() => {
                                        isFromModal ? deleteModalToken(index) : handleDeleteToken();
                                    }}
                                >
                                    <DeleteOutlinedIcon sx={{ color: "#EC5952" }} />
                                </IconButton>
                            )}
                        </Box>
                        // </Form>
                    );
                }}
            </Formik>
        </Box>
    );
}

export default TokenSelectCollapse;
