export const TOKEN_PRICE_PREFIX = "TokenFiatPrice";
export const TRACKED_TOKENS_PREFIX = "Tracked_tokens_for";
export const TRACKED_TOKENS_INDEX_PREFIX = "Tracked_tokens_index_for";
export const VERIFY_WALLET_OTP = "VerifyWalletOTP";

// CoinGecko Specific
export const CG_TOKEN_PRICE_PREFIX = "CGTokenFiatPrice";
export const CG_TRACKED_TOKENS_PREFIX = "CgTrackedTokens";
export const CG_TRACKED_TOKENS_INDEX_PREFIX = "CgTrackedTokensIndex";

// tokensMetaData
export const TOKEN_METADATA = "TokenMetaData_Address_NetworkId";
