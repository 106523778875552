import { createContext, useContext, useEffect, useState } from "react";
import {
    NATIVE_ICON_URL,
    nativeTokenName,
    nativeTokenSymbol,
    gnosisURL,
    ZERO_ADDRESS,
    FETCH_TOKEN_CONVERSION_ENDPOINT,
} from "src/queries/constants";
import { useGetCurrentSafeDetails, useGetTokensAndPriceBySafe } from "src/queries/Safe/api";
import { useGetGnosisSafeDetails } from "src/queries/onboard/api";
import { getAmountFromWei } from "src/helpers/tx-helpers";

import { TokenConversionFromGnosis } from "src/queries/ContributorRequest/api";
import { getTokenContractAddress } from "mapping/tokenAddress";
import { networkId } from "src/helpers/utils/networks";
import { weiToString } from "src/helpers/bignumberUtils";
import Decimal from "decimal.js-light";

const ChildSafeContext = createContext({});

export const ChildSafeContextProvider = ({ children }) => {
    const [currentSafeAddress, setCurrentSafeAddress] = useState("");
    const [tokensInSafe, setTokensInSafe] = useState({
        [nativeTokenSymbol]: {
            name: nativeTokenName,
            symbol: nativeTokenSymbol,
            logoUri: NATIVE_ICON_URL,
            decimals: 18,
            tokenAddress: nativeTokenSymbol,
            fiatConversion: 1,
        },
    });
    const [safeTokenBalancesByTokenName, setSafeTokenBalancesByTokenName] = useState({});
    const [safeTokenBalances, setSafeTokenBalances] = useState({});
    const [safeBalanceInUSD, setSafeBalanceInUSD] = useState({ [nativeTokenSymbol]: 0 }); //added default value to avoid error in reduce function
    const [safeStaticDetails, setSafeStaticDetails] = useState<any>({
        owners: [],
        safeName: "",
        isMetaTxEnabled: false,
        whitelistedTokens: [],
        categories: [],
    });
    const [rawSafeTokenData, setRawSafeTokenData] = useState([]);
    const [safeTokenBalancesInDecimal, setSafeTokenBalancesInDecimal] = useState({});
    const [safeDetailsFromGnosis, setSafeDetailsFromGnosis] = useState({});
    const { data: gnosisSafeDetailsApi, isFetching: loadingGnosisDetails } =
        useGetGnosisSafeDetails(currentSafeAddress, {
            enabled: Boolean(currentSafeAddress),
        });
    const [loadingChildSafeContextVariable, setLoadingChildSafeContextVariable] = useState(false);

    useEffect(() => {
        if (gnosisSafeDetailsApi) {
            setSafeDetailsFromGnosis(gnosisSafeDetailsApi);
        }
    }, [gnosisSafeDetailsApi]);

    const safeDetails: any = useGetCurrentSafeDetails(currentSafeAddress);

    useEffect(() => {
        if (safeDetails.isError) {
            return;
        }
        setSafeStaticDetails(safeDetails.data);
    }, [safeDetails]);

    const { data, isSuccess, isError, isLoading, isFetching } = useGetTokensAndPriceBySafe(
        currentSafeAddress,
        { enabled: !!currentSafeAddress },
    );

    useEffect(() => {
        if (isError) {
            return;
        }
        async function setUpToken() {
            if (data && isSuccess && !isError && !isLoading) {
                let newData = await Promise.all(
                    data?.map(async token => {
                        try {
                            const tokenAddressContract = token?.token
                                ? getTokenContractAddress(token?.tokenAddress)
                                : ZERO_ADDRESS;
                            const decimals = token?.token ? token?.token?.decimals : 18;
                            let fiatConversion = "0";
                            let fiatBalance = "0";

                            const res = await fetch(FETCH_TOKEN_CONVERSION_ENDPOINT, {
                                method: "POST",
                                body: JSON.stringify({
                                    tokenAddress: tokenAddressContract,
                                    networkId: networkId,
                                }),
                                headers: {
                                    "content-type": "application/json",
                                },
                            });
                            const resData = await res.json();
                            fiatConversion = resData?.value;

                            const tokenAmount = token?.balance;

                            const tokenAmountInDecimals = weiToString(tokenAmount || 0, decimals);
                            fiatBalance = new Decimal(tokenAmountInDecimals)
                                ?.mul(fiatConversion)
                                ?.toString();

                            return {
                                ...token,
                                fiatConversion,
                                fiatBalance,
                            };
                        } catch (err) {
                            return {
                                ...token,
                            };
                        }
                    }),
                );

                newData && newData.length > 0 && setRawSafeTokenData(newData);

                if (Array.isArray(newData)) {
                    resolvingTokensInSafe(newData);
                }

                Array.isArray(newData) &&
                    setSafeTokenBalances(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] =
                                item.balance;
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeTokenBalancesByTokenName(
                        newData?.reduce((obj, item) => {
                            obj[item.token ? item.token?.symbol : nativeTokenSymbol] = {
                                balance: Number(item.balance),
                                decimal: item.token ? item.token?.decimals : 18,
                                logoURI: item.token ? item.token?.logoUri : NATIVE_ICON_URL,
                                fiatConversion: item.fiatConversion,
                                fiatBalance: item.fiatBalance,
                                address: item.tokenAddress,
                            };
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeTokenBalancesInDecimal(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] =
                                getAmountFromWei(
                                    item.balance,
                                    item.tokenAddress ? item.token.decimals : 18,
                                );
                            return obj;
                        }, {}),
                    );
                Array.isArray(newData) &&
                    setSafeBalanceInUSD(
                        newData?.reduce((obj, item) => {
                            obj[item.tokenAddress ? item.tokenAddress : nativeTokenSymbol] = Number(
                                item.fiatBalance,
                            );
                            return obj;
                        }, {}),
                    );
            }
        }
        setUpToken();
    }, [data, isSuccess, isError, isLoading]);

    const resolvingTokensInSafe = async data => {
        let tokenData = await Promise.all(
            data.map(async token => {
                if (token.token) {
                    return {
                        ...token.token,
                        tokenAddress: token.tokenAddress,
                        fiatConversion: Number(token.fiatConversion),
                    };
                } else {
                    return {
                        name: nativeTokenName,
                        symbol: nativeTokenSymbol,
                        logoUri: NATIVE_ICON_URL,
                        decimals: 18,
                        tokenAddress: nativeTokenSymbol,
                        fiatConversion: Number(token.fiatConversion),
                    };
                }
            }),
        );
        let result = tokenData.reduce((agg, tokenItem) => {
            agg[tokenItem.tokenAddress] = tokenItem;
            return agg;
        }, {});
        setTokensInSafe(result);
    };

    const getSafeBalance = () => {
        return Object.values(safeBalanceInUSD).reduce(
            (sum: number, balance: number) => sum + balance,
            0,
        );
    };

    return (
        <ChildSafeContext.Provider
            value={{
                currentSafeAddress,
                setCurrentSafeAddress,
                tokensInSafe,
                setTokensInSafe,
                safeTokenBalances,
                setSafeTokenBalances,
                safeBalanceInUSD,
                setSafeBalanceInUSD,
                rawSafeTokenData,
                getSafeBalance,
                safeStaticDetails,
                safeTokenBalancesInDecimal,
                safeTokenBalancesByTokenName,
                safeDetailsFromGnosis,
                loadingContext:
                    loadingChildSafeContextVariable ||
                    isLoading ||
                    loadingGnosisDetails ||
                    isFetching ||
                    safeDetails?.isLoading,
            }}
        >
            {children}
        </ChildSafeContext.Provider>
    );
};

export default ChildSafeContext;
