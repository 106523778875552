import {
    gnosisSafeTransactionEndpoint,
    gnosisSafeTransactionV2Endpoint,
} from "src/constants/endpoints";
import { gnosisURL } from "src/queries/constants";
import { networkNames } from "src/helpers/utils/networks";
import * as Sentry from "@sentry/nextjs";

const GAS_ESTIMATE_RETRIES = 3;

export const getGasEstimate = async (
    safeAddress,
    postData,
    currentCallCount,
    safeTransactionCallCount = 0,
) => {
    // console.log({ safeAddress, postData, currentCallCount, safeTransactionCallCount });
    if (process.env.NEXT_PUBLIC_NETWORK_NAME === networkNames.POLYGON) {
        const BASE_GAS = (postData.data?.length || 1) * 400 + 56000;
        const estimateResponse = await fetch(
            `${gnosisSafeTransactionEndpoint}${safeAddress}/multisig-transactions/estimations/`,
            {
                method: "POST",
                body: JSON.stringify(postData),
                headers: {
                    "content-type": "application/json",
                },
            },
        );

        const estimateResult = await estimateResponse.json();
        if (estimateResponse.status !== 200 || estimateResult?.exception) {
            console.error(estimateResult?.exception);
            if (currentCallCount < GAS_ESTIMATE_RETRIES) {
                return await getGasEstimate(safeAddress, postData, currentCallCount + 1);
            } else {
                //throw new Error("Gas estimation error. The transaction might fail.");
                console.error(
                    "Gas estimation error. The transaction might fail.",
                    estimateResponse,
                );
                Sentry.captureException(estimateResult?.exception);
            }
        } else {
            return {
                safeTxGas: estimateResult?.safeTxGas,
                baseGas: BASE_GAS,
            };
        }
    } else {
        let estimateResponse;
        const BASE_GAS = (postData.data?.length || 1) * 4 + 56000;
        if (safeTransactionCallCount > 0) {
            estimateResponse = await fetch(
                `${gnosisURL}/safes/${safeAddress}/multisig-transactions/estimations/`,
                {
                    method: "POST",
                    body: JSON.stringify(postData),
                    headers: {
                        "content-type": "application/json",
                    },
                },
            );
        } else {
            estimateResponse = await fetch(
                `${gnosisSafeTransactionV2Endpoint}${safeAddress}/transactions/estimate/`,
                {
                    method: "POST",
                    body: JSON.stringify(postData),
                    headers: {
                        "content-type": "application/json",
                    },
                },
            );
        }

        const estimateResult = await estimateResponse.json();
        // console.log(estimateResult, "from estimateResult");
        if (estimateResponse.status !== 200 || estimateResult?.exception) {
            console.error(estimateResult?.exception);
            if (currentCallCount < GAS_ESTIMATE_RETRIES) {
                return await getGasEstimate(safeAddress, postData, currentCallCount + 1, 0);
            } else if (safeTransactionCallCount < GAS_ESTIMATE_RETRIES) {
                return await getGasEstimate(safeAddress, postData, 3, safeTransactionCallCount + 1);
            } else {
                throw new Error("Gas estimation error. The transaction might fail.");
            }
        } else {
            return {
                safeTxGas: estimateResult?.safeTxGas,
                baseGas: estimateResult?.baseGas ?? BASE_GAS,
            };
        }
    }
};
