import DialogActions from "@mui/material/DialogActions";
import styled from "@emotion/styled";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
export const StyledModal: any = styled.div``;

export const StyledDialog: any = styled(Dialog)`
    z-index: 2000;

    .MuiPaper-root {
    }
`;

export const StyledDialogContent: any = styled(DialogContent)`
    padding: 0px 56px;
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    overflow: visible;

    .metatx-img {
        span {
            width: 250px;
            height: 200px;
        }
    }

    .title {
        font-size: 18px;
        font-weight: 600;
        line-height: 18px;
    }

    .subtitle {
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;
        color: #9998a4;
    }
`;

export const DialogActionsStyled: any = styled(DialogActions)`
    display: flex;
    background: #f6f9fe;
    padding-right: 30px;
    height: 80px;
    /* opacity: 0.04; */
    border-radius: 0px 0px 8px 8px;

    .confirm-btn {
        margin-left: 20px;
        border-radius: 4px;
    }
    .cancel-btn {
        border-radius: 4px;
    }

    /* button {
        padding: 13px 26px;
        border: 1px solid #e4e4ea;
        border-radius: 4px;
        background-color: white;
        font-family: Metropolis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0px;
        text-align: left;
        opacity: 1;
        color: #5e5c6c;
    } */
`;
