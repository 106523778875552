//not used --

import SafeContext from "src/contexts/SafeContext";
import useContract from "./useContract";
import { useContext } from "react";
import GnosisSafeABI from "src/constants/abis/GnosisSafe.json";
import useActiveBaseContext from "./useActiveBaseContext";
import { BigNumber } from "ethers";
import { getGasEstimateBackup } from "src/helpers/getGasEstimateBackup";

export default function useEstimateGasContract(safeAddress = null) {
    const { account, library } = useActiveBaseContext().web3;
    const { currentSafeAddress }: any = useContext(SafeContext);
    //initialise gnosis contract
    const gnosisSafeMasterContract = useContract(
        safeAddress ? safeAddress : currentSafeAddress,
        GnosisSafeABI,
        true,
        library,
        account,
    );

    const getGasEstimateTransactionExecution = async (safeTransaction, singleSignature = null) => {
        // console.log("safeTransaction", safeTransaction);
        // console.log("gnosisSafeMasterContract", gnosisSafeMasterContract);
        if (safeTransaction?.to && gnosisSafeMasterContract) {
            try {
                let combinedSignature;
                if (!singleSignature) {
                    combinedSignature = safeTransaction.confirmations
                        .sort((a, b) => (a.owner.toLowerCase() > b.owner.toLowerCase() ? 1 : -1))
                        .reduce((prev, item) => (prev += item.signature.replace("0x", "")), "0x");
                }

                const contractGas = await gnosisSafeMasterContract.estimateGas.execTransaction(
                    safeTransaction.to,
                    safeTransaction.value,
                    safeTransaction.data ?? "0x",
                    safeTransaction.operation,
                    safeTransaction.safeTxGas,
                    safeTransaction.baseGas,
                    safeTransaction.gasPrice,
                    safeTransaction.gasToken,
                    safeTransaction.refundReceiver,
                    singleSignature ? singleSignature : combinedSignature,
                );
                const apiGas = await getGasEstimateBackup(
                    safeAddress ? safeAddress : currentSafeAddress,
                    {
                        safe: safeAddress ? safeAddress : currentSafeAddress,
                        to: safeTransaction.to,
                        value: safeTransaction.value,
                        data: safeTransaction.data ?? "0x",
                        operation: safeTransaction.operation,
                        gasToken: safeTransaction.gasToken,
                        // gasToken,
                    },
                );

                // console.log("api Gas", apiGas);
                // console.log("contractGas", contractGas.toNumber());
                return (contractGas.toNumber() || 0) >= Number(apiGas)
                    ? contractGas.toNumber()
                    : Number(apiGas);
            } catch (error) {
                console.log("error in estimating", error);
                return 0;
            }
        }
    };

    return { getGasEstimateTransactionExecution };
}
