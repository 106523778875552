import { createContext, useEffect, useState } from "react";
import { useTransactionMeta } from "src/queries/Transaction/api";

interface TransactionContextInterface {
    pendingTxn?: any;
    executedTxn?: any;
    setUniqueNonceMap?: (transactions: any, safeAddress: string, isPending: boolean) => void;
}

export const TransactionContext = createContext({
    setUniqueNonceMap: useTransactionMeta,
    pendingTxn: [],
    executedTxn: [],
} as unknown as TransactionContextInterface);

export const TransactionContextProvider = ({ children }) => {
    const [pendingTransaction, setPendingTransaction] = useState([]);
    const [executedTransaction, setExecutedTransaction] = useState([]);

    const setUniqueNonceMap = transactions => {
        // console.log(transactions, "Data");
    };

    return (
        <TransactionContext.Provider
            value={{
                pendingTxn: pendingTransaction,
                executedTxn: executedTransaction,
                setUniqueNonceMap,
            }}
        >
            {children}
        </TransactionContext.Provider>
    );
};

export default TransactionContext;
