import { MainHeaderWrapper } from "../styles";

export const MainHeader = () => (
    <MainHeaderWrapper>
        <div>
            <span>NAME</span>
        </div>
        <div>
            <span>PAYOUT</span>
        </div>
        <div>
            <span>TOKEN AMOUNT</span>
        </div>
        <div>
            <span>ACTION</span>
        </div>
    </MainHeaderWrapper>
);
