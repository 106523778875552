import { BigNumber, ethers } from "ethers";
import { getAddress, parseUnits } from "ethers/lib/utils";
export const defaultTxOperation = 0;
export const defaultTxValue = 0;
export const defaultTxData = "0x";

export function standardizeTransaction(tx) {
    return {
        operation: tx.operation ? tx.operation : defaultTxOperation,
        to: getAddress(tx.to),
        value: tx.value ? tx.value.toString() : defaultTxValue,
        data: tx.data ? tx.data : defaultTxData,
    };
}

export function getHexDataLength(hexData) {
    return Math.ceil((hexData.startsWith("0x") ? hexData.length - 2 : hexData.length) / 2);
}
export function joinHexData(hexData) {
    return `0x${hexData
        .map(hex => {
            const stripped = hex.replace(/^0x/, "");
            return stripped.length % 2 === 0 ? stripped : "0" + stripped;
        })
        .join("")}`;
}

export const getAmountInWei = (tokenAmount, decimals) => {
    let amount = tokenAmount;
    if (typeof tokenAmount === "string") {
        const [whole, fraction] = tokenAmount.split(".");
        if (fraction) {
            amount = whole + "." + fraction.slice(0, decimals);
        } else {
            amount = whole;
        }
    } else if (typeof tokenAmount === "number") {
        amount = tokenAmount.toFixed(decimals);
    }

    return ethers.utils.parseUnits(amount, decimals);
};
