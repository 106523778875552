import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import { useRouter } from "next/router";

import Header from "./Header";
import ContributorNavSideBar from "src/components/LayoutSideBar/ContributorNavSideBar";
import NavSideBar from "src/components/LayoutSideBar/NavSideBar";
import { useActiveBaseContext } from "src/hooks";
import { MainContainer } from "./styles";

import OnBoardSidebar from "src/components/LayoutSideBar/OnBoardSidebar";

const drawerWidth = 250;

export default function PrimaryLayout({ children, ...props }) {
    const router = useRouter();
    // const pathname = router.pathname.split("/");
    // console.log(router.pathname.startsWith("/contributor"));
    const SideBarToRender = router.pathname.startsWith("/contributor")
        ? ContributorNavSideBar
        : NavSideBar;
    const { account } = useActiveBaseContext().web3;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };
    // const { library, isLoggedIn } = useActiveBaseContext().web3;
    // if (!isLoggedIn) {
    //     router.push("/");
    // }
    // useEffect(() => {
    //     console.log(library, isLoggedIn, "from lib");
    //     if (library && isLoggedIn !== undefined && isLoggedIn === false) {
    //         router.push("/");
    //     }
    //     if (!library && isLoggedIn == undefined) {
    //         router.push("/");
    //     }
    // }, [isLoggedIn, library]);

    return (
        <Box sx={{ display: "flex" }}>
            {!(router.pathname.startsWith("/onboard") || router.pathname === "/") && (
                <Box
                    component="nav"
                    sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
                    aria-label="menus navigations"
                >
                    <Drawer
                        variant="temporary"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                        sx={{
                            display: { xs: "block", sm: "none" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                            },
                        }}
                    >
                        {<SideBarToRender />}
                    </Drawer>
                    <Drawer
                        variant="permanent"
                        sx={{
                            display: { xs: "none", sm: "block" },
                            "& .MuiDrawer-paper": {
                                boxSizing: "border-box",
                                width: drawerWidth,
                                padding: "10px",
                            },
                        }}
                        open
                    >
                        {<SideBarToRender />}
                    </Drawer>
                </Box>
            )}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    px: { xs: 3, sm: 3, md: 5, lg: 14.5, xl: 14.5 },
                    width: { sm: `calc(100% - ${drawerWidth}px)` },
                    minHeight: "100vh",
                    // maxWidth: "1400px",
                    padding: "0rem 115px 4rem 115px",
                    background: router.pathname.startsWith("/squads") && "#ffffff",
                }}
            >
                <MainContainer>
                    {<Header />}
                    {children}
                </MainContainer>
            </Box>
        </Box>
    );
}
