import { IconButton, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";
import NameAddress from "../NameAddress";
import TokenSummary from "../TokenSummary";
import { ReviewScreenWrapper } from "./styles/ReviewScreen";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useGetContributor } from "src/queries/PeopleSection/api";
import SafeContext from "src/contexts/SafeContext";
import TokenSummaryPayroll from "../TokenSummaryPayroll";

export default function ReviewScreenSummary({ payeeDetails, fromWorflow = false }) {
    const [payees, setPayees] = useState({});

    useEffect(() => {
        const payees = {};
        payeeDetails?.forEach(item => {
            if (payees[item?.address]) {
                const disbursement: any = {};
                disbursement.token = item?.symbol || item?.token;
                disbursement.amount = item.amount.toNumber();
                disbursement.fiatValue = item.fiatValue;
                payees[item?.address].disbursement.push(disbursement);
                const t1 = payees[item?.address]?.tags;
                let t2 = {};
                item?.tagName?.forEach(k => {
                    t2 = { ...t2, [k?.tagName]: k?.tagName };
                });
                payees[item?.address].tags = { ...t1, ...t2 };
            } else {
                const payee: any = {};
                payee.name = item?.username;
                payee.address = item?.address;
                const disbursement: any = {};
                disbursement.token = item?.symbol || item?.token;
                disbursement.amount = item.amount.toNumber();
                disbursement.logoUri = item.logoUri || item.tokenURI;
                disbursement.fiatValue = item.fiatValue;

                payee.disbursement = [disbursement];
                let d = {};
                item?.tagName?.forEach(k => {
                    d = { ...d, [k?.tagName]: k?.tagName };
                });
                if (item?.tagName) payee.tags = { ...d };
                else payee.tags = {};
                payees[item?.address] = payee;
            }
        });
        setPayees(payees);
    }, [payeeDetails]);

    return (
        <ReviewScreenWrapper>
            <Box className="header">
                <Box>
                    <Typography color="#9998A4" fontSize="13px" fontWeight={500}>
                        NAME
                    </Typography>
                </Box>
                <Box>
                    <Typography color="#9998A4" fontSize="13px" fontWeight={500}>
                        TOKEN
                    </Typography>
                </Box>
                <Box>
                    <Typography color="#9998A4" fontSize="13px" fontWeight={500}>
                        TAGS
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    maxHeight: "582px",
                    overflowY: "auto",
                }}
            >
                {Object.values(payees).map((item: any) => (
                    <Box className="body">
                        <Box>
                            <NameAddress
                                name={item?.name ? item.name : "Unnamed"}
                                address={item.address}
                                ens={""}
                            ></NameAddress>
                        </Box>
                        <Box>
                            {fromWorflow ? (
                                <TokenSummaryPayroll
                                    disbursment={item.disbursement}
                                    fromSummary={true}
                                />
                            ) : (
                                <TokenSummary
                                    disbursment={item.disbursement}
                                    fromReviewScreenSummary
                                ></TokenSummary>
                            )}
                        </Box>
                        <Box display="flex" alignItems="center" style={{ flexWrap: "wrap" }}>
                            {Object.values(item.tags).map(t => {
                                return (
                                    <Box
                                        display="flex"
                                        alignItems="center"
                                        sx={{
                                            border: "1px solid #DFE1E5",
                                            borderRadius: "4px",

                                            padding: "7px",
                                            height: "29px",
                                            margin: "1px",
                                        }}
                                    >
                                        <Typography
                                            fontSize={"13px"}
                                            fontWeight={500}
                                            color="black"
                                        >
                                            {t}
                                        </Typography>
                                    </Box>
                                );
                            })}
                        </Box>
                        {/*  <Box>
                           <Box sx={{ alignSelf: "flex-end" }}>
                                <IconButton
                                    id="basic-button"
                                    aria-controls={open ? "basic-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    onClick={() => {}}
                                    sx={{
                                        background: "#F2F2F2",
                                        borderRadius: "4px",
                                    }}
                                >
                                    <MoreVertIcon color="disabled"></MoreVertIcon>
                                </IconButton>
                            </Box> 
                        </Box> */}
                    </Box>
                ))}
            </Box>
        </ReviewScreenWrapper>
    );
}
