import { useQuery } from "react-query";
import { networkId } from "src/helpers/utils/networks";

export function useIsSafeWhitelistedOnPayroll(safeAddress, options = {}) {
    return useQuery(
        ["is-safe-whitelisted-parcel-payroll", safeAddress],
        async () => {
            const response: any = await fetch(
                `${
                    process.env.NEXT_PUBLIC_PAYROLL_URL || "https://v3.parcel.money"
                }/api/safe/isWhitelisted?safeAddress=${safeAddress}&networkId=${networkId}`,
            );
            return await response.json();
        },
        { staleTime: 10000, ...options },
    );
}

export function useGetSafeMigrationDetails(safes, networkId, options = {}) {
    return useQuery(
        ["get-safe-migration-details", safes, networkId],
        async () => {
            // fetch post request with safes and networkId as body
            const response: any = await fetch(
                `${
                    process.env.NEXT_PUBLIC_PAYROLL_URL || "https://v3.parcel.money"
                }/api/safe/migrationDetails`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        safes,
                        networkId,
                    }),
                },
            );
            return await response.json();
        },
        { staleTime: 10000, ...options },
    );
}
