import React, { useContext, useEffect, useState } from "react";
import { SummaryWrapper } from "./style";
import Image from "next/image";

import multitoken from "../../../assets/newMultiToken.png";

import SafeContext from "src/contexts/SafeContext";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";
import FastAverageColor from "fast-average-color";
import { Box } from "@mui/system";
import { formatAmount } from "src/helpers/utils/web3Utils";
import { nativeTokenSymbol } from "src/queries/constants";
import { formatTokenDecimals, validNumberString } from "src/helpers/utils/common";
import Decimal from "decimal.js-light";
import { formatUnits, isHexString } from "ethers/lib/utils";
import { BigNumber } from "ethers";

export default function TokenSummary({
    disbursment,
    fromReviewScreenSummary = false,
    fromTransaction = false,
    gnosis = false,
    pending = false,
    contextProps = SafeContext,
    transactionData = null,
}) {
    const { tokensInSafe, safeTokenBalancesByTokenName }: any = useContext(contextProps);
    const [color, setColor] = useState("#f7f7f7");

    const isContractInteraction = disbursment => {
        return disbursment[0]?.token === "contractInteraction";
    };

    useEffect(() => {
        const fac = new FastAverageColor();
        fac.getColorAsync(
            tokensInSafe[disbursment[0]?.token == "" ? nativeTokenSymbol : disbursment[0]?.token]
                ?.logoUri ?? UndiscoveredCoin.src,
        )
            .then(color => {
                setColor(`rba(${color.value[0]}, ${color.value[1]}, ${color.value[2]}, 0.4)`);
            })
            .catch(e => {
                console.error(e);
            });
    }, []);

    const getAmount = () => {
        if (transactionData?.payrollTransaction) {
            if (transactionData?.payrollTransaction?.resultType === "receipt") {
                let fiatValue = (transactionData?.payrollTransaction?.invoiceIds || []).reduce(
                    (sum, { invoiceId }) => {
                        sum = sum + Number(invoiceId?.fiatValue);
                        return sum;
                    },
                    0,
                );

                return fiatValue > 0 ? `$${formatAmount(fiatValue, 0, 2)}` : "--";
            } else {
                let payout = transactionData?.payrollTransaction?.tokens[0];

                let fiatValue = payout.isAmountInFiat
                    ? new Decimal(payout.fiatAmount)
                    : new Decimal(
                          formatUnits(
                              BigNumber.from(transactionData?.payrollTransaction.amount),
                              payout.decimals,
                          ),
                      ).mul(payout.fiatConversion);

                return fiatValue.greaterThan(0) ? `$${fiatValue.toDecimalPlaces(2)}` : "--";
            }
        }
        if (!fromTransaction) {
            if (
                safeTokenBalancesByTokenName[disbursment[0]?.token] &&
                safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion &&
                safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion > 0
            ) {
                let fiatValue = new Decimal(
                    validNumberString(
                        safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion,
                    ),
                )
                    .times(new Decimal(validNumberString(disbursment[0].amount, true, 18)))
                    .toNumber();

                if (fiatValue < 0.01) return "< $0.01";

                return `$${formatAmount(fiatValue, 0, 2)}`;
            }

            return "--";
        }
        if (gnosis) {
            if (!pending) return "--";

            if (
                safeTokenBalancesByTokenName[disbursment[0]?.token] &&
                safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion &&
                safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion > 0
            ) {
                let fiatValue = new Decimal(
                    validNumberString(
                        safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion,
                    ),
                )
                    .times(new Decimal(validNumberString(disbursment[0].amount, true, 18)))
                    .toNumber();

                if (fiatValue < 0.01) return "< $0.01";

                return `$${formatAmount(fiatValue, 0, 2)}`;
            }

            return "--";
        } else {
            if (disbursment.length > 1) {
                let disValue = disbursment
                    .map(item => item.fiatValue)
                    .reduce((a, b) => a + b, 0)
                    .toFixed(2);

                return disValue > 0 ? `$${formatAmount(disValue, 0, 2)}` : "--";
            } else {
                if (
                    pending &&
                    safeTokenBalancesByTokenName[disbursment[0]?.token] &&
                    safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion &&
                    safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion > 0
                ) {
                    let fiatValue = new Decimal(
                        validNumberString(
                            safeTokenBalancesByTokenName[disbursment[0].token].fiatConversion,
                        ),
                    )
                        .times(new Decimal(validNumberString(disbursment[0].amount)))
                        .toNumber();

                    if (fiatValue < 0.01) return "< $0.01";

                    return `$${formatAmount(fiatValue, 0, 2)}`;
                }

                if (disbursment[0].fiatValue > 0)
                    return `$${formatAmount(disbursment[0].fiatValue, 0, 2)}`;

                return "--";
            }
        }
    };
    const getTokenSymbol = () => {
        return disbursment[0].token === ""
            ? nativeTokenSymbol
            : isHexString(disbursment[0].token)
            ? disbursment[0].tokenSymbol || tokensInSafe[disbursment[0].token].symbol
            : disbursment[0].token;
    };
    const getTotalTokenAmount = disbursment => {
        let disValue = disbursment.map(item => parseFloat(item.amount)).reduce((a, b) => a + b, 0);
        return disValue > 0
            ? `${formatAmount(disValue, 0, 7)} ${getTokenSymbol()}`
            : `-- ${getTokenSymbol()}`;
    };

    const calculateUniqueTokens = disbursment => {
        return Array.from(new Set(disbursment.map(({ token }) => token)));
    };

    let valueToDisplay = disbursment[0] && validNumberString(disbursment[0].amount);

    return (
        <SummaryWrapper>
            {disbursment.length && !isContractInteraction(disbursment) ? (
                <>
                    <Box
                        className="circle"
                        sx={{
                            padding: disbursment.length > 1 ? "0px !important" : "8px",
                            background: `${color} !important`,
                        }}
                    >
                        {disbursment.length > 1 && calculateUniqueTokens(disbursment).length > 1 ? (
                            <Image width={"42px"} height={"42px"} src={multitoken}></Image>
                        ) : fromTransaction ? (
                            <img
                                src={
                                    safeTokenBalancesByTokenName[disbursment[0]?.token]?.logoURI ||
                                    disbursment[0]?.tokenLogoUrl ||
                                    UndiscoveredCoin.src
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = UndiscoveredCoin.src;
                                }}
                            />
                        ) : (
                            <img
                                src={
                                    fromReviewScreenSummary
                                        ? disbursment[0]?.logoUri
                                        : `${
                                              tokensInSafe[
                                                  disbursment[0].token == ""
                                                      ? nativeTokenSymbol
                                                      : disbursment[0].token
                                              ]?.logoUri
                                          }`
                                }
                                onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = UndiscoveredCoin.src;
                                }}
                            />
                        )}
                    </Box>
                    <div className="flex">
                        <span>
                            {disbursment.length > 1
                                ? calculateUniqueTokens(disbursment).length > 1
                                    ? "Multiple Tokens"
                                    : getTotalTokenAmount(disbursment)
                                : `${
                                      //   isNaN(Number(disbursment[0]?.amount))
                                      //       ? 0
                                      //       : formatAmountWithoutTruncate(
                                      //             disbursment[0]?.amount,
                                      //             0,
                                      //             6,
                                      //         )
                                      disbursment[0].amount < 0.01
                                          ? formatTokenDecimals(new Decimal(valueToDisplay), 4)
                                          : formatAmount(valueToDisplay, 0, 4)
                                  } ${getTokenSymbol()}`}
                        </span>
                        <span id="amount">{getAmount()}</span>
                    </div>
                </>
            ) : null}
        </SummaryWrapper>
    );
}
