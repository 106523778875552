import { Circle } from "@mui/icons-material";
import { Divider, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { BigNumber, ethers, utils } from "ethers";
import React, { useContext, useEffect, useState } from "react";
import InputComponent from "src/components/InputComponent";
import SafeContext from "src/contexts/SafeContext";
import { getAmountInWei } from "src/helpers/tx-helpers";
import ReviewScreenSummaryPayroll from "./ReviewScreenSummaryPayroll";
import ReviewTokenSummaryPayroll from "./ReviewScreenTokenPayroll";
import TransferContext from "src/contexts/TransferContext";
import { formatAmount } from "src/helpers/utils/web3Utils";
import { nativeTokenSymbol } from "src/queries/constants";
import SelectBoxParcel from "src/components/CategorySelect/index";
import Decimal from "decimal.js-light";
import { validNumberString } from "src/helpers/utils/common";
import SimulationBox from "src/components/common/SimulateTransaction";
import { useActiveBaseContext } from "src/hooks";
import { transformDataForRecurringSimulation } from "src/helpers/simulationUtils";
import BudgetSelect from "src/components/Squads/BudgetSelect";

export default function ReviewScreen({
    description,
    setDescription,
    payoutQueueByTag,
    tagInfocus,
    setCategory,
    budget,
    setBudget,
    category,
}) {
    const [renderPayeeInSummary, setRenderPayeeInSummary] = useState([]);
    const [allPayeeToRender, setAllPayeeToRender] = useState([]);
    const { ethersAdapterOwner }: any = useActiveBaseContext().web3;

    const [simulateState, setSimulateState] = useState("begins");
    const [simulationData, setSimulationData] = useState([]);

    const [payable, setPayable] = useState(0);
    const {
        tokensInSafe,
        currentSafeAddress,
        safeBalanceInUSD,
        rawSafeTokenData,
        safeTokenBalances,
        setIsExceed,
        safeStaticDetails: { categories: _categories = [] } = {},
    }: any = useContext(SafeContext);

    const { setPayrollPreviewStep }: any = useContext(TransferContext);

    const totalBalInUSD: any = Object.values(safeBalanceInUSD).reduce(
        (a: number, b: number) => a + b,
        0,
    );

    useEffect(() => {
        if (Object.keys(payoutQueueByTag || {}).length === 0) {
            setPayrollPreviewStep(1);
        }
    }, payoutQueueByTag);

    useEffect(() => {
        setRenderPayees();
    }, [tagInfocus, payoutQueueByTag]);

    const setRenderPayees = () => {
        const payees = payoutQueueByTag[tagInfocus];
        const allPayees: any = Object.values(payoutQueueByTag).flat();
        const payeeDetails = [];
        const allPayeeDetails = [];
        payees &&
            payees.forEach(payee => {
                payee?.selectedTokens?.forEach(token => {
                    const data: any = {};
                    data.address = payee.address;
                    data.name = payee.nickName;
                    data.token = token?.token?.symbol;
                    data.tokenURI = token?.token?.logoUri;
                    data.fiatConversion = token?.token?.fiatConversion;
                    data.amount = token?.amount;
                    data.fiatValue = token?.fiatValue;
                    data.tokenAddress = token.token.tokenAddress;
                    data.fixedUsd = token?.fixedUsd;
                    payeeDetails.push({ data });
                });
            });

        allPayees &&
            allPayees.forEach((payee): any => {
                payee.selectedTokens.forEach(token => {
                    const data: any = {};
                    data.address = payee.address;
                    data.name = payee.nickName;
                    data.token = token?.token?.symbol;
                    data.tokenURI = token?.token?.logoUri;
                    data.fiatConversion = token?.token?.fiatConversion;
                    data.amount = token?.amount;
                    data.fiatValue = token?.fiatValue;
                    data.tokenAddress = token.token.tokenAddress;
                    data.fixedUsd = token?.fixedUsd;
                    allPayeeDetails.push({ data });
                });
            });

        setSimulationData(
            transformDataForRecurringSimulation(
                allPayeeDetails,
                tokensInSafe,
                description,
                category,
            ),
        );
        setAllPayeeToRender(allPayeeDetails);
        setRenderPayeeInSummary(payeeDetails);
    };

    useEffect(() => {
        let total = 0;
        let reStruct = Object.values(payoutQueueByTag).flat();
        reStruct.map((item: { selectedTokens: any }) => {
            if (item.selectedTokens) {
                item.selectedTokens.map(token => {
                    if (token.fixedUsd)
                        total = total + new Decimal(validNumberString(token.fiatValue)).toNumber();
                    else
                        total =
                            total +
                            new Decimal(validNumberString(token.amount))
                                .times(new Decimal(validNumberString(token.token.fiatConversion)))
                                .toNumber();
                });
            }
        });

        setPayable(total);
    }, [payoutQueueByTag]);

    // useEffect(() => {
    //     let mapping = {};
    //     let payableMapping = {};
    //     rawSafeTokenData.map(token => {
    //         mapping[token.tokenAddress ? token.tokenAddress : nativeTokenSymbol] = token.balance;
    //     });

    //     Object.values(payoutQueueByTag)
    //         .flat()
    //         .map((item: { selectedTokens: any }) => {
    //             item?.selectedTokens?.map(token => {
    //                 payableMapping[token.token.tokenAddress]
    //                     ? (payableMapping[token.token.tokenAddress] = payableMapping[
    //                           token.token.tokenAddress
    //                       ].add(getAmountInWei(token.amount, token.token.decimals)))
    //                     : (payableMapping[token.token.tokenAddress] = getAmountInWei(
    //                           token.amount,
    //                           token.token.decimals,
    //                       ));
    //             });
    //         });

    //     Object.keys(mapping).map(item => {
    //         if (
    //             payableMapping[item] &&
    //             mapping[item] &&
    //             payableMapping[item].gt(BigNumber.from(mapping[item]))
    //         ) {
    //             setIsExceed(true);
    //         }
    //     });
    // }, [payoutQueueByTag]);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
            }}
        >
            <Box
                sx={{
                    width: "55%",
                    marginRight: "30px",
                }}
            >
                <ReviewScreenSummaryPayroll
                    payeeDetails={{ rows: renderPayeeInSummary }}
                ></ReviewScreenSummaryPayroll>
            </Box>
            <Box
                sx={{
                    width: "45%",
                }}
            >
                <ReviewTokenSummaryPayroll
                    payeeDetails={{ rows: allPayeeToRender }}
                ></ReviewTokenSummaryPayroll>
                <Box
                    sx={{
                        marginTop: "22px",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"13px"} color={"#9998A4"}>
                            Total Amount
                        </Typography>
                        <Typography fontWeight={700} color="#232427" fontSize="13px">
                            {isNaN(totalBalInUSD) ? "--" : `$${formatAmount(totalBalInUSD, 2, 2)}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"13px"} color={"#9998A4"}>
                            Payable Amount
                        </Typography>
                        <Typography fontWeight={700} color="#EC5952" fontSize="13px">
                            - {isNaN(payable) ? "--" : `$${formatAmount(payable, 2, 2)}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "16px",
                        }}
                    >
                        <Typography fontSize={"13px"} color={"#9998A4"}>
                            Remaining Amount
                        </Typography>
                        <Typography fontWeight={700} fontSize={"13px"} color="#232427">
                            {isNaN(totalBalInUSD - payable)
                                ? "--"
                                : `$${formatAmount(totalBalInUSD - payable, 2, 2)}`}
                        </Typography>
                    </Box>
                </Box>
                <Stack
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="submit-holder"
                >
                    <SelectBoxParcel
                        label={"Category"}
                        items={_categories.filter(({ isEnabled }) => Boolean(isEnabled))}
                        onChange={setCategory}
                    ></SelectBoxParcel>
                    {/* <Divider className="divider" /> */}
                </Stack>
                <Stack
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                    className="submit-holder"
                >
                    <BudgetSelect budget={budget} setBudget={setBudget} />
                </Stack>
                <InputComponent
                    sx={{ background: "#F5F6F7" }}
                    wrapperStyle={{}}
                    type="text"
                    multiline
                    value={description}
                    onChange={e => setDescription(e.target.value)}
                    label={"Description"}
                    placeholder="Enter Description"
                    inputStyles={{
                        alignItems: "flex-start",
                        background: "#F5F6F7",
                        color: "#636B81",
                        border: "none",
                        height: "120px",
                        padding: "15px 15px 0",
                        lineHeight: 1.5,
                        overflowY: "scroll",
                    }}
                />
                <SimulationBox
                    simulateState={simulateState}
                    setSimulateState={setSimulateState}
                    payoutQueueForSimulation={simulationData}
                />
            </Box>
        </Box>
    );
}
