import styled from "@emotion/styled";
import { Box } from "@mui/system";

export const SelectedPeopleBlockStyled = styled(Box)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 100%;
    margin-top: 20px;
    border: 1px solid ${({ theme }: any) => theme.table.borderColor};
    /* height: 500px; */
    height: 350px;
    @media only screen and (min-height: 768px) {
        height: 500px;
    }
    /* padding: 0 20px; */
    border-radius: 7px;

    @media only screen and (min-width: 768px) {
        margin-left: 15px;
        flex: 50%;
        max-width: 50%;
    }
`;

export const ContentContainer = styled(Box)`
    max-height: 420px;
    overflow-y: scroll;
    flex: 100%;
`;
