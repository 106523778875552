import React, { useContext } from "react";
import TransferContext from "src/contexts/TransferContext";
import MassPayout from "./MassPayout";
import CSVPayout from "./CSVPayout";
import QuickTransfer from "./QuickTransfer";
import { TransferBodyContainer, TransferModuleBodyStyled } from "./styles/TransferModuleBody";
import WorkflowTransfer from "./WorkflowTransfer";

const TransferModuleBody = () => {
    const { transferType }: any = useContext(TransferContext);

    const getTransferSectionByType = () => {
        if (transferType === "quick") return <QuickTransfer />;
        if (transferType === "bulk")
            return (
                <TransferBodyContainer>
                    <MassPayout />
                </TransferBodyContainer>
            );
        if (transferType === "csv")
            return (
                <TransferBodyContainer>
                    <CSVPayout />
                </TransferBodyContainer>
            );
        if (transferType === "recurring")
            return (
                <TransferBodyContainer>
                    <WorkflowTransfer />
                </TransferBodyContainer>
            );
    };

    return <TransferModuleBodyStyled>{getTransferSectionByType()}</TransferModuleBodyStyled>;
};

export default TransferModuleBody;
