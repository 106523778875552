export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS;

export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_TRACKING_ID;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
    (<any>window).gtag("config", GA_TRACKING_ID, {
        page_path: url,
    });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const gaEvent = ({ action, category, label }) => {
    (<any>window).gtag("event", action, {
        event_category: category,
        event_label: label,
    });
};
