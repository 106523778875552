import React from "react";

import { SelectedTagItem, SelectedTagsBlockStyled } from "./styles/SelectedTagsBlock";

const SelectedTagsBlock = ({ tags, tagInfocus, setTagInFocus, payoutQueueByTag }) => {
    return (
        <SelectedTagsBlockStyled direction={"row"}>
            {tags.map((tag, t) => {
                return (
                    <SelectedTagItem
                        onClick={e => setTagInFocus(tag)}
                        selected={tagInfocus === tag}
                        key={t}
                        label={
                            tagInfocus === tag
                                ? `${tag} (${payoutQueueByTag[tag]?.length})`
                                : `${tag}`
                        }
                    />
                );
            })}
        </SelectedTagsBlockStyled>
    );
};

export default SelectedTagsBlock;
