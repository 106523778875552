import { Box, Button, Typography, IconButton } from "@mui/material";
import React from "react";
import Image from "next/image";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { minifyAddress } from "src/helpers/utils/web3Utils";
import { PeopleCardWrapper } from "./styles";
import DeleteIcon from "@mui/icons-material/Delete";
import Popover from "@mui/material/Popover";
import StampAvatar from "src/components/StampAvatar";

function PeopleCard({
    data,
    icon = true,
    margin = true,
    deletePeople = (add, tag) => {},
    tag = "",
}) {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;
    return (
        <PeopleCardWrapper
            display="flex"
            justifyContent="space-between"
            mx={margin ? "20px" : "0px"}
            py={margin ? "13px" : "0px"}
            alignItems="center"
        >
            <Box display="flex" alignItems="center">
                <StampAvatar seed={data.address} size={36} />

                <Box ml="8px">
                    <Typography fontSize="14px" color="title.main" fontWeight={600}>
                        {data.nickName}
                    </Typography>
                    <Typography fontSize="13px" color="#8B91A1" fontWeight={500}>
                        {minifyAddress(data.address)}
                    </Typography>
                </Box>
            </Box>

            {icon && (
                <>
                    <IconButton
                        aria-label="more-vertical"
                        aria-describedby={id}
                        onClick={handleClick}
                    >
                        <MoreVertIcon
                            sx={{
                                background: "#F2F2F2",
                                color: "#5E5C6C",
                                borderRadius: "4px",
                            }}
                            fontSize="medium"
                        />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                        }}
                    >
                        <Button
                            variant="text"
                            color="inherit"
                            sx={{
                                fontWeight: 400,
                            }}
                            onClick={() => deletePeople(data.address, tag)}
                        >
                            Remove from Queue{" "}
                        </Button>
                    </Popover>
                </>
            )}
        </PeopleCardWrapper>
    );
}

export default PeopleCard;
