import SafeContext from "src/contexts/SafeContext";
import { Banner, BannerTitle, ButtonHolder, CloseWrapper, Iconholder } from "./styles";
import Button from "@mui/material/Button";
import { useContext } from "react";

const ParcelPayrollBanner = ({ recurringPage = false }) => {
    const {
        payrollStatus,
        currentSafeAddress,
        safeStaticDetails: { networkId },
    }: any = useContext(SafeContext);

    return (
        <>
            <Banner>
                <BannerTitle component={"h3"}>
                    {/* {recurringPage && payrollStatus?.payrollsMigrated ? ( */}
                    <>Your payments have now moved to Parcel Payroll</>
                    {/* ) : (
                        <>
                            <strong>Time is money, save both. </strong>
                            Move to 3x faster payroll experience ⚡️
                        </>
                    )} */}
                </BannerTitle>
                <ButtonHolder>
                    {/* {payrollStatus?.payrollsMigrated ? (
                        <Button
                            onClick={() => {
                                // Open Payroll App in new tab
                                window.open(
                                    process.env.NEXT_PUBLIC_PAYROLL_URL ||
                                        "https://v3.parcel.money",
                                    "_blank",
                                );
                            }}
                            variant="contained"
                            disableRipple={false}
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                        >
                            {recurringPage ? "Go to Parcel Payroll" : "Get Started"}
                        </Button>
                    ) : payrollStatus?.migrationStaged ? (
                        <Button
                            onClick={() => {
                                // Open Payroll App in new tab
                                window.open(
                                    `${
                                        process.env.NEXT_PUBLIC_PAYROLL_URL ||
                                        "https://v3.parcel.money"
                                    }/migration?safeAddress=${currentSafeAddress}&networkId=${networkId}`,
                                    "_blank",
                                );
                            }}
                            variant="contained"
                            disableRipple={false}
                            sx={{
                                fontSize: "14px",
                                fontWeight: 500,
                            }}
                        >
                            Migrate Now
                        </Button>
                    ) : payrollStatus?.demoCompleted ? (*/}
                    <Button
                        onClick={() => {
                            // Open Payroll App in new tab
                            window.open(
                                `${
                                    process.env.NEXT_PUBLIC_PAYROLL_URL || "https://v3.parcel.money"
                                }?redirectFromV2=true`,
                                "_blank",
                            );
                        }}
                        variant="contained"
                        disableRipple={false}
                        sx={{
                            fontSize: "14px",
                            fontWeight: 500,
                        }}
                    >
                        Get Started
                    </Button>
                    {/*
                    ) : (
                     <Button
                        onClick={() => {
                            // Open Calendly link in new tab
                            window.open(
                                process.env.NEXT_PUBLIC_CALENDLY_URL ||
                                    "https://calendly.com/teamparcel/parcel-payroll-app-1",
                                "_blank",
                            );
                        }}
                        color="primary"
                        variant="contained"
                        disableRipple={false}
                        sx={{ fontSize: "14px", fontWeight: 500 }}
                    >
                        Schedule a Demo
                    </Button> */}
                    {/* )} */}
                </ButtonHolder>
                <Iconholder>
                    <img src="/svg/bannericon.svg" alt="" />
                </Iconholder>
            </Banner>
        </>
    );
};

export default ParcelPayrollBanner;
