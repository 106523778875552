import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

export const StyledDrawerWithCloseIcon = styled(Drawer)`
    .MuiDrawer-paper {
        overflow: visible;
    }
`;
export const CloseIconWrapper: any = styled.div`
    background: white;
    border-radius: 50%;
    top: 28px;
    left: -23px;
    cursor: pointer;
    padding: 10px;
    overflow: visible;
    transition: none !important;
    position: absolute;
    z-index: ${(props: any) => (props.dragActive ? -101 : 1)};
    svg {
        color: ${({ theme }: any) => theme.palette.title.light};
        width: 24px;
        height: 24px;
        vertical-align: middle;
    }
`;

export const StyledBox: any = styled(Box)`
    .MuiLinearProgress-root {
        background-color: #f7f7f7;
        z-index: -200;
    }
    box-shadow: inset 0 0 0 grey;

    div.drawer-heading {
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        margin-bottom: 16px;
    }
`;

export const MainBodyWrapper: any = styled.div``;

export const Header: any = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .title {
        font-size: 22px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        color: #232427;
    }

    .subtitle {
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
    }
    > div:nth-of-type(1) {
        .MuiBreadcrumbs-separator {
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            color: #232427;
        }
    }

    > div:nth-of-type(2) {
        .MuiBreadcrumbs-separator {
            font-size: 14px;
            margin: 0;
            color: ${({ theme }: any) => theme.palette.title.light};
        }

        .total {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            letter-spacing: 1px;
            vertical-align: text-bottom;
            color: ${({ theme }: any) => theme.palette.title.light};
        }
    }
`;
