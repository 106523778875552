import styled from "@emotion/styled";

export const InputComponentStyled: any = styled.div`
    padding-top: 21px;
    width: 100%;

    input {
        ::placeholder,
        ::-webkit-input-placeholder {
            opacity: 1;
        }
        :-ms-input-placeholder {
            opacity: 1;
        }
    }
    .input-text,
    .input-select {
        color: #636b81;
        width: 100%;
        background: #f5f6f7;
        padding: 0 10px;
        height: 44px;
        align-items: center;
        border-radius: 4px;
        box-shadow: none;
        font-size: 14px;
        line-height: 14px;
        border: ${(props: any) => (props.error ? `1px solid red` : "none")};

        .MuiInputBase-input {
            padding-bottom: 2px;
        }
        .token-logo {
            max-width: 20px;
            border-radius: 50%;
        }
        .MuiSelect-select {
            height: 100%;
            width: 100%;
            padding: 0;
            align-items: center;
            display: flex;
            &:focus {
                background-color: #f5f6f7;
            }
        }
    }
    .MuiInput-root {
        &:before {
            border-bottom: none;
        }
        &:hover {
            &:before {
                border-bottom: none !important;
            }
        }
        &:after {
            border-bottom: none;
        }
    }

    .MuiSelect-icon {
        margin-right: 10px;
    }
    .label-text {
        margin-bottom: 5px;
        font-size: 12px;
        line-height: 12px;
        color: #636b81;
        font-weight: 500;
    }
    .identicon {
        border-radius: 50%;
    }

    .required-field {
        font-size: 12px;
        color: #ee365a;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0px;
        text-align: left;
    }
`;
