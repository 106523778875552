import React, { useState } from "react";
import Collapse from "@mui/material/Collapse";
import { CollapseBodyWrapper, TokenImg } from "./styles/ReviewScreen";
import NameAddress from "src/components/NameAddress";
import TokenSummaryPayroll from "src/components/TokenSummaryPayroll";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import UndiscoveredCoin from "assets/Undiscovered-Coin.svg";

import { Box } from "@mui/system";
import { IconButton, Typography } from "@mui/material";
const CollapseReviewScreen = ({ item, usdValueOff }) => {
    const [open, setOpen] = useState(false);
    const handleClick = event => {
        setOpen(!open);
        event.stopPropagation();
    };
    const styles = {
        border: "1px solid #2962EF",
        borderRadius: 4,
    };
    const totalCount = item?.disbursement?.length || 0;

    const getValue = (fixedUsd, fiatValue, token, amount) => {
        if (usdValueOff && fixedUsd) {
            return `${Number(fiatValue).toFixed(2)} USD of `;
        }
        if (fixedUsd) {
            return `${Number(fiatValue).toFixed(2)} USD of ${token} = ${Number(amount).toFixed(
                5,
            )} `;
        }
        return amount;
    };

    return (
        <div style={open ? styles : {}}>
            <Box className="body">
                <Box>
                    <NameAddress
                        name={item?.name ? item.name : "Unnamed Recipient"}
                        address={item.address}
                        ens={""}
                    ></NameAddress>
                </Box>
                <Box>
                    <TokenSummaryPayroll disbursment={item.disbursement} fromSummary={true} />
                </Box>
                <IconButton onClick={handleClick}>
                    {!open && <KeyboardArrowDownIcon />}
                    {open && <KeyboardArrowUpIcon />}
                </IconButton>
            </Box>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin="8px auto" padding={"0 20px"} width="100%">
                    {item?.disbursement.map(
                        ({ fixedUsd, token, amount, fiatValue, logoUri }, index) => (
                            <CollapseBodyWrapper key={index} isLastIndex={index === totalCount - 1}>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: "2px",
                                        marginLeft: "5px",
                                    }}
                                >
                                    <Typography fontSize={"12px"} style={{ color: "#636B81" }}>
                                        {getValue(fixedUsd, fiatValue, token, amount)}
                                        {token}
                                    </Typography>
                                    <TokenImg
                                        src={logoUri}
                                        onError={({ currentTarget }) => {
                                            currentTarget.onerror = null; // prevents looping
                                            currentTarget.src = UndiscoveredCoin.src;
                                        }}
                                    />
                                </div>
                            </CollapseBodyWrapper>
                        ),
                    )}
                </Box>
            </Collapse>
        </div>
    );
};

export default CollapseReviewScreen;
