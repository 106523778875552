import { IconButton, Tooltip } from "@mui/material";
import NorthEastIcon from "@mui/icons-material/NorthEast";
import { Row, ProfileWrapper } from "../styles";
import TokenSummaryPayroll from "src/components/TokenSummaryPayroll";
import { calculateDueDate } from "src/helpers/Payroll";

const MIN_DIFF = 3;
export const NormalRow = ({ row, index, showReviewPage }) => {
    const { name, payoutDetails: disbursement, payrollId } = row;
    const uniqueRecipient = new Set(disbursement.map(({ recipient }) => recipient?.address)).size;

    const getNameColumnWithDue = ({ lastTransaction, scheduleType, scheduleValue, startDate }) => {
        let showDue = false;
        let dueIn = null;
        let nextDue = "";

        if (scheduleType !== "none") {
            const { _showDue, _dueIn, _nextDue } = calculateDueDate({
                lastTransaction,
                scheduleType,
                scheduleValue,
                startDate,
            });

            (showDue = _showDue), (dueIn = _dueIn), (nextDue = _nextDue);
        }
        if (showDue) {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "flex-start",
                        alignItems: "flex-start",
                    }}
                >
                    <div className="name">{name}</div>

                    {dueIn < 0 && <div className="overdue">OverDue by {-dueIn} day(s)</div>}
                    {dueIn >= 0 &&
                        (dueIn === 0 ? (
                            <div className="due-in">Due Today</div>
                        ) : (
                            <div className="due-in">
                                {dueIn === 1 ? `Due Tomorrow` : `Due in ${dueIn} days`}
                            </div>
                        ))}
                </div>
            );
        } else {
            return (
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "flex-start",
                        alignItems: "flex-start",
                    }}
                >
                    <div className="name">{name}</div>
                    <div className="due-in" style={{ color: "#9998a4" }}>
                        {nextDue}
                    </div>
                </div>
            );
        }
    };

    return (
        <Row className="normal-row" key={index}>
            {getNameColumnWithDue(row)}
            <div>
                <ProfileWrapper>
                    <span>{disbursement?.length}</span>
                    <span>{uniqueRecipient} Recipient(s)</span>
                </ProfileWrapper>
            </div>
            <div>
                <TokenSummaryPayroll disbursment={disbursement} />
            </div>
            <div className="action">
                <Tooltip title="Send Fund">
                    <IconButton
                        aria-label="send money"
                        size="large"
                        onClick={() => showReviewPage(payrollId, name, disbursement)}
                        disabled={!uniqueRecipient || uniqueRecipient == 0}
                        color="secondary"
                    >
                        <NorthEastIcon
                            color="primary"
                            style={{
                                color: (!uniqueRecipient || uniqueRecipient == 0) && "#9998A4",
                            }}
                        />
                    </IconButton>
                </Tooltip>
            </div>
        </Row>
    );
};
