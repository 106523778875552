import { utils } from "ethers";
import addresses from "src/constants/addresses";
import { getAmountInWei } from "./tx-helper";
import { createMetaTx } from "src/queries/Transaction/api";
import * as Sentry from "@sentry/nextjs";
import { nativeTokenSymbol } from "src/queries/constants";
import { getAddress } from "ethers/lib/utils";
import Decimal from "decimal.js-light";
import { getNextPendingNonce } from "./tx-helpers";
import SafeApiKit from "@safe-global/api-kit";
import Safe from "@safe-global/protocol-kit";
import { MetaTransactionData } from "@safe-global/safe-core-sdk-types";
import { generatePreValidatedSignature } from "@safe-global/protocol-kit/dist/src/utils";

const { ZERO_ADDRESS } = addresses;

const gasPrice = 0; // If 0, then no refund to relayer
const gasToken = ZERO_ADDRESS;
const baseGasEstimate = 0;
const executor = ZERO_ADDRESS;
export const getERC20Contract = (contractAddress, customToken) => {
    // console.log(customToken);
    if (contractAddress && customToken) {
        return customToken.attach(contractAddress);
    }
    return customToken;
};

//quick transfer
export default async function createTransaction(
    safeService: SafeApiKit,
    safeSdk: Safe,
    transactions: any[],
    safeAddress: string,
    customToken: any,
    multiSend: any,
    library: any,
    account: string,
    enqueueSnackbar,
    setIsTxStarted,
    closeModal,
    isMetaTxEnabled = false,
    isMetaTxLimitAllowed = false,
    setOpenModal,
    setTxStatus,
    proxyContract,
    isDelegateAccess = false,
    isDelegateCanCreateTx = false,
    isWalletEOA = false,
    gnosisSafeVersion = undefined,
    networkId = 1,
    isHardwareWallet = false,
    getGasEstimateTransactionExecution = undefined,
    ethAdapter,
    isGasLowEnough = true, // setting this to true by default to avoid breaking the existing functionality
): Promise<{
    status: boolean;
    data: {
        message?: string;
        txHash?: string;
        nonce?: number;
        status?: string;
    };
}> {
    setIsTxStarted(true);
    setOpenModal(true);
    // console.log(transactions, "from tx");
    try {
        // enqueueSnackbar(`Transfer started`, {
        //     variant: "success",
        //     persist: true,
        // });
        const { saveToStorage } = await import("src/helpers/utils/storage/index");
        const { formatUnits, parseEther } = utils;

        const setTransactions: MetaTransactionData[] | MetaTransactionData = await Promise.all(
            transactions.map(async tx => {
                if (tx.selectedToken === nativeTokenSymbol) {
                    return {
                        to: getAddress(tx.walletAddress),
                        value: formatUnits(
                            parseEther(`${new Decimal(tx.tokenValue).toFixed(18)}`),
                            "wei",
                        ) as string,
                        data: "0x",
                        operation: 0,
                    };
                } else {
                    const erc20 = getERC20Contract(tx.selectedToken, customToken);
                    console.log({ erc20, selectedToken: tx.selectedToken, customToken });

                    if (!erc20) {
                        throw new Error("ERC20 token undefined");
                    }
                    let decimals = await erc20.decimals();
                    return {
                        to: tx.selectedToken as string,
                        value: "0",
                        operation: 0,
                        data: erc20.interface.encodeFunctionData("transfer", [
                            getAddress(tx.walletAddress),
                            getAmountInWei(
                                `${new Decimal(tx.tokenValue).toFixed(decimals)}`,
                                decimals,
                            ),
                        ]) as string,
                    };
                }
            }),
        );

        // Use Custom calculated Nonce
        const nonce = await getNextPendingNonce(safeAddress, safeSdk);
        // const nonce = await safeService.getNextNonce(safeAddress);

        // console.log(gasLimit, "from gasLimit");
        // return;
        const options = {
            // safeTxGas, // Optional
            // baseGas, // Optional
            // gasPrice, // Optional
            // gasToken, // Optional
            // refundReceiver, // Optional
            nonce,

            // Optional
        };

        // console.log(setTransactions, nonce, safeSdk, "Transaction Creation");

        const threshold = await safeSdk.getThreshold();

        // const safeTransaction = await safeSdk.createTransaction(setTransactions, options);
        const safeTransaction = await safeSdk.createTransaction({
            safeTransactionData: setTransactions,
            options,
        });

        const txHash = await safeSdk.getTransactionHash(safeTransaction);

        if (isDelegateAccess && isDelegateCanCreateTx) {
            try {
                // Gnosis only allows delegates to sign the hash
                const signature = await safeSdk.signTransactionHash(txHash);
                let txResponse = await safeService.proposeTransaction({
                    safeAddress,
                    safeTransactionData: {
                        ...safeTransaction.data,
                    },
                    safeTxHash: txHash,
                    senderAddress: account,
                    senderSignature: signature.data,
                    origin: "Parcel V2",
                });

                setIsTxStarted(false);

                return {
                    status: true,
                    data: {
                        txHash,
                        nonce,
                        status: "Pending",
                    },
                };

                return {
                    status: true,
                    data: {
                        nonce,
                        status: "Pending",
                    },
                };
            } catch (e) {
                setIsTxStarted(false);
                if (
                    e &&
                    (e === 4001 ||
                        e.message === "wallet_rejected_transaction" ||
                        e.code === "ACTION_REJECTED")
                ) {
                    return {
                        status: false,
                        data: {
                            message: `Transaction Declined`,
                        },
                    };
                } else if (e && e.message.includes("is not an owner or delegate")) {
                    console.error(e);
                    Sentry.captureException(e);
                    return {
                        status: false,
                        data: {
                            message: `You do not have permissions to take this action. Please contact your safe owner.`,
                        },
                    };
                } else {
                    console.error(e);
                    Sentry.captureException(e);
                    return {
                        status: false,
                        data: {
                            message: `Creation Failed, Please Try Again.`,
                        },
                    };
                }
            }
        }
        if (threshold && threshold == 1) {
            // console.log(threshold, txHash, "from threshold");
            // await safeSdk.signTransaction(safeTransaction);
            setTxStatus({
                status: null,
                data: {
                    message: `Please Check Your Wallet To Proceed`,
                },
            });
            //saveToStorage("LAST_CREATED_TX_HASH", txHash);
            // const ethLibAdapter = new EthersAdapter({
            //     ethers,
            //     signer: library.getSigner(account),
            // });

            // const dataHash = encodeMultiSendCallData(setTransactions, ethLibAdapter, multiSend);
            // // console.log(multiSend, dataHash, "from contract check");
            // let txsignatureBytes = "0x";
            // // for (let i = 0; i < transaction.confirmations.length; i++) {
            // //     signatureBytes += transaction.confirmations[i].signature.replace("0x", "");
            // // }
            // const autoApprovedSignature = ethLibAdapter.abiEncodePacked(
            //     { type: "uint256", value: account }, // r
            //     { type: "uint256", value: 0 }, // s
            //     { type: "uint8", value: 1 }, // v
            // );

            // const tx = await proxyContract.estimateGas.execTransaction(
            //     MULTISEND_ADDRESS,
            //     valueWei,
            //     dataHash,
            //     operation,
            //     txGasEstimate,
            //     baseGasEstimate,
            //     gasPrice,
            //     gasToken,
            //     executor,
            //     autoApprovedSignature,
            // );
            // console.log(tx, autoApprovedSignature);
            // console.log(safeTransaction, "from tx");
            // const { safeTxGas, baseGas } = await getGasEstimate(
            //     safeAddress,
            //     {
            //         safe: safeAddress,
            //         to: safeTransaction.data.to,
            //         value: safeTransaction.data.value,
            //         data: safeTransaction.data.data,
            //         operation: safeTransaction.data.operation,
            //         gasToken: safeTransaction.data.gasToken,
            //     },
            //     0,
            // );

            await library.getFeeData();
            const preValidateSignature = generatePreValidatedSignature(account);
            const gasEstimationResult = await getGasEstimateTransactionExecution(
                safeTransaction?.data,
                preValidateSignature?.data,
            );
            const gasLimit = gasEstimationResult + 21000;
            console.log("gasLimit from contract", gasLimit);

            if (isMetaTxEnabled && isMetaTxLimitAllowed && isGasLowEnough) {
                await safeSdk.signTransaction(safeTransaction);
                // await txApprove.transactionResponse?.wait();
                const approvedSign = safeTransaction.signatures.get(account.toLowerCase()).data;
                setTxStatus({
                    status: null,
                    data: {
                        message: `Please Check Your Wallet To Proceed.`,
                    },
                });
                const {
                    data: { to, data, value, operation, safeTxGas: safeTxGasSdk },
                } = safeTransaction;

                const txHash = await createMetaTx({
                    from: safeAddress,
                    to: safeAddress,
                    params: [
                        to,
                        value,
                        data,
                        operation,
                        safeTxGasSdk,
                        baseGasEstimate,
                        gasPrice,
                        gasToken,
                        executor,
                        approvedSign,
                    ],
                    gasLimit,
                });
                // console.log(txHash, "from Meta txs");
                setTxStatus({
                    status: null,
                    data: {
                        message: `Creating Transaction`,
                        hash: txHash,
                    },
                });
                await library.waitForTransaction(txHash);

                saveToStorage("OPEN_TX", "1");
                saveToStorage("LAST_NOUNCE", nonce);
                saveToStorage("LAST_TX_HASH", txHash);
                setIsTxStarted(false);

                setTxStatus({
                    status: true,
                    data: {
                        hash: txHash,
                        txHash,
                        nonce,
                        status: "Success",
                    },
                });
                return {
                    status: true,
                    data: {
                        txHash,
                        nonce,
                        status: "Success",
                    },
                };
            } else {
                const executed = await safeSdk.executeTransaction(safeTransaction, {
                    gasLimit: gasLimit,
                });
                setTxStatus({
                    status: null,
                    data: {
                        message: `Creating Transaction`,
                        hash: txHash,
                    },
                });
                await executed.transactionResponse?.wait();
                // console.log(executed, txHash, "sucess");
                setTxStatus({
                    status: true,
                    data: {
                        hash: executed.hash,
                        txHash: executed.hash,
                        nonce,
                        status: "Success",
                        message: "Transaction Created",
                    },
                });
                saveToStorage("OPEN_TX", "1");
                saveToStorage("LAST_NOUNCE", nonce);
                saveToStorage("LAST_TX_HASH", executed.hash);
                setIsTxStarted(false);
                // enqueueSnackbar(`Transaction submitted successfully`, {
                //     variant: "success",
                // });

                return {
                    status: true,
                    data: {
                        txHash: executed.hash,
                        nonce,
                        status: "Success",
                    },
                };
            }

            // const isTransactionExecutable = (safeThreshold: number, transaction: SafeMultisigTransactionResponse) => {
            //     return transaction.confirmations.length >= safeThreshold
            //   }
            // console.log(safeTransaction, txHash, executed);
        } else {
            if (threshold && threshold > 1) {
                // setIsTxStarted(false);
                setTxStatus({
                    status: null,
                    data: {
                        message: `Please Check Your Wallet To Proceed`,
                    },
                });

                // const txResponse = await safeSdk.approveTransactionHash(txHash);
                // await txResponse.transactionResponse?.wait();
                if (isWalletEOA) {
                    try {
                        let txResponse = await safeService.proposeTransaction({
                            safeAddress,
                            safeTransactionData: {
                                ...safeTransaction.data,
                            },
                            safeTxHash: txHash,
                            senderAddress: account,
                            origin: "Parcel V2",
                            senderSignature: null,
                        });
                    } catch (error) {
                        Sentry.captureException(error);
                        return {
                            status: false,
                            data: {
                                message: `Creation Failed, Please Try Again.`,
                            },
                        };
                    }
                } else {
                    try {
                        const signature = await safeSdk.signTransaction(safeTransaction);

                        let txResponse = await safeService.proposeTransaction({
                            safeAddress,
                            safeTransactionData: {
                                ...safeTransaction.data,
                            },
                            safeTxHash: txHash,
                            senderAddress: account,
                            senderSignature: signature.signatures.get(account.toLowerCase()).data,
                        });
                    } catch (error) {
                        console.error(error);
                        setIsTxStarted(false);
                        if (error && (error.code === 4001 || error.code === "ACTION_REJECTED")) {
                            return {
                                status: false,
                                data: {
                                    message: `Transaction Declined`,
                                },
                            };
                        }

                        Sentry.captureException(error);
                        return {
                            status: false,
                            data: {
                                message: `Creation Failed, Please Try Again.`,
                            },
                        };
                    }
                }

                if (isWalletEOA) {
                    setTxStatus({
                        status: true,
                        data: {
                            hash: txHash,
                            txHash,
                            nonce,
                            status: "Pending",
                            message:
                                "Transaction Created successfully. Since you are signed as a Nested Multisig, additional Approval is required in the next step.",
                        },
                    });
                } else {
                    setTxStatus({
                        status: true,
                        data: {
                            hash: txHash,
                            txHash,
                            nonce,
                            status: "Pending",
                            message: "Transaction Created",
                        },
                    });
                }

                // console.log("Multi sig created", txResponse);
                saveToStorage("LAST_NOUNCE", nonce);
                saveToStorage("LAST_TX_HASH", txHash);
                saveToStorage("OPEN_TX", "1");
                setIsTxStarted(false);
                // enqueueSnackbar(`Transaction submitted successfully`, {
                //     variant: "success",
                // });

                if (isWalletEOA) {
                    return {
                        status: true,
                        data: {
                            txHash,
                            nonce,
                            status: "Pending",
                            message:
                                "Transaction Created successfully. Since you are signed as a Nested Multisig, additional Approval is required in the next step.",
                        },
                    };
                } else {
                    return {
                        status: true,
                        data: {
                            txHash,
                            nonce,
                            status: "Pending",
                        },
                    };
                }
            } else {
                setTxStatus({
                    status: false,
                    data: {
                        message: `Creation Failed, Please Try Again.`,
                    },
                });
                throw new Error("Error in threshold");
            }

            // enqueueSnackbar("Not supporting ", { variant: "error" });
        }

        // const safeTxHash = await safeSdk.getTransactionHash(safeTransaction);
        // const tx = await safeService.getTransaction(safeTxHash);

        // console.log(tx, safeTxHash);
    } catch (error) {
        setIsTxStarted(false);
        console.error(error);
        // enqueueSnackbar("Transfer Failed", { variant: "error" });
        if (error && (error.code === 4001 || error.code === "ACTION_REJECTED")) {
            return {
                status: false,
                data: {
                    message: `Transaction Declined`,
                },
            };
        } else {
            Sentry.captureException(error);
            return {
                status: false,
                data: {
                    message: `Creation Failed, Please Try Again.`,
                },
            };
        }
    }
}
