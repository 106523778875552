import { baseURLToSimulate } from "src/queries/constants";
export async function simulateAPI(
    networkId,
    safeAddress,
    from,
    input,
    gas,
    gas_price,
    save_if_fails,
    save,
    simulation_type,
) {
    return fetch(baseURLToSimulate, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
            networkId,
            safeAddress,
            from,
            input,
            gas,
            gas_price,
            save_if_fails,
            save,
            simulation_type,
        }),
    });
}

export async function getSimulation(id) {
    return fetch(`${baseURLToSimulate}/${id}`, {
        method: "POST",
        headers: { "content-type": "application/json" },
    });
}
