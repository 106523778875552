import IconButton from "@mui/material/IconButton";
import SearchIcon from "assets/search.svg";
import debounce from "lodash.debounce";
import CircularProgress from "@mui/material/CircularProgress";
import { AccessibilityText } from "src/components/SearchBar/styles";
import { MassPayoutContext } from "src/contexts/MassPayoutContext";
import TransferContext from "src/contexts/TransferContext";
import { InputBase, Paper } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import Image from "next/image";

type SearchBarProps = {
    style: any;
};

export default function SearchBar({ style }: SearchBarProps) {
    const massInputBox = useRef(null);
    const [query, setQuery] = useState("");
    const { massPayoutStep, transferType }: any = useContext(TransferContext);
    const {
        selectedTags,
        setIsCollapsed,
        setSearchString,
        isCollapsed,
        searchString,
        rawData,
    }: any = useContext(MassPayoutContext);

    const handleSearch = () => {
        setSearchString(query);
    };

    useEffect(() => {
        if (searchString.length === 0) {
            setQuery("");
        }
    }, [searchString]);

    useEffect(() => {
        debouncedHandler();
    }, [query]);
    const debouncedHandler = useMemo(() => debounce(handleSearch, 1000), [query]);

    useEffect(() => {
        document.addEventListener("keydown", (e: any) => {
            if (e.key === "f" && (e.ctrlKey || e.metaKey)) {
                e.preventDefault();
                const input = massInputBox?.current?.childNodes[0];
                input && input.focus() ? input.focus() : null;
            }
            if (e.key === "Escape") {
                const input = massInputBox?.current?.childNodes[0];
                input && input.blur() ? input.blur() : null;
            }
        });

        return () => {
            document.removeEventListener("keydown", () => {});
        };
    }, []);
    return (
        <Paper
            component="form"
            sx={{
                p: "10px",
                display: "flex",
                alignItems: "center",
                boxShadow: "none",
                marginLeft: massPayoutStep === 1 ? "20px" : "0px",
                border: "1px solid #ededed",
                transition: "all 3s",
                height: "44px",
                flex: "100%",
                ...style,
            }}
            style={{ background: "#F5F6F7" }}
        >
            <IconButton type="button" sx={{ p: "4px" }} aria-label="search">
                <Image src={SearchIcon} />
            </IconButton>
            <InputBase
                sx={{ ml: 1, flex: 1, fontSize: "14px" }}
                placeholder="Search By Name, Address/ENS"
                inputProps={{ "aria-label": "Search By Name, Address/ENS" }}
                onFocus={() => setIsCollapsed(true)}
                onBlur={() => setIsCollapsed(false)}
                ref={massInputBox}
                value={query}
                onChange={e => setQuery(e.target.value)}
                onSubmit={e => e.preventDefault()}
                disabled={selectedTags.length === 0}
            />
            {searchString.length > 0 && rawData && (rawData.isLoading || rawData.isFetching) && (
                <CircularProgress size="15px" sx={{ mr: "10px" }} />
            )}
            {searchString.length > 0 ? (
                <IconButton
                    onClick={() => {
                        setQuery("");
                    }}
                >
                    <HighlightOffIcon sx={{ mr: "5px", fill: "#25274F", color: "title.sub" }} />
                </IconButton>
            ) : (
                <></>
            )}
            <AccessibilityText>{isCollapsed ? "ESC" : "Cmd + F"}</AccessibilityText>
        </Paper>
    );
}
