import metmask from "public/svg/metamask.svg";
import fortmatic from "public/svg/fortmatic.svg";
import trezor from "public/svg/trezor.svg";
import ledger from "public/svg/ledger.svg";
import walletconnect from "public/svg/walletconnect.svg";
import coinbase from "public/svg/coinbase.svg";
export const selectWalletIcon = {
    MetaMask: metmask,
    BrowserWallet: metmask,
    Ledger: ledger,
    Trezor: trezor,
    Fortmatic: fortmatic,
    WalletConnect: walletconnect,
    "Coinbase Wallet": coinbase,
};
