import styled from "@emotion/styled";

export const DrawerBodyWrapper: any = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    .heading {
        font-size: 12px;
        color: #636b81;
        font-weight: 400;
        line-height: 12px;
        padding-bottom: 20px;
        padding-left: 28px;
    }
`;
export const ImageWrapper: any = styled.div`
    padding: 14px 0px;
    padding-right: 8px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    box-sizing: border-box;

    :hover {
        background-color: #eef2fe;
    }
    cursor: pointer;
    .flex-column {
        display: flex;
        flex-direction: column;
    }
    .safe-name {
        font-size: 14px;

        font-weight: 600;
        line-height: 14px;
        color: #232427;
    }

    .subtitle {
        padding-top: 4px;
        font-size: 13px;
        font-weight: 400;
        line-height: 13px;

        color: #8b91a1;
    }

    .divider-dot {
        font-size: 5px;
        margin: 0 7px;
    }
    .done-icon {
        color: ${({ theme }: any) => theme.palette.primary.main};
    }
`;

export const SafeListWrapper = styled.div`
    max-height: calc(100vh - 200px);
    overflow-y: scroll;
    padding: 0px 24px;

    > div {
        border-bottom: 1px solid #eaeaea;
        :last-child {
            border-bottom: none;
        }
    }
`;

export const FooterActionWrapper = styled.div`
    padding: 13px 21px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0px;

    > div:nth-of-type(1) {
        margin-bottom: 14px;
    }

    .flex-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 14px;
        cursor: pointer;

        :hover {
            background-color: #eef2fe;
        }

        .icon {
            font-size: 20px;
        }
    }
`;
