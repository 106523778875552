import { request, gql } from "graphql-request";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "pages/_app";
import {
    CREATE_NEW_BUDGET_REQUEST,
    baseURI,
    FETCH_ACTIVE_BUDGETS_BY_SQUADS,
    FETCH_PENDING_BUDGETS_BY_SQUADS,
    FETCH_HISTORY_BUDGETS_BY_SQUADS,
    CREATE_NEW_ACTIVITY,
    UPDATE_BUDGET_STATUS,
    FETCH_BUDGET_BY_ID,
    GET_UPCOMING_MILESTONES_BYPARENT,
    GET_BUDGET_ACTIVITY,
    UPDATE_BUDGET_META,
} from "src/queries/constants";
import { graphQLClient } from "../graphqlClient";

export function useAddNewBudgetRequest() {
    return useMutation(
        async ({ safeAddress, name, type, description, milestones, seasonId }: any) => {
            const { addBudget } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Mutation($budgetParams: BudgetParams) {
                        addBudget(budgetParams: $budgetParams) {
                            budgetId
                            name
                            type
                            description
                            milestones {
                                step
                                description
                                kpi
                                status
                                tokenAddress
                                token
                                amount
                                fiatValue
                                isFixedUSD
                                budgetId
                                transactionId
                            }
                            SquadSafe {
                                safeAddress
                            }
                        }
                    }
                `,
                {
                    budgetParams: {
                        name: name,
                        type: type,
                        description: description,
                        seasonId: seasonId,
                        milestones,
                    },
                },
            );

            return addBudget;
        },
        {
            mutationKey: CREATE_NEW_BUDGET_REQUEST,
        },
    );
}

export function useFetchActiveBudgetsBySquads(safeAddress, options = {}) {
    return useQuery(
        [FETCH_ACTIVE_BUDGETS_BY_SQUADS],
        async () => {
            const { activeBudgets } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    query Budgets {
                        activeBudgets {
                            budgetId
                            name
                            type
                            status
                            description
                            metadata {
                                snapshotProposalId
                                acceptedChoice
                                rejectedChoice
                            }
                            nextMilestone {
                                step
                                dueDate
                            }
                            milestones {
                                step
                                description
                                dueDate
                                kpi
                                status
                                tokenAddress
                                token
                                amount
                                fiatValue
                                isFixedUSD
                                budgetId
                                transactionId
                            }
                            SquadSafe {
                                safeAddress
                            }
                        }
                    }
                `,
            );

            return activeBudgets;
        },
        {
            ...options,
        },
    );
}

export function useFetchPendingBudgetsBySquads(safeAddress) {
    return useQuery([FETCH_PENDING_BUDGETS_BY_SQUADS], async () => {
        const { pendingBudgets } = await graphQLClient(baseURI, safeAddress).request(
            gql`
                query Budgets {
                    pendingBudgets {
                        budgetId
                        name
                        type
                        status
                        description
                        createdAt
                        metadata {
                            snapshotProposalId
                            acceptedChoice
                            rejectedChoice
                        }
                        milestones {
                            step
                            description
                            dueDate
                            kpi
                            status
                            tokenAddress
                            token
                            amount
                            fiatValue
                            isFixedUSD
                            budgetId
                            transactionId
                        }
                        SquadSafe {
                            safeAddress
                        }
                    }
                }
            `,
        );

        return pendingBudgets;
    });
}

export function useFetchHistoryBudgetsBySquads(safeAddress, options = {}) {
    return useQuery(
        [FETCH_HISTORY_BUDGETS_BY_SQUADS],
        async () => {
            const { historyBudgets } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    query Budgets {
                        historyBudgets {
                            budgetId
                            name
                            type
                            status
                            description
                            metadata {
                                snapshotProposalId
                                acceptedChoice
                                rejectedChoice
                            }
                            milestones {
                                step
                                description
                                dueDate
                                kpi
                                status
                                tokenAddress
                                token
                                amount
                                fiatValue
                                isFixedUSD
                                budgetId
                                transactionId
                            }
                            seasonDetails {
                                name
                                seasonId
                            }
                            SquadSafe {
                                safeAddress
                            }
                        }
                    }
                `,
            );

            return historyBudgets;
        },
        {
            ...options,
        },
    );
}

export function useFetchBudgetById(budgetId, safeAddress) {
    return useQuery([FETCH_BUDGET_BY_ID, budgetId], async () => {
        const { budget } = await graphQLClient(baseURI, safeAddress).request(
            gql`
                query Budget($budgetId: String) {
                    budget(budgetId: $budgetId) {
                        budgetId
                        name
                        type
                        status
                        description
                        metadata {
                            snapshotProposalId
                            acceptedChoice
                            rejectedChoice
                        }
                        seasonDetails {
                            name
                        }
                        activities {
                            activityId
                            referenceId
                            referenceType
                            description
                            createdBy
                            createdAt
                            isViewedByParent
                            isViewedByChild
                            activityType
                            metadata {
                                url
                                title
                                transactionHash
                            }
                        }
                        milestones {
                            id
                            step
                            description
                            dueDate
                            kpi
                            status
                            tokenAddress
                            token
                            amount
                            fiatValue
                            isFixedUSD
                            budgetId
                            transactionId
                            squad {
                                safeAddress
                                safeName
                            }
                            linkedTransaction {
                                transactionId
                                description
                                paymentType
                                nonce
                                status
                                transactionHash
                                transactionFees
                                createdBy
                                transactionDisbursmentDetails {
                                    address
                                    amount
                                    token
                                    fiatValue
                                    fiatCurrency
                                    tokenValue
                                    tagName
                                    recipient {
                                        address
                                        name
                                        ens
                                    }
                                    disbursementType
                                }
                                safe {
                                    safeName
                                    safeAddress
                                }
                                networkId
                            }
                            activities {
                                activityId
                                referenceId
                                referenceType
                                description
                                createdBy
                                createdAt
                                metadata {
                                    url
                                    title
                                    transactionHash
                                }
                                isViewedByParent
                                isViewedByChild
                                activityType
                            }
                        }
                        SquadSafe {
                            safeName
                            safeAddress
                        }
                        link
                        nextMilestone {
                            id
                            step
                            description
                            dueDate
                            kpi
                            status
                            tokenAddress
                            token
                            amount
                            fiatValue
                            isFixedUSD
                            budgetId
                            transactionId
                        }
                        nextMilestoneStep {
                            id
                            step
                        }
                        createdAt
                    }
                }
            `,
            { budgetId },
        );

        return budget;
    });
}

export function useAddNewActivity(options = {}) {
    return useMutation(
        async ({
            safeAddress,
            referenceId,
            referenceType,
            activityType,
            description,
            createdBy,
            metadata,
            isViewedByParent = false,
            isViewedByChild = false,
        }: any) => {
            const { addActivity } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation Activity($activityParams: ActivityParams) {
                        addActivity(activityParams: $activityParams) {
                            activityId
                            createdAt
                            createdBy
                            description
                            isViewedByParent
                            isViewedByChild
                            metadata {
                                url
                                title
                            }
                            referenceId
                            activityType
                            referenceType
                        }
                    }
                `,
                {
                    activityParams: {
                        referenceId,
                        referenceType,
                        description,
                        activityType,
                        createdBy,
                        metadata,
                        isViewedByParent,
                        isViewedByChild,
                    },
                },
            );

            return addActivity;
        },
        {
            mutationKey: CREATE_NEW_ACTIVITY,
            ...options,
        },
    );
}

export function useUpdateBudgetStatus() {
    return useMutation(
        async ({ safeAddress, budgetId, newStatus, comment }: any) => {
            const { updateBudgetStatus } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation UpdateBudgetStatus(
                        $budgetId: String
                        $newStatus: String
                        $comment: String
                    ) {
                        updateBudgetStatus(
                            budgetId: $budgetId
                            newStatus: $newStatus
                            comment: $comment
                        ) {
                            budgetId
                            name
                            type
                            status
                            description
                            milestones {
                                step
                                description
                                dueDate
                                kpi
                                status
                                tokenAddress
                                token
                                amount
                                fiatValue
                                isFixedUSD
                                budgetId
                                transactionId
                            }
                            SquadSafe {
                                safeAddress
                                safeName
                            }
                        }
                    }
                `,
                {
                    budgetId,
                    newStatus,
                    comment,
                },
            );

            return updateBudgetStatus;
        },
        {
            mutationKey: UPDATE_BUDGET_STATUS,
        },
    );
}

export function useUpdateBudgetMeta() {
    return useMutation(
        async ({ safeAddress, budgetId, metadata, comment }: any) => {
            const { updateBudgetMeta } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    mutation UpdateBudgetMeta(
                        $budgetId: String
                        $metadata: BudgetMetaParams
                        $comment: String
                    ) {
                        updateBudgetMeta(
                            budgetId: $budgetId
                            metadata: $metadata
                            comment: $comment
                        ) {
                            budgetId
                            name
                            type
                            status
                            description
                            milestones {
                                step
                                description
                                dueDate
                                kpi
                                status
                                tokenAddress
                                token
                                amount
                                fiatValue
                                isFixedUSD
                                budgetId
                                transactionId
                            }
                            SquadSafe {
                                safeAddress
                                safeName
                            }
                        }
                    }
                `,
                {
                    budgetId,
                    metadata,
                    comment,
                },
            );

            return updateBudgetMeta;
        },
        {
            mutationKey: UPDATE_BUDGET_META,
        },
    );
}

export function useGetUpcomingMilestonesByParent(safeAddress) {
    return useQuery(GET_UPCOMING_MILESTONES_BYPARENT, async () => {
        const { upcomingMilstones } = await graphQLClient(baseURI, safeAddress).request(
            gql`
                query Query {
                    upcomingMilstones {
                        status
                        step
                        budgetId
                        budget {
                            name
                            budgetId
                        }
                        dueDate
                        transactionId
                        description
                        kpi
                        fiatValue
                        isFixedUSD
                        id
                        tokenAddress
                        token
                        amount
                        squad {
                            safeAddress
                            safeName
                        }
                    }
                }
            `,
        );
        return upcomingMilstones;
    });
}

export function useGetBudgetActivity(budgetId, safeAddress) {
    return useQuery(
        GET_BUDGET_ACTIVITY,
        async () => {
            const { getBudgetActivity } = await graphQLClient(baseURI, safeAddress).request(
                gql`
                    query Query($budgetId: String) {
                        getBudgetActivity(budgetId: $budgetId) {
                            activityId
                            activityType
                            createdAt
                            createdBy
                            description
                            metadata {
                                url
                                title
                                transactionHash
                            }
                            referenceId
                            referenceType
                        }
                    }
                `,
                {
                    budgetId,
                },
            );
            return getBudgetActivity;
        },
        {
            enabled: !!budgetId,
        },
    );
}
