import styled from "@emotion/styled";
import { Popover } from "@mui/material";
import TableContainer from "@mui/material/TableContainer";
import { Box } from "@mui/system";

export const TableWrapper: any = styled(TableContainer)`
    margin-top: 21px;
    padding: 0px 0px;
    border: 1px solid #ededed;
    box-shadow: none;
    height: 72vh;
    overflow: hidden;
`;

export const TableMain = styled(Box)`
    width: 100%;
`;

export const TableBody = styled(Box)`
    height: 81%;
    overflow-y: auto;
`;

export const Row = styled(Box)`
    display: flex;
    padding: 17px 10px;
    margin: 4px 40px;
    border-top: 1px solid #eaeaea;
    cursor: pointer;

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    > div:nth-of-type(1) {
        width: 6%;
    }
    > div:nth-of-type(2) {
        min-width: 28%;
        justify-content: flex-start;
        div {
            .MuiSkeleton-root {
                margin: 8px 0px;
            }
            /* margin-left: 10px; */
        }
    }
    > div:nth-of-type(3) {
        width: 0%;
    }
    > div:nth-of-type(4) {
        min-width: 37%;
    }
    > div:nth-of-type(5) {
        width: 19%;
        text-align: right;
        justify-content: flex-end;
    }

    &.normal-row {
        padding: 8px 10px;
        cursor: auto;
        .MuiCheckbox-root > input:not(:checked) .MuiCheckbox-root {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 14px;
            /* identical to box height */

            letter-spacing: 1px;
            text-transform: uppercase;

            color: ${({ theme }: any) => theme.palette.title.light};
        }

        > div:nth-of-type(3) {
            width: 9%;
        }
        > div:nth-of-type(4) {
            width: 48%;
        }
        > div:nth-of-type(5) {
            width: 8%;
            text-align: right;
            justify-content: flex-end;
        }
    }

    // &.normal-row:hover {
    //     background: #fafafd;
    //     border-top: none;
    //     padding: 9px 50px;
    //     margin: 4px 0px;
    //     &.normal-row + .normal-row {
    //         border-top: none;
    //     }
    // }

    &#selected {
        background: rgba(41, 98, 239, 0.08);
        border-radius: 4px;
        border: none;
        &#selected + .normal-row {
            border-top: none;
        }
    }
`;

export const ActionMenu = styled(Popover)`
    .MuiPaper-root {
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05);
        border-radius: 4px;
    }
    div.action {
        padding: 12px 8px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        cursor: pointer;
        font-size: 14px;
        line-height: 14px;
        &:hover {
            background: #f2f3f5;
        }

        .MuiSvgIcon-root {
            font-size: 17px;
        }

        color: #5e5c6c;
        span {
            margin-left: 10px;
        }

        &:nth-of-type(1) {
            border: 1px solid #f2f3f5;
            border-radius: 3px 3px 0px 0px;
        }
        &:nth-of-type(3) {
            border: 1px solid #f2f3f5;
            border-radius: 0px 0px 3px 3px;
        }
    }
`;

export const AdditionRow = styled(Box)`
    display: flex;
    padding: 12px 5px;
    margin: 4px 40px;
    background: rgba(41, 98, 239, 0.08);
    border-radius: 4px;
    border: none;
    justify-content: center;
    align-items: center;

    > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    > div:nth-of-type(1) {
        width: 20%;
    }
    > div:nth-of-type(2) {
        min-width: 30%;
        justify-content: flex-start;
        div {
            .MuiSkeleton-root {
                margin: 8px 0px;
            }
            margin-left: 10px;
        }
    }
    > div:nth-of-type(3) {
        min-width: 40%;
    }
    > div:nth-of-type(4) {
        width: 10%;
        text-align: center;
        justify-content: center;
        padding-left: 15px;
    }

    & + .normal-row {
        border-top: none;
    }
`;

export const MainHeaderWrapper = styled(Box)`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 14px 10px;
    border-top: 1px solid #ededed;

    .selectedHeader {
        font-size: 15px;
        font-weight: 600;
        line-height: 15px;
    }
    &.selected-row {
        border-top: none;
        .MuiButton-outlined {
            color: #ee365a;
            border: 1px solid #ee365a;
            span {
                color: #ee365a;
            }
            margin-right: 10px;
        }
        .MuiButton-contained {
            span {
                color: white;
            }
        }
        padding: 11px 10px;
    }

    margin: 0px 40px;
    span {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        /* identical to box height */

        letter-spacing: 1px;
        text-transform: uppercase;

        color: ${({ theme }: any) => theme.palette.title.light};
    }

    div:nth-of-type(1) {
        width: 6%;
    }
    div:nth-of-type(2) {
        width: 28%;
    }
    div:nth-of-type(3) {
        width: 9%;
    }
    div:nth-of-type(4) {
        width: 26%;
    }
    div:nth-of-type(5) {
        width: 29%;
        text-align: right;
    }

    span {
        padding: 0px;
    }

    &.adding-row {
        border-top: none;

        div:nth-of-type(1) {
            width: 0%;
        }
        div:nth-of-type(2) {
            width: 43%;
        }
        div:nth-of-type(3) {
            width: 27%;
        }
        div:nth-of-type(4) {
            width: 29%;
            text-align: right;

            button: {
                margin-right: 21px;
            }
        }
        .MuiButton-contained {
            span {
                color: white;
            }
        }
    }
`;

export const Caption = styled(Box)`
    border-top: 1px solid #eaeaea;
    padding: 4px 40px;
    width: 100%;

    div {
        display: flex;
        align-items: center;
    }

    .count {
        font-size: 14px;
        line-height: 14px;
        color: ${({ theme }: any) => theme.palette.title.light};
        padding: 15px 0;
    }

    .loadmore {
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: center;
        margin: auto;
        cursor: pointer;
        color: #5e5c6c;
    }
`;

export const EmptyTableWrapper = styled(Box)`
    height: 100%;
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    flex-direction: column;

    h2 {
        font-weight: 600;
        font-size: 24px;
        line-height: 24px;
        color: #232427;
        margin-top: 32px;
    }
    span {
        font-family: Metropolis;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 18px;

        text-align: center;

        color: #5e5c6c;
    }
    button {
        margin-top: 20px;
        padding: 10px 64px;
    }
`;
